import React, { useMemo, useState } from "react";
import DataTable from "../common/dataTable/DataTable";
import HomeDataTable from "components/common/dataTable/HomeDataTable";
import { PlayerProfileInterface } from "../../types/userTypes";
import { TABLE_AVG_VAL, TABLE_HEADER } from '../../types/homeTabelTypes';

interface IUsersTableProps {
  loading: boolean;
  containerRef?: React.RefObject<HTMLDivElement>;
  lastItemRef?: React.RefObject<HTMLDivElement>;
  playerProfiles: Array<PlayerProfileInterface>;
  sortBy: number;
  avgStats: {
    EventCountFromLast7Days: number;
    Fitness: number;
    Dynamic: number;
    DynamicPercentage: number;
    Energy: number;
    TotalDistanceFromLast7Days: number;
    AverageDistance: number;
    MaxSpeedFromLast7Days: number;
    AverageSpeed: number;
    HSR: number;
    HSRPercentage: number;
    HMLD: number;
    HMLDPercentage: number;
    MaxHeartRate: number;
    AverageHeartRateFromLast7Days: number;
  };
  searchQuery: string;
}

const HomeTable: React.FC<IUsersTableProps> = ({
  loading,
  containerRef,
  lastItemRef,
  playerProfiles,
  sortBy,
  avgStats,
  searchQuery,
}) => {

  function roundToTwo(num: number): number {
    return +(Math.round(num * 100) / 100).toFixed();
  }

  const headers: TABLE_HEADER = {
    firstCol: ['imię'],
    secendCol: ['Nazwisko'],
    thirdCol: ['Rocznik'],
    task: ['Zadań', '', ''],
    distance: ['Dystans', 'total', 'średnia'],
    speed: ['Prędkość', 'max', 'średnia'],
    HSR: ['HSR', '%', 'm'],
    Hmld: ['Hmld', '%', 'm'],
    dynamic: ['Dynamika'],
    energy: ['Energia'],
    condition: ['Kondycja'],
    pulse: ['Tętno', 'max', 'średnia'],
  }

  const tabelAvgVal: TABLE_AVG_VAL = {
    task: [`x ${roundToTwo(avgStats.EventCountFromLast7Days)}`],
    distance: [`x ${roundToTwo(avgStats.TotalDistanceFromLast7Days)} m`, `x ${roundToTwo(avgStats.AverageDistance)} m`],
    speed: [`x ${roundToTwo(avgStats.MaxSpeedFromLast7Days)} km/h`, `x ${roundToTwo(avgStats.AverageSpeed)} km/h`],
    HSR: [`x ${roundToTwo(avgStats.HSRPercentage)} %`, `x ${roundToTwo(avgStats.HSR)} m`],
    Hmld: [`x ${roundToTwo(avgStats.HMLDPercentage)} %`, `x ${roundToTwo(avgStats.HMLD)} m`],
    pulse: [`x ${roundToTwo(avgStats.MaxHeartRate)} bmp`, `${roundToTwo(avgStats.AverageHeartRateFromLast7Days)} bmp`]
  }

  const RowsName: { [key: string]: string } = {
    "Zadań": "EventCountFromLast7Days",
    "Dynamika": "Dynamic",
    "Energia": "Energy",
    "Dystans total": "TotalDistanceFromLast7Days",
    "Dystans średnia": "AverageDistance",
    "Prędkość max": "MaxSpeedFromLast7Days",
    "Prędkość średnia": "AverageSpeed",
    "HSR %": "HSRPercentage",
    "HSR m": "HSR",
    "Hmld %": "HMLDPercentage",
    "Hmld m": "HMLD",
    "Energian": "Energy",
    "Kondycja": "Fitness",
    "Tętno max": "MaxHeartRate",
    "Tętno średnia": "AverageHeartRateFromLast7Days",
  };

  const getStyledSpan = (value: number, avgValue: number) => {
    const lowerThreshold = avgValue * 0.8;
    const upperThreshold = avgValue * 1.2;

    return (
      <span
        style={{
          fontWeight: "500",
          width: value < 999 ? '100%' :
          value <= 99999 ? '85%' :
          '75%',
          color: value < 0.50 ? "white" :
            value < lowerThreshold ? "rgb(220, 33, 33)" :
              value > upperThreshold ? "#008800" :
                "white",
        }}
      >
        {roundToTwo(value) === 0 ? '-' : roundToTwo(value).toLocaleString('pl-PL', {
          maximumFractionDigits: 3,
          minimumFractionDigits: 0,
          useGrouping: true
        })}
      </span>
    );
  };

  const compare = (a: any, b: any, sortKey: string, order: "asc" | "desc") => {
    if (a[sortKey] < b[sortKey]) return order === "asc" ? -1 : 1;
    if (a[sortKey] > b[sortKey]) return order === "asc" ? 1 : -1;
    return 0;
  };

  const [sortConfig, setSortConfig] = useState<{ key: string, order: "asc" | "desc" }>({ key: "", order: "asc" });

  const filterBy = (primaryValue: string, secondaryValue?: string) => {
    let key = primaryValue;

    if (secondaryValue) {
      key = `${primaryValue} ${secondaryValue}`;
    }

    setSortConfig((prevSortConfig) => ({
      key,
      order: prevSortConfig.key === key && prevSortConfig.order === "asc" ? "desc" : "asc",
    }));

  };

  const filteredItems = useMemo(() => {
    const str = searchQuery.toLowerCase();

    const filtered = !searchQuery.length ? playerProfiles : playerProfiles.filter(player =>
      player.LastName?.toLowerCase().includes(str) || player.FirstName?.toLowerCase().includes(str)
    );

    if (!sortConfig.key) return filtered.slice().sort((a, b) => (a.LastName ?? '').localeCompare(b.LastName ?? ''));

    const sortKey = RowsName[sortConfig.key];
    return filtered.slice().sort((a, b) => compare(a, b, sortKey, sortConfig.order));
  }, [playerProfiles, searchQuery, sortConfig, sortBy]);

  const rows = filteredItems
    .map((player: any) => ({
      // data: { link: /player-profiles/detail/${player.Id} },
      cols: [
        `${player.FirstName}`,
        `${player.LastName}`,
        `${player.YearOfBirth}`,
        getStyledSpan(player.EventCountFromLast7Days, avgStats.EventCountFromLast7Days),
        [
          getStyledSpan(player.TotalDistanceFromLast7Days, avgStats.TotalDistanceFromLast7Days),
          getStyledSpan(player.AverageDistance, avgStats.AverageDistance)
        ],

        [
          getStyledSpan(player.MaxSpeedFromLast7Days, avgStats.MaxSpeedFromLast7Days
          ),
          getStyledSpan(player.AverageSpeed, avgStats.AverageSpeed
          )
        ],

        [
          getStyledSpan(player.HSRPercentage, avgStats.HSRPercentage),
          getStyledSpan(player.HSR, avgStats.HSR)
        ],

        [
          getStyledSpan(player.HMLDPercentage, avgStats.HMLDPercentage),
          getStyledSpan(player.HMLD, avgStats.HMLD)
        ],
        getStyledSpan(player.Dynamic, avgStats.Dynamic),
        getStyledSpan(player.Energy, avgStats.Energy),
        getStyledSpan(player.Fitness, avgStats.Fitness),
        [
          getStyledSpan(player.MaxHeartRate, avgStats.MaxHeartRate),
          getStyledSpan(player.AverageHeartRateFromLast7Days, avgStats.AverageHeartRateFromLast7Days)
        ]
      ],
    }));

  return (
    <div>
      <div
        className="flex"

      >
        <span
          style={{ background: 'rgba(0, 0, 0, 0.5)', width: '33.3%' }}
        >
        </span>
        <span
          className="w-full text-white p-8 text-center "
          style={{
            border: '2px solid rgba(0, 0, 0, 0.9)',
            borderRight:'transparent',
            borderLeft: '4px solid rgba(0, 0, 0, 0.9)'
            }}
        >
          Mikrocykl = 7dni
        </span>
      </div>
      <HomeDataTable
        rows={rows}
        headers={headers}
        isDataLoading={loading}
        containerRef={containerRef}
        lastItemRef={lastItemRef}
        filterBy={filterBy}
        tabelAvgVal={tabelAvgVal}
      />
    </div>
  );
};

export default HomeTable;
