import { useField } from "formik";
import { IInputProps } from "../inputTypes";
import TextInput from "./TextInput";
import TextInputLogin from "./TextInputLogin";

const TextFieldLogin: React.FC<IInputProps> = ({
  name,
  isError = true,
  ...props
}) => {
  const [field, { error, touched }] = useField(name);

  return (
    <TextInputLogin
      {...props}
      {...field}
      error={error}
      touched={touched}
      isError={isError}
    />
  );
};

export default TextFieldLogin;
