interface IActiveFilterProps {
  filter: { name: string; value: string; pl: any };
  removeFilter: () => void;
}

const ActiveFilter: React.FC<IActiveFilterProps> = ({
  filter,
  removeFilter,
}) => {
  return (
    <div
      className="p-8 shadow"
      style={{ color: "white", background: "rgba(2,2,15,0.15)" }}
    >
      <span>{filter.pl} </span>
      <span>
        <button onClick={removeFilter} className="text-red font-bold">X</button>
      </span>
    </div>
  );
};

export default ActiveFilter;
