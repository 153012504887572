export interface IPlayerEvent {
  EventId: string;
  Type: EVENT_ACTIVITY_TYPE;
  Level: EVENT_ACTIVITY_LEVEL;
  Organizer: EVENT_ORGANIZER;
  Visibility: EVENT_VISIBILITY;
  OccurenceDate: Date;
  EndDate: Date;
  Name: string;
  PostCode: string;
  Localization: string;
}

export enum EVENT_VISIBILITY {
  "Wszyscy",
  "Tylko zaproszeni",
}

export enum EVENT_ORGANIZER {
  "JustWin",
  "Trener",
  "Zawodnik",
  "Klub",
  "Reprezentacja",
  "Nieznany",
}

export enum EVENT_ACTIVITY_LEVEL {
  "Klub",
  "Reprezentacja",
  "Własny",
}

export enum EVENT_ACTIVITY_TYPE {
  "Trening",
  "Trening aerobowy",
  "Trening personalny",
  "Badania okresowe",
  "Mecz piłkarski",
  "Nieznany",
}
