import { IAdditionalFile, IFile } from 'types/fileType';
import { ReactComponent as DeleteIco } from 'assets/icons/delete.svg';
import { ReactComponent as FileIco } from 'assets/icons/filesIco.svg';

interface IDocumentActionContentProps {
  document: IFile;
  onRemoveButton: () => void;
  isDeletable?: boolean;
}

const DocumentActionContent = ({
  document,
  onRemoveButton,
  isDeletable = true,
}: IDocumentActionContentProps) => {
  return (
    <div className='flex gap-x-6 justify-center'>
      <button
        title='download'
        className='opacity-70 transition-colors duration-150 hover:text-green'>
        <a
          href={document.FilePath}
          target='_blank'
          rel='noreferrer'
          download={document.Name}
          className='flex items-center h-full w-full'>
          <FileIco />
        </a>
      </button>
      {isDeletable && (
        <button
          title='remove'
          onClick={onRemoveButton}
          className='opacity-70 transition-colors duration-150 hover:text-red'>
          <DeleteIco className='w-5' />
        </button>
      )}
    </div>
  );
};

export default DocumentActionContent;
