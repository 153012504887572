import HeaderContainer from 'components/common/Containers/HeaderContainer';
import TabContent from 'components/common/tabs/TabContent';
import React from 'react';
import DeliveryData from './DeliveryData';
import DeliveryAddress from './DeliveryAddress';
import { useOrderDetailTabsContext } from '../../hook/useOrderDetailTabsContext';

const OrderDelivery = () => {
  const { data: order, refresh } = useOrderDetailTabsContext();
  return (
    <TabContent id='delivery'>
      <div className='flex gap-4 p-24 max-w-6xl'>
        <DeliveryData delivery={order.Delivery} />
        <DeliveryAddress delivery={order.Delivery} />
      </div>
    </TabContent>
  );
};

export default OrderDelivery;
