import { ORDER_SORT_LIST } from 'types/marketpleace/order.interface';
import { enumToSelectOptions } from 'utils/baseUtils';
import { getDetailTabsByEnum } from 'utils/objectUtils';

export const getOrderSorts = enumToSelectOptions(ORDER_SORT_LIST);

export const headers = [
  'nr zamówienia',
  'klient',
  'Data zamówienia',
  'Wartość',
  'Płatność',
  'Dostawa',
  'Status',
];

export enum ORDER_DETAIL_TABS {
  products = 'Produkty',
  delivery = 'Dostawa',
  documents = 'Dokumenty',
  state = 'Historia statusów',
  messages = 'Uwagi',
  refunds = 'Zwroty',
}

export const orderDetailTabs = getDetailTabsByEnum(ORDER_DETAIL_TABS);
