import { Middleware } from 'redux';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { toast } from 'react-toastify';
import { updateNotification } from '../actions/notificationData';
import * as signalR from '@microsoft/signalr';
import notificationsServices from 'services/notificationsServices';
import jwtDecode from 'jwt-decode';

const signalRMiddleware: Middleware = store => {
  let connection: signalR.HubConnection | null = null;

  return next => async action => {
    if (action.type === 'START_SIGNALR') {
      const token = localStorage.getItem('token');

      if (token) {
        const decodedToken: any = jwtDecode(token);

        connection = new HubConnectionBuilder()
          .withUrl('https://api.academiq.pl/notifications', {
            transport: signalR.HttpTransportType.LongPolling,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .configureLogging(LogLevel.Information)
          .withAutomaticReconnect([0, 2000, 10000, 30000])
          .build();

        connection
          .start()
          .then(() => {
            console.log('SignalR connected');

            connection!.on('ConsumeNotification', async data => {
              try {
                const notification = JSON.parse(data);
                toast.info('Dostałeś nowe powiadomienie!');

                const response = await notificationsServices.getAllNotifications(decodedToken.jti, 1);

                store.dispatch(updateNotification(response.Items));

              } catch (error) {
                console.error('Failed to parse notification data:', error);
              }
            });

            connection!.onreconnecting(err => {
              console.warn('Reconnecting...', err);
            });

            connection!.onreconnected(connectionId => {
              console.log('Reconnected. Connection ID:', connectionId);
            });

            connection!.onclose(err => {
              if (err) {
                console.error('Connection closed with error:', err);
              } else {
                console.log('Connection closed');
              }
            });
          })
          .catch(err => {
            console.error('Connection failed:', err);
          });
      }
    } else if (action.type === 'STOP_SIGNALR' && connection) {
      connection.stop().then(() => {
        console.log('Connection stopped');
      }).catch(err => {
        console.error('Error stopping connection:', err);
      });
    }

    return next(action);
  };
};

export default signalRMiddleware;
