import Tabs from 'components/common/tabs/Tabs';
import TabsView from 'components/common/tabs/TabsView';
import { productDetailTabs } from '../utils/helpers';
import {
  ProductDocuments,
  ProductSupplementaryData,
  ProductAttributes,
  ProductConditions,
  ProductStatisticts,
} from './tabs';

const ProductDetailTabs = () => {
  return (
    <TabsView>
      <Tabs tabs={productDetailTabs} />
      <ProductSupplementaryData />
      <ProductDocuments />
      <ProductAttributes />
      <ProductConditions />
      <ProductStatisticts />
    </TabsView>
  );
};

export default ProductDetailTabs;
