import HeaderContainer from 'components/common/Containers/HeaderContainer';
import NewImageInput from 'components/common/inputs/imageInput/NewImageInput';
import React, { useEffect, useState } from 'react';
import logo from 'assets/images/logo.svg';
import systemServices from 'services/systemServices';
import { showServerErrors } from 'utils/errorsUtils';
import { usePrimaryDataFormContext } from '../usePrimaryDataFormContext';

const AcademyPhoto = () => {
  const { editEnable, form, setForm } = usePrimaryDataFormContext();

  const [base64, setBase64] = useState<string>('');

  const filePath = !!form.primaryData.PhotoFilePath.length
    ? form.primaryData.PhotoFilePath
    : logo;

  const onBaseChange = async () => {
    try {
      if (!base64) {
        return;
      }

      const fileUrl = await systemServices.uploadFile(base64);

      setForm(prev => ({
        ...prev,
        primaryData: { ...prev.primaryData, PhotoFilePath: fileUrl },
      }));
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  useEffect(() => {
    onBaseChange();
  }, [base64]);

  return (
    <HeaderContainer title='Logo' withContainer>
      <div className='flex w-full justify-center' style={{height: '155px'}}>
        {!editEnable ? (
          <img
            className='object-cover w-36 h-36'
            src={filePath}
            alt=''
            loading='lazy'
          />
        ) : (
          <NewImageInput
            name='academyPhoto'
            titleName='Logo akademii'
            imgSrc={filePath}
            base64={base64}
            setBase64={setBase64}
          />
        )}
      </div>
    </HeaderContainer>
  );
};

export default AcademyPhoto;
