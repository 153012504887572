import { IOrder, ORDER_SORT_LIST } from 'types/marketpleace/order.interface';
import { compareArray } from 'utils/baseUtils';

export const sortOrdersByListSort = (
  array: IOrder[],
  type?: ORDER_SORT_LIST
) => {
  switch (type) {
    case 0:
      return array
        .slice()
        .sort((a, b) => compareArray<IOrder>(a, b, 'Created'))
        .reverse();
    case 1:
      return array
        .slice()
        .sort((a, b) => compareArray<IOrder>(a, b, 'Created'));
    case 2:
      return array
        .slice()
        .sort((a, b) => compareArray<IOrder>(a, b, 'Amount'))
        .reverse();
    case 3:
      return array.slice().sort((a, b) => compareArray<IOrder>(a, b, 'Amount'));
    default:
      return array;
  }
};
