import DataTable from 'components/common/dataTable/DataTable';
import React from 'react';
import { IPassAppliedProfile } from 'types/transfer/pass.interface';

interface IAppliedProfilesTableProps {
  appliedProfiles: IPassAppliedProfile[];
  isLoading: boolean;
}

const AppliedProfilesTable = ({
  appliedProfiles,
  isLoading,
}: IAppliedProfilesTableProps) => {
  const tableRows = appliedProfiles.map(profile => ({
    data: { link: `/players/detail/${profile.ProfileId}` },
    cols: [profile.Name],
  }));

  return (
    <div className='w-full'>
      <DataTable
        headers={['Imię i Nazwisko']}
        rows={tableRows}
        isDataLoading={isLoading}
      />
    </div>
  );
};

export default AppliedProfilesTable;
