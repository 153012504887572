import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import SubmitButton from 'components/common/buttons/submitButton/SubmitButton';
import NewImageField from 'components/common/inputs/imageInput/NewImageField';
import NewSelectStatystics from 'components/common/inputs/select/NewSelectStatystics';
import ProductTextAreaField from 'components/common/inputs/textArea/ProductTextAreaField';
import TextAreaField from 'components/common/inputs/textArea/TextAreaField';
import TextField from 'components/common/inputs/textInput/TextField';
import TextFieldWithUnit from 'components/common/inputs/textInput/TextFieldWithUnit';
import TextInputWithUnit from 'components/common/inputs/textInput/TextInputWithUnit';
import { SingleColumn } from 'styles/styles';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  IAddDelivery,
  IDeliveryInitialValues,
  IUpdateDelivery,
} from 'types/marketpleace/delivery.interface';

export type DeliveryType =
  | IAddDelivery
  | IUpdateDelivery
  | IDeliveryInitialValues;

interface IDeliveryFormProps<T> {
  initialValues: T;
  handleSubmit: (
    delivery: T,
    base64: string,
    PhotoFilePath?: string
  ) => Promise<void>;
}

const DeliveryForm = ({
  initialValues,
  handleSubmit,
}: IDeliveryFormProps<DeliveryType>) => {
  const [base64, setBase64] = useState<string>('');

  const onSubmit = async (values: DeliveryType) => {
    let delivery = values;

    delivery.Deadline = String(values.Deadline);

    if (!values.PhotoFilePath && !base64) return toast.error('Dodaj zdjęcie!');

    if (!delivery.Name) return toast.error('Nazwa dostawy jest wymagana');

    if (!delivery.Description) return toast.error('Opis jest wymagany');

    if (!delivery.Deadline) return toast.error('Czas dostawy jest wymagany');

    if (typeof delivery.Netto !== 'number')
      return toast.error('Kwota netto dostawy jest wymagana');

    if (!delivery.ConditionForFree) delivery.ConditionForFree = 0;

    if (!delivery.Tax) delivery.Tax = 0;

    if (values.PhotoFilePath && !base64)
      return handleSubmit(delivery, '', initialValues.PhotoFilePath);

    return handleSubmit(delivery as DeliveryType, base64);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnChange>
      {({ errors, isSubmitting, values }) => (
        <Form className='flex h-full w-full'>
          <SingleColumn style={{ padding: '0 15px' }}>
            <p>dane podstawowe</p>

            <NewImageField
              name='PhotoFilePath'
              className='mx-auto md:mx-0 mb-8'
              imgSrc={values.PhotoFilePath}
              base64={base64}
              setBase64={setBase64}
            />

            <TextField name='Name' label='Nazwa' />
            <TextAreaField name='Description' label='Opis' />
          </SingleColumn>

          <SingleColumn className='flex flex-col gap-4'>
            <p>Informacje</p>

            <TextInputWithUnit
              name='Tax'
              label='Podatek'
              type='number'
              unit=' %'
            />
            <TextInputWithUnit
              name='Netto'
              label='Netto'
              type='number'
              unit=' Zł'
            />
            <TextInputWithUnit
              name='ConditionForFree'
              label='Darmowa dostawa od'
              type='number'
              unit=' Zł'
            />
            <TextInputWithUnit
              name='Deadline'
              label='Czas dostawy'
              type='number'
              unit=' H'
            />

            <SubmitButton
              isSubmitting={isSubmitting}
              type='submit'
              variant={ButtonVariant.Submit}
              className='mt-6'>
              Zapisz
            </SubmitButton>
          </SingleColumn>
        </Form>
      )}
    </Formik>
  );
};

export default DeliveryForm;
