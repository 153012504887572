import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import TextInput from 'components/common/inputs/textInput/TextInput';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import orderService from 'services/marketpleace/orderServices';
import systemServices from 'services/systemServices';
import { showServerErrors } from 'utils/errorsUtils';
import { handleNewBase64Change } from 'utils/fileUtils';

import { useSelector } from 'react-redux';

interface IOrderDocumentsTopbarProps {
  orderId: string;
  refresh: () => Promise<void>;
}

const OrderDocumentsTopbar = ({
  orderId,
  refresh,
}: IOrderDocumentsTopbarProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [newBase64String, setNewBase64String] = useState<string>('');
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const permission = useSelector((state: any) => state.permissions.permissions);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const sendFile = async () => {
    try {
      setIsLoading(true);
      if (!newBase64String) {
        toast.error('Brak pliku');
        return;
      }

      if (!fileName) {
        toast.error('Wpisz nazwę pliku');
        return;
      }

      const fileUrl = await systemServices.uploadFile(
        newBase64String.split(',')[1]
      );

      await orderService.addDocument(orderId, fileName, fileUrl);

      toast.success(`Dodano dokument ${fileName}`);

      setNewBase64String('');
      setFileName('');

      await refresh();

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      setIsLoading(false);
    } catch (error: any) {
      showServerErrors(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const file = fileInputRef.current?.files && fileInputRef.current?.files[0];
    if (file) {
      setFileName(file.name.split('.', 1)[0]);
      console.log(file);
    }
  }, [fileInputRef.current?.files]);

  const isDocumentUploadActive = permission[0]?.Id === -1 ? true : !!permission.find((permission: any) => permission.Id === 21 );

  return (
    <div className='flex p-8 gap-2'>
      <input
        type='file'
        onChange={e => handleNewBase64Change(e, setNewBase64String)}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />

      <div
        style={{
          pointerEvents: isDocumentUploadActive ? 'auto' : 'none',
          opacity: isDocumentUploadActive ? '' : '0.6',
        }}
      >
        <Button
          className='px-8 '
          variant={ButtonVariant.Abort}
          onClick={handleButtonClick}
          disabled={isLoading}>
          <span className='material-icons-outlined text-3xl'>upload_file</span>
        </Button>
      </div>
      <TextInput
        type='text'
        name='tesad'
        label='Zmień nazwę'
        placeholder={!newBase64String ? 'Wybierz dokument' : 'Wpisz nazwę'}
        showErrors={false}
        disabled={!newBase64String || isLoading}
        value={fileName}
        onChange={e => setFileName(e.target.value)}
      />

      <Button
        variant={ButtonVariant.Submit}
        disabled={!newBase64String || !fileName || isLoading}
        onClick={sendFile}>
        Dodaj
      </Button>
    </div>
  );
};

export default OrderDocumentsTopbar;
