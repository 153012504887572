import InfoBox from 'components/common/boxes/InfoBox/InfoBox';
import {
  IOrderDetail,
  ORDER_HISTORY_STATE,
} from 'types/marketpleace/order.interface';
import logo from '../../../../assets/images/logo.svg';
import { getFormatedDate } from 'utils/dateUtils';

interface IOrderDetailInfoProps {
  order: IOrderDetail | null;
}

const OrderDetailInfo = ({ order }: IOrderDetailInfoProps) => {
  return (
    <div className='flex flex-col'>
      <InfoBox
        className={`p-18 transition-opacity duration-300 ${
          order ? 'opacity-100' : 'opacity-50'
        }`}>
        <InfoBox.Image src={logo} />
        <InfoBox.Items>
          <InfoBox.InfoItem label={'Zamówienie'} value={order?.Number} />
          <InfoBox.InfoItem label={'Kupujący'} value={order?.Buyer.Name} />
          <InfoBox.InfoItem label={'Wartość'} value={`${order?.Amount} zł`} />
          <InfoBox.InfoItem
            label={'Data zamówienia'}
            value={
              order ? getFormatedDate(order.Created, 'dd.MM.yyyy') : undefined
            }
          />
          <InfoBox.InfoItem
            label={'Status'}
            value={order ? ORDER_HISTORY_STATE[order.CurrentState] : undefined}
          />
        </InfoBox.Items>
      </InfoBox>
    </div>
  );
};

export default OrderDetailInfo;
