import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useHistory, useParams, useLocation } from "react-router";
import { useState } from "react";
import SubmitButton from "../../common/buttons/submitButton/SubmitButton";
import TextField from "../../common/inputs/textInput/TextField";
import AuthHeader from "../LoginHeader";
import Visible from "../../../assets/icons/visible.png";
import NotVisible from "../../../assets/icons/not-visible.png";
import { remindPassword, resetPassword } from "../../../store/actions/auth";
import {
  resetPasswordValidation,
  resetPasswordInitValues,
} from "../authHelpers";
import styled from "styled-components";

interface IForgotPasswordProps {}

const ResetPassword: React.FC<IForgotPasswordProps> = (props) => {
  const { push } = useHistory();
  const params = useParams();
  const location = useLocation();
  const [isStatuteChecked1, setStatuteChecked1] = useState(false);
  const [isStatuteChecked2, setStatuteChecked2] = useState(false);
  const [isStatuteChecked3, setStatuteChecked3] = useState(false);
  const [isStatuteChecked4, setStatuteChecked4] = useState(false);
  const [isNewPasswordShown, setIsNewPasswordShown] = useState(false);
  const [isRepeatNewPasswordShown, setIsRepeatNewPasswordShown] =
    useState(false);

  const handleSubmit = async ({ NewPassword, RepeatNewPassword }: any) => {
    try {
      // if (
      //   !isStatuteChecked1 ||
      //   !isStatuteChecked2 ||
      //   !isStatuteChecked3 ||
      //   !isStatuteChecked4
      // )
      //   return toast.error("Zaakceptuj zgody!");
      const searchParams = new URLSearchParams(location.search);
      const userId = searchParams.get("UserId");
      const resetToken = searchParams.get("ResetToken");
      console.log("params>>>", userId, resetToken);
      if (NewPassword !== RepeatNewPassword)
        return toast.error("Podane hasła się nie zgadzają!");
      // @ts-ignore
      await resetPassword(userId, resetToken, NewPassword);
      toast.success("Twoje hasło zostało zmienione!");
      push("/auth");
    } catch (errors: any) {
      toast.error(`Błąd: ${errors.join(" | ")}`);
    }
  };

  const handleStatute1CheckboxChange = () => {
    setStatuteChecked1((prev: any) => !prev);
  };
  const handleStatute2CheckboxChange = () => {
    setStatuteChecked2((prev: any) => !prev);
  };
  const handleStatute3CheckboxChange = () => {
    setStatuteChecked3((prev: any) => !prev);
  };
  const handleStatute4CheckboxChange = () => {
    setStatuteChecked4((prev: any) => !prev);
  };

  return (
    <>
      <div className="flex flex-1 xl:flex-2 flex-col items-center md:justify-center lg:h-96 mt-8 py-36  px-24">
        <div
          className="text-xl sm:text-xxl  mt-4 mb-8"
          style={{ color: "white" }}
        >
          Resetowanie hasła
        </div>
        <Formik
          initialValues={resetPasswordInitValues}
          onSubmit={handleSubmit}
          validationSchema={resetPasswordValidation}
        >
          {({ isSubmitting }) => (
            <Form>
              <fieldset
                className="flex flex-col gap-2 mx-2 sm:mx-12"
                disabled={isSubmitting}
              >
                <InputContainer>
                  <TextField
                    className="sm:max-w-sm mx-auto"
                    name="NewPassword"
                    label="Nowe hasło"
                    placeholder="Wprowadź nowe hasło"
                    type={isNewPasswordShown ? "text" : "password"}
                  />
                  <div onClick={() => setIsNewPasswordShown((prev) => !prev)}>
                    <img src={isNewPasswordShown ? Visible : NotVisible} />
                  </div>
                </InputContainer>

                <InputContainer>
                  <TextField
                    className="sm:max-w-sm mx-auto"
                    name="RepeatNewPassword"
                    label="Powtórz nowe hasło"
                    placeholder="Powtórz nowe hasło"
                    type={isRepeatNewPasswordShown ? "text" : "password"}
                  />
                  <div
                    onClick={() => setIsRepeatNewPasswordShown((prev) => !prev)}
                  >
                    <img
                      src={isRepeatNewPasswordShown ? Visible : NotVisible}
                    />
                  </div>
                </InputContainer>

                {/* <div
                  style={{
                    display: "flex",
                    color: "white",
                    justifyContent: "flex-end",
                    gap: "10px",
                    fontSize: "9px",
                    width: "374px",
                  }}
                >
                  <label
                    style={{ width: "calc(374px - 30px)" }}
                    htmlFor="statute1"
                  >
                    Oświadczam, że zapoznałam/łem się z Regulaminem oraz
                    Polityką prywatności i akceptuję ich postanowienia
                  </label>
                  <input
                    style={{ width: "20px", height: "20px" }}
                    type="checkbox"
                    name="statute1"
                    id="statute1"
                    checked={isStatuteChecked1}
                    onChange={handleStatute1CheckboxChange}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    color: "white",
                    justifyContent: "flex-end",
                    gap: "10px",
                    fontSize: "9px",
                    width: "374px",
                  }}
                >
                  <label
                    style={{ width: "calc(374px - 30px)" }}
                    htmlFor="statute2"
                  >
                    Aktualny cennik i zasady współpracy z dnia 01.02.2024
                  </label>
                  <input
                    style={{ width: "20px", height: "20px" }}
                    type="checkbox"
                    name="statute2"
                    id="statute2"
                    checked={isStatuteChecked2}
                    onChange={handleStatute2CheckboxChange}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    color: "white",
                    justifyContent: "flex-end",
                    gap: "10px",
                    fontSize: "9px",
                    width: "374px",
                  }}
                >
                  <label
                    style={{ width: "calc(374px - 30px)" }}
                    htmlFor="statute3"
                  >
                    Wyrażam zgodę na przekazywanie przez JUSTWIN sp. zo.o. w
                    Wysogotowie, przy ulicy Wierzbowej 31, 62-081 Wysogotowo,
                    treści marketingowych za pośrednictwem moich urządzeń
                    telekomunikacyjnych, w szczególności takich jak laptop,
                    telefon czy smartfon, zgodnie z art. 172 ust.1 ustawy z dnia
                    16 lipca 2004 r. Prawo telekomunikacyjne.
                  </label>
                  <input
                    style={{ width: "20px", height: "20px" }}
                    type="checkbox"
                    name="statute3"
                    id="statute3"
                    checked={isStatuteChecked3}
                    onChange={handleStatute3CheckboxChange}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    color: "white",
                    justifyContent: "flex-end",
                    gap: "10px",
                    fontSize: "9px",
                    width: "374px",
                  }}
                >
                  <label
                    style={{ width: "calc(374px - 30px)" }}
                    htmlFor="statute4"
                  >
                    Wyrażam zgodę na otrzymywanie informacji handlowej od
                    JUSTWIN sp. zo.o. z siedzibą w Wysogotowie, przy ul.
                    Wierzbowej 31, 62-081 Wysogotowo, zgodnie z art. 10 ustawy z
                    dnia 18 lipca 2002 r. o świadczeni usług drogą
                    elektroniczną.
                  </label>
                  <input
                    style={{ width: "20px", height: "20px" }}
                    type="checkbox"
                    name="statute4"
                    id="statute4"
                    checked={isStatuteChecked4}
                    onChange={handleStatute4CheckboxChange}
                  />
                </div> */}

                {/* <div className="text-sm text-center opacity-90 mx-auto">
                  <div>
                    Na zarejestrowanego maila wyślemy automatycznie wygenerowane
                    nowe hasło.
                  </div>
                  <div>
                    Zalecamy zmianę otrzymanego hasła na własne zaraz po
                    zalogowaniu.
                  </div>
                </div> */}
              </fieldset>
              <SubmitButton
                className="mt-8 mb-4 mx-auto"
                isSubmitting={isSubmitting}
              >
                Wyślij hasło
              </SubmitButton>
            </Form>
          )}
        </Formik>
      </div>
      {/* <AuthHeader /> */}
    </>
  );
};

export default ResetPassword;

const InputContainer = styled.div`
  display: flex;
  width: 384px;

  & > div:nth-of-type(1) {
    width: calc(100% - 50px);
  }
  & > div:nth-of-type(2) {
    width: 50px;
    height: 48px;
    display: flex;
    background: rgba(163, 158, 157, 0.11);
    opacity: 0.7;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & > img {
      user-select: none;
      width: 30px;
      height: 25px;
    }
  }
`;
