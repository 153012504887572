import { useHistory } from "react-router";
import { useState } from "react";
import Button from "../../common/buttons/basicButton/Button";
import { ButtonVariant } from "../../common/buttons/buttonTypes";
import styled from "styled-components";
import folderIco from "../../../assets/icons/folder.png";
import { createFolder } from "services/documentServices";

const FolderContainer = styled.div`
  height: 100%;
  display: flex;
  gap: 10px;
  margin-left: 20px;

  & > img {
    width: 25px;
    height: 25px;
  }

  & > p {
    color: white;
  }
`;

const BlurBox = styled.div<{ isAddMenuOpen: boolean }>`
  position: absolute;
  display: ${(props) => (props.isAddMenuOpen ? "unset" : "none")};
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: lur(3px);
  z-index: 99;
`;

const SideAddMenu = styled.div<{ isAddMenuOpen: boolean }>`
  position: absolute;
  height: calc(100vh - 48px);
  top: 48px;
  /* padding: ${(props) => (props.isAddMenuOpen ? "23px 44px" : "0")}; */
  padding: ${(props) => (props.isAddMenuOpen ? "23px 0" : "23px 0")};
  right: 0;
  width: ${(props) => (props.isAddMenuOpen ? "627px" : "0px")};
  background: rgba(2, 2, 15, 1);
  transition: all 0.4s ease-in-out;
  color: rgba(163, 158, 157, 1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  & > * {
    opacity: ${(props) => (props.isAddMenuOpen ? "1" : "0")};
    transition: all 0.2s ease-in-out;
  }

  & > div {
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
    gap: 9px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    & > div {
      & > form {
        & > p {
          /* text-align: center; */
          text-transform: uppercase;
          font-size: 15px;
          font-weight: 400;
          margin-bottom: 10px;
        }
      }
    }
  }
`;

interface Props {
  folderName: any;
  activeFolderId: string;
  setIsFolderAdded: any;
  setIsDocumentAdded: any;
  setShowOptions: any;
  showOptions: any;
}

const FilesTopBar: React.FC<Props> = ({
  folderName,
  activeFolderId,
  setIsFolderAdded,
  setIsDocumentAdded,
  setShowOptions,
  showOptions,
}) => {
  const { push } = useHistory();
  const [isAddMenuOpen, toggleAddMenuOpen] = useState(false);

  const handleAddFile = () => {
    push("/files/add");
  };

  return (
    <div
      className="flex flex-wrap items-center  gap-y-2"
      style={{ gap: "3px", justifyContent: "space-between" }}
    >
      {folderName ? (
        <FolderContainer>
          <img src={folderIco} alt="folder" />
          <p>{folderName}</p>
        </FolderContainer>
      ) : (
        <FolderContainer></FolderContainer>
      )}

      <BlurBox
        isAddMenuOpen={isAddMenuOpen}
        onClick={() => {
          toggleAddMenuOpen(false);
        }}
      />
      <SideAddMenu isAddMenuOpen={isAddMenuOpen}></SideAddMenu>
      <div style={{ display: "flex", gap: "5px" }}>
        {activeFolderId && (
          <Button
            onClick={() => {
              setIsDocumentAdded(true);
            }}
            className="px-24 xl:order-2"
            variant={ButtonVariant.Submit}
            style={{
              fontSize: "13px",
              fontWeight: 400,
              color: "white",
              background: "rgb(0, 160, 255)",
            }}
          >
            Dodaj dokument
          </Button>
        )}

        <Button
          onClick={() => {
            setIsFolderAdded(true);
          }}
          className="px-24 xl:order-2"
          variant={ButtonVariant.Submit}
          style={{
            fontSize: "13px",
            fontWeight: 400,
            color: "white",
            background: "rgba(163, 158, 157, 0.38)",
          }}
        >
          Dodaj folder
        </Button>

        <Button
          onClick={() => {
            setShowOptions((prev: any) => !prev);
          }}
          className="px-24 xl:order-2"
          variant={ButtonVariant.Submit}
          style={{
            fontSize: "13px",
            fontWeight: 400,
            color: "white",
            background: "rgb(0, 160, 255)",
          }}
        >
          {showOptions ? "Ukryj opcje" : "Pokaż opcje"}
        </Button>
      </div>
    </div>
  );
};

export default FilesTopBar;
