import React from 'react';
import cs from 'classnames';

import Input from '../Input';
import { ITextareaProps } from '../inputTypes';

const TextArea: React.FC<ITextareaProps> = ({
  children,
  name,
  className = '',
  inputClassName = '',
  label,
  placeholder,
  isError,
  style,
  wrapperClassname = '',
  value,
  ...props
}) => {
  const inputClassNames = cs(
    'flex-1 outline-none font-regular bg-white w-full',
    {
      [inputClassName]: inputClassName,
    }
  );

  return (
    <div style={{ position: 'relative' }}>
      <Input
        isError={isError}
        name={name}
        label={label}
        render={() => (
          <textarea
            {...props}
            className={inputClassNames}
            id={name}
            placeholder={placeholder}
            value={value}
            style={{
              resize: 'none',
              width: '260px',
              height: '152px',
              paddingTop: '18px',
            }}
            maxLength={200}
          />
        )}
        type='textarea'
        wrapperClassName={wrapperClassname}
        style={{ resize: "none", width: "260px", height: "172px" }}
        value={value}
      />

      <p
        style={{
          position: 'absolute',
          bottom: '25px',
          right: '15px',
          color: 'rgb(0,145,255)',
          fontSize: '11px',
          fontWeight: 400,
        }}>
        {/* @ts-ignore */}
        Liczba znaków {value?.length ? value.length : 0} / 200
      </p>
    </div>
  );
};

export default TextArea;
