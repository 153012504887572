import { IFilterValue } from 'components/filters/NewFilterPanel';
import { toast } from 'react-toastify';
import { categoryServices } from 'services/marketpleace';
import { ISelectOption } from 'types/globalTypes';
import { ICategory } from 'types/marketpleace/category.interface';

export const getCategories = async (): Promise<ICategory[]> => {
  try {
    const categories = await categoryServices.get();

    return categories;
  } catch (error) {
    console.error(error);
    toast.error(
      'Wystąpił błąd przy wczytywaniu kategorii. Spróbuj ponownie później.'
    );
    return [];
  }
};

export const getCategoriesSelectOptions = async (): Promise<
  ISelectOption<string>[]
> => {
  const categories = await getCategories();

  const categoriesSelectOptions = categories.map(
    (category: ICategory) =>
      ({
        value: category.CategoryId,
        label: category.Name,
      } as ISelectOption<string>)
  );

  return categoriesSelectOptions;
};

export const getCategoriesTabFilterValues = async (): Promise<
  IFilterValue[]
> => {
  const categories = await getCategories();

  const categoriesSelectOptions = categories.map(
    (category: ICategory) =>
      ({
        backend: category.CategoryId,
        pl: category.Name,
      } as IFilterValue)
  );

  return categoriesSelectOptions;
};
