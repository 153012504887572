import React, { useEffect, useState } from "react"
import styled from "styled-components";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import Button from "components/common/buttons/basicButton/Button";
import FilterDropdownPanel from "components/common/panels/FilterDropdownPanel";
import FilterSingleSelect from "./FilterSingleSelect";
import { useUrlQuery } from "../../hooks/useUrlQuery";
import { FilterPassData } from 'types/filterPassData';
import { createTrue } from "typescript";

const AgeInputBox = styled.div`
  width: 100%;
  height: 48px;
  min-height: 48px;
  background: rgba(163, 158, 157, 0.11);
  /* border-radius: 5px; */
  padding: 0 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div:nth-of-type(1) {
    width: 50%;
    height: 100%;
    padding: 5px 6px;
    display: flex;
    & > p {
      font-size: 11px;
      font-weight: 400;
      color: rgba(163, 158, 157, 1);
    }
  }

  & > div:nth-of-type(2) {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
  }
`;

const AgeInput = styled.input`
  height: 100%;
  width: 100%;
  /* background: rgba(163, 158, 157, 0.15); */
  border-left: 5px solid rgba(163, 158, 157, 0.15);
  background: transparent;
  color: white;
  font-size: 15px;
  text-align: center;
  font-weight: 400;
  padding: 0 5px;
`;

const DateContainer = styled.div`
  cursor: pointer;
  min-height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  /* border: 1px solid rgba(90, 90, 100, 0.3); */
  /* border-radius: 14px; */
  padding-left: 130px;
  min-width: 280px;
  background: rgba(163, 158, 157, 0.11);
  color: white;

  /* margin-top: 10px; */
`;

const DataInput = styled.input`
  background-color: transparent;
  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
    /* color-scheme: orange; */
  }
  padding: 0 10px;
`;

const DataLabel = styled.label`
  position: absolute;

  top: 7px;
  left: 15px;
  font-size: 11px;
  letter-spacing: 0px;
  font-weight: 400;
  color: rgb(163, 158, 157);
`;

const AmountContainer = styled.div`
  min-height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  /* border: 1px solid rgba(90, 90, 100, 0.3); */
  background: rgba(163, 158, 157, 0.11);
  width: 100%;
  /* margin: 5px 0; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const AmountInput = styled.input`
  background: transparent;
  opacity: 1;
  height: 100%;
  width: 70%;
  text-align: right;
  padding: 0 10px;
  color: white;
  font-size: 15px;
  font-weight: 500;

  &::-webkit-input-placeholder {
    color: white; /*Change the placeholder color*/
    opacity: 1; /*Change the opacity between 0 and 1*/
  }
`;

const AmountLabel = styled.label`
  color: rgba(163, 158, 157, 1);
  font-size: 11px;
  position: absolute;
  top: 7px;
  left: 10px;
`;

const StyledInputDate = styled.input`
  background: transparent;
  border: 1px solid rgba(163, 158, 157, 0.3);
  width: 100%;
  border-radius: 5px;
  height: 40px;
  opacity: 1;
  text-align-last: center;
  text-align: center;
  -webkit-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  color-scheme: dark;
`;

interface FiltrProps {
    filters: any,
    filterData?: (value: FilterPassData) => void;
}

const FilterPass: React.FC<FiltrProps> = ({ filters, filterData }) => {
    const { query, changeQuery } = useUrlQuery();

    const [activeFilters, setActiveFilters] = useState<
        Array<{ name: string; value: string; pl: string; id: number }>
    >([]);

    const [dataFrom, setDataFrom] = useState('');
    const [dataTo, setDataTo] = useState('');
    const [eventCountMin, setEventCountMin] = useState('');
    const [eventCountMax, setEventCountMax] = useState('');
    const [isActive, setIsActive] = useState(true)

    const handleDataFrom = (event: React.ChangeEvent<HTMLInputElement>) => {

        setDataFrom(event.target.value)
    }

    const handleDataTo = (event: React.ChangeEvent<HTMLInputElement>) => {

        setDataTo(event.target.value)
    }


    const handleFilterChange = (filter: typeof query[0], checked: any) => {
        setActiveFilters((prev: any) => {
            if (checked) {
                let obj = [...prev, filter];
                const fil = filterFun(obj);
                return [...fil];
            }
            return prev.filter(
                (f: any) =>
                    !(
                        f.name === filter.name &&
                        f.value === filter.value &&
                        f.pl === filter.pl
                    ),
            );
        });
    };

    const filterFun = (arr: any) => {
        return arr
            .filter(
                //@ts-ignore
                (value, index, self) =>
                    index === self.findLastIndex((t: any) => t.name === value.name),
            )
            .filter((t: any) => t.value !== "");
    };

    const handleEventCountMinChange = (value: any): void => {
        setEventCountMin(value.target.value)
    }

    const handleEventCountMaxChange = (value: any): void => {
        setEventCountMax(value.target.value)
    }

    const handleSubmit = () => {

        if (filterData) {
            const obj = {
                dataFrom: dataFrom,
                dataTo: dataTo,
                state: activeFilters[0]?.value,
                passValueFrom: eventCountMin,
                passValueTo: eventCountMax,
            }
            filterData(obj);
        }
    }

    const handleDeleteFilterState = () => {
        setActiveFilters(
            []
        );

        if (filterData) {
            filterData(
                {
                    dataFrom: '',
                    dataTo: '',
                    state: '',
                    passValueFrom: '',
                    passValueTo: '',
                }
            )
        }


    }

    const handleDeletePassValue = () => {
        setEventCountMin('')
        setEventCountMax('')

        if (filterData) {
            filterData(
                {
                    dataFrom: '',
                    dataTo: '',
                    state: '',
                    passValueFrom: '',
                    passValueTo: '',
                }
            )
        }
    }

    const handleDeleteData = () => {
        setDataFrom('')
        setDataTo('')
        if (filterData) {
            filterData(
                {
                    dataFrom: '',
                    dataTo: '',
                    state: '',
                    passValueFrom: '',
                    passValueTo: '',
                }
            )
        }
    }

    useEffect(() => {

        const isBtnDisable = (eventCountMin.length > 0 && eventCountMax.length > 0) || (dataTo.length > 0 && dataFrom.length > 0) || activeFilters.length > 0

        setIsActive(!isBtnDisable)

    }, [dataFrom, dataTo, eventCountMin, eventCountMax, activeFilters])

    return (
        <div style={{ padding: '0 20px', textAlign: 'center' }}>

            <div className="flex gap-1 md:gap-8 w-full justify-around pb-18">
                <div
                    style={{ display: activeFilters[0]?.value === undefined ? 'none' : 'flex', gap: '10px' }}
                >
                    <div style={{ fontSize: '16px', color: 'white' }}>
                        Status
                    </div>
                    <button onClick={handleDeleteFilterState} style={{ fontSize: '17px', color: 'white' }}>X</button>
                </div>

                <div
                    style={{ display: eventCountMin !== '' && eventCountMax !== '' ? 'flex' : 'none', gap: '10px' }}
                >
                    <div style={{ fontSize: '16px', color: 'white' }}>
                        Wartość składki
                    </div>
                    <button onClick={handleDeletePassValue} style={{ fontSize: '17px', color: 'white' }}>X</button>
                </div>

                <div
                    style={{ display: dataFrom !== '' && dataTo !== '' ? 'flex' : 'none', gap: '10px' }}
                >
                    <div style={{ fontSize: '16px', color: 'white' }}>
                        Data
                    </div>
                    <button onClick={handleDeleteData} style={{ fontSize: '17px', color: 'white' }}>X</button>
                </div>
            </div>

            <div style={{ paddingBottom: '10px' }}>
                <AgeInputBox style={{ width: '100%' }}>
                    <div>
                        <p>Wartość składki</p>
                    </div>
                    <div>
                        <AgeInput
                            placeholder="od"
                            value={eventCountMin}
                            onChange={handleEventCountMinChange}
                            type="number"
                            maxLength={8}
                        />
                        <AgeInput
                            placeholder="do"
                            value={eventCountMax}
                            onChange={handleEventCountMaxChange}
                            type="number"
                            maxLength={8}
                        />
                    </div>
                </AgeInputBox>
            </div>
            <div>
                <AgeInputBox style={{ width: '100%' }}>
                    <div>
                        <p>Data</p>
                    </div>
                    <div
                        style={{ fontSize: '11px' }}
                    >
                        <StyledInputDate
                            type='date'
                            value={dataFrom}
                            onChange={handleDataFrom}
                            style={{ padding: '0 0 0 14px' }}
                        />
                        <StyledInputDate
                            type='date'
                            value={dataTo}
                            onChange={handleDataTo}
                            style={{ textAlign: 'center', padding: '0 0 0 4px' }}
                        />
                    </div>
                </AgeInputBox>
            </div>

            <div className="flex justify-end">
                <div style={{ height: "80%", paddingTop: '10px' }}>
                    {filters?.map((filter: any, id: number) =>
                        filter.type === "checkbox" ? (
                            <FilterDropdownPanel
                                label={filter.pl}
                            >
                                <div>
                                    <p
                                        style={{
                                            textAlign: "center",
                                            fontSize: "15px",
                                            fontWeight: 400,
                                            textTransform: "uppercase",
                                            marginTop: "10px",
                                        }}
                                    >
                                        {filter.pl}
                                    </p>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "15px",
                                            flexDirection: "column",
                                        }}
                                    >
                                        {filter?.values?.map((val: any, index: any) => (
                                            <div
                                                key={index}
                                                style={{
                                                    display: "flex",
                                                    gap: "13px",
                                                    width: "175px",
                                                }}
                                            >
                                                <label
                                                    // htmlFor={filter.name}
                                                    htmlFor={`${filter.name}${index}`}
                                                    style={{
                                                        color: "white",
                                                        fontSize: "15px",
                                                        fontWeight: 400,
                                                        textAlign: "right",
                                                        width: "130px",
                                                    }}
                                                >
                                                    {val.pl}
                                                </label>
                                                <div style={{ width: "75px" }}>
                                                    <input
                                                        style={{ width: "20px", height: "20px" }}
                                                        type="checkbox"
                                                        name={filter.name}
                                                        // id={filter.name}
                                                        id={`${filter.name}${index}`}
                                                        checked={activeFilters.some(
                                                            (f) =>
                                                                f.name === filter.name && f.value === val.backend,
                                                        )}
                                                        onChange={(e) => {
                                                            const { checked } = e.target;
                                                            handleFilterChange(
                                                                {
                                                                    name: filter.name,
                                                                    value: val.backend,
                                                                    pl: val.pl,
                                                                },
                                                                checked,
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </FilterDropdownPanel>
                        ) : //{' '}
                            filter.type === "select" ? (
                                <FilterSingleSelect
                                    filters={filters}
                                    activeFilters={activeFilters}
                                    filter={filter}
                                    handleFilterChange={handleFilterChange}
                                />
                            ) : filter.type === "date" ? (
                                <>
                                    <>
                                        <DateContainer>
                                            <DataInput
                                                type="date"
                                                name={filter.name}
                                                id={filter.name}
                                                value={
                                                    filter.name === "From"
                                                        ? activeFilters.find((obj) => obj.name === "From")
                                                            ? activeFilters.find((obj) => obj.name === "From")
                                                                ?.value
                                                            : ""
                                                        : filter.name === "To"
                                                            ? activeFilters.find((obj) => obj.name === "To")
                                                                ? activeFilters.find((obj) => obj.name === "To")
                                                                    ?.value
                                                                : ""
                                                            : ""
                                                }
                                                onChange={(e) => {
                                                    const checked = true;
                                                    handleFilterChange(
                                                        {
                                                            name: filter.name,
                                                            value: e.target.value,
                                                            pl: filter.pl,
                                                        },
                                                        checked,
                                                    );
                                                }}
                                            />
                                            <DataLabel htmlFor={filter.name}>{filter.pl}</DataLabel>
                                        </DateContainer>
                                    </>
                                </>
                            ) : filter.type === "rangeOfAmount" ? (
                                <>
                                    <AmountContainer>
                                        <AmountInput
                                            type="text"
                                            name={filter.name}
                                            id={filter.name}
                                            autoComplete="off"
                                            placeholder="Uzupełnij"
                                            value={
                                                activeFilters.find((obj) => obj.name === filter.name)?.value
                                            }
                                            onChange={(e) => {
                                                const checked = true;

                                                if (e.target.value.length < 0) {
                                                    return;
                                                }
                                                handleFilterChange(
                                                    {
                                                        name: filter.name,

                                                        value: e.target.value,
                                                        pl: filter.pl,
                                                    },
                                                    checked,
                                                );
                                            }}
                                        />
                                        <AmountLabel htmlFor={filter.name}>{filter.pl}</AmountLabel>
                                    </AmountContainer>
                                </>
                            ) : (
                                <></>
                            ),
                    )}
                </div>
            </div>

            <div style={{
                position: 'absolute',
                bottom: '0', paddingBottom: '30px',
                width: '90%'
            }}>
                <Button
                    className="px-24 xl:order-2"
                    variant={ButtonVariant.Normal}
                    disabled={isActive}
                    style={{
                        background: `${!isActive ? 'rgb(0, 160, 255)' : 'rgba(0, 160, 255, 0.7)'}`,
                        opacity: 1,
                        width: "100%",
                        height: "48px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "white",
                        marginTop: "20px",
                        border: 'transparent'
                    }}
                    onClick={handleSubmit}
                >
                    Zastostuj
                </Button>
            </div>
        </div>
    )
}

export default FilterPass
