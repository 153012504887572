import { conn } from "api/BaseConnection";
import api from "./axiosConfig";
import { toast } from "react-toastify";

export const getRootFolder = async () => {
  try {
    const response = await api.get(`/api/Folder/GetRootFolders`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAvailablePlayerFolders = async () => {
  try {
    const response = await api.get(`/api/Folder/GetAvailablePlayerFolders`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getFolderDetail = async (FolderId: string) => {
  try {
    const response = await api.get(
      `/api/Folder/GetFolderDetail?FolderId=${FolderId}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getPlayerPasses = async (FolderId: string) => {
  try {
    const response = await api.get(
      `/api/Folder/GetPlayerPasses?FolderId=${FolderId}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getFolderItemsByFolderId = async (FolderId: string) => {
  try {
    const response = await api.get(
      `/api/Folder/GetFolderItemsByFolderId?FolderId=${FolderId}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const GetOwnFolders = async () => {
  try {
    const response = await api.get(`/api/Folder/GetOwnFolders`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getSearchFolders = async (SearchQuery: string) => {
  try {
    const response = await api.get(
      `/api/Folder/SearchFolders?SearchQuery=${SearchQuery}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getSearchFolderPath = async (FolderId: string) => {
  try {
    const response = await api.get(
      `/api/Folder/SearchFolderPath?FolderId=${FolderId}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createFolder = async (Name: string) => {
  try {
    const response = await api.post(`/api/Folder/CreateFolder`, {
      Name,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createSubFolder = async (FolderId: string, Name: string) => {
  try {
    const response = await api.post(`/api/Folder/CreateSubFolder`, {
      FolderId,
      Name,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const addItemToFolder = async (FolderId: string, PlayerId: string) => {
  try {
    const response = await api.post(`/api/Folder/AddItemToFolder`, {
      FolderId,
      PlayerId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateFolderName = async (FolderId: string, NewName: string) => {
  try {
    const response = await api.put(`/api/Folder/UpdateFolderName`, {
      FolderId,
      NewName,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const grantFolderToTrainer = async (
  FolderId: string,
  TrainerId: string,
) => {
  try {
    const response = await api.post(`/api/Folder/GrantFolderToTrainer`, {
      FolderId,
      TrainerId,
      CanCopyOut: true,
      CanTransfer: true,
      CanDeleteFolders: true,
      CanUpdateFolderName: true,
      CanCopyIn: true,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const revokeFolderToTrainer = async (
  FolderId: string,
  TrainerId: string,
) => {
  try {
    const response = await api.post(`/api/Folder/RevokeFolderToTrainer`, {
      FolderId,
      TrainerId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const copyItemToFolder = async (
  FolderFromId: string,
  FolderToId: string,
  CopyItemId: string,
  PlayerId: string,
) => {
  try {
    const response = await api.post(`/api/Folder/CopyItemToFolder`, {
      FolderFromId,
      FolderToId,
      CopyItemId,
      PlayerId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const transferItemToFolder = async (
  FolderFromId: string,
  FolderToId: string,
  TransferItemId: string,
  PlayerId: string,
) => {
  try {
    const response = await api.post(`/api/Folder/TransferItemToFolder`, {
      FolderFromId,
      FolderToId,
      TransferItemId,
      PlayerId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error("W folderze już znajduje się dany obiekt!");
  }
};

export const replaceItem = async (
  FolderId: string,
  FolderItemId: string,
  OrderTo: number,
) => {
  try {
    const response = await api.post(`/api/Folder/ReplaceItem`, {
      FolderId,
      FolderItemId,
      OrderTo,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteFolderItem = async (
  FolderId: string,
  FolderItemId: string,
) => {
  try {
    const response = await api.delete(`/api/Folder/DeleteFolderItem`, {
      data: { FolderId, FolderItemId },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteFolder = async (FolderId: string) => {
  try {
    const response = await api.delete(`/api/Folder/DeleteFolder`, {
      data: { FolderId },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
