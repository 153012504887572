import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";

const ArrowDiv = styled.div`
  & > svg {
    width: 20px;
    fill: rgb(163, 158, 157);
  }
`;

const Options = styled.ul`
  position: absolute;
  margin: 0;
  z-index: 99;
  padding: 0;
  list-style: none;
  overflow-y: auto;
  border: 1px solid rgb(163, 158, 157, 0.2);
  width: 280px;
  height: auto;
  left: 0;
  top: calc(100% + 0.25em);
  background-color: rgb(2, 2, 15);
  z-index: 100;
  display: none;
  &.show {
    display: block;
  }
`;
const Option = styled.li`
  padding: 0.25em 0.5em;
  &:hover {
    background: rgba(17, 126, 228, 0.384);
  }
  cursor: pointer;
`;

const Container = styled.div`
  position: relative;
  width: 280px;
  height: 48px;
  text-align: right;
  background: rgba(163, 158, 157, 0.11);
  color: white;
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
  padding: 0.5em;
  outline: none;
  &:focus {
    //if needed
  }
  & > p {
    position: absolute;
    top: 7px;
    left: 9px;
    font-size: 11px;
    letter-spacing: 0px;
    font-weight: 400;
    color: rgb(163, 158, 157);
  }
`;
const LabTitle = styled.span`
  flex-grow: 1;
  padding-right: 30px;
`;

type Filter = {
  name: string;
  values?: any;
  pl?: any;
  id?: any;
  type?: string;
};

type ActiveFilter = {
  name: string;
  value: any;
  pl: any;
  id?: any;
};

interface IFilterPanelProps {
  filters?: Array<Filter>;
  activeFilters: Array<ActiveFilter>;
  filter: any;
  handleFilterChange: any;
}

const FilterSingleSelect: React.FC<IFilterPanelProps> = ({
  filters,
  activeFilters,
  filter,
  handleFilterChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <Container
        tabIndex={0}
        onClick={() => setIsOpen((prev) => !prev)}
        onBlur={() => setIsOpen(false)}
      >
        <ArrowDiv className="absolute top-0 bottom-0 flex items-center right-3">
          <Arrow
            className={`duration-100 transition-transform transform ${
              isOpen ? "rotate-90" : "-rotate-90"
            }`}
          />
        </ArrowDiv>
        <p>{filter.pl}</p>
        {activeFilters.map((item, idx) => {
          if (item.name === filter.name) {
            return (
              <LabTitle key={item.name + item.value + idx}>{item.pl}</LabTitle>
            );
          }
          return null;
        })}
        {(activeFilters.length === 0 ||
          activeFilters.filter((el, idx) => el.name === filter.name).length ===
            0) && <LabTitle key="default">Dowolny</LabTitle>}

        <Options className={isOpen ? "show" : ""}>
          {filter?.values?.map((val: any, idx: number) => (
            <Option
              key={val.backend + idx}
              onClick={(e) => {
                e.stopPropagation();
                const checked = true;
                handleFilterChange(
                  {
                    name: filter.name,
                    value: val.backend,
                    pl: val.pl,
                    id: filter.id,
                  },
                  checked
                );
                setIsOpen(false);
              }}
            >
              {val.pl}
            </Option>
          ))}
        </Options>
      </Container>
    </div>
  );
};

export default FilterSingleSelect;
