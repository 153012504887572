import React, { ReactNode } from 'react';

interface ITableHeaderProps {
  header: string;
  children: ReactNode;
}

const TableHeader = ({ header, children }: ITableHeaderProps) => {
  return (
    <div
      className='flex justify-between w-full items-center p-8'
      style={{ backgroundColor: 'rgba(2, 2, 15, 0.3)' }}>
      <div className='text-lg px-18 font-light'>{header}</div>
      <div className='flex gap-2 h-full '>{children}</div>
    </div>
  );
};

export default TableHeader;
