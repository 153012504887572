import { ISelectOption } from 'components/common/inputs/inputTypes';
import React from 'react';
import ScannerForm from './ScannerForm';
import { IFilterPlayerValue } from 'types/filterPassData';

interface IScannerSideMenuProps {
  refreshScanner: () => Promise<void>;
  refreshFilters: () => Promise<void>;
  filter: ISelectOption<string> | null;
  setFilter: React.Dispatch<React.SetStateAction<ISelectOption<string> | null>>;
  filters: ISelectOption<string>[];
  handleFilterData?: (obj: IFilterPlayerValue) => void;
}

const ScannerSideMenu = ({
  refreshScanner,
  refreshFilters,
  filter,
  filters,
  setFilter,
  handleFilterData,
}: IScannerSideMenuProps) => {
  return (
    <div className='flex  flex-col h-full gap-6 items-center'>
       <ScannerForm
        refreshScanner={refreshScanner}
        refreshFilters={refreshFilters}
        filter={filter}
        setFilter={setFilter}
        filters={filters}
      />
    </div>
  );
};

export default ScannerSideMenu;
