import {
  ICreateFreePass,
  ICreatePaidPass,
  IPassForm,
  PASS_PAYMENT_TYPE,
  PASS_PRICE_TYPE,
  PASS_TYPE,
} from 'types/transfer/pass.interface';

export const getFreePassFromPassForm = (
  passForm: ICreatePaidPass
): ICreateFreePass => ({
  Name: passForm.Name,
  PaymentType: passForm.PaymentType,
  Period:
    passForm.Type === PASS_TYPE['Określony']
      ? passForm.Period
      : { EndAt: new Date(), StartAt: new Date() },
  Type: passForm.Type,
});

export const getPaidPassFromPassForm = (
  passForm: ICreatePaidPass
): ICreatePaidPass => ({
  Name: passForm.Name,
  PaymentType: passForm.PaymentType,
  Period: passForm.Period,
  Type: passForm.Type,
  Netto: passForm.Netto,
  Tax: passForm.Tax,
});

export const getPassFormInitialValues: IPassForm = {
  Name: '',
  PaymentType: PASS_PAYMENT_TYPE['Miesięcznie'],
  Period: { EndAt: null, StartAt: null },
  Type: PASS_TYPE['Nieokreślony'],
  Netto: 0,
  Tax: 0,
  NettoString: '',
};
