import InfoBox from 'components/common/boxes/InfoBox/InfoBox';
import React, { useContext } from 'react';
import {
  IProductDetail,
  PRODUCT_TYPES,
} from 'types/marketpleace/product.interface';
import logo from '../../../../assets/images/logo.svg';

interface IProductDetailInfoProps {
  product: IProductDetail | null;
}

const ProductDetailInfo = ({ product }: IProductDetailInfoProps) => {
  return (
    <div className='flex flex-col'>
      <InfoBox
        className={`p-18 transition-opacity duration-300 ${
          product ? 'opacity-100' : 'opacity-50'
        }`}>
        <InfoBox.Image
          src={
            product && product.BannerPhotoFilePath
              ? product.BannerPhotoFilePath
              : logo
          }
        />

        <InfoBox.Items>
          <InfoBox.InfoItem label={'Nazwa'} value={product?.Name} />

          <InfoBox.InfoItem
            label={'Krótki opis'}
            value={product?.ShortDescription}
          />

          <InfoBox.InfoItem
            label={'Kategoria'}
            value={product?.Category.Name}
          />

          <InfoBox.InfoItem
            label={'Typ produktu'}
            value={product ? PRODUCT_TYPES[product.Type] : ''}
          />

          <InfoBox.InfoItem label={'Tag'} value={product?.Tag} />
        </InfoBox.Items>
      </InfoBox>
    </div>
  );
};

export default ProductDetailInfo;
