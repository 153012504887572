import {
  ICreateScanner,
  IScanner,
  IScannerForm,
} from 'types/scanner/scanner.interface';
import { enumToSelectOptions } from 'utils/baseUtils';

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    maxWidth: '80%',
    maxHeight: '80%',
    width: '80%',
    height: '80%',
    background: '#1a2530',
    margin: 0,
    padding: 0,
    border: '2px solid lightgray',
    borderTop: '30px solid lightgray',
  },
};

export const scannerValues = {
  FilterId: null,
  Name: '',
  //primary data
  Gender: null,
  WeightMin: '',
  WeightMax: '',
  HeightMin: '',
  HeightMax: '',
  AgeMin: '',
  AgeMax: '',
  //player card
  DominantLeg: null,
  PreferredPosition: null,
  //details
  HeartRateMin: '',
  HeartRateMax: '',
  DistanceMin: '',
  DistanceMax: '',
  SpeedMin: '',
  SpeedMax: '',
  HsrMin: '',
  HsrMax: '',
  HmldMin: '',
  HmldMax: '',
  FitnessMin: '',
  FitnessMax: '',
  DynamicsMin: '',
  DynamicsMax: '',
  EnergyMin: '',
  EnergyMax: '',
  //address
  Address: '',
  Longitude: 0,
  Latitude: 0,
  Area: '',
};

export const adjustScannerToForm = (scanner: IScanner): IScannerForm => ({
  ...scanner,
  Area: scanner.Area === null ? '' : `${scanner.Area}`,
  HeartRateMin: scanner.HeartRateMin === null ? '' : `${scanner.HeartRateMin}`,
  HeartRateMax: scanner.HeartRateMax === null ? '' : `${scanner.HeartRateMax}`,
  DistanceMin: scanner.DistanceMin === null ? '' : `${scanner.DistanceMin}`,
  DistanceMax: scanner.DistanceMax === null ? '' : `${scanner.DistanceMax}`,
  SpeedMin: scanner.SpeedMin === null ? '' : `${scanner.SpeedMin}`,
  SpeedMax: scanner.SpeedMax === null ? '' : `${scanner.SpeedMax}`,
  HsrMin: scanner.HsrMin === null ? '' : `${scanner.HsrMin}`,
  HsrMax: scanner.HsrMax === null ? '' : `${scanner.HsrMax}`,
  HmldMin: scanner.HmldMin === null ? '' : `${scanner.HmldMin}`,
  HmldMax: scanner.HmldMax === null ? '' : `${scanner.HmldMax}`,
  FitnessMin: scanner.FitnessMin === null ? '' : `${scanner.FitnessMin}`,
  FitnessMax: scanner.FitnessMax === null ? '' : `${scanner.FitnessMax}`,
  DynamicsMin: scanner.DynamicsMin === null ? '' : `${scanner.DynamicsMin}`,
  DynamicsMax: scanner.DynamicsMax === null ? '' : `${scanner.DynamicsMax}`,
  EnergyMin: scanner.EnergyMin === null ? '' : `${scanner.EnergyMin}`,
  EnergyMax: scanner.EnergyMax === null ? '' : `${scanner.EnergyMax}`,
  WeightMin: scanner.WeightMin === null ? '' : `${scanner.WeightMin}`,
  WeightMax: scanner.WeightMax === null ? '' : `${scanner.WeightMax}`,
  HeightMin: scanner.HeightMin === null ? '' : `${scanner.HeightMin}`,
  HeightMax: scanner.HeightMax === null ? '' : `${scanner.HeightMax}`,
  AgeMin: scanner.AgeMin === null ? '' : `${scanner.AgeMin}`,
  AgeMax: scanner.AgeMax === null ? '' : `${scanner.AgeMax}`,
});

export const adjustFormToScanner = (scanner: IScannerForm): ICreateScanner => ({
  ...scanner,
  Area: parseInt(scanner.Area),
  HeartRateMin: parseInt(scanner.HeartRateMin),
  HeartRateMax: parseInt(scanner.HeartRateMax),
  DistanceMin: parseInt(scanner.DistanceMin),
  DistanceMax: parseInt(scanner.DistanceMax),
  SpeedMin: parseInt(scanner.SpeedMin),
  SpeedMax: parseInt(scanner.SpeedMax),
  HsrMin: parseInt(scanner.HsrMin),
  HsrMax: parseInt(scanner.HsrMax),
  HmldMin: parseInt(scanner.HmldMin),
  HmldMax: parseInt(scanner.HmldMax),
  FitnessMin: parseInt(scanner.FitnessMin),
  FitnessMax: parseInt(scanner.FitnessMax),
  DynamicsMin: parseInt(scanner.DynamicsMin),
  DynamicsMax: parseInt(scanner.DynamicsMax),
  EnergyMin: parseInt(scanner.EnergyMin),
  EnergyMax: parseInt(scanner.EnergyMax),
  WeightMin: parseInt(scanner.WeightMin),
  WeightMax: parseInt(scanner.WeightMax),
  HeightMin: parseInt(scanner.HeightMin),
  HeightMax: parseInt(scanner.HeightMax),
  AgeMin: parseInt(scanner.AgeMin),
  AgeMax: parseInt(scanner.AgeMax),
});

export enum SCANNER_DOMINANT_LEG {
  'Dowolna',
  'Prawa',
  'Lewa',
  'Obunożny',
}

export enum SCANNER_PREFERRED_POSITION {
  'Dowolna',
  'Bramkarz',
  'Obrońca',
  'Pomocnik',
  'Strzelec',
}

export enum SCANNER_GENDER {
  'Dowolna',
  'Mężczyzna',
  'Kobieta',
}

export const genderItems = enumToSelectOptions(SCANNER_GENDER);
export const dominantLegItems = enumToSelectOptions(SCANNER_DOMINANT_LEG);
export const preferredPositionItems = enumToSelectOptions(
  SCANNER_PREFERRED_POSITION
);
