import { ReactNode, useState } from "react";
import { ReactComponent as Arrow } from "../../../assets/icons/sortArrow.svg";

type DataTableHeaderProps = {
  headers: Array<ReactNode>;
  clickedIndex: any;
  setClickedIndex: any;
  selectedParam: any;
  setSelectedParam: any;
  selectedParamValueName: any;
  setSelectedParamValueName: any;
  selectedTableParam: any;
  setSelectedTableParam: any;
};

const AttendancesDataTableHeader: React.FC<DataTableHeaderProps> = ({
  headers,
  clickedIndex,
  setClickedIndex,
  selectedParam,
  setSelectedParam,
  selectedParamValueName,
  setSelectedParamValueName,
  selectedTableParam,
  setSelectedTableParam,
}) => {
  const [activeMock, setActiveMock] = useState(`${headers[0]}_up`);

  const setSelectedParamByIndex = (idx: number) => {
    if (idx === 4) {
      setSelectedParam("Distance");
      setSelectedParamValueName("Value");
      setSelectedTableParam("totalDistance");
    }

    if (idx === 5) {
      setSelectedParam("Speed");
      setSelectedParamValueName("Value");
      setSelectedTableParam("avgSpeed");
    }

    if (idx === 6) {
      setSelectedParam("HsrDistance");
      setSelectedParamValueName("Value");
      setSelectedTableParam("avgHSR");
    }

    if (idx === 7) {
      setSelectedParam("HmldDistance");
      setSelectedParamValueName("Value");
      setSelectedTableParam("avgHmld");
    }

    if (idx === 8) {
      setSelectedParam("Dynamic");
      setSelectedParamValueName("Value");
      setSelectedTableParam("avgDynamic");
    }

    if (idx === 9) {
      setSelectedParam("Fitness");
      setSelectedParamValueName("Value");
      setSelectedTableParam("avgFitness");
    }

    if (idx === 10) {
      setSelectedParam("Energy");
      setSelectedParamValueName("Value");
      setSelectedTableParam("avgEnergy");
    }

    if (idx === 11) {
      setSelectedParam("HeartRate");
      setSelectedParamValueName("Value");
      setSelectedTableParam("avgHeartRate");
    }
  };

  return (
    <thead>
      <tr>
        {headers.map((header, idx) => {
          if (idx === 0) {
            return (
              <th key={idx} style={{ width: "50px" }}>
                <div
                  className=" relative"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{ fontSize: "11px", lineHeight: "15px" }}
                    className="text-sm font-regular opacity-70"
                  >
                    {header}
                  </span>
                </div>
              </th>
            );
          } else {
            return (
              <th
                key={idx}
                onClick={
                  idx > 3 && idx < 12
                    ? () => {
                        setClickedIndex(idx);
                        setSelectedParamByIndex(idx);
                      }
                    : undefined
                }
                style={{ margin: "0", padding: "0" }}
              >
                <div
                  className=" relative"
                  // style={{
                  //   display: "flex",
                  //   flexDirection: "column",
                  //   alignItems: "center",
                  //   width:"100%",
                  //   height:"100%"

                  // }}
                  style={
                    idx === clickedIndex
                      ? {
                          background: "rgba(0,0,0,0.2)",
                          borderTop: "1px solid #FF9200",
                          borderRight: "1px solid #FF9200",
                          borderLeft: "1px solid #FF9200",
                          // borderBottom: "none",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          height: "100%",
                        }
                      : {
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          height: "100%",
                        }
                  }
                >
                  <span
                    // style={{ fontSize: "11px", lineHeight: "15px" }}
                    className="text-sm font-regular opacity-70"
                    style={
                      idx === clickedIndex
                        ? {
                            color: " #FF9200",
                            fontSize: "11px",
                            lineHeight: "15px",
                            // opacity: 0.5,
                          }
                        : {
                            color: " white",
                            fontSize: "11px",
                            lineHeight: "15px",
                          }
                    }
                  >
                    {header}
                  </span>
                </div>
              </th>
            );
          }
        })}
      </tr>
    </thead>
  );
};

export default AttendancesDataTableHeader;
