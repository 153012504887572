import HeaderContainer from 'components/common/Containers/HeaderContainer';
import ColumnTableRow from 'components/common/FormTable/ColumnTableRow';
import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import passService from 'services/transfer/passServices';
import { GridColumn, StyledTableSelect, GridTextItem } from 'styles/styles';
import { IPass, IPlayerHistoryPass } from 'types/transfer/pass.interface';
import { IAppliedPass } from 'types/transfer/player.interface';
import { showServerErrors } from 'utils/errorsUtils';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'styles/confirmAlertStyles.scss'; // Import css
import { getFormatedDate } from 'utils/dateUtils';
import styled from "styled-components";
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/delete.svg';
import { useSelector } from 'react-redux';

const StyledInput = styled.input`
  background: transparent;
  border: 1px solid rgba(163, 158, 157, 0.3);
  width: 100%;
  border-radius: 5px;
  height: 38px;
  opacity: 1;
  text-align-last: center;
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
  outline: none;

  & > * {
    background: rgb(57, 57, 65);
    opacity: 0.5;
    /* height: 48px; */
  }
`;

const StyledInputDate = styled.input`
  background: transparent;
  border: 1px solid rgba(163, 158, 157, 0.3);
  width: 100%;
  border-radius: 5px;
  height: 40px;
  opacity: 1;
  text-align-last: center;
  text-align: center;
  -webkit-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  color-scheme: dark;
`;

interface IApplyPassProps {
  refresh: () => Promise<void>;
  appliedPass: IAppliedPass;
  newestHistoryPass: IPlayerHistoryPass;
}

const ApplyPass = ({
  refresh,
  appliedPass,
  newestHistoryPass,
}: IApplyPassProps) => {
  const { id: playerId } = useParams<{ id: string }>();
  const [passes, setPasses] = useState<IPass[]>([]);
  const [passId, setPassId] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [amount, setAmount] = useState<number | string>();
  const [isEditing, setIsEditing] = useState(false);
  const [newDate, setNewDate] = useState('');
  const [isTableEdit, setIsTableEdit] = useState(false);
  const [newPass, setNewPass] = useState(false);
  const [editRow, setEditRow] = useState('')
  const [availablePass, setAvailablePass] = useState<IPass[]>([]);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const permission = useSelector((state: any) => state.permissions.permissions);

  const isElemHasPermission = (id: number) => permission[0]?.Id === -1 ? true : !!permission.find((permission: any) => permission.Id === id);


  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value);
  }

  const handleNewDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewDate(event.target.value)
  }

  const handleEditBtn = (value: string) => {
    setIsEditing(prev => !prev);
    setEditRow(value);
  }

  const getAcademyPasses = async () => {
    const { Items } = await passService.getAll();
    setPasses(Items);
  };

  const applyPass = async () => {
    if (!appliedPass) {
      setLoading(true);
      try {
        if (!passId) {
          toast.error('Wybierz karnet z listy wyboru');
        }
        await passService.applyPlayer(playerId, passId);
        toast.success(
          `Karnet ${passes.find(pass => pass.PassId === passId)?.Name
          } został przypisany i oczekuje na zatwierdzenie przez zawodnika`
        );

        setPassId('');

        await refresh();
        setLoading(false);
        setNewPass(false)
      } catch (error: any) {
        showServerErrors(error);
        setLoading(false);
      }
    }
  };

  const showWarningPopup = () => {
    confirmAlert({
      title: 'Usuwanie karnetu',
      message: `Usunięcie karnetu zawodnikowi spowoduje anulowanie transakcji. Czy chcesz usunąć karnet?`,
      buttons: [
        {
          label: 'Tak',
          onClick: revokePass,
        },
        {
          label: 'Nie',
          onClick: () => { },
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  const revokePass = async () => {
    setLoading(true);
    try {
      await passService.revokePlayer(playerId, appliedPass.PassId);
      toast.success(`Pomyślnie usunięto ${appliedPass.Name}`);

      await refresh();
    } catch (error: any) {
      showServerErrors(error);
    } finally {
      setLoading(false);
      setNewPass(false)
    }
  };

  useEffect(() => {
    getAcademyPasses();
  }, []);

  useEffect(() => {
    if (!isEditing && passes.length > 0) {
      setAmount(passes[0].Gross)
    }
  }, [passes, isEditing, newDate]);

  useEffect(() => {
    if ([newestHistoryPass].length > 0 && passes.length > 0) {
      const availablePasses = [newestHistoryPass].map(elem => elem?.Pass.Name);
      const filteredPasses = passes.filter(pass => !availablePasses.includes(pass.Name));
      setAvailablePass(filteredPasses)
    }
    if (appliedPass === undefined) {
      setAvailablePass(passes);

      setIsBtnDisabled(false)
    } else {
      setIsBtnDisabled(true)
    }
  }, [newestHistoryPass, passes, appliedPass])

  return (
    <div className={`flex lg:w-full overflow-x-auto md:overflow-x-hidden w-[50vw]`}
    >
      <HeaderContainer
        title={appliedPass ? 'Przypisz składkę' : 'Aktualna składka'}
        headerContent={
          <div className='flex h-full p-4 gap-2'>

            <div
              style={{
                pointerEvents: isElemHasPermission(179) ? 'auto' : 'none',
                opacity: isElemHasPermission(179) ? '' : '0.6'
              }}
            >
              <Button
                onClick={() => {
                  setPassId('');
                  setNewPass(prev => !prev);
                }}
                style={{
                  backgroundColor: `${!newPass ? 'rgba(163, 158, 157, 0.38)' : 'rgba(163, 158, 157, 0.6)'} `,
                  fontFamily: 'Inter',
                  fontWeight: '500',
                  padding: '8px 40px',
                  fontSize: '12px',
                  border: 'transparent'
                }}
                className='hover:opacity-80 font-inter font-light'
                disabled={isBtnDisabled}
              >
                {!newPass ? 'Dodaj składkę' : 'Zakończ'}
              </Button>
            </div>

            <div
              style={{
                pointerEvents: isElemHasPermission(180) ? 'auto' : 'none',
                opacity: isElemHasPermission(180) ? '' : '0.6'
              }}
            >
              <Button
                onClick={() => {
                  setIsTableEdit(prev => {
                    if (prev && !newPass) {
                      setIsEditing(false);
                    }
                    return !prev;
                  });
                }}
                style={{
                  backgroundColor: `${isTableEdit ?
                    'rgba(0, 145, 255, 0.5)' :
                    'rgba(0, 145, 255, 0.7)'} `,
                  fontFamily: 'Inter',
                  fontWeight: '500',
                  padding: '8px 15px',
                  fontSize: '12px',
                  border: 'transparent'
                }}
                className={'px-3 py-1 hover:opacity-80 xl:pl-24 xl:pr-24  md:pl-8 md:pr-8 pl-2 pr-2 text-sm font-light font-inter'}
              >
                {isTableEdit && !newPass ? 'Zakończ' : 'Edycja'}
              </Button>
            </div>

          </div>
        }>

        {passes && (
          <GridColumn cols={4}>
            <GridTextItem isHeader>Nazwa</GridTextItem>
            <GridTextItem isHeader>Data</GridTextItem>
            <GridTextItem isHeader>Cena</GridTextItem>
            <GridTextItem isHeader>Działanie</GridTextItem>
            <>
              {newPass && passes.slice(0, 1).map((elem, idx) => (
                <React.Fragment key={idx + '-fragment'}>
                  <GridTextItem>
                    <ColumnTableRow header='' disableUppercase>
                      {availablePass ? (
                        <StyledTableSelect
                          value={passId}
                          onChange={e => setPassId(e.target.value)}
                          style={{ lineHeight: '15px' }}
                        >
                          <option value='' disabled>
                            Wybierz składkę
                          </option>
                          {availablePass.map(pass => (
                            <option key={pass.PassId} value={pass.PassId}>
                              {pass.Name}
                            </option>
                          ))}
                        </StyledTableSelect>
                      ) : (
                        null
                      )}
                    </ColumnTableRow>
                  </GridTextItem>
                  <GridTextItem>
                    {
                      passes.map((elem => (
                        <React.Fragment key={elem.PassId}>
                          {elem.PassId === passId ? elem.Period.StartAt : '-'}
                        </React.Fragment>
                      )))
                    }
                  </GridTextItem>
                  <GridTextItem>
                    {(elem.PassId === passId) && elem.Gross} zł
                  </GridTextItem>
                  <GridTextItem
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Button
                      variant={
                        ButtonVariant.Submit
                      }
                      style={{ lineHeight: '10px' }}
                      onClick={applyPass}
                      disabled={isBtnDisabled}
                      className='bg-orange hover:opacity-80 w-full font-inter'>
                      {isLoading ? 'Dodaję...' : 'Dodaj'}
                    </Button>
                  </GridTextItem>
                </React.Fragment>
              ))}
            </>
            {newestHistoryPass && appliedPass !== undefined &&
              newestHistoryPass.Pass.PassId === appliedPass.PassId && [newestHistoryPass].map((elem, idx) => (
                <React.Fragment key={idx}>
                  <GridTextItem>{elem.Pass.Name}</GridTextItem>
                  <GridTextItem>
                    {isEditing
                      && (editRow === elem.Pass.Name)
                      ? (
                        <StyledInputDate
                          type='date'
                          value={newDate}
                          onChange={handleNewDate}
                        />
                      ) : newestHistoryPass.Period.StartAt
                        ? getFormatedDate(
                          newestHistoryPass.Period.StartAt,
                          'date'
                        )
                        : '-'
                    }
                  </GridTextItem>
                  <GridTextItem>
                    {isEditing
                      && (editRow === elem.Pass.Name)
                      ? (
                        <StyledInput
                          style={{ background: "rgba(0,0,0,0.04)" }}
                          type="number"
                          value={amount}
                          onChange={handleAmountChange}
                        />
                      ) : elem.Price.Netto} zł
                  </GridTextItem>
                  <GridTextItem style={{ display: 'flex' }}>
                    {isTableEdit && !newPass && (
                      <div className="flex w-full h-full gap-2">
                        <Button
                          variant={ButtonVariant.Abort}
                          onClick={() => handleEditBtn(elem.Pass.Name)}
                          style={{ backgroundColor: 'rgba(0, 145, 255, 0.7)' }}
                          className='w-2/3 p-0'
                        >
                          <EditIcon className='h-1/3 w-full' />
                        </Button>
                        <Button
                          onClick={showWarningPopup}
                          variant={ButtonVariant.Abort}
                          disabled={(!appliedPass && !passId) || !playerId || isLoading}
                          className='w-1/3 p-0'
                        >
                          <DeleteIcon className='h-1/3' />
                        </Button>
                      </div>
                    )}
                  </GridTextItem>
                </React.Fragment>
              ))
            }
          </GridColumn>
        )}
      </HeaderContainer>
    </div>
  );
};

export default ApplyPass;
