import React from "react";

import TrainersDetail from 'components/trainers/detail/TrainersDetail';

const TrainerOutsideDatail = () => {

    return (
        <TrainersDetail />
    )
}

export default TrainerOutsideDatail;