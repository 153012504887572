import { conn } from 'api/BaseConnection';
import {
  IAttribute,
  IAttributes,
} from 'types/marketpleace/attribute.interface';
import {
  IAddCombination,
  IRemoveCombination,
  IUpdateCombination,
} from 'types/marketpleace/combination.interface';

const endpoint = conn.endpoints.marketpleace.product.combination;

const getAttributes = async () => {
  return await conn.getJSON<IAttributes>(endpoint.getAttributes, 'json');
};

const add = async (combination: IAddCombination) => {
  return await conn.postJSON(endpoint.add, 'json', combination);
};

const update = async (combination: IUpdateCombination) => {
  return await conn.putJSON(endpoint.update, 'json', combination);
};

const remove = async (combination: IRemoveCombination) => {
  return await conn.deleteJSON(endpoint.remove, 'json', combination);
};

const combinationServices = {
  getAttributes,
  add,
  update,
  remove,
};

export default combinationServices;
