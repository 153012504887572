import HeaderContainer from 'components/common/Containers/HeaderContainer';
import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import { IMerchantRegistryDispatcherProps } from '../Merchant';
import { useAcademyTabsContext } from 'components/academy/hook/useAcademyTabsContext';
import { MERCHANT_REGISTRATION_STATUS } from 'types/merchant.interface';
import MerchantStatus from '../components/MerchantStatus';

const MerchantDescription = ({
  setMerchantRegistrationDisplay,
  isSellerActiveted
}: IMerchantRegistryDispatcherProps) => {
  const { data: academy, refresh } = useAcademyTabsContext();
  return (
    <div className='w-full'>
      <HeaderContainer
        title='Sprzedawca'
        withContainer
        headerContent={
          <div className='mr-3'>
            <MerchantStatus status={academy.MerchantRegistrationStatus} />
          </div>
        }>
        <div className='flex flex-col gap-6'>
          <div className='flex flex-col gap-4 font-light opacity-80'>
            <div>
              Usługę płatności natychmiastowych dostarcza Firma PRZELEWY24.
            </div>
            <div>Wymagana jest dodatkowa rejestracja konta Merchant.</div>
            <div>
              Chcąc zarejestrować konto sprzedawcy wyślij dane rejestrowe.
            </div>
            <div>
              Uwaga! Usłyga może być aktywna jeśli zostaną poprawnie usupełnione
              dane w pozostałych polach.
            </div>
          </div>

          {academy.MerchantRegistrationStatus ===
            MERCHANT_REGISTRATION_STATUS['Gotowy do rejestracji'] && (
            <Button
              className='flex max-w-max self-end'
              disabled={isSellerActiveted ? false : true}
              variant={ButtonVariant.Submit}
              onClick={() => setMerchantRegistrationDisplay(true)}>
              Zarejestruj konto
            </Button>
          )}
        </div>
      </HeaderContainer>
    </div>
  );
};

export default MerchantDescription;
