import { ArtistPropItem } from "types/artistTypes";
import {
  DigitalReleaseRequest,
  IDigitalRelease,
  IAddProduct,
} from "types/digitalReleaseTypes";
import { IListPageRequest, IListPageResponse } from "types/globalTypes";

import { conn } from "../api/BaseConnection";

const endpoint = conn.endpoints.chat;

const add = (digitalRelease: IDigitalRelease | any) => {
  return conn.postJSON(endpoint, "json", digitalRelease);
};

const remove = (id: string) => {
  return conn.deleteJSON(`${endpoint}/${id}`);
};

const removeMessage = (MessageId: string, ConversationId: string) => {
  return conn.deleteJSON(`${endpoint}/RemoveMessage`, "json", {
    MessageId,
    ConversationId,
  });
};

const removeConversation = (ConversationId: string) => {
  return conn.deleteJSON(`${endpoint}/DeletePrivateConversation`, "json", {
    ConversationId,
  });
};

const deleteProfileFromConversation = (
  ConversationId: string,
  Id: string,
  Type: number,
) => {
  return conn.deleteJSON(`${endpoint}/deleteProfileFromConversation`, "json", {
    ConversationId,
    RemoveProfile: {
      Id,
      Type,
    },
  });
};

const removeGroupConversation = (ConversationId: string) => {
  return conn.deleteJSON(`${endpoint}/DeleteGroupConversation`, "json", {
    ConversationId,
  });
};

const edit = (digitalRelease: Partial<DigitalReleaseRequest>) => {
  return conn.putJSON(endpoint, "json", digitalRelease);
};

const getAll = (
  pageInfo: IListPageRequest,
): Promise<IListPageResponse<IDigitalRelease>> => {
  return conn.getJSON<IListPageResponse<IDigitalRelease>>(
    endpoint,
    "json",
    pageInfo,
  );
};

// const getAllConversations = (
//   SearchQuery: string,
//   IsClosed: any,
// ): Promise<IListPageResponse<any>> => {
//   return conn.getJSON(
//     `${endpoint}/Filter?IsClosed=${IsClosed}&PageSize=300&SearchQuery=${SearchQuery}`,
//     "json",
//   );
// };

export const getAllConversations = (SearchQuery: any) => {
  if (SearchQuery) {
    return conn.getJSON(`${endpoint}/GetConversations`, "json", {
      SearchQuery,
    });
  } else {
    return conn.getJSON(`${endpoint}/GetConversations`, "json");
  }
};

export const getConversation = (ConversationId: string): Promise<any> => {
  return conn.getJSON(`${endpoint}/GetMessages`, "json", { ConversationId });
};

export const getGroupConversationById = (
  ConversationId: string,
): Promise<any> => {
  return conn.getJSON(`${endpoint}/GetGroupConversationById`, "json", {
    ConversationId,
  });
};

export const getPrivateConversationById = (
  MemberId: string,
  MemberType: number,
): Promise<any> => {
  return conn.getJSON(`${endpoint}/GetPrivateConversationById`, "json", {
    MemberId,
    MemberType,
  });
};

export const getConversationMembers = (
  ConversationId: string,
): Promise<any> => {
  return conn.getJSON(`${endpoint}/GetConversationMembers`, "json", {
    ConversationId,
  });
};

export const getAllUnreadMessages = (): Promise<any> => {
  return conn.getJSON(`${endpoint}/GetAllUnreadMessages`, "json");
};

export const getOnlineProfiles = (): Promise<any> => {
  return conn.getJSON(`${endpoint}/GetOnlineProfiles`, "json");
};

const sendMessage = (ConversationId: string, Content: string) => {
  return conn.postJSON(`${endpoint}/SendMessage`, "json", {
    ConversationId,
    Content,
  });
};

const joinProfileToConversation = (
  ConversationId: string,
  Id: string,
  Type: number,
) => {
  return conn.postJSON(`${endpoint}/JoinProfileToConversation`, "json", {
    ConversationId,
    InvitedProfile: {
      Id,
      Type,
    },
  });
};

const replyToMessage = (
  ConversationId: string,
  MessageId: string,
  Content: string,
) => {
  return conn.postJSON(`${endpoint}/ReplyToMessage`, "json", {
    ConversationId,
    Content,
    MessageId,
  });
};

const forwardMessage = (ConversationId: string, MessageId: string) => {
  return conn.postJSON(`${endpoint}/ForwardMessage`, "json", {
    ConversationId,
    MessageId,
  });
};

const readMessage = (ConversationId: string) => {
  return conn.postJSON(`${endpoint}/ReadMessage`, "json", {
    ConversationId,
  });
};

const readMessageById = (ConversationId: string, MessageId: string) => {
  return conn.postJSON(`${endpoint}/ReadMessageById`, "json", {
    ConversationId,
    MessageId,
  });
};

const sendFile = (ConversationId: string, Base64String: string) => {
  return conn.postJSON(`${endpoint}/SendFilesMessage`, "json", {
    ConversationId,
    FilePaths: [Base64String],
  });
};

const sendVoiceMessage = (
  ConversationId: string,
  VoiceMessageFilePath: string,
) => {
  return conn.postJSON(`${endpoint}/SendVoiceMessage`, "json", {
    ConversationId,
    VoiceMessageFilePath,
  });
};

const createNewConversation = (InterlocutorId: string, ProfileType: number) => {
  return conn.postJSON(`${endpoint}/CreatePrivateConversation`, "json", {
    Interlocutor: {
      InterlocutorId,
      ProfileType,
    },
  });
};

const createNewGroupConversation = (
  Name: string,
  LogoFilePath: string,
  Interlocutors: any,
  ConversationId: string,
) => {
  return conn.postJSON(`${endpoint}/CreateGroupConversation`, "json", {
    Name,
    LogoFilePath,
    Interlocutors,
    ConversationId,
  });
};

const leaveConversation = (ConversationId: string) => {
  return conn.postJSON(`${endpoint}/LeaveConversation`, "json", {
    ConversationId,
  });
};

const changeConversationName = (ConversationId: string, NewName: string) => {
  return conn.putJSON(`${endpoint}/RenameConversation`, "json", {
    ConversationId,
    NewName,
  });
};

const changeConversationLogo = (
  ConversationId: string,
  LogoFilePath: string,
) => {
  return conn.putJSON(`${endpoint}/ChangeConversationLogo`, "json", {
    ConversationId,
    LogoFilePath,
    LogoFilePathMin: LogoFilePath,
  });
};

const chatServices = {
  add,
  edit,
  remove,
  getAll,
  getAllConversations,
  getConversation,
  sendMessage,
  sendFile,
  createNewConversation,
  createNewGroupConversation,
  leaveConversation,
  sendVoiceMessage,
  readMessageById,
  readMessage,
  forwardMessage,
  replyToMessage,
  removeMessage,
  removeConversation,
  removeGroupConversation,
  changeConversationName,
  changeConversationLogo,
  joinProfileToConversation,
  getConversationMembers,
  deleteProfileFromConversation,
  getGroupConversationById,
  getPrivateConversationById,
  getOnlineProfiles,
  getAllUnreadMessages,
};

export default chatServices;
