import { IPlayerClubHistory } from 'types/transfer/player.interface';
import PlayerTimeline from './PlayerTimeline';
import { useEffect, useState } from 'react';
import playerService from 'services/transfer/playerServices';
import TabContent from 'components/common/tabs/TabContent';

interface IPlayerCareerProps {
  PlayerCardId?: string;
  PlayerId: string;
}

const PlayerCareer = ({ PlayerCardId, PlayerId }: IPlayerCareerProps) => {
  const [clubHistories, setClubHistories] = useState<IPlayerClubHistory[]>([]);

  const getPlayerClubHistories = async (PlayerCardId: string) => {
    const response = await playerService.getClubHistories(
      PlayerId,
      PlayerCardId
    );

    setClubHistories(response);
  };

  useEffect(() => {
    PlayerCardId && getPlayerClubHistories(PlayerCardId);
  }, []);

  return (
    <TabContent id='career'>
      <div className='flex flex-col w-full'>
        <PlayerTimeline clubHistories={clubHistories} />
      </div>
    </TabContent>
  );
};

export default PlayerCareer;
