import { IAddress } from 'types/address.interface';
import { PLAYER_STATE, TRANSFER_LEVEL } from './player.interface';

export interface ISentTransfer {
  RequestId: string;
  PlayerId: string;
  Name: string;
  Address: IAddress;
  Receiver: RELATION_RECEIVER;
  Level: TRANSFER_LEVEL;
  State: PLAYER_STATE;
  Birthday: Date;
  TrainerId?: string;
  LicenseType?: number;
  LicenseId?: number;
}

export enum RELATION_RECEIVER {
  'Trener',
  'Akademia',
}

export enum OBSERVATION_LEVEL {
  'Podstawowa',
  'Pełna'
}

export enum LICENSE_TYPE
{
  'UEFA',
  'UEFA_PRO'
}

export enum TRAINER_TYPE
{
  'Trener' ,
  'Skaut',
  'Menager',
  'Analityk',
}