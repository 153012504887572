import { LegacyRef, ReactNode } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import TableHeader from './DataTableHeader';
import TableRow from './DataTableRow';

import 'overlayscrollbars/css/OverlayScrollbars.css';

export interface IDataTableRows {
  data?: { link?: string };
  cols: Array<ReactNode>;
}

interface ITableProps {
  colSizes?: Array<number>;
  containerRef?: LegacyRef<HTMLTableSectionElement> | any;
  headers: Array<ReactNode>;
  lastItemRef?: LegacyRef<HTMLTableSectionElement> | any;
  rows: Array<IDataTableRows>;
  isDataLoading?: boolean;
  emptyListMessage?: string;
}

const DataTable: React.FC<ITableProps> = ({
  colSizes = [],
  headers,
  rows,
  containerRef,
  isDataLoading = false,
  lastItemRef,
  emptyListMessage,
}) => {
  return (
    <OverlayScrollbarsComponent
      className='pb-12 pr-4'
      style={{ width: '100%' }}>
      <table
        className='table'
        cellSpacing={12}
        // cellPadding={10}
        style={{ minWidth: '800px' }}>
        <colgroup>
          {colSizes.map((size, idx) => (
            <col key={idx} style={{ width: size + '%' }} />
          ))}
        </colgroup>

        <TableHeader headers={headers} />
        <tbody ref={containerRef}>
          {!isDataLoading &&
            rows.map((row, index) => {
              const isLast = index === rows.length - 1;
              return isLast ? (
                <TableRow key={index} row={row} innerRef={lastItemRef} />
              ) : (
                <TableRow key={index} row={row} />
              );
            })}
        </tbody>
      </table>
      {isDataLoading && (
        <div className='flex w-full justify-center items-center text-center text-white opacity-70 h-12 font-light bg-white bg-opacity-5 '>
          Wczytuję dane...
        </div>
      )}
      {!isDataLoading && !rows.length && (
        <div className='flex w-full justify-center items-center text-center text-white opacity-70 h-12 font-medium bg-white bg-opacity-5 uppercase '>
          {emptyListMessage ?? 'brak zaproszeń'}
        </div>
      )}
    </OverlayScrollbarsComponent>
  );
};

export default DataTable;
