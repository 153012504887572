import TabContent from 'components/common/tabs/TabContent';
import React, { useEffect, useState } from 'react';
import Merchant from './Merchant';
import MerchantRegistry from './Registry/MerchantRegistry';
import { ISellerDetail } from 'types/seller.interface';
import sellerServices from 'services/sellerServices';

interface Props {
  isSellerActiveted?: boolean
}

const AcademyMerchant: React.FC<Props> = ({ isSellerActiveted }) => {
  const [isMerchantRegistrationDisplayed, setMerchantRegistrationDisplay] =
    useState(false);

  const [seller, setSeller] = useState<ISellerDetail>();

  const getSellerDetail = async () => {
    const response = await sellerServices.getSeller();

    setSeller(response);
  };

  useEffect(() => {
    getSellerDetail();
  }, []);

  return (
    <TabContent id='merchant'>
      <div className='flex flex-col w-full gap-12'>
        {!isMerchantRegistrationDisplayed ? (
          <Merchant
          seller={seller} setMerchantRegistrationDisplay={setMerchantRegistrationDisplay}
          isSellerActiveted={isSellerActiveted}
          />
        ) : (
          <MerchantRegistry
          seller={seller} setMerchantRegistrationDisplay={setMerchantRegistrationDisplay}
          />
        )}
      </div>
    </TabContent>
  );
};

export default AcademyMerchant;
