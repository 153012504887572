import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  IDigitalRelease,
  Product,
  IAddProduct,
  DigitalReleaseRequest,
} from "types/digitalReleaseTypes";

import { showServerErrors } from "utils/errorsUtils";

import { useTranslation } from "react-i18next";
import EditDigitalReleaseForm from "./EditDigitalReleaseForm";
import eventsServices from "services/eventsServices";
import EditDraftEventForm from "./EditDraftEventForm";
import { getCyclicalEventDetail } from "services/eventServices";
import systemServices from "services/systemServices";
import cyclicalEventServices from "services/cyclicalEventServices";

const EditDraftEvent: React.FC<any> = ({
  clickedCyclicalEventId,
  isAddMenuOpen,
  setClickedCyclicalEventId,
}) => {
  const [event, setEvent] = useState<any>({});
  const { goBack } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const getCyclicalEventDetailFn = async (id: string) => {
    const resp = await getCyclicalEventDetail(clickedCyclicalEventId);
    console.log("RESEPSEPSPESPESPES", resp.Data);
    setEvent(resp.Data);
  };

  useEffect(() => {
    if (clickedCyclicalEventId) {
      getCyclicalEventDetailFn(clickedCyclicalEventId);
    }
  }, [clickedCyclicalEventId]);

  useEffect(() => {
    if (!isAddMenuOpen) {
      setClickedCyclicalEventId("");
    }
  }, [isAddMenuOpen]);

  const handleSubmit = async (
    digitalReleaseData: any,
    OccurenceDate: any,
    EndDate: any,
    Visibility: any,
    ActivityLevel: any,
    ActivityType: any,
    FieldId: any,
    FieldValueId: any,
    Limit: any,
    allPlayers: any,
    OrganizerType: any,
    RepetitationInterval: any,
    Latitude: number,
    Longitude: number,
    IsCalendarChecked: boolean,
    TimeInterval: any,
    IsPostChecked: boolean,
    IsDraft: boolean,
    CyclicalEventId: string,
    FilesArray: any,
    AllFolders: any,
  ) => {
    try {
      let newProduct = digitalReleaseData;
      console.log("newproduct", newProduct);
      const imageUrl =
        IsPostChecked && newProduct?.photos
          ? await systemServices.uploadFile(newProduct?.photos)
          : "";

      const newOccurenceDate = new Date(OccurenceDate);
      const occurenceDateUTC = newOccurenceDate.toISOString();

      const newEndDate = new Date(EndDate);
      const endDateUTC = newEndDate.toISOString();

      await cyclicalEventServices.editCyclicalEvent(
        newProduct,
        imageUrl,
        occurenceDateUTC,
        endDateUTC,
        Visibility,
        ActivityLevel,
        ActivityType,
        FieldId,
        FieldValueId,
        Limit,
        allPlayers,
        OrganizerType,
        RepetitationInterval,
        Latitude,
        Longitude,
        IsCalendarChecked,
        TimeInterval,
        IsPostChecked,
        IsDraft,
        CyclicalEventId,
        FilesArray,
        AllFolders,
      );

      toast.success("Dodano cykliczne zajęcie!");
      window.location.reload();
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  console.log("QWEQWEQWE", event);
  const isEventNotEmpty = Object.keys(event).length > 0;

  return isEventNotEmpty ? (
    <>
      <EditDraftEventForm event={event} onSubmit={handleSubmit} isEdit={true} />
    </>
  ) : (
    <div>Loading...</div>
  );
};

export default EditDraftEvent;
