import { ReactNode, useState } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import TableHeader from "./DataTableHeader";
import TableRow from "./DataTableRow";
import "overlayscrollbars/css/OverlayScrollbars.css";
import HomeDataTableHeader from "./HomeDataTableHeader";
import HomeDataTableRow from "./HomeDataTableRow";
import '../../../styles/animationRing.scss';
import LoaderRing from 'components/common/loaderRing/LoaderRing';

import { TABLE_AVG_VAL } from '../../../types/homeTabelTypes';

interface ITableProps {
  colSizes?: Array<number>;
  containerRef?: any;
  headers: any;
  lastItemRef?: any;
  // rows: Array<{ data?: { link?: string }; cols: Array<ReactNode> }>;
  rows: any,
  isDataLoading: boolean;
  filterBy?: (primaryValue: string, secondaryValue: string) => void;
  tabelAvgVal?: TABLE_AVG_VAL;
}

const HomeDataTable: React.FC<ITableProps> = ({
  colSizes = [],
  headers,
  rows,
  containerRef,
  isDataLoading,
  lastItemRef,
  filterBy,
  tabelAvgVal
}) => {

  return (
    <OverlayScrollbarsComponent style={{ width: '100%', height: '100%' }}>
      <table className="table min-w-full"
      >
        <colgroup>
          {colSizes.map((size, idx) => (
            <col key={idx} style={{ width: size + "%" }} />
          ))}
        </colgroup>

        <HomeDataTableHeader
          headers={headers}
          filterBy={filterBy}
          tabelAvgVal={tabelAvgVal}
        />

        <tbody>
          {rows.map((row: any, rowIndex: any) => (
            <tr
              key={rowIndex}
            >
              {row.cols.map((col: any, colIndex: any) => (
                <td key={colIndex}
                >
                  {Array.isArray(col) ? (
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 50%)',
                        fontSize: '1rem',
                        marginLeft: '-3px',
                        alignItems: 'center',
                      }}
                    >
                      {col.map((nestedCol, nestedIndex) => (
                        <div
                          key={nestedIndex}
                          style={{
                            fontSize: '0.75cqw',
                            borderRight: nestedIndex === 0 ? '4px solid rgba(2, 2, 15, 0.3)' : '',
                            height: '48px',
                            display: 'flex',
                            alignContent: 'center',

                          }}
                        >
                          <div style={{
                            height: '48px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            width: '100%',

                          }}>
                            <span
                              style={{
                                padding: '0 5%'
                              }}
                            >
                              {nestedCol}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: (typeof col === 'string' || colIndex === 3)
                          ? 'center'
                          : 'flex-end',
                      }}
                    >
                      <span
                        style={{
                          padding: typeof col === 'string'
                            ?
                            '' : '0 10%'
                        }}
                      >
                        {col}
                      </span>
                    </div>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {isDataLoading && <LoaderRing text='wczytywanie danych...' />}

      {!rows.length && !isDataLoading &&
        <div className="flex w-full justify-center items-center text-center text-white opacity-70 h-12 font-medium bg-white bg-opacity-5 uppercase">
          <div>Brak danych</div>
        </div>
      }
    </OverlayScrollbarsComponent>
  );
};

export default HomeDataTable;
