import NewFilterPanel, { Filter } from 'components/filters/NewFilterPanel';
import React, { useEffect, useState } from 'react';
import { getProductFilters } from '../utils/helpers';

interface IFilterProductsProps {
  isMenuOpen: boolean;
}

const FilterProducts = ({ isMenuOpen }: IFilterProductsProps) => {
  const [productsFilters, setProductsFilters] = useState<Filter[] | null>(null);

  const setNewFilters = async () => {
    const filters = await getProductFilters();
    setProductsFilters(filters);
  };

  useEffect(() => {
    isMenuOpen && setNewFilters();
  }, [isMenuOpen]);

  return <NewFilterPanel filters={productsFilters ?? undefined} />;
};

export default FilterProducts;
