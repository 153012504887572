import { IFilterValue } from 'components/filters/NewFilterPanel';
import { IStrictDetailTab, ITab } from 'types/globalTypes';
import { IScanner, IScannerForm } from 'types/scanner/scanner.interface';

export const getNotEmptyFields = <T>(object: Record<string, any>): Partial<T> =>
  Object.keys(object).reduce(
    (acc, key) =>
      object[key] || typeof object[key] === 'number'
        ? { ...acc, [key]: object[key] }
        : acc,
    {}
  );

export const getFilterTabsByEnum = <T extends { [key: number]: string }>(
  enumType: T
): Array<IFilterValue<keyof T>> =>
  Object.keys(enumType)
    .filter(key => typeof enumType[key] !== 'number')
    .map(key => ({ backend: key, pl: enumType[key] }));

export const getDetailTabsByEnum = <T extends { [key: number]: string }>(
  enumType: T
): Array<ITab> =>
  Object.keys(enumType).map(key => ({
    id: `${key}`,
    label: enumType[key],
  }));

export const getTabsByStrictLevel = <STRICT_LEVEL>(
  tabs: IStrictDetailTab<STRICT_LEVEL>[],
  strictLevel: STRICT_LEVEL
): ITab[] =>
  tabs.map(tab => ({
    id: tab.id,
    label: tab.name,
    disabled: !(strictLevel === null && strictLevel !== undefined
      ? tab.strictLevel === null
      : tab.strictLevel <= strictLevel || tab.strictLevel === null),
  }));