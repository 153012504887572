import styled from "styled-components";
import Folder from "assets/icons/structure-folder.png";
import { useRef, useState } from "react";

interface Props {
  name: string;
  style?: React.CSSProperties;
  click: any;
}

const SearchSingleFolder: React.FC<Props> = ({ name, style, click }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleFolderMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Container
      style={style}
      onMouseEnter={handleMouseEnter}
      onClick={click}
      onMouseLeave={handleFolderMouseLeave}
      tabIndex={0}
    >
      <div>
        <div>
          <p>{name ? name : "-"}</p>

          <div>
            <div>
              <p>{"0"}</p>
            </div>
            <div>
              <p>ZAWODNIKÓW</p>
            </div>
          </div>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10.189"
          height="21"
          viewBox="0 0 10.189 21"
        >
          <path
            id="Path_21495"
            data-name="Path 21495"
            d="M449.656,1344.208a1.391,1.391,0,0,0,1.079-.513l7.411-9.107a1.393,1.393,0,0,0,0-1.755l-7.411-9.114a1.389,1.389,0,0,0-2.155,1.754l6.7,8.237-6.7,8.231a1.389,1.389,0,0,0,1.076,2.266Z"
            transform="translate(-448.268 -1323.208)"
            // fill="rgba(248,248,248,0.5)"
          />
        </svg>
      </div>
      <FolderSvg
        // width="340"
        // height="208"
        // viewBox="0 0 184.333 169"
        isHovered={isHovered}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="256"
          height="125.12"
          viewBox="0 0 256 125.12"
        >
          <defs>
            <filter
              id="Path_21500"
              x="0"
              y="0"
              width="256"
              height="125.12"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dy="8" />
              <feGaussianBlur stdDeviation="2.5" result="blur" />
              <feFlood />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_21500)">
            <path
              id="Path_21500-2"
              data-name="Path 21500"
              d="M232.948,14.65,47.479,14.482a1.466,1.466,0,0,1-1.312-.821L40.059,1.552A2.825,2.825,0,0,0,37.538,0H7.059A7.059,7.059,0,0,0,0,7.059v94.5a7.059,7.059,0,0,0,7.059,7.059H232.942A7.059,7.059,0,0,0,240,101.561V21.71a7.059,7.059,0,0,0-7.052-7.059"
              transform="translate(8 0.5)"
              fill="#1C2A37"
              stroke="rgba(156,176,196,0.3)"
              stroke-width="1"
              fill-rule="evenodd"
            />
          </g>
        </svg>
      </FolderSvg>
    </Container>
  );
};

export default SearchSingleFolder;

// const FolderSvg = styled.svg<{ isHovered: boolean }>`
//   position: absolute;
//   top: -4px;
//   left: -2px;
//   z-index: 2;
//   stroke: ${(props) =>
//     props.isHovered ? "rgba(0,145,255,1)" : "rgba(156, 176, 196, 0.3)"};
// `;

const FolderSvg = styled.svg<{
  isHovered: boolean;
}>`
  position: absolute;
  top: -4px;
  left: -18px;

  z-index: 2;
  fill: #1d2a36;
  stroke: ${(props) =>
    props.isHovered ? "rgba(0,145,255,1)" : "rgba(156, 176, 196, 0.3)"};
  /* padding-left: 20px; */
  & > svg {
    & > g {
      & > path {
        width: 220px !important;
        stroke: ${(props) =>
          props.isHovered ? "rgba(0,145,255,1)" : "rgba(156, 176, 196, 0.3)"};
      }
    }
  }
  /* width: 100%;
  height: 100%; */
`;

const Container = styled.div`
  cursor: pointer;
  width: 100%;
  height: 108px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  a:focus &:focus {
    outline: none;
  }
  * > :focus {
    outline: none;
  }

  & > div:first-of-type {
    height: 97px;
    /* margin-left: 20px; */
    /* width: 100%; */
    /* width: 100%; */
    transition: width 0.1s ease-in-out;
    width: 220px;

    background: #0091ff;

    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
    /* border-radius: 16px; */
    border-radius: 7px;
    margin-left: 12px;

    & > div {
      height: 70%;

      min-width: 230px;
      display: flex;
      margin-right: 10px;
      flex-direction: column;
      gap: 8px;
      z-index: 3;
      border-top: 1px solid black;

      display: flex;
      justify-content: center;
      align-items: center;
      /* padding-right: 30px; */

      & > p:nth-of-type(1) {
        font-size: 15px;
        color: white;
        line-height: 15px;
        height: 50px;
        padding-top: 15px;
      }

      & > div {
        position: relative;
        font-size: 11px;
        color: white;
        width: 100%;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;

        & > div:first-of-type {
          width: 40px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0 8px;
        }

        & > div:last-of-type {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          & > p {
            color: rgb(156, 176, 196);
            font-size: 11px;
          }
        }
      }
    }

    & > svg {
      position: absolute;
      /* right: 10px; */
      fill: rgba(248, 248, 248, 0.5);
      right: 10px;
      stroke: none;
      transition: all 0.1s ease-in-out;
    }
  }
`;

// const Container = styled.div`
//   cursor: pointer;
//   width: 192px;
//   height: 139.5px;
//   position: relative;
//   display: flex;
//   align-items: flex-end;
//   justify-content: flex-start;

//   a:focus &:focus {
//     outline: none;
//   }
//   * > :focus {
//     outline: none;
//   }

//   & > div:first-of-type {
//     height: 116px;
//     /* width: 100%; */
//     /* width: 100%; */
//     transition: width 0.1s ease-in-out;
//     width: 80%;

//     background: rgba(156, 176, 196, 0.11);

//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
//     padding: 12.5px;
//     border-radius: 16px;

//     & > div {
//       height: 100%;
//       width: 140px;
//       display: flex;
//       flex-direction: column;
//       gap: 8px;
//       z-index: 3;
//       margin-right: 15px;

//       & > p:nth-of-type(1) {
//         font-size: 13px;
//         color: white;
//         line-height: 15px;
//         height: 50px;
//       }

//       & > div {
//         font-size: 11px;
//         color: white;
//         width: 100%;
//         display: flex;
//         height: 100%;
//         align-items: flex-end;

//         & > div:first-of-type {
//           width: 40px;
//           display: flex;
//           justify-content: flex-start;
//           align-items: center;
//           padding-left: 8px;
//         }

//         & > div:last-of-type {
//           display: flex;
//           justify-content: flex-start;
//           align-items: center;

//           & > p {
//             color: rgb(156, 176, 196);
//             font-size: 11px;
//           }
//         }
//       }
//     }

//     & > svg {
//       fill: #0091ff;
//       stroke: none;
//     }
//   }
// `;
