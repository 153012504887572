import styled from 'styled-components';

import SelectArrow from '../../../../assets/icons/selectArrow.png';

export const TableRowItem = styled.div`
  background: rgba(163, 158, 157, 0.09);
  color: white;
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  height: '62px';
  font-size: 11px;
  padding: 12px;
  text-align: center;
`;

export const StyledSelect = styled.select`
  background: transparent;
  border: 1px solid rgba(163, 158, 157, 0.3);
  width: 100%;
  border-radius: 5px;
  height: 40px;
  opacity: 1;
  text-align-last: center;
  text-align: center;
  background: url(${SelectArrow}) calc(100% - 10px) 60% no-repeat;
  -webkit-appearance: none;
  cursor: pointer;
  appearance: none;
  outline: none;

  & > * {
    background: rgb(57, 57, 65);
    opacity: 0.5;
    height: 48px;
  }
`;

export const StyledInput = styled.input`
  background: transparent;
  border: 1px solid rgba(163, 158, 157, 0.3);
  width: 100%;
  border-radius: 5px;
  height: 40px;
  opacity: 1;
  text-align-last: center;
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
  outline: none;

  & > * {
    background: rgb(57, 57, 65);
    opacity: 0.5;
    /* height: 48px; */
  }
  color-scheme: dark;
`;
