import { useState, useEffect, useContext } from "react";
import ContentContainer from "components/layout/ContentContainer";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import PlayersTable from "./PlayersTable";
import PlayersTopbar from "./PlayersTopbar";
import { DefaultSortContext } from "contexts/defaultSortContext";
import { IAcademyPlayer } from "types/transfer/player.interface";
import playerService from "services/transfer/playerServices";
import { DataViewMode, ISelectOption, LIST_SORT } from "types/globalTypes";
import { useDebounce } from "use-debounce";
import { enumToSelectOptions } from "utils/baseUtils";
import { getAllSentTransfers } from "services/transferServices";
import { getFolderDetail } from "services/folderServices";

const sorts = enumToSelectOptions(LIST_SORT);

const Players: React.FC = () => {
  const [defaultSort, setDefaultSort] = useContext(DefaultSortContext);

  const [sortBy, setSortBy] = useState<ISelectOption<LIST_SORT> | null>(null);

  const [queryString, setQueryString] = useState("");

  const [debounceSearchInput] = useDebounce(queryString.trim(), 500);

  const {
    items: players,
    loading,
    containerRef,
    lastItemRef,
    refresh,
  } = useInfiniteScroll<IAcademyPlayer>(
    playerService.getAcademy,
    debounceSearchInput,
  );

  const {
    items: transfers,
    loading: transfersLoading,
    containerRef: transfersContainerRef,
    lastItemRef: transfersLastItemRef,
    refresh: transfersRefresh,
  } = useInfiniteScroll<any>(getAllSentTransfers, debounceSearchInput);

  const handleQueryChange = (query: string) => {
    if (!query.length || query.length > 2) {
      setQueryString(query);
    }
  };

  const getUniqueGroupName = Array.from(
    new Set(transfers.map((item: any) => item.GroupName)),
  ).map((elem, idx) => {
    return {
      backend: elem,
      pl: elem,
    };
  });

  return (
    <ContentContainer
      title={"Zawodnicy"}
      TopBar={
        <PlayersTopbar
          refresh={refresh}
          sorts={sorts}
          sortBy={sortBy}
          setSortBy={setSortBy}
          defaultSort={defaultSort}
          handleQueryChange={handleQueryChange}
          getUniqueGroupName={getUniqueGroupName}
        />
      }
    >
      <PlayersTable
        data={transfers}
        containerRef={containerRef}
        lastItemRef={lastItemRef}
        isDataLoading={loading}
        sortBy={sortBy}
      />
      {/* <PlayersTable
        data={players}
        containerRef={containerRef}
        lastItemRef={lastItemRef}
        isDataLoading={loading}
        sortBy={sortBy}
      /> */}

      {/* {viewType === 'table' ? (
        <PlayersTable
          players={players}
          containerRef={containerRef}
          lastItemRef={lastItemRef}
          isDataLoading={loading}
          sortBy={sortBy}
        />
      ) : (
        <PlayersList
          sortBy={sortBy}
          players={players}
          containerRef={containerRef}
          lastItemRef={lastItemRef}
          isDataLoading={loading}
        />
      )} */}
    </ContentContainer>
  );
};

export default Players;
