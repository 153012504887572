import scannerServices from 'services/scanner/scannerServices';
import { IListPageRequest, LIST_SORT } from 'types/globalTypes';
import { IScannerPlayer } from 'types/scanner/scanner.interface';
import { compareArray } from 'utils/baseUtils';

export const getFilteredScannerPlayers = (
  array: IScannerPlayer[],
  type: LIST_SORT | null
) => {
  switch (type) {
    case 0:
      return array.slice().reverse();
    case 1:
      return array;
    case 2:
      return array
        .slice()
        .sort((a, b) => compareArray<IScannerPlayer>(a, b, 'Name'))
        .reverse();
    case 3:
      return array
        .slice()
        .sort((a, b) => compareArray<IScannerPlayer>(a, b, 'Name'));
    default:
      return array;
  }
};

export const getScanner = async (id: string, pageInfo: IListPageRequest) => {
  const response = await scannerServices.getDetail(id, pageInfo);

  return response;
};