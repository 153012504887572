import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { OneLinerText } from 'styles/styles';

interface IHeaderContainerProps {
  title: string;
  headerContent?: ReactNode;
  children: ReactNode;
  withContainer?: boolean;
  className?: string;
}

const HeaderContainer = ({
  children,
  title,
  headerContent,
  withContainer,
  className = '',
}: IHeaderContainerProps) => {
  return (
    <div className={`flex flex-col w-full gap-1`}>
      <div
        title={title}
        className={`flex justify-between items-center border-solid border-l-4 border-orange ${className}`}
        style={{
          backgroundColor: 'rgba(2, 2, 15, 0.3)',
          color: 'white',
          minHeight: '44px',
        }}>
        <OneLinerText className='flex pl-18 text-sm uppercase text-center'>
          {title}
        </OneLinerText>
        {headerContent}
      </div>
      {!withContainer ? (
        <div>{children}</div>
      ) : (
        <div
          className='text-white px-18 py-12'
          style={{ backgroundColor: 'rgba(163,158,157,0.05)' }}>
          {children}
        </div>
      )}
    </div>
  );
};

export default HeaderContainer;
