import { LIST_SORT } from 'types/globalTypes';
import {
  IAcademyPlayer,
  IAvaliablePlayer,
  IOutsidePlayer,
} from 'types/transfer/player.interface';
import { ISentTransfer } from 'types/transfer/transfer.interface';
import { compareArray } from 'utils/baseUtils';

export const getSortedRequestPlayersByListSort = (
  array: Array<ISentTransfer>,
  type?: LIST_SORT
) => {
  switch (type) {
    case 0:
      return array.slice().reverse();
    case 1:
      return array;
    case 2:
      return array
        .slice()
        .sort((a, b) => compareArray<ISentTransfer>(a, b, 'Name'))
        .reverse();
    case 3:
      return array
        .slice()
        .sort((a, b) => compareArray<ISentTransfer>(a, b, 'Name'));
    default:
      return array;
  }
};

export const getSortedAvailablePlayersByListSort = (
  array: Array<IAvaliablePlayer>,
  type?: LIST_SORT
) => {
  switch (type) {
    case 0:
      return array.slice().reverse();
    case 1:
      return array;
    case 2:
      return array
        .slice()
        .sort((a, b) => compareArray<IAvaliablePlayer>(a, b, 'Name'))
        .reverse();
    case 3:
      return array
        .slice()
        .sort((a, b) => compareArray<IAvaliablePlayer>(a, b, 'Name'));
    default:
      return array;
  }
};
