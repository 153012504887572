import cs from "classnames";
import { ButtonVariant, IButtonProps } from "../buttonTypes";
import styled from "styled-components";

const Button = styled.button`
  width: 48px;
  height: 48px;
  background: rgba(0, 145, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;

  & > i {
    & > svg {
      fill: white;
    }
  }
`;

const BackButton = ({
  className = "",
  children,
  disabled = false,
  size,
  type = "button",
  variant = ButtonVariant.Normal,
  onClick,
  ...props
}: IButtonProps) => {
  const classNames = cs("button", {
    "button--normal": variant === ButtonVariant.Normal,
    "button--submit": variant === ButtonVariant.Submit,
    "button--abort": [ButtonVariant.Abort, ButtonVariant.Remove].includes(
      variant,
    ),
    "button--disabled": disabled,
    [className]: className,
  });
  return (
    <Button
      {...props}
      // className={classNames}
      disabled={disabled}
      onClick={onClick}
      type={type}
      data-testid="button"
    >
      {children}
    </Button>
  );
};

export default BackButton;
