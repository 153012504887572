import { useState } from 'react';
// @ts-ignore
import HorizontalTimeline from 'react-horizontal-timeline';
import './styles.css';
import { IPlayerClubHistory } from 'types/transfer/player.interface';
import { compareArray } from 'utils/baseUtils';
import { HorizontalBox, TimelineDetailHeader, ValueBox } from './timelineStyles';
import { getFormatedDate } from 'utils/dateUtils';

interface IPlayerTimelineProps {
  clubHistories: IPlayerClubHistory[];
}

const PlayerTimeline = ({ clubHistories }: IPlayerTimelineProps) => {
  const [state, setState] = useState({ value: 0, previous: -1 });

  const histories = clubHistories.sort((a, b) => compareArray(a, b, 'From'));

  const selectedHistory = !!histories.length ? histories[state.value] : null;

  const timelineDates = !!histories.length
    ? histories.map(history => new Date(history.From))
    : null;

  return (
    <div>
      <HorizontalBox>
        <div>
          <HorizontalTimeline
            index={state.value}
            indexClick={(index: number) => {
              setState({ value: index, previous: state.value });
            }}
            values={timelineDates ?? [new Date()]}
            styles={{
              background: '#f8f8f8',
              foreground: '#0091FF',
              outline: 'rgba(255,255,255,0.15)',
            }}
          />
        </div>
      </HorizontalBox>

      <TimelineDetailHeader>
        <div
          style={{
            display: 'flex',
            gap: '13px',
            alignItems: 'center',
            minWidth: '150px',
          }}>
          <p>Akademia</p>
          <ValueBox>{selectedHistory?.Academy ?? '-'}</ValueBox>
        </div>

        <div style={{ display: 'flex', gap: '13px', alignItems: 'center' }}>
          <p>Grupa</p>
          <ValueBox>{selectedHistory?.Group?.split('/')[0] ?? '-'}</ValueBox>

          <p>Trener</p>
          <ValueBox>{selectedHistory?.Trainer ?? '-'}</ValueBox>
        </div>

        <div style={{ display: 'flex', gap: '13px', alignItems: 'center' }}>
          <p>Od</p>
          <ValueBox>
            {!!selectedHistory
              ? getFormatedDate(selectedHistory.From, 'dd-MM-yyyy')
              : '-'}
          </ValueBox>
          <p>Do</p>
          <ValueBox>
            {!!selectedHistory
              ? getFormatedDate(selectedHistory.To, 'dd-MM-yyyy')
              : '-'}
          </ValueBox>
        </div>
      </TimelineDetailHeader>
      <div
        style={{
          width: '100%',
          height: '48px',
          background: 'rgba(163,158,157,0.05)',
          marginTop: '5px',
          textTransform: 'uppercase',
          color: 'white',
          fontWeight: 400,
          fontSize: '15px',
          display: 'flex',
          alignItems: 'center',
          padding: '0px 26px',
          letterSpacing: '0.225px',
        }}>
        osiągnięcia
      </div>
      <div
        className='px-18 py-8'
        style={{
          display: 'flex',
          minHeight: '60px',
          background: 'rgba(163,158,157,0.05)',
          color: 'rgba(0,0,0,0.7)',
          marginTop: '5px',
        }}>
        <div>
          <p
            style={{
              fontSize: '13px',
              paddingTop: '13px',
              paddingBottom: '13px',
              paddingLeft: '8px',
              color: 'white',
              fontWeight: 400,
            }}>
            {selectedHistory?.Achievements ?? 'Brak osiągnięć'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PlayerTimeline;
