import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import RangeInput from 'components/common/inputs/rangeInput/RangeInput';
import NewSelectStatystics from 'components/common/inputs/select/NewSelectStatystics';
import TextField from 'components/common/inputs/textInput/TextField';
import TextFieldWithUnit from 'components/common/inputs/textInput/TextFieldWithUnit';
import TextInputWithUnit from 'components/common/inputs/textInput/TextInputWithUnit';
import { Form, Formik } from 'formik';
import { useUrlQuery } from 'hooks/useUrlQuery';
import { getScanner } from 'middleware/scanner/getFilteredPlayers';
import React, { useEffect, useState } from 'react';
import { SingleColumn } from 'styles/styles';
import { ISelectOption } from 'types/globalTypes';
import {
  ICreateScanner,
  IListedScanner,
  IScanner,
  IScannerForm,
  IUpdateScanner,
} from 'types/scanner/scanner.interface';
import { enumToSelectOptions } from 'utils/baseUtils';
import { object } from 'yup';
//@ts-ignore
import Modal from 'react-modal';
import Map from 'components/events/Map';
import { IGoogleLocation } from 'types/location.interface';
import { toast } from 'react-toastify';
import { showServerErrors } from 'utils/errorsUtils';
import scannerServices from 'services/scanner/scannerServices';
import {
  SCANNER_DOMINANT_LEG,
  SCANNER_GENDER,
  SCANNER_PREFERRED_POSITION,
  adjustFormToScanner,
  adjustScannerToForm,
  customStyles,
  dominantLegItems,
  genderItems,
  preferredPositionItems,
  scannerValues,
} from './utils';
import {
  DOMINANT_LEG,
  PREFERRED_POSITION,
} from 'types/transfer/player.interface';
interface IScannerFormProps {
  refreshScanner: () => Promise<void>;
  refreshFilters: () => Promise<void>;
  filter: ISelectOption<string> | null;
  setFilter: React.Dispatch<React.SetStateAction<ISelectOption<string> | null>>;
  filters: ISelectOption<string>[];
}

const ScannerForm = ({
  refreshScanner,
  refreshFilters,
  filter,
  filters,
  setFilter,
}: IScannerFormProps) => {
  const [scannerForm, setScannerForm] = useState<IScannerForm>(scannerValues);

  const [gender, setGender] =
    useState<ISelectOption<SCANNER_GENDER> | null>(null);
  const [dominantLeg, setDominantLeg] =
    useState<ISelectOption<SCANNER_DOMINANT_LEG> | null>(null);
  const [preferredPosition, setPreferredPosition] =
    useState<ISelectOption<SCANNER_PREFERRED_POSITION> | null>(null);

  const [isMapModalOpen, setIsMapModalOpen] = useState(false);

  const closeMapModalOpen = () => {
    setIsMapModalOpen(false);
  };

  const validateScanner = (form: IScannerForm) => {
    const scanner = adjustFormToScanner(form);

    if (!form.Latitude || !form.Longitude || !form.Address || !form.Area) {
      toast.error('Wybierz adres z mapy i podaj promień');
      return false;
    }

    if (!form.Name.length) {
      toast.error('Podaj prawidłową nazwę');
      return false;
    }

    return scanner;
  };

  const deleteScanner = async (id: string) => {
    try {
      await scannerServices.remove(id);

      await refreshFilters();
      await refreshScanner();
    } catch (error) {
      showServerErrors(error as any);
    }
  };
  const updateScanner = async (filterId: string, form: IScannerForm) => {
    try {
      const scanner = validateScanner(form);

      if (!scanner) {
        return;
      }

      await scannerServices.update(scanner as IUpdateScanner);

      await refreshFilters();
      await refreshScanner();
    } catch (error) {
      showServerErrors(error as any);
    }
  };
  const createScanner = async (form: IScannerForm) => {
    try {
      const scanner = validateScanner(form);

      if (!scanner) {
        return;
      }

      await scannerServices.create(scanner);

      await refreshFilters();
      await refreshScanner();
    } catch (error) {
      showServerErrors(error as any);
    }
  };

  const changeScannerActivation = async (checked: boolean) => {};

  const getScannerForm = async (id: string) => {
    const scanner = await getScanner(id, { pageNumber: 1 });

    setGender(
      scanner.Gender !== null
        ? {
            label: SCANNER_GENDER[scanner.Gender + 1],
            value: scanner.Gender + 1,
          }
        : null
    );
    setDominantLeg(
      scanner.DominantLeg !== null
        ? {
            label: SCANNER_DOMINANT_LEG[scanner.DominantLeg + 1],
            value: scanner.DominantLeg + 1,
          }
        : null
    );
    setPreferredPosition(
      scanner.PreferredPosition !== null
        ? {
            label: SCANNER_PREFERRED_POSITION[scanner.PreferredPosition + 1],
            value: scanner.PreferredPosition + 1,
          }
        : null
    );

    setScannerForm(adjustScannerToForm(scanner));
  };

  useEffect(() => {
    filter && getScannerForm(filter.value);
  }, [filter]);

  useEffect(() => {
    console.log('scanner form: ', scannerForm);
  }, [scannerForm]);

  return (
    <Formik
      initialValues={scannerForm}
      onSubmit={() => {}}
      validationSchema={object().shape({})}
      enableReinitialize={true}>
      {({ values, isSubmitting, setValues }) => (
        <>
          <div className='flex w-full justify-end pr-24'>
            <NewSelectStatystics<string>
              name='scanners'
              label='Filtry zawodników'
              items={filters}
              setSelectedItem={setFilter}
              selectedItem={filter}
              style={{ width: '350px' }}
              defaultValue={scannerForm.FilterId}
            />
          </div>
          <Form className='flex gap-6 p-24'>
            <Modal
              isOpen={isMapModalOpen}
              onRequestClose={closeMapModalOpen}
              style={customStyles}>
              <Map
                onLocationChange={(location: IGoogleLocation) =>
                  setValues(prev => ({
                    ...prev,
                    Longitude: location.position.lng,
                    Latitude: location.position.lat,
                    Address: `${location.country} ${location.city} ${location.postalCode} ${location.street} ${location.streetNumber}`,
                  }))
                }
              />
            </Modal>

            <SingleColumn>
              <p>dane podstawowe</p>

              <NewSelectStatystics
                name='Gender'
                label='Płeć'
                onChange={e =>
                  setValues(prev => ({
                    ...prev,
                    Gender: e.value === 0 ? null : parseInt(e.value) - 1,
                  }))
                }
                items={genderItems}
                selectedItem={gender}
                setSelectedItem={setGender}
                defaultValue={values.Gender ? values.Gender + 1 : 0}
              />
              <RangeInput label='Wiek (LAT)' name='Age' />
              <RangeInput label='Waga (KG)' name='Weight' />
              <RangeInput label='Wzrost (CM)' name='Height' />
              <p>Karta zawodnika</p>
              <NewSelectStatystics
                name='PreferredPosition'
                label='Preferowana pozycja'
                onChange={e =>
                  setValues(prev => ({
                    ...prev,
                    PreferredPosition:
                      e.value === 0 ? null : parseInt(e.value) - 1,
                  }))
                }
                items={preferredPositionItems}
                selectedItem={preferredPosition}
                setSelectedItem={setPreferredPosition}
                defaultValue={
                  values.PreferredPosition ? values.PreferredPosition + 1 : 0
                }
              />
              <NewSelectStatystics
                name='DominantLeg'
                label='Noga dominująca'
                onChange={e =>
                  setValues(prev => ({
                    ...prev,
                    DominantLeg: e.value === 0 ? null : parseInt(e.value) - 1,
                  }))
                }
                items={dominantLegItems}
                selectedItem={dominantLeg}
                setSelectedItem={setDominantLeg}
                defaultValue={values.DominantLeg ? values.DominantLeg + 1 : 0}
              />
              <p>Adres</p>
              <Button
                variant={ButtonVariant.Submit}
                onClick={() => setIsMapModalOpen(true)}>
                Wybierz z mapy
              </Button>

              <TextField name='Address' label='Adres' disabled />
              <div className='-mt-5'>
                <TextInputWithUnit name='Area' label='Promień' unit='KM' />
              </div>
            </SingleColumn>
            <SingleColumn>
              <p>dane szczegółowe</p>
              <RangeInput label='Tętno' name='HeartRate' />
              <RangeInput label='Dystans' name='Distance' />
              <RangeInput label='Kondycja' name='Fitness' />
              <RangeInput label='Dynamika' name='Dynamics' />
              <RangeInput label='Prędkość' name='Speed' />
              <RangeInput label='Energia' name='Energy' />
              <RangeInput label='HRS' name='Hrs' />
              <RangeInput label='HMLD' name='Hmld' />
              <p></p>
              <TextField name='Name' label='Nazwa' />

              {filter?.value?.length ? (
                <>
                  <div className='flex gap-2 w-full'>
                    <Button
                      className='w-full'
                      variant={ButtonVariant.Abort}
                      onClick={() => deleteScanner(filter?.value)}
                      disabled={!filter?.value?.length}>
                      Usuń
                    </Button>
                    <Button
                      className='w-full'
                      variant={ButtonVariant.Submit}
                      onClick={() => updateScanner(filter?.value, values)}
                      disabled={
                        scannerForm === values || !filter?.value?.length
                      }>
                      Zapisz
                    </Button>
                  </div>

                  {scannerForm.Name.trim().toLocaleLowerCase() !==
                    values.Name.trim().toLocaleLowerCase() && (
                    <Button
                      className='w-full'
                      style={{
                        backgroundColor: 'rgb(255, 146, 38)',
                        color: '#fff',
                      }}
                      onClick={() => createScanner(values)}
                      disabled={!values.Name.length || !filter?.value?.length}>
                      Zapisz jako nowy
                    </Button>
                  )}
                  {/* <div className='flex h-full w-full gap-2 items-center justify-end pr-8 text-white text-opacity-50 font-light'>
                  {isScannerActive ? 'Aktywny' : 'Niekatywny'}
                  <Switch
                    checked={isScannerActive}
                    onChange={checked => changeScannerActivation(checked)}
                    disabled={!filter?.value}
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                </div> */}
                </>
              ) : (
                <Button
                  className='w-full'
                  style={{
                    backgroundColor: 'rgb(255, 146, 38)',
                    color: '#fff',
                  }}
                  onClick={() => createScanner(values)}
                  disabled={!values.Name.length}>
                  Zapisz jako nowy
                </Button>
              )}
            </SingleColumn>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default ScannerForm;
