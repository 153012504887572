import { ResponseType } from "axios";
import jwtDecode from "jwt-decode";

import { conn } from "../api/BaseConnection";
import { IRequestSucces } from "../types/globalTypes";
import { IRegisterDTO } from "../types/userTypes";
import { IAuthToken, IDecodeToken } from "../store/reducers/auth";
import { showServerErrors } from "utils/errorsUtils";

const endpoint = conn.endpoints.auth;

const login = (
  Email: string,
  Password: string,
  requestType?: ResponseType,
): Promise<IAuthToken> => {
  return conn.postJSON(`${endpoint}/Login`, requestType, {
    Email,
    Password,
  });
};

const changePassword = (
  CurrentPassword: string,
  NewPassword: string,
  requestType?: ResponseType,
): Promise<IAuthToken> => {
  return conn.patchJSON(`${endpoint}/ChangePassword`, requestType, {
    CurrentPassword,
    NewPassword,
  });
};

const logout = (
  userId: string,
  requestType?: ResponseType,
): Promise<IRequestSucces> => {
  //TODO: handle logOut
  // return conn.postJSON(`${endpoint}/LogOut`, requestType, {
  //   userId,
  // });
  return new Promise((resolve, reject) => {
    resolve({ data: "ok" });
  });
};

const register = (
  user: Partial<IRegisterDTO>,
  profile: { label: string; value: number },
): Promise<string> => {
  return conn.postJSON(`${endpoint}/Register`, "json", {
    ...user,
    Profile: profile.value,
  });
};

const getTokenInfoFromLocal = () => {
  const token = localStorage.getItem("token");
  if (token) {
    const { exp } = jwtDecode<IDecodeToken>(token);
    const expiresInMiliseconds = exp * 1000;
    // const expiresInMiliseconds = exp * 1000 - 30000 - 60000 * 29;
    const expiresAt = new Date(expiresInMiliseconds);
    const milisecondsToExpire = expiresInMiliseconds - Date.now();
    return { token, expiresAt, milisecondsToExpire };
  }

  return { token: "", expiresAt: "", milisecondsToExpire: 0 };
};

//all in miliseconds
const CHECK_TIME_OFFSET = 10000;
const EXPIRES_OFFSET = CHECK_TIME_OFFSET * 2;
const MINIMUM_CHECK_TIME = 1000;

let sessionHandler: ReturnType<typeof setTimeout>;

const keepSession = () => {
  const { milisecondsToExpire } = getTokenInfoFromLocal();
  const checkTimeout = Math.max(
    milisecondsToExpire - CHECK_TIME_OFFSET,
    MINIMUM_CHECK_TIME,
  );

  if (sessionHandler) {
    clearTimeout(sessionHandler);
  }
  sessionHandler = setTimeout(async () => {
    const shouldRefreshToken =
      milisecondsToExpire - checkTimeout <= EXPIRES_OFFSET;
    if (shouldRefreshToken) {
      try {
        refreshToken();
        return keepSession();
      } catch (errors: any) {
        showServerErrors(errors);
      }
    }
  }, checkTimeout);
};

const setToken = (token: string, tokenType = "Bearer") => {
  const authorizationHeader = `${tokenType} ${token}`;
  conn.setAuthToken(authorizationHeader);
  localStorage.setItem("token", token);
};

export const refreshToken = async () => {
  try {
    await conn.postJSON(`${endpoint}/RefreshToken`, "json", {
      token: localStorage.getItem("token"),
    });

    // if (token) {
    //   setToken(token);
    // }
  } catch (error: any) {
    localStorage.removeItem("token");
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window.location.pathname === "/login";
    throw error;
  }
};

const revokeToken = () => {
  clearTimeout(sessionHandler);
  conn.setAuthToken("");
  localStorage.removeItem("token");
  localStorage.removeItem("token-expires");
};

const remindPassword = (Email: string) => {
  return conn.getJSON(`${endpoint}/ResetPasswordToken`, "json", {
    Email,
  });
};

const resetPassword = (UserId: string, Token: string, NewPassword: string) => {
  const ValidToken = Token.replaceAll(/ /g, "+");
  return conn.postJSON(`${endpoint}/ResetPassword`, "json", {
    UserId,
    Token: ValidToken,
    NewPassword,
  });
};

// const resetPassword = (UserId: string, Token: string, NewPassword: string) => {

//   const headers = {
//     Authorization: `Bearer ${Token}`,
//   };

//   return conn.postxJSON(
//     `${endpoint}/ResetPassword`,
//     "json",
//     {
//       UserId,
//       Token,
//       NewPassword,
//     },
//     headers,
//   );
// };

const authService = {
  getTokenInfoFromLocal,
  keepSession,
  login,
  resetPassword,
  logout,
  refreshToken,
  register,
  changePassword,
  remindPassword,
  revokeToken,
  setToken,
};

export default authService;
