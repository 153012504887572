import React, { useContext, useEffect, useState } from 'react';
import ContentContainer from 'components/layout/ContentContainer';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { DefaultSortContext } from 'contexts/defaultSortContext';
import {
  IListPageRequest,
  IListPageResponse,
  ISelectOption,
  LIST_SORT,
} from 'types/globalTypes';
import { useDebounce } from 'use-debounce';
import { enumToSelectOptions } from 'utils/baseUtils';
import { LIST_VIEW } from 'types/transfer/player.interface';
import RequestedPlayersTable from './RequestedPlayersTable';
import AvailablePlayersTable from './AvailablePlayersTable';
import {
  IListedScanner,
  IScanner,
  IScannerPlayer,
} from 'types/scanner/scanner.interface';
import { getScanner } from 'middleware/scanner/getFilteredPlayers';
import ScannerTopbar from './ScannerTopbar';
import ScannerTable from './ScannerTable';
import scannerServices from 'services/scanner/scannerServices';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import { useSelector } from 'react-redux';

const sorts = enumToSelectOptions(LIST_SORT);

const listViews = enumToSelectOptions(LIST_VIEW);

const OutsidePlayers = () => {
  const [defaultSort, setDefaultSort] = useContext(DefaultSortContext);

  const [sortBy, setSortBy] = useState<ISelectOption<LIST_SORT> | null>(null);

  const [queryString, setQueryString] = useState('');

  const [listView, setListView] =
    useState<ISelectOption<LIST_VIEW> | null>(null);

  const [debounceSearchInput] = useDebounce(queryString.trim(), 500);

  const [scanner, setScanner] = useState<IScanner>();

  const [filters, setFilters] = useState<ISelectOption<string>[]>([]);

  const [filter, setFilter] = useState<ISelectOption<string> | null>(null);

  const [isQueryEmpty, setIsQueryEmpty] = useState(false);

  const selectOptionFilters = (filters: IListedScanner[]) =>
    filters.map(filter => ({
      label: `${filter.Name} ( ${
        filter.IsActivate ? 'Aktywny' : 'Nieaktywny'
      } )`,
      value: filter.FilterId,
    }));

  const getFilters = async () => {
    const response = await scannerServices.getListing();

    setFilters(selectOptionFilters(response.Items));

  };

  useEffect(() => {
    getFilters();
  }, []);

  const getScannerPlayers = async (
    pageInfo: IListPageRequest
  ): Promise<IListPageResponse<IScannerPlayer>> => {
    if (!filter) {
      return {
        Items: [],
        HasNextPage: false,
        HasPreviousPage: false,
        pageNumber: 0,
      };
    }

    console.log(filter.value);
    const scannerResponse = await getScanner(filter.value, pageInfo);

    setScanner(scannerResponse);

    return scannerResponse.Players;
  };

  const {
    items: scannerPlayers,
    loading,
    containerRef,
    lastItemRef,
    refresh: refreshScanner,
  } = useInfiniteScroll<IScannerPlayer>(getScannerPlayers, debounceSearchInput);

  useEffect(() => {
    console.log('api/Scanner/Detail :', scanner);
  }, [scanner]);

  useEffect(() => {
    console.log('api/Scanner/Detail (players) :', scannerPlayers);
  }, [scannerPlayers]);

  useEffect(() => {
    refreshScanner();
  }, [filter]);

  const handleQueryChange = (query: string) => {
      setQueryString(query);
  };

  useEffect(() => {

    if ( listView?.value === LIST_VIEW["Dostępni zawodnicy"]) {
      setIsQueryEmpty(true);
      setQueryString('')
    } else {
      setIsQueryEmpty(false)
      setQueryString('')
      }

  }, [listView])

  const viewLabels = [
    { label: 'Dostępni zawodnicy', value: 0 },
    { label: 'Zaproszenia', value: 1 },
  ];

  const permission = useSelector((state: any) => state.permissions.permissions);

  const isFilterPlayersActive = permission[0]?.Id === -1 ? true : !!permission.find((permission: any) => permission.Id === 154 );

  return (
    <ContentContainer
      title={'Radar zawodników'}
      TopBar={
        <ScannerTopbar
          defaultSort={defaultSort}
          handleQueryChange={handleQueryChange}
          setSortBy={setSortBy}
          sortBy={sortBy}
          sorts={sorts}
          listView={listView}
          setListView={setListView}
          listViews={listViews}
          refreshScanner={refreshScanner}
          refreshFilters={getFilters}
          filter={filter}
          setFilter={setFilter}
          filters={filters}
          isQueryEmpty={isQueryEmpty}
          viewLabels={viewLabels}
          isFilterPlayersActive={isFilterPlayersActive}
        />
      }>

      {(listView?.value === LIST_VIEW.Zaproszenia || listView === null) && (
         <ScannerTable
         sortBy={sortBy}
         queryString={debounceSearchInput}
       />
      )}

      {listView?.value === LIST_VIEW["Dostępni zawodnicy"] ? (
        <RequestedPlayersTable
        queryString={debounceSearchInput}
        sortBy={sortBy}
        />
      ) : null}
    </ContentContainer>
  );
};

export default OutsidePlayers;
