import React, { useEffect, useState } from 'react';
import AttributesHeader from './Table/AttributesHeader';
import { IAttribute } from 'types/marketpleace/attribute.interface';
import AttributesTableRow from './Table/AttributesTableRow';
import { GridColumn } from 'styles/styles';
import {
  TABLE_MODE,
  ROW_MODE,
  getTableHeadersByLongestCombination,
  getLargestCombinationValuesLength,
} from './utils/helpers';
import { IProductDetail } from 'types/marketpleace/product.interface';
import { IAddCombinationValue } from 'types/marketpleace/combination.interface';

interface IAttributesTableProps {
  attributes: IAttribute[];
  product: IProductDetail;
  tableMode: TABLE_MODE;
  refresh: () => void;
  displayAddForm: boolean;
  combinationValuesLength: number;
  setCombinationValuesLength: React.Dispatch<React.SetStateAction<number>>;
}

const AttributesTable = ({
  attributes,
  product,
  tableMode,
  refresh,
  displayAddForm,
  combinationValuesLength,
  setCombinationValuesLength,
}: IAttributesTableProps) => {
  const [combinationValues, setCombinationValues] = useState<
    IAddCombinationValue[]
  >([]);

  const headers = getTableHeadersByLongestCombination(
    combinationValuesLength > combinationValues.length
      ? combinationValuesLength
      : combinationValues.length
  );

  useEffect(() => {
    !!product.Combinations.length &&
      setCombinationValuesLength(
        getLargestCombinationValuesLength(product.Combinations)
      );
    !displayAddForm && setCombinationValues([]);
  }, [displayAddForm]);

  return (
    <GridColumn cols={headers.length}>
      <AttributesHeader headers={headers} />

      {displayAddForm && (
        <AttributesTableRow
          attributes={attributes}
          rowMode={ROW_MODE.add}
          tableMode={TABLE_MODE.edit}
          longestCombinationValuesLength={getLargestCombinationValuesLength(
            product.Combinations
          )}
          refresh={refresh}
          productId={product.ProductId}
          combinationValues={combinationValues}
          setCombinationValues={setCombinationValues}
          combinationValuesLength={combinationValuesLength}
          setCombinationValuesLength={setCombinationValuesLength}
        />
      )}

      {product.Combinations.map(combination => (
        <AttributesTableRow
          attributes={attributes}
          rowMode={ROW_MODE.default}
          tableMode={tableMode}
          productCombination={combination}
          longestCombinationValuesLength={getLargestCombinationValuesLength(
            product.Combinations
          )}
          refresh={refresh}
          productId={product.ProductId}
          combinationValues={combinationValues}
          setCombinationValues={setCombinationValues}
          combinationValuesLength={combinationValuesLength}
          setCombinationValuesLength={setCombinationValuesLength}
        />
      ))}
    </GridColumn>
  );
};

export default AttributesTable;

// 324 - 424 header
