export interface IMerchant {
  Email: string;
  InvoiceEmail: string;
  PhoneNumber: string;
  BankAccount: string;
  ShopURL: string;
  ServiceDescription: string;
  BusinessTrade: BUSINESS_TRADE;
  BusinessType: BUSINESS_TYPE;
}

export enum ADDRESS_TYPE {
  Academy = 1,
  Correspondence = 2,
}

export enum CONTACT_TYPE {
  Academy = 1,
  Technical = 2,
}

export enum BUSINESS_TYPE {
  'Osoba fizyczna' = 1,
  'Jednoosobowa działalność gospodarcza' = 2,
  'Spółka cywilna' = 3,
  'Spółka jawna' = 4,
  'Spółka komandytowa' = 5,
  'Spółka komandytowo-akcyjna' = 6,
  'Spółka akcyjna' = 7,
  'Spółka z ograniczoną odpowiedzialnością' = 8,
  'Stowarzyszenie, fundacja, organizacja pożytku publicznego' = 9,
  'Spółdzielnia' = 10,
}

export enum BUSINESS_TRADE {
  'AGD',
  'AGD i RTV',
  'Alkohole',
  'Apteki',
  'Artykuły laboratoryjne',
  'Artykuły medyczne',
  'Artykuły spożywcze',
  'Aukcje',
  'BHP',
  'Bielizna',
  'Bilety',
  'Bukmacher',
  'Biżuteria i zegarki',
  'Budownictwo',
  'Chemia',
  'Czasopisma',
  'Dekoracje',
  'Dewocjonalia',
  'Dom i ogród',
  'Dziecko',
  'Elektronika',
  'E-papierosy',
  'Erotyka',
  'Ezoteryka',
  'Filatelistyka',
  'Finanse',
  'Fotografia',
  'Fundacja',
  'Galanteria',
  'Gadżety',
  'Gry',
  'Hosting',
  'Hotelarstwo',
  'Instytucje',
  'IPSP',
  'Komputery',
  'Książki',
  'Kosmetyki',
  'Księgarnia',
  'Kwiaty i prezenty',
  'Masowi Wystawcy Faktur',
  'Maszyny',
  'Materiały biurowe',
  'Materiały foliowe',
  'Materiały papierowe',
  'Militaria',
  'Motoryzacja',
  'Multimedia i muzyka',
  'Nagrobki',
  'Narzędzia',
  'Nauka i szkolnictwo',
  'Numizmatyka',
  'Obuwie',
  'Odzież',
  'Ogłoszenia',
  'Ogród',
  'Oprogramowanie',
  'Oświetlenie',
  'Pasmanteria',
  'Podróże',
  'Portal randkowy',
  'Portfel elektroniczny',
  'Prasa',
  'Prawo',
  'Przesyłki kurierskie',
  'Reklama',
  'Rękodzieło',
  'Rodzice',
  'RTV',
  'Serwis internetowy',
  'Sklep muzyczny',
  'Sport i wypoczynek',
  'Suplementy diety',
  'Szkło',
  'Szkolenia',
  'Sztuka',
  'Ślubne',
  'Teatr i film',
  'Telekomunikacja',
  'Tkaniny',
  'Tworzenie stron WWW',
  'Ubezpieczenia',
  'Usługi',
  'Wielobranżowość',
  'Wyposażenie mieszkania',
  'Wyposażenie sklepów',
  'Wyroby tytoniowe',
  'Wzrok i okulary',
  'Vod',
  'Zabawki',
  'Zdrowie i kosmetyki',
  'Zwierzęta',
}

export enum MERCHANT_REGISTRATION_STATUS {
  'Gotowy do rejestracji' = 1,
  'Zarejestrowany' = 2,
  'Przetwarzany' = 3,
}
