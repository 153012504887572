import { Notification } from "types/notificatonTypes";

type State = {
  notifications: Notification[];
};

const initialState: State = {
  notifications: [],
};

const currentNotification = (state: State = initialState, action: { type: string, payload: string }) => {
  switch (action.type) {
    case 'UPDATE_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.payload,
      };
    case 'REMOVE_NOTIFICATION':
      return {
        ...state,
        notifications: state.notifications.filter((notification: Notification) => notification.NotificationId !== action.payload),
      };
    case 'SEE_NOTIFICATION':
      if (action.payload && typeof action.payload === 'string') {
        return {
          ...state,
          notifications: state.notifications.map(notification =>
            notification.NotificationId === action.payload ? { ...notification, IsSee: true } : notification
          ),
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default currentNotification;
