import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import { TABLE_MODE } from './utils/helpers';
import { Dispatch, SetStateAction } from 'react';

interface IAttributesTopbarProps {
  onEditMode: () => void;
  editMode: TABLE_MODE;
  attributesExists: boolean;
  displayAddForm: () => void;
  addForm: boolean;
}

const AttributesTopbar = ({
  onEditMode,
  editMode,
  attributesExists,
  displayAddForm,
  addForm,
}: IAttributesTopbarProps) => {
  return (
    <div
      className='p-12 flex justify-end gap-3 w-full'
      style={{ background: 'rgba(2, 2, 15, 0.3)' }}>
      <Button
        onClick={displayAddForm}
        disabled={!attributesExists}
        variant={ButtonVariant.Abort}>
        {!addForm ? 'Dodaj' : 'Zakończ'}
      </Button>

      <Button
        onClick={onEditMode}
        disabled={!attributesExists}
        variant={ButtonVariant.Submit}>
        {editMode === TABLE_MODE.edit ? 'Zakończ' : 'Edytuj'}
      </Button>
    </div>
  );
};

export default AttributesTopbar;
