import transferService from 'services/transfer/transferService';
import {
  ITransferRequest,
  PLAYER_STATE_CHANGE,
  TRANSFER_LEVEL,
  TRANSFER_RECIEVER,
} from 'types/transfer/player.interface';

export interface IPlayerStateConditions {
  id: PLAYER_STATE_CHANGE;
  conditions: boolean[];
  changeState?: () => Promise<string | void>;
}

export const changeRequest = async (
  transferRequest: ITransferRequest | null,
  playerId: string,
  level: TRANSFER_LEVEL
) => {
  if (transferRequest !== null) {
    await transferService.cancel(transferRequest.DisciplineId);
    await transferService.send(playerId, level);
  }
  if (transferRequest === null) {
    await transferService.send(playerId, level);
  }
};

export const playerStateConditions = (
  playerId: string,
  level: TRANSFER_LEVEL | null,
  transferRequest: ITransferRequest | null,
  isExclude: boolean
): IPlayerStateConditions[] => {
  return [
    {
      id: PLAYER_STATE_CHANGE['Prośba o obserwacje podstawową'],
      conditions: [
        transferRequest?.Level !== TRANSFER_LEVEL['Podstawowa'],
        level !== TRANSFER_LEVEL['Podstawowa'],
        level !== TRANSFER_LEVEL['Członkowska'],
      ],
      changeState: async () =>
        await changeRequest(
          transferRequest,
          playerId,
          TRANSFER_LEVEL['Podstawowa']
        ),
    },
    {
      id: PLAYER_STATE_CHANGE['Prośba o obserwacje pełną'],
      conditions: [
        transferRequest?.Level !== TRANSFER_LEVEL['Pełna'],
        level !== TRANSFER_LEVEL['Pełna'],
        level !== TRANSFER_LEVEL['Członkowska'],
      ],
      changeState: async () =>
        await changeRequest(transferRequest, playerId, TRANSFER_LEVEL['Pełna']),
    },
    {
      id: PLAYER_STATE_CHANGE['Prośba o członkostwo'],
      conditions: [
        transferRequest?.Level !== TRANSFER_LEVEL['Członkowska'],
        level !== TRANSFER_LEVEL['Członkowska'],
      ],
      changeState: async () =>
        await changeRequest(
          transferRequest,
          playerId,
          TRANSFER_LEVEL['Członkowska']
        ),
    },
    {
      id: PLAYER_STATE_CHANGE['Podstawowa obserwacja'],
      conditions: [
        !!transferRequest,
        transferRequest?.Receiver === TRANSFER_RECIEVER['Akademia'],
        transferRequest?.Level === TRANSFER_LEVEL['Podstawowa'],
      ],
      changeState: async () =>
        !!transferRequest
          ? await transferService.accept(transferRequest.DisciplineId, playerId)
          : '',
    },
    {
      id: PLAYER_STATE_CHANGE['Pełna obserwacja'],
      conditions: [
        !!transferRequest,
        transferRequest?.Receiver === TRANSFER_RECIEVER['Akademia'],
        transferRequest?.Level === TRANSFER_LEVEL['Pełna'],
      ],
      changeState: async () =>
        !!transferRequest
          ? await transferService.accept(transferRequest.DisciplineId, playerId)
          : '',
    },
    {
      id: PLAYER_STATE_CHANGE['Aktywny'],
      conditions: [
        !!transferRequest,
        transferRequest?.Receiver === TRANSFER_RECIEVER['Akademia'],
        transferRequest?.Level === TRANSFER_LEVEL['Członkowska'],
      ],
      changeState: async () =>
        !!transferRequest
          ? await transferService.accept(transferRequest.DisciplineId, playerId)
          : '',
    },
    {
      id: PLAYER_STATE_CHANGE['Odmowa'],
      conditions: [transferRequest?.Receiver === TRANSFER_RECIEVER['Akademia']],
      changeState: async () =>
        !!transferRequest
          ? await transferService.cancel(transferRequest.DisciplineId)
          : '',
    },
    {
      id: PLAYER_STATE_CHANGE['Anuluj'],
      conditions: [transferRequest?.Receiver === TRANSFER_RECIEVER['Zawodnik']],
      changeState: async () =>
        !!transferRequest
          ? await transferService.cancel(transferRequest.DisciplineId)
          : '',
    },
    {
      id: PLAYER_STATE_CHANGE['Odwołaj'],
      conditions: [level !== null],
      changeState: async () => await transferService.revoke(playerId),
    },
    {
      id: PLAYER_STATE_CHANGE['Były zawodnik częściowo obserwowany przez Akademię'],
      conditions: [
        !transferRequest,
        level === TRANSFER_LEVEL['Członkowska'],
        level !== null,
      ],
      changeState: async () =>
        await transferService.send(playerId, TRANSFER_LEVEL['Podstawowa']),
    },
    {
      id: PLAYER_STATE_CHANGE['Były zawodnik w pełni obserwowany przez Akademię'],
      conditions: [
        !transferRequest,
        level === TRANSFER_LEVEL['Członkowska'],
        level !== null,
      ],
      changeState: async () =>
        await transferService.send(playerId, TRANSFER_LEVEL['Pełna']),
    },
    {
      id: PLAYER_STATE_CHANGE['Wykluczony'],
      conditions: [
        !isExclude,
        level === TRANSFER_LEVEL['Członkowska'],
        level !== null,
      ],
      changeState: async () => await transferService.exclude(playerId),
    },
    {
      id: PLAYER_STATE_CHANGE['Dołączony'],
      conditions: [
        isExclude,
        level !== null,
        level === TRANSFER_LEVEL['Członkowska'],
      ],
      changeState: async () => await transferService.include(playerId),
    },
  ];
};
