import { LegacyRef, ReactNode } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import TableHeader from "./DataTableHeader";
import styled from "styled-components";
import "overlayscrollbars/css/OverlayScrollbars.css";
import EventListingDataTableRow from "./EventListingDataTableRow";
import EventDataTableHeader from "./EventDataTableHeader";

export interface IDataTableRows {
  data?: { link?: string };
  cols: Array<ReactNode>;
}

interface ITableProps {
  colSizes?: Array<number>;
  containerRef?: LegacyRef<HTMLTableSectionElement> | any;
  headers: Array<ReactNode>;
  lastItemRef?: LegacyRef<HTMLTableSectionElement> | any;
  rows: Array<IDataTableRows>;
  isDataLoading?: boolean;
  setClickedCyclicalEventId: any;
  toggleDraftMenuOpen: any;
}

const EventListingDataTable: React.FC<ITableProps> = ({
  colSizes = [],
  headers,
  rows,
  containerRef,
  isDataLoading = false,
  lastItemRef,
  setClickedCyclicalEventId,
  toggleDraftMenuOpen,
}) => {
  return (
    <OverlayScrollbarsComponent
      className="pb-12 pr-4"
      style={{ width: "100%" }}
    >
      <table
        className="table"
        cellSpacing={12}
        // cellPadding={10}
        style={{ minWidth: "800px" }}
      >
        <colgroup>
          {colSizes.map((size, idx) => (
            <col key={idx} style={{ width: size + "%" }} />
          ))}
        </colgroup>

        <EventDataTableHeader headers={headers} />
        <tbody ref={containerRef}>
          {!isDataLoading &&
            rows.map((row, index) => {
              const isLast = index === rows.length - 1;
              return isLast ? (
                <EventListingDataTableRow
                  key={index}
                  row={row}
                  innerRef={lastItemRef}
                  setClickedCyclicalEventId={setClickedCyclicalEventId}
                  toggleDraftMenuOpen={toggleDraftMenuOpen}
                />
              ) : (
                <EventListingDataTableRow
                  key={index}
                  row={row}
                  setClickedCyclicalEventId={setClickedCyclicalEventId}
                  toggleDraftMenuOpen={toggleDraftMenuOpen}
                />
              );
            })}
        </tbody>
      </table>
      {isDataLoading && (
        <div className="flex w-full justify-center items-center text-center text-white opacity-70 h-12 font-light bg-white bg-opacity-5 ">
          Loading...
        </div>
      )}
      {!isDataLoading && !rows.length && (
        <div className="flex w-full justify-center items-center text-center text-white opacity-70 h-12 font-medium bg-white bg-opacity-5 ">
          BRAK DANYCH
        </div>
      )}
    </OverlayScrollbarsComponent>
  );
};

export default EventListingDataTable;
