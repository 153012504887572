import React from "react";
import { Container } from "./style";
import xIco from "../../../assets/icons/xIco.svg";
import { toast } from "react-toastify";
import documentIco from "../../../assets/icons/document.png";
import { deleteDocument } from "services/documentServices";

interface Props {
  name: string;
  id: string;
  filePath: string;
  getAllFoldersFn: any;
  activeFolderId: string;
  getFolderDetailFn: any;
  setEditedDocumentId: any;
  setIsDocumentEdited: any;
  showOptions: any;
}

export const SingleFile: React.FC<Props> = ({
  name,
  id,
  filePath,
  getAllFoldersFn,
  activeFolderId,
  getFolderDetailFn,
  setEditedDocumentId,
  setIsDocumentEdited,
  showOptions,
}) => {
  const handleClick = async (id: string) => {
    await deleteDocument(activeFolderId, id);
    toast.success("Usunięto dokument!");
    getAllFoldersFn();
  };

  const handleEditClick = async (id: string) => {
    setEditedDocumentId(id);
    setIsDocumentEdited(true);
  };

  return (
    <Container>
      <td>
        <img src={documentIco} alt="document" style={{ marginLeft: "40px" }} />
      </td>
      <td>
        <p style={{ marginLeft: "15px" }}>{name}</p>
      </td>
      {showOptions ? (
        <>
          {" "}
          <td>
            <a href={filePath} rel="noreferrer" download target="_blank">
              Pobierz
            </a>
          </td>
          <td>
            <div
              style={{
                fontSize: "15px",
                textTransform: "uppercase",
                fontWeight: 400,
                width: "100%",
                height: "100%",
                background: "#f5be0c",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "4px 12px",
                color: "white",
              }}
              onClick={(e: any) => {
                e.stopPropagation();
                handleEditClick(id);
              }}
            >
              EDYTUJ
            </div>
          </td>
          <td>
            <div
              style={{
                fontSize: "15px",
                textTransform: "uppercase",
                fontWeight: 400,
                width: "100%",
                height: "100%",
                background: "rgb(220,33,33)",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "4px 12px",
              }}
              onClick={(e: any) => {
                e.stopPropagation();
                handleClick(id);
              }}
            >
              USUŃ
            </div>
          </td>
        </>
      ) : (
        <>
          {" "}
          <td></td>
          <td></td>
          <td>
            <a href={filePath} rel="noreferrer" download target="_blank">
              Pobierz
            </a>
          </td>
        </>
      )}
    </Container>
  );
};

export default SingleFile;
