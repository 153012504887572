import React, { useEffect } from "react";
import DataTable, { IDataTableRows } from 'components/common/dataTable/DataTable';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import transferService from 'services/transfer/transferService';
import { getSortedRequestPlayersByListSort } from 'middleware/player/getSortedOutsidePlayersByListSort';
import { getFormatedDate } from 'utils/dateUtils';
import {  TRAINER_TYPE } from 'types/transfer/trainer.interface';
import { ISelectOption, LIST_SORT } from 'types/globalTypes';
import {  getRelationRequestsToTrainers } from 'services/transferServices'
import { ISentTransfer, RELATION_RECEIVER, LICENSE_TYPE  } from 'types/transfer/transfer.interface';
import { setRelationRequestId } from 'store/actions/trainerReqId';
import { useDispatch } from 'react-redux';


interface IRequestedPlayersTableProps {
    queryString: string;
    sortBy: ISelectOption<LIST_SORT> | null;
  }

const RequestedTrainersTable: React.FC<IRequestedPlayersTableProps> = ({queryString, sortBy}) => {

  const dispatch = useDispatch();

  const { items, loading, containerRef, lastItemRef, refresh } = useInfiniteScroll<ISentTransfer>(
    getRelationRequestsToTrainers,
      queryString,
  );

  const headers = ['Imię i nazwisko', 'Funkcja', 'Typ licencji', 'Numer licencji'];
  const sortedArray = getSortedRequestPlayersByListSort(items, sortBy?.value);

  useEffect(() => {
    dispatch(setRelationRequestId(sortedArray));
  }, [items, dispatch]);

  const rows = sortedArray.map(
    trainer =>
      ({
        data: {
          link: `/outside-trainers/detail/${trainer.TrainerId}`,
        },
        cols: [
          trainer.Name,
          RELATION_RECEIVER[trainer.Receiver],
          trainer.LicenseType !== undefined ? LICENSE_TYPE[trainer.LicenseType] : '',
          trainer.LicenseId,
        ],
      } as IDataTableRows)
  );

    return (
       <DataTable
        rows={rows}
        headers={headers}
        isDataLoading={loading}
        containerRef={containerRef}
        lastItemRef={lastItemRef}
      />
    )
}

export default RequestedTrainersTable