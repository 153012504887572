import { useCallback, useEffect, useRef } from 'react';
import { useTabs } from './TabsContext';
interface Props {
  scrollOffset?: number;
  tabs: any;
}

const Tabs: React.FC<Props> = ({ scrollOffset = 200, tabs }) => {
  const {
    activeTab,
    currentPosition,
    changeEndLimit,
    changeActiveTab,
    changeCurrentPosiotion,
  } = useTabs();

  const contRef = useRef<HTMLDivElement>(null);

  const calcEndLimit = useCallback(() => {
    if (!contRef.current) {
      return;
    }
    const { offsetWidth, scrollWidth } = contRef.current;
    const endLimit = scrollWidth - offsetWidth;
    changeEndLimit(endLimit);

    if (currentPosition > 0) {
      changeCurrentPosiotion(0);
    }
    if (currentPosition < -endLimit) {
      changeCurrentPosiotion(-endLimit);
    }
  }, [changeCurrentPosiotion, changeEndLimit, currentPosition]);

  useEffect(() => {
    calcEndLimit();
    window.addEventListener('resize', calcEndLimit);

    return () => {
      window.removeEventListener('resize', calcEndLimit);
    };
  }, [calcEndLimit]);

  return (
    <>
      <div className='overflow-x-hidden border-t-2 border-black border-opacity-20 '>
        <div
          className='flex text-base text-black gap-1 text-opacity-70 transition-transform duration-500 ease-in-out'
          ref={contRef}
          style={{
            transform: `translateX(${currentPosition}px)`,
            // background: "rgba(0,145,255,0.1)",
          }}>
          {tabs.map((tab: any) => (
            <div
              key={tab.id}
              title={`${tab.disabled ? 'Brak dostępu do:' : ''} ${tab.label}`}
              className={`flex justify-center items-center flex-shrink-0 relative
              hover:bg-opacity-90
              w-36 h-12
              ${tab.disabled ? 'cursor-default' : 'cursor-pointer'}
              text-sm
              transition-opacity duration-150
               ${
                 !tab.disabled
                   ? activeTab === tab.id && ' bg-opacity-100'
                   : 'opacity-50'
               }`}
              onClick={() => !tab.disabled && changeActiveTab(tab.id)}
              style={{
                border:
                  !tab.disabled && activeTab === tab.id
                    ? '1px solid #FF9226'
                    : 'unset',
                color:
                  !tab.disabled && activeTab === tab.id
                    ? ' #FF9226'
                    : '#A39E9D',
                // background: "#0b192aa0",
                background: 'rgba(12,25,41,1)' ,
                opacity: typeof tab.permission === 'boolean' ? (tab.permission ? '' : '0.6') : '',
                pointerEvents: typeof tab.permission === 'boolean' ? (tab.permission ? 'auto' : 'none') : 'auto'
              }}>
              <span className='capitalize-first '>{tab.label}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Tabs;
