import TabContent from 'components/common/tabs/TabContent';
import React, { useState } from 'react';
import HeaderContainer from 'components/common/Containers/HeaderContainer';
import InputCheckbox from 'components/common/inputs/inputCheckbox/InputCheckbox';

import AcademyLicenceData from 'components/academy/tabs/Merchant/DataFields/AcademyLicenceData'

const inputContentHelper = [
  {
    id: 1,
    text:`Oświadczam, że zapoznałam/łem z <a style="color: #3B82F6; text-decoration: underline" href="https://example.com">Regulaminem</a> oraz <a style="color: #3B82F6; text-decoration: underline" href="https://example.com">Polityką prywatności </a> i akceptuję ich postanowienia`,
  },
  {
    id: 2,
    text:'Przeczytałem i zrozumiałam/em <a style="color: #3B82F6; text-decoration: underline" href="https://example.com">Klauzulę informacyjną</a> dot. prztwarzania mich danych osobowych',
  },
  {
    id: 3,
    text:'* Zgoda na przechowywanie danych, jak pliki cookie oraz identyfikator aplikacji, które są związane z analityką, np. czas trwania wizyty na stronie',
  }
]

interface CheckedState {
  [key: number]: boolean;
}

const AcademyLicence = () => {
  const [checkedState, setCheckedState] = useState<CheckedState>({});

  const handleCheckboxChange = (id: number, isChecked: boolean) => {
    setCheckedState(prevState => ({
      ...prevState,
      [id]: isChecked
    }));
  };

  return (
    <TabContent id='licence'>
      <div className='flex flex-col w-full gap-1'>
        <HeaderContainer
          title='Licencja'
          className='h-16'
        >
          <div>
            <div className='pb-12 pt-4 text-xs text-gray-smoke font-light'>
              Pola oznaczone (*) jest opcjonalne
            </div>
              {inputContentHelper.map((content) => (
              <div key={content.id}>
                <InputCheckbox
                  id={content.id}
                  text={content.text}
                  isChecked={checkedState[content.id] || false}
                  onCheckboxChange={handleCheckboxChange}
                />
              </div>
            ))}
          </div>
        </HeaderContainer>
        <AcademyLicenceData />
      </div>
    </TabContent>
  );
};

export default AcademyLicence;
