import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import passService from 'services/transfer/passServices';
import {
  GridItem,
  GridTextItem,
  StyledTableCurrencyInput,
  StyledTableSelect,
} from 'styles/styles';
import {
  IPass,
  IPassDetail,
  PASS_PAYMENT_TYPE,
  PASS_TYPE,
} from 'types/transfer/pass.interface';
import { showServerErrors } from 'utils/errorsUtils';

import { ReactComponent as AddIcon } from '../../../../../assets/icons/add-circle-white.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/delete.svg';
import { ReactComponent as SaveIcon } from '../../../../../assets/icons/save.svg';
import { getGrossPrice } from 'utils/baseUtils';
import { getFormatedDate } from 'utils/dateUtils';

interface IAcademyPassesRowProps {
  isEditMode: boolean;
  pass: IPass;
  setUpdatedPass: React.Dispatch<React.SetStateAction<IPass | undefined>>;
  updatedPass: IPass | undefined;
  setPass: Dispatch<SetStateAction<IPass | null>>;
  refreshPasses: () => Promise<void>;
}

const AcademyPassesRow = ({
  isEditMode,
  pass,
  refreshPasses,
  setUpdatedPass,
  updatedPass,
  setPass,
}: IAcademyPassesRowProps) => {
  const [nettoValue, setNettoValue] = useState('');

  useEffect(() => {
    if (!isEditMode) {
      setNettoValue(pass ? pass.Netto.toString() : '');
      setUpdatedPass(undefined);
    }
  }, [isEditMode]);

  const updatePass = async (newPass: IPass) => {
    try {
      if (!newPass.IsFree && newPass.Netto < 0) {
        return toast.error('Podaj rzeczywistą kwotę netto');
      }

      newPass.Netto === 0
        ? await passService.setForFree(newPass.PassId)
        : await passService.changePrice({
            Netto: newPass.Netto,
            PassId: newPass.PassId,
            Tax: newPass.Tax,
          });

      toast.success(`Pomyślnie zapisano karnet ${newPass.Name}`);

      setUpdatedPass(undefined);

      await refreshPasses();
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  const deletePass = async (pass: IPass) => {
    try {
      await passService.remove(pass.PassId);

      toast.success(`Pomyślnie usunięto karnet ${pass.Name}`);

      setUpdatedPass(undefined);
      setNettoValue('');
      await refreshPasses();
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  return (
    <>
      <GridTextItem>{pass.Name}</GridTextItem>

      <GridTextItem>{PASS_TYPE[pass.Type]}</GridTextItem>

      <GridTextItem disabled={pass.Type !== PASS_TYPE['Określony']}>
        {pass.Period.StartAt
          ? getFormatedDate(pass.Period.StartAt, 'date')
          : '-'}
      </GridTextItem>
      <GridTextItem disabled={pass.Type !== PASS_TYPE['Określony']}>
        {pass.Period.EndAt ? getFormatedDate(pass.Period.EndAt, 'date') : '-'}
      </GridTextItem>

      <GridTextItem>
        {pass.PaymentType === PASS_PAYMENT_TYPE['Ratalnie']
          ? PASS_PAYMENT_TYPE[0]
          : PASS_PAYMENT_TYPE[pass.PaymentType]}
      </GridTextItem>

      {!!updatedPass ? (
        <GridItem>
          <StyledTableCurrencyInput
            suffix=' zł'
            placeholder='0 zł'
            decimalSeparator='.'
            value={nettoValue}
            onValueChange={(value, name, values) => {
              setNettoValue(value ?? '');
              setUpdatedPass({
                ...updatedPass,
                Netto: values?.float ?? 0,
              });
            }}
          />
        </GridItem>
      ) : (
        <GridTextItem disabled={pass.IsFree}>
          {!pass.IsFree ? `${pass.Netto.toFixed(2)} zł` : '-'}
        </GridTextItem>
      )}

      {!!updatedPass ? (
        <GridItem>
          <StyledTableSelect
            value={updatedPass.Tax}
            onChange={e =>
              setUpdatedPass({
                ...updatedPass,
                Tax: parseInt(e.target.value),
              })
            }>
            <option value={0}>0%</option>
            <option value={8}>8%</option>
            <option value={23}>23%</option>
          </StyledTableSelect>
        </GridItem>
      ) : (
        <GridTextItem disabled={pass.IsFree}>
          {!pass.IsFree ? `${pass.Tax} %` : '-'}
        </GridTextItem>
      )}

      {!!updatedPass ? (
        <GridTextItem>
            {getGrossPrice(updatedPass.Netto, updatedPass.Tax)} zł
        </GridTextItem>
      ) : (
        <GridTextItem disabled={pass.IsFree}>
          {!pass.IsFree ? `${getGrossPrice(pass.Netto, pass.Tax)} zł` : '-'}
        </GridTextItem>
      )}

      <GridItem>
        {isEditMode ? (
          !updatedPass ? (
            <div className='flex w-full h-full gap-1'>
              <Button
                onClick={() => {
                  setUpdatedPass(pass);
                  setNettoValue(pass.Netto.toString());
                }}
                variant={ButtonVariant.Submit}
                className='w-2/3 p-0 h-10 '>
                <EditIcon className='h-1/2' />
              </Button>
              <Button
                onClick={() => deletePass(pass)}
                variant={ButtonVariant.Abort}
                className='w-1/3 p-0 h-10'>
                <DeleteIcon className='h-1/2' />
              </Button>
            </div>
          ) : (
            <div className='flex w-full h-full gap-1'>
              <Button
                onClick={() => updatePass(updatedPass)}
                disabled={pass === updatedPass}
                variant={ButtonVariant.Submit}
                className='w-2/3 p-0 h-10 '>
                <SaveIcon className='h-1/2' />
              </Button>
              <Button
                onClick={() => {
                  setUpdatedPass(undefined);
                  setNettoValue('');
                }}
                variant={ButtonVariant.Abort}
                className='w-1/3 p-0 h-10 '>
                <AddIcon
                  className='h-1/2 '
                  style={{ transform: 'rotate(45deg)' }}
                />
              </Button>
            </div>
          )
        ) : (
          <Button
            onClick={() => setPass(pass)}
            variant={ButtonVariant.Abort}
            className='p-0 w-full h-full '>
            Podgląd
          </Button>
        )}
      </GridItem>
    </>
  );
};

export default AcademyPassesRow;
