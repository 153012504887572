import { ReactNode, useState } from "react";
import { ReactComponent as Arrow } from "../../../assets/icons/sortArrow.svg";

type DataTableHeaderProps = {
  headers: Array<ReactNode>;
};

const EventDataTableHeader: React.FC<DataTableHeaderProps> = ({ headers }) => {
  const [activeMock, setActiveMock] = useState(`${headers[0]}_up`);

  return (
    <thead>
      <tr>
        {headers.map((header, idx) => (
          <th
            key={idx}
            style={{
              width:
                idx === 0
                  ? "100px"
                  : idx === 1
                  ? "100px"
                  : idx === 2
                  ? "22%"
                  : idx === 3
                  ? "22%"
                  : idx === 4
                  ? "220px"
                  : "unset",
            }}
          >
            <div
              className=" relative"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span
                style={{ fontSize: "11px", lineHeight: "15px" }}
                className="text-sm font-regular opacity-70"
              >
                {header}
              </span>
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default EventDataTableHeader;
