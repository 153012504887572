import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  BlurBox,
  SearchBox,
  SearchInput,
  SideFilterMenu,
  SideMenu,
  SortSquare,
} from 'styles/styles';
import HomeSearchIco from '../../../assets/icons/home-search-ico.png';
import GridIco from '../../../assets/icons/home-grid-ico.png';
import ListIco from '../../../assets/icons/home-list-ico.png';
import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import NewSelectStatystics from 'components/common/inputs/select/NewSelectStatystics';
import AddProduct from './form/AddProduct';
import { DataViewMode, ISelectOption, LIST_SORT } from 'types/globalTypes';
import FilterProducts from './form/FilterProducts';
import { ISortingProps } from 'types/sortType';

interface IProductsTopbarProps extends ISortingProps<LIST_SORT> {
  handleQueryChange: (value: string) => void;
  viewType: keyof typeof DataViewMode;
  setViewType: React.Dispatch<React.SetStateAction<keyof typeof DataViewMode>>;
  refresh: () => Promise<void>;
}

const ProductsTopbar: React.FC<IProductsTopbarProps> = ({
  handleQueryChange,
  sortBy,
  setSortBy,
  sorts,
  defaultSort,
  setViewType,
  viewType,
  refresh,
}) => {
  const [isAddMenuOpen, toggleAddMenuOpen] = useState(false);
  const [isFilterMenuOpen, toggleFilterMenuOpen] = useState(false);

  useEffect(() => {
    console.log('sortBy', sortBy);
  }, [sortBy]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    handleQueryChange(e.target.value);
  };

  const openFilterSideMenu = () => {
    if (isFilterMenuOpen) {
      toggleFilterMenuOpen(false);
    } else if (!isFilterMenuOpen && !isAddMenuOpen) {
      toggleFilterMenuOpen(true);
    } else {
      toggleAddMenuOpen(false);
      setTimeout(() => {
        toggleFilterMenuOpen(true);
      }, 400);
    }
  };

  const openAddSideMenu = () => {
    if (isAddMenuOpen) {
      toggleAddMenuOpen(false);
    } else if (!isFilterMenuOpen && !isAddMenuOpen) {
      toggleAddMenuOpen(true);
    } else {
      toggleFilterMenuOpen(false);
      setTimeout(() => {
        toggleAddMenuOpen(true);
      }, 250);
    }
  };

  return (
    <div
      className='flex flex-wrap items-center justify-end gap-y-2'
      style={{ gap: '3px' }}>
      <BlurBox
        isMenuOpen={isAddMenuOpen || isFilterMenuOpen}
        onClick={() => {
          if (isAddMenuOpen) {
            toggleAddMenuOpen(false);
          }
          if (isFilterMenuOpen) {
            toggleFilterMenuOpen(false);
          }
        }}
      />

      <SideFilterMenu isMenuOpen={isFilterMenuOpen && !isAddMenuOpen}>
        <FilterProducts isMenuOpen={isFilterMenuOpen} />
      </SideFilterMenu>

      <SideMenu isMenuOpen={isAddMenuOpen && !isFilterMenuOpen}>
        <div>
          <AddProduct
            isAddMenuOpen={isAddMenuOpen}
            toggleMenuOpen={toggleAddMenuOpen}
            refresh={refresh}
          />
        </div>
      </SideMenu>

      {sorts?.length ? (
        <NewSelectStatystics<LIST_SORT>
          name='Sort'
          items={sorts}
          label='Sortowanie'
          selectedItem={sortBy}
          setSelectedItem={setSortBy}
          defaultValue={defaultSort}
        />
      ) : null}

      <SearchBox>
        <SearchInput
          className='w-full md:w-36 xl:w-72'
          placeholder='Szukaj...'
          autoComplete='off'
          name='search'
          onChange={handleSearch}
        />
        <div>
          <img src={HomeSearchIco} alt='ico' />
        </div>
      </SearchBox>

      <SortSquare
        viewType={viewType}
        view='table'
        onClick={() => setViewType('table')}>
        <img src={ListIco} alt='ico' />
      </SortSquare>

      <SortSquare
        viewType={viewType}
        view='tiles'
        onClick={() => setViewType('tiles')}>
        <img src={GridIco} alt='ico' />
      </SortSquare>

      <Button
        className='px-24 xl:order-2'
        variant={ButtonVariant.Submit}
        style={{
          padding: '15px 41.4064px',
          background: 'rgba(163,158,157,0.38)',
          width: '120px',
          fontSize: '15px',
          fontWeight: 400,
        }}
        onClick={openFilterSideMenu}>
        Filtruj
      </Button>

      <Button
        onClick={openAddSideMenu}
        className='px-24 xl:order-2'
        variant={ButtonVariant.Submit}
        style={{
          fontSize: '13px',
          fontWeight: 400,
          color: 'white',
          background: 'rgb(0, 160, 255)',
        }}>
        Dodaj produkt
      </Button>
    </div>
  );
};

export default ProductsTopbar;
