import styled from 'styled-components';
// @ts-ignore
import HorizontalTimeline from 'react-horizontal-timeline';

export const TimelineDetailHeader = styled.div`
  padding: 8px 25px 8px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(2, 2, 15, 0.3);
  font-size: 11px;
  text-transform: uppercase;
  margin-top: 10px;
  color: white;
  gap: 10px;
  flexwrap: wrap;
  font-weight: 400;
  border-left: 5px solid #0091ff;
`;

export const StyledHorizontalTimeline = styled(HorizontalTimeline)`
  background: #f8f8f8;
`;

export const ValueBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 15px 6px 13px;
  background: rgba(163, 158, 157, 0.11);
  font-size: 11px;
`;

export const HorizontalBox = styled.div`
  width: 100%;
  display: flex;
  height: 60px;
  font-size: 13px;
  padding: 0px 25px;
  gap: 10px;
  background: rgba(2, 2, 15, 0.3);

  & > div:nth-of-type(2) {
    & > button {
      width: 120px !important;
      font-size: 15px;
      font-weight: 400;
      background: rgb(0, 160, 255);
    }
  }

  & > div:nth-of-type(1) {
    display: flex;
    width: calc(100%);

    & > div:nth-of-type(1) {
      & > ul {
        background: rgba(0, 0, 255, 0.8) !important;
      }

      & > div {
        & > ul:nth-of-type(1) {
          & > li {
            background-image: unset !important;
          }
        }
      }
    }
  }
`;
