import {
  IUserManagement,
  UserLabels,
  UserInterface,
} from "../../types/userTypes";

import UserStatusInfo from "./status/UserStatusInfo";
import TileViewLinkItem from "components/common/tileView/TileViewLinkItem";
import styled from "styled-components";

const SingleLabel = styled.div`
  margin-top: 8px;

  & > span {
    &:nth-of-type(1) {
      width: 270px;
      letter-spacing: -0.375px;
    }

    &:nth-of-type(2) {
      width: calc(100% - 270px);
      color: white;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.45px;
    }
  }
`;

interface IUserListItemProps {
  user: UserInterface;
  innerRef?: any;
}

const UserListItem: React.FC<IUserListItemProps> = ({ user, innerRef }) => {
  return (
    <div ref={innerRef}>
      <TileViewLinkItem
        title={""}
        link={`/users/detail/${user.UserId}`}
        img=""
        content={
          <>
            <div className="tileInfo">
              <SingleLabel className="my-1" style={{ margin: 0 }}>
                <span className="tileInfo__label">Imię</span>
                <span style={{ paddingLeft: "3px" }}>{user.FirstName}</span>
              </SingleLabel>
              <SingleLabel className="my-1">
                <span className="tileInfo__label">Nazwisko</span>
                <span> {user.LastName} </span>
              </SingleLabel>
              <SingleLabel className="my-1">
                <span className="tileInfo__label">{UserLabels.email}</span>
                <span> {user.Email} </span>
              </SingleLabel>

              <SingleLabel className="my-1">
                <span className="tileInfo__label">
                  {UserLabels.phoneNumber}
                </span>
                <span> {user.PhoneNumber} </span>
              </SingleLabel>

              <SingleLabel className="my-1">
                <span className="tileInfo__label">{UserLabels.position}</span>
                <span> {user.Position} </span>
              </SingleLabel>

              <SingleLabel className="my-1">
                <span className="tileInfo__label">status</span>
                <span> {user.IsActivate ? "Aktywny" : "Nieaktywny"} </span>
              </SingleLabel>
            </div>
            {/* <div className="justify-self-end ">
              <UserStatusInfo isActive={user.IsActivate} />
            </div> */}
          </>
        }
      />
    </div>
  );
};

export default UserListItem;
