import { FormikHelpers, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";

import TextArea from "components/common/inputs/textArea/TextArea";
import DropdownPanel, { Modes } from "components/common/panels/DropdownPanel";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { showServerErrors } from "utils/errorsUtils";
import styled from "styled-components";

const AboutMeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > div:nth-child(1) {
    background-color: rgba(2, 2, 15, 0.3);
    padding: 17px 37px;
    color: white;
    width: 100%;
    border-left: 5px solid #ff9226;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0.7px;
    text-transform: uppercase;
  }

  & > div:nth-child(2) {
    background: rgba(163, 158, 157, 0.05);
    width: 100%;
    padding: 12px 30px;
    color: white;
    font-size: 13px;
    font-weight: 400;
  }
`;

interface Props {
  order: any;
}

const TrainerDescription: React.FC<Props> = ({ order }) => {
  const presspack = useSelector((state: RootState) => state.artist.presspack);
  const permissions = useSelector(
    (state: RootState) => state.userPermissions?.Artist,
  );
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const dispatch = useDispatch();

  return (
    <AboutMeContainer>
      <div>BIO</div>
      <div className="flex justify-center">{order.Description}</div>
    </AboutMeContainer>
  );
};

export default TrainerDescription;
