import InfoBox from 'components/common/boxes/InfoBox/InfoBox';
import React, { useEffect, useState } from 'react';
import {
  ACADEMY_ACCESS_STATUS,
  ACADEMY_TYPE,
  ACADEMY_VISIBILITY,
  IAcademy,
  IAcademyMembersCount,
} from 'types/academy.interface';
import logo from '../../assets/images/logo.svg';
import transferService from 'services/transfer/transferService';
import { enumToSelectOptions } from 'utils/baseUtils';
import { showServerErrors } from 'utils/errorsUtils';
import { updateAcademyAccessState } from 'services/academyServices';

interface IAcademyInfoProps {
  academy: IAcademy;
  refresh: () => Promise<void>;
}

const accessStates = enumToSelectOptions(ACADEMY_ACCESS_STATUS);

const AcademyInfo = ({ academy, refresh }: IAcademyInfoProps) => {
  const [accessState, setAccessState] = useState<ACADEMY_ACCESS_STATUS>(
    academy.AccessStatus
  );
  const [isAccessChangeing, setAccessChangeing] = useState(false);

  const [membersCount, setMembersCount] =
    useState<IAcademyMembersCount | null>(null);

  const isAcademyPrivate =
    academy.Visibility === ACADEMY_VISIBILITY['Prywatny'];

  const academyImage = !!academy.PhotoFilePath ? academy.PhotoFilePath : logo;

  const getAcademyMembersCount = async () => {
    try {
      const response = await transferService.getAcademyMembersCount();

      setMembersCount(response);
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  const changeAccessState = async () => {
    try {
      await updateAcademyAccessState(accessState);

      await refresh();

      setAccessChangeing(false);
    } catch (error: any) {
      showServerErrors(error);
      setAccessState(academy.AccessStatus);
      setAccessChangeing(false);
    }
  };

  useEffect(() => {
    getAcademyMembersCount();
  }, [academy]);

  useEffect(() => {
    if (academy.AccessStatus !== accessState) {
      setAccessChangeing(true);
      changeAccessState();
    }
  }, [accessState]);

  return (
    <div className='flex flex-col'>
      <InfoBox className='p-18'>
        <InfoBox.Image src={academyImage} />
        <InfoBox.Items>
          <InfoBox.InfoItem label={'Pełna nazwa'} value={`${academy.Name}`} />
          <InfoBox.InfoItem label='Stan konta' value='-' />
          <InfoBox.InfoItem
            label={'Liczba zawodników'}
            value={membersCount !== null ? `${membersCount.PlayersCount}` : '-'}
          />
          <InfoBox.InfoItem label={'Typ'} value={ACADEMY_TYPE[academy.Type]} />
          <InfoBox.InfoItem />
          <InfoBox.InfoItem
            label={'Liczba trenerów'}
            value={
              membersCount !== null ? `${membersCount.TrainersCount}` : '-'
            }
          />
          <InfoBox.InfoItem label={'NIP'} value={academy.NIP} />
          <InfoBox.InfoItem />

          <InfoBox.SelectiveItem
            label={'Status'}
            value={
              !isAcademyPrivate
                ? accessState
                : ACADEMY_ACCESS_STATUS['Nabór zamknięty']
            }
            values={accessStates}
            setValue={setAccessState}
            disabled={isAccessChangeing || isAcademyPrivate}
          />
        </InfoBox.Items>
        {/* <InfoBox.Items className='2xl:flex-col '>
          <InfoBox.InfoItem label={'Pełna nazwa'} value={`${academy.Name}`} />
          <InfoBox.InfoItem label={'Typ'} value={ACADEMY_TYPE[academy.Type]} />
          <InfoBox.InfoItem label={'NIP'} value={academy.NIP} />
        </InfoBox.Items>
        <InfoBox.Items className='justify-end 2xl:flex-col 2xl:items-end'>
          <InfoBox.InfoItem
            label={'Liczba zawodników'}
            value={membersCount !== null ? `${membersCount.PlayersCount}` : '-'}
          />
          <InfoBox.InfoItem
            label={'Liczba trenerów'}
            value={
              membersCount !== null ? `${membersCount.TrainersCount}` : '-'
            }
          />
          <InfoBox.SelectiveItem
            label={'Status'}
            value={
              !isAcademyPrivate
                ? accessState
                : ACADEMY_ACCESS_STATUS['Zamknięty']
            }
            values={accessStates}
            setValue={setAccessState}
            disabled={isAccessChangeing || isAcademyPrivate}
          />
        </InfoBox.Items> */}
      </InfoBox>
    </div>
  );
};

export default AcademyInfo;
