import { conn } from "api/BaseConnection";
import api from "./axiosConfig";

export const getAllAcademyPlayers = async () => {
  try {
    const response = await api.get("/api/Transfer/AcademyPlayers");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAllAcademyTrainers = async () => {
  try {
    const response = await api.get("/api/Transfer/AcademyTrainers");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAvailableTrainers = async () => {
  try {
    const response = await api.get("/api/Transfer/AvailableTrainers");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


export const designateTrainerToPlayers = async (
  TrainerId: string,
  PlayerIds: string[],
) => {
  try {
    const response = await api.post(`/api/Transfer/DesignateTrainerToPlayers`, {
      TrainerId,
      PlayerIds,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const removeTrainerForPlayers = async (
  TrainerId: string,
  PlayerIds: string[],
) => {
  try {
    const response = await api.delete(`/api/Transfer/RemoveTrainerForPlayers`, {
      data: { TrainerId, PlayerIds },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAcademyTrainers = async () => {
  try {
    const response = await api.get("/api/Transfer/AcademyTrainers");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAllAcademyPlayersRef = (pageInfo: any) => {
  return conn.getJSON(`/api/Transfer/AcademyPlayers`, "json", { ...pageInfo });
};

export const getAllAcademyTrainersRef = (pageInfo: any) => {
  return conn.getJSON(`/api/Transfer/AcademyTrainers`, "json", { ...pageInfo });
};

export const getAllSentTransfers = (pageInfo: any): any => {
  return conn.getJSON(`/api/Transfer/AcademyPlayers`, "json", { ...pageInfo });
};

export const getAvailableTrainer = (pageInfo: any): any => {
  return conn.getJSON(`/api/Transfer/AvailableTrainers`, "json", { ...pageInfo });
};

export const getRelationRequestsToTrainers = (pageInfo: any): any => {
  return conn.getJSON(`/api/Transfer/RelationRequests`, "json", { ...pageInfo });
};

export const sendRequestToTrainer = (TrainerId: any): any => {
  return conn.postJSON(`/api/Transfer/SendRelationRequest`, "json", { TrainerId: TrainerId });
};

export const revokeRelation = (RelationRequestId: string)=> {
  return conn.deleteJSON(`/api/Transfer/RevokeRelation`, "json", { RelationRequestId: RelationRequestId });
};

export const deleteTrainerFromAcademy = (TrainerId: string) => {
  return conn.deleteJSON(`/api/Transfer/RemoveTrainer`, "json", {
    TrainerId: TrainerId
  })
}

export const getAllAcademyPlayersQuery = async (SearchQuery: string) => {
  try {
    const response = await api.get(
      `/api/Transfer/AcademyPlayers?SearchQuery=${SearchQuery}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getPlayerDetail = async (PlayerId: string) => {
  try {
    const response = await api.get(
      `/api/Transfer/PlayerDetail?PlayerId=${PlayerId}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getTrainerDetail = async (trainerId: string) => {
  try {
    const response = await api.get(
      `/api/Transfer/TrainerDetail?trainerId=${trainerId}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getTrainerRelationDetail = async (trainerId: string) => {
  try {
    const response = await api.get(
      `/api/Transfer/TrainerRelationDetail?TrainerId=${trainerId}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const acceptTrainerReq = (RelationRequestId: string)=> {
  const body = {
    RelationRequestId: RelationRequestId
  }
  return conn.patchJSON(`/api/Transfer/AcceptRelation/`, "json", body)
};
