import TableHeader from 'components/common/FormTable/TableHeader';
import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import passService from 'services/transfer/passServices';
import { IPass, IPassDetail } from 'types/transfer/pass.interface';
import AppliedProfilesTable from './AppliedProfilesTable';

interface IPassAppliedProfilesProps {
  pass: IPass;
  setPass: Dispatch<SetStateAction<IPass | null>>;
}

export const PassAppliedProfiles = ({
  pass,
  setPass,
}: IPassAppliedProfilesProps) => {
  const [passDetail, setPassDetail] = useState<IPassDetail>();
  const [isLoading, setLoading] = useState(false);

  const getPassDetail = async () => {
    const response = await passService.getDetail(pass.PassId);

    setPassDetail(response);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getPassDetail();
  }, []);

  return (
    <>
      <TableHeader header={`Przypisani zawodnicy`}>
        <Button onClick={() => setPass(null)} variant={ButtonVariant.Submit}>
          Wróć
        </Button>
      </TableHeader>
      <AppliedProfilesTable
        appliedProfiles={passDetail?.AppliedProfiles ?? []}
        isLoading={isLoading}
      />
    </>
  );
};
