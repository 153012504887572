import { IStrictDetailTab } from "types/globalTypes";
import { TRANSFER_LEVEL } from "types/transfer/player.interface";

export const playerDetailStrictTabs: IStrictDetailTab<TRANSFER_LEVEL | null>[] =
  [
    {
      id: "events",
      name: "Zajęcia",
      strictLevel: null,
    },
    {
      id: "address",
      name: "Adres",
      strictLevel: null,
    },
    {
      id: "card",
      name: "Karta zawodnika",
      strictLevel: null,
    },
    {
      id: "manager",
      name: "Trener",
      strictLevel: TRANSFER_LEVEL["Podstawowa"],
    },
    {
      id: "career",
      name: "Kariera",
      strictLevel: null,
    },
    {
      id: "statistics",
      name: "Statystyki",
      strictLevel: TRANSFER_LEVEL["Podstawowa"],
    },
    {
      id: "passes",
      name: "Składki",
      strictLevel: TRANSFER_LEVEL["Podstawowa"],
    },

    {
      id: "historyState",
      name: "Historia statusów",
      strictLevel: null,
    },
  ];
