import { useParams } from 'react-router-dom';
import ContentContainer from 'components/layout/ContentContainer';
import ProductDetailTopbar from './ProductDetailTopbar';
import ProductDetailInfo from './ProductDetailInfo';
import { createContext, useEffect, useState } from 'react';
import { IProductDetail } from 'types/marketpleace/product.interface';
import { productServices } from 'services/marketpleace';
import ProductDetailTabs from './ProductDetailTabs';
import { ITabsContext } from 'types/globalTypes';


export const ProductDetailTabsContext =
  createContext<ITabsContext<IProductDetail>>(null!);

const ProductDetail = () => {
  const [product, setProduct] = useState<IProductDetail | null>(null);

  const { id: productId } = useParams<{ id: string }>();

  const getProductDetail = async () => {
    const product = await productServices.getDetailById(productId);
    console.log('Product detail: ', product);
    setProduct(product);
  };

  useEffect(() => {
    getProductDetail();
  }, [productId]);

  return (
    <ContentContainer
      title={product?.Name ?? ''}
      TopBar={<ProductDetailTopbar product={product} reload={getProductDetail} />}
      path='/shop/products'>
      <div>
        <div className='flex flex-col'>
          <ProductDetailInfo product={product} />
        </div>
        {product ? (
          <ProductDetailTabsContext.Provider
            value={{ data: product, refresh: getProductDetail }}>
            <ProductDetailTabs />
          </ProductDetailTabsContext.Provider>
        ) : null}
      </div>
    </ContentContainer>
  );
};

export default ProductDetail;
