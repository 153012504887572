import { useField } from "formik";
import { ITextareaProps } from "../inputTypes";
import TextArea from "./TextArea";
import ProductTextArea from "./ProductTextArea";

const ProductTextAreaField: React.FC<ITextareaProps> = ({ name, ...props }) => {
  const [field, { error, touched }] = useField(name);
  return (
    <ProductTextArea {...props} {...field} error={error} touched={touched} />
  );
};

export default ProductTextAreaField;
