import ContentContainer from 'components/layout/ContentContainer';
import AcademyInfo from './AcademyInfo';
import AcademyTopbar from './AcademyTopbar';
import InfoBoxPlaceholder from 'components/common/boxes/InfoBox/InfoBoxPlaceholder';
import { createContext, useEffect, useState } from 'react';
import { showServerErrors } from 'utils/errorsUtils';
import { getAcademy } from 'services/academyServices';
import { IAcademy, IAcademyMembersCount } from 'types/academy.interface';
import AcademyTabs from './tabs/AcademyTabs';
import { ITabsContext } from 'types/globalTypes';
import transferService from 'services/transfer/transferService';

export const AcademyTabsContext = createContext<ITabsContext<IAcademy>>(null!);

const Academy = () => {
  const token = localStorage.getItem('token');

  const [academy, setAcademy] = useState<IAcademy | null>(null);

  const getAcademyData = async () => {
    try {
      const response = await getAcademy();
      setAcademy(response);
    } catch (error: any) {
      // showServerErrors(error);
    }
  };

  useEffect(() => {
    if (token) {
      getAcademyData();
    }
    if (!token) {
      setAcademy(null);
    }
  }, [token]);

  if (!academy) {
    return <InfoBoxPlaceholder />;
  }

  return (
    <ContentContainer
      title={academy.Name}
      TopBar={<AcademyTopbar academy={academy} refresh={getAcademyData} />}
      path='/'>
      <div>
        <AcademyInfo academy={academy} refresh={getAcademyData} />

        <AcademyTabsContext.Provider
          value={{ data: academy, refresh: getAcademyData }}>
          <AcademyTabs />
        </AcademyTabsContext.Provider>
      </div>
    </ContentContainer>
  );
};

export default Academy;
