import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import NewSelectStatystics from "components/common/inputs/select/NewSelectStatystics";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import transferService from "services/transfer/transferService";
import { getAllAcademyPlayersQuery } from "services/transferServices";
import styled from "styled-components";
import { getFormatedDate } from "utils/dateUtils";

const TrainerMenager = () => {
  const [editAttributes, setEditAttributes] = useState(false);
  const [designatedPlayers, setDesignatedPlayers] = useState([]);
  const { id } = useParams<{ id: string }>();

  const [isFocused, toggleFocused] = useState(false);
  const [receiver, setReceiver] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [folderReceiver, setFolderReceiver] = useState("");
  const [folderReceiverId, setFolderReceiverId] = useState("");
  const [playerImage, setPlayerImage] = useState("");
  const [playerType, setPlayerType] = useState("");
  const [allPlayers, setAllPlayers] = useState<any>([]);
  const [allFolders, setAllFolders] = useState<any>([]);
  const [playerProfiles, setPlayerProfiles] = useState([]);

  const levelSwitch = (level: number) => {
    switch (level) {
      case 0:
        return "Ogólny";
      case 1:
        return "Pełny";
      default:
        return level;
    }
  };

  const handleReceiverChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setReceiver(e.target.value);

  const handleReceiverIdChange = (value: string) => setReceiverId(value);

  const handleOnFocusChange = (value: boolean) => toggleFocused(value);

  const onBlur = (e: any) => {
    if (!e.currentTarget.contains(e.relatedTarget)) return toggleFocused(false);
    return toggleFocused(true);
  };

  const deletePlayer = (id: string) => {
    setAllPlayers((prevPlayers: any) =>
      prevPlayers.filter((player: any) => player.Id !== id),
    );
  };

  useEffect(() => {
    getAllAcademyPlayersQuery(receiver)
      .then((data) => {
        setPlayerProfiles(data.Data.Items);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [receiver]);

  const getAllDesignatePlayers = async () => {
    const resp: any = await transferService.getDesignatePlayers(id);
    console.log("designateplayers", resp);
    setDesignatedPlayers(resp);
  };

  useEffect(() => {
    getAllDesignatePlayers();
  }, []);

  const addPlayer = async () => {
    if (!receiver || !receiverId) return toast.error("Wybierz zawodnika");
  };

  const sortDataTable = designatedPlayers.sort((a: any, b: any) => {
    if (a.DesignatedAt > b.DesignatedAt) return -1;
    if (a.DesignatedAt < b.DesignatedAt) return 1;
    return 0;
});

  return (
    <>
      <div className="flex flex-col lg:flex-row gap-16 mx-auto w-full">
        <div className="w-full text-sm">
          <div
            className="px-18 py-12 opacity-80"
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "rgba(2,2,15,0.3)",
              color: "white",
              opacity: 1,
              height: "60px",
              alignItems: "center",
              borderLeft: "5px solid rgb(255,146,38)",
            }}
          >
            <span
              style={{
                textTransform: "uppercase",
                fontSize: "15px",
                fontWeight: 300,
                letterSpacing: -0.5,
              }}
            >
              Obserwowani
            </span>
            {/* <div style={{ display: "flex", gap: "5px" }}>
              <Button
                variant={ButtonVariant.Submit}
                style={{ cursor: "pointer", userSelect: "none" }}
                onClick={() => {
                  setEditAttributes((prev) => !prev);
                }}
              >
                {editAttributes ? "Zapisz" : "Edytuj"}
              </Button>
            </div> */}
          </div>

          <>
            <div className={`grid relative grid-cols-3 gap-1 my-1`}>
              <div
                className="bg-opacity-70 p-12 text-center"
                style={{
                  background: "rgba(2,2,15,0.3)",
                  color: "white",
                  textTransform: "uppercase",
                  fontSize: "11px",
                  fontWeight: 500,
                }}
              >
                <span>Imię i nazwisko</span>
              </div>
              <div
                className="bg-opacity-70 p-12 text-center"
                style={{
                  background: "rgba(2,2,15,0.3)",
                  color: "white",
                  textTransform: "uppercase",
                  fontSize: "11px",
                  fontWeight: 500,
                }}
              >
                <span>Data</span>
              </div>
              <div
                className="bg-opacity-70 p-12 text-center"
                style={{
                  background: "rgba(2,2,15,0.3)",
                  color: "white",
                  textTransform: "uppercase",
                  fontSize: "11px",
                  fontWeight: 500,
                }}
              >
                <span>Zakładki</span>
              </div>
            </div>
          </>

          {sortDataTable?.length > 0 &&
            sortDataTable.map((player: any, idx: number) => {
              const { Name, PlayerId, DesignatedAt, Level } = player;

              return (
                <div className={`grid relative grid-cols-3 gap-1 my-1`}
                     key={idx}
                >
                  <div
                    className="p-12 text-center"
                    style={{
                      background: "rgba(163,158,157,0.09)",
                      color: "white",
                      fontSize: "15px",
                      fontWeight: 400,
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>{Name}</span>
                  </div>

                  <div
                    className="p-12 text-center"
                    style={{
                      background: "rgba(163,158,157,0.09)",
                      color: "white",
                      fontSize: "15px",
                      fontWeight: 400,
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      {getFormatedDate(
                        DesignatedAt,
                        "date",
                      )}
                    </span>
                  </div>
                  <div
                    className="p-12 text-center"
                    style={{
                      background: "rgba(163,158,157,0.09)",
                      color: "white",
                      fontSize: "15px",
                      fontWeight: 400,
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>{levelSwitch(Level)}</span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default TrainerMenager;

const InputWithSearchResultsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    font-size: 11px;
    color: white;
    width: 80px;
  }

  & > input {
    color: white;
    font-size: 11px;
    width: 100%;
    outline: none;
    background: transparent;
    border: none;
    color: white;
  }
`;

const PlayersBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
  /* height: 250px; */
  max-height: 250px;
  overflow-y: auto;
  /* background: red; */
  margin-top: 9px;
`;

const PlayerBox = styled.div`
  width: 100%;
  height: 48px;
  min-height: 48px;
  /* background: blue; */
  background: rgba(163, 158, 157, 0.1);
  cursor: pointer;
  margin-bottom: 9px;
  display: flex;

  & > img {
    width: 48px;
    height: 48px;
  }

  & > div:nth-of-type(1) {
    width: calc(100% - 52px);
    height: 100%;
    display: flex;
    align-items: center;

    & > p {
      color: #0091ff;
      padding-left: 10px;
      width: 100%;
      margin-right: 17px;
      text-align: right;
      font-size: 13px;
      font-weight: 600;
    }
  }

  & > div:nth-of-type(2) {
    width: 52px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(163, 158, 157, 0.3);
  }
`;

const RightSectionAddressAndTitleWriteMode = styled.div`
  display: flex;
  width: 300px;
  background: rgba(163, 158, 157, 0.1);
  height: 48px;
  position: relative;
  margin-bottom: 10px;

  & > div {
    cursor: pointer;
    height: 100%;
    width: 52px;
    background: #0091ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* padding: 5px 11.5px; */
  /* border-radius: 5px; */
  /* border: 1px solid rgba(112, 112, 112, 0.3); */

  & > p {
    font-size: 11px;
    color: rgb(163, 158, 157);
    position: absolute;
    top: 7px;
    left: 9px;
    font-weight: 400;
    width: 80px;
  }
  & > img {
    width: 48px;
    height: 100%;
    /* background: white; */
  }

  & > input {
    color: white;
    text-align: right;
    font-size: 13px;
    font-weight: 600;
    padding-right: 17px;
    width: calc(100% - 52px);
    outline: none;
    background: transparent;
    border: none;

    &::placeholder {
      color: white;
      opacity: 1;
    }
  }
`;

const SingleSearchResultWrapper = styled.div`
  width: 100%;
  height: 20px;
  background: rgba(163, 158, 157, 0.1);
  /* border-radius: 5px; */
  padding-left: 20px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  z-index: 5;
  cursor: pointer;

  & > p {
    font-size: 11px;
    color: white;
  }

  &:hover {
    /* background: #16265c; */
    background: #89bfff;
  }
`;
const SearchResultWrapper = styled.div<{
  isFocused: boolean;
  autoCompleted: boolean;
}>`
  width: 100%;
  position: absolute;
  background: rgba(2, 2, 15, 1);
  /* border-radius: 5px; */
  /* border: 1px solid #e1e1e1; */
  backdrop-filter: blur(3px);
  color: white;
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 51px;
  /* padding: 5px 0; */
  z-index: 5;
  visibility: ${({ autoCompleted, isFocused }) =>
    autoCompleted && isFocused ? "visible" : "hidden"};
  display: ${({ autoCompleted, isFocused }) =>
    autoCompleted && isFocused ? "flex" : "none"};
`;
