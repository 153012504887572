import { ArtistPropItem } from "types/artistTypes";
import {
  DigitalReleaseRequest,
  IDigitalRelease,
  IAddProduct,
} from "types/digitalReleaseTypes";
import { IListPageRequest, IListPageResponse } from "types/globalTypes";

import { conn } from "../api/BaseConnection";
import jwtDecode from "jwt-decode";
import api from "./axiosConfig";

const endpoint = conn.endpoints.favoriteLocation;

const token: any = localStorage?.getItem("token");
const decodedToken: any = token && jwtDecode(token);
console.log("tokendeccyc", decodedToken);

const add = (digitalRelease: IDigitalRelease | any) => {
  return conn.postJSON(endpoint, "json", digitalRelease);
};

const remove = (id: string) => {
  return conn.deleteJSON(`${endpoint}/${id}`);
};

const edit = (digitalRelease: Partial<DigitalReleaseRequest>) => {
  return conn.putJSON(endpoint, "json", digitalRelease);
};

const getAll = (
  pageInfo: IListPageRequest,
): Promise<IListPageResponse<IDigitalRelease>> => {
  return conn.getJSON<IListPageResponse<IDigitalRelease>>(
    endpoint,
    "json",
    pageInfo,
  );
};

// const deleteFavoriteLocation = (locationId: string) => {
//   return conn.deleteJSON(`${endpoint}/${locationId}`, "json");
// };

export const deleteFavoriteLocation = async (FavoriteLocationId: string) => {
  try {
    const response = await api.delete(`/api/FavoriteLocation/DeleteLocation`, {
      data: { FavoriteLocationId },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const getAllFavoriteLocations = () => {
  return conn.getJSON(`${endpoint}/AllLocation`, "json");
};

const getFavoriteLocation = (favoriteLocationId: string) => {
  return conn.getJSON(
    `${endpoint}/FavoriteLocationById/${favoriteLocationId}`,
    "json",
  );
};

const editFavoriteLocation = (
  FavoriteLocationId: string,
  Name: string,
  Note: string,
  Latitude: number,
  Longitude: number,
) => {
  const body = {
    FavoriteLocationId,
    Name,
    Note,
    Latitude,
    Longitude,
  };

  return conn.putJSON(`${endpoint}/EditLocation`, "json", body);
};

const addFavoriteLocation = (
  Name: string,
  Note: string,
  Latitude: number,
  Longitude: number,
  FieldId: any,
  ValueId: any,
) => {
  const body = {
    UserId: decodedToken?.UserId,
    Name,
    Note,
    Latitude,
    Longitude,
    FieldId: "6fdf4cdf-1ef9-416b-a4ea-4aa2dca7b8e2",
    ValueId: "015b3d09-e43b-4840-b398-c1567ce79daa",
    PassedByHandValue: "",
  };

  return conn.postJSON(`${endpoint}/AddLocation`, "json", body);
};

const favoriteLocationServices = {
  add,
  edit,
  remove,
  getAll,
  addFavoriteLocation,
  editFavoriteLocation,
  deleteFavoriteLocation,
  getAllFavoriteLocations,
  getFavoriteLocation,
};

export default favoriteLocationServices;
