import { conn } from "api/BaseConnection";
import api from "./axiosConfig";
import {
  IUserManagement,
  /*  IUserManagementEditData,
   UserInterface,
   UserManagmentEditInterface, */
} from "../types/userTypes";

const endpoint = conn.endpoints.user;

export const getUserDetail = async (UserId: string) => {
  try {
    const response = await api.get(`/api/User/Detail?UserId=${UserId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// export const createUser = async (
//   Email: string,
//   FirstName: string,
//   LastName: string,
//   Position: string,
//   PhoneNumber: string,
// ) => {
//   try {
//     const response = await api.post(`/api/User/CreateUser`, {
//       Email,
//       FirstName,
//       LastName,
//       Position,
//       PhoneNumber,
//     });
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

export const createUser = async (
  Email: string,
  FirstName: string,
  LastName: string,
  Position: string,
  PhoneNumber: string,
) => {
  try {
    const response = await api.post(`/api/User/CreateUser`, {
      Email,
      FirstName,
      LastName,
      Position,
      PhoneNumber,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editUser = (array: any, UserId: any) => {
  const body = {
    ...array,
    UserId,
  };

  return conn.putJSON(`/api/User/Update`, "json", body);
};

export const deactivateUser = async (
  UserId: string
) => {
  try {
    const response = await api.post('/api/User/DeactivateUser', {
      UserId
    });
    return response.data;
  } catch (error) {
    throw error
  }
}

export const activateUser = async (
  UserId: string
) => {
  try {
    const response = await api.post('/api/User/ActivateUser', {
      UserId
    });
    return response.data;
  } catch (error) {
    throw error
  }
}

export const deleteUser = async (userId: string) => {
  try {
    const response = await api.delete('/api/User/Delete', {
      data: { UserId: userId }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
