import { IAddress } from './address.interface';
import { IMerchant, MERCHANT_REGISTRATION_STATUS } from './merchant.interface';
import { IAcademySeller } from './seller.interface';
import { USER_TYPE } from './userTypes';

export interface IAcademyRepresentingPerson {
  Name: string;
  PESEL: string;
  PhoneNumber: string;
}

export interface IAcademyContact {
  Name: string;
  Email: string;
  PhoneNumber: string;
}

export interface IAcademyUser {
  UserId: string;
  FirstName: string;
  LastName: string;
  Contact: IAcademyContact;
  IsActivate: boolean;
  Type: USER_TYPE;
}

export interface IUpdateAcademyUnion {
  Name: string;
  Number: string;
  EntryDate: Date;
}

export interface IAcademyUnion extends IUpdateAcademyUnion {
  IsInUnion: boolean;
}

export interface IAcademy {
  AcademyId: string;
  NIP?: string;
  REGON?: string;
  KRS?: string;
  Name: string;
  NameShort: string;
  Type: ACADEMY_TYPE;
  PhotoFilePath: string;
  WebsiteURL: string;
  Description: string;
  IsActivate: boolean;
  IsSellerApplied: boolean;
  SellerId: string;
  MerchantRegistrationStatus: MERCHANT_REGISTRATION_STATUS;
  Visibility: ACADEMY_VISIBILITY;
  AccessStatus: ACADEMY_ACCESS_STATUS;
  CreatedAt: Date;
  RepresentingPerson: IAcademyRepresentingPerson;
  Contact: IAcademyContact;
  TechnicalContact: IAcademyContact;
  Address: IAddress;
  CorrespondenceAddress: IAddress;
  Merchant: IMerchant;
  Union: IAcademyUnion;
  Seller: IAcademySeller;
  Users: IAcademyUser[];
}

export interface IAcademyMembersCount {
  PlayersCount: number;
  TrainersCount: number;
}

export interface IUpdateAcademyPrimaryData {
  PhotoFilePath: string;
  NameShort: string;
  WebsiteURL: string;
  Description: string;
}

export enum ACADEMY_VISIBILITY {
  'Prywatny',
  'Publiczny',
}

export enum ACADEMY_TYPE {
  'Akademia',
  'Reprezentacja',
  'Klub',
}

export enum ACADEMY_ACCESS_STATUS {
  'Nabór zamknięty',
  'Obserwacja zawodników',
  'Nabór otwarty',
  'Dołączenie do akademii',
}
