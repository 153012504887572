import { conn } from 'api/BaseConnection';
import { IListPageRequest, IListPageResponse } from 'types/globalTypes';
import {
  ICreateScanner,
  IListedScanner,
  IScanner,
  IUpdateScanner,
} from 'types/scanner/scanner.interface';

const endpoints = conn.endpoints.scanner;

const getDetail = async (
  FilterId: string,
  listPage?: IListPageRequest
): Promise<IScanner> => {
  return await conn.getJSON(endpoints.get.detail, 'json', {
    FilterId,
    ...listPage,
  });
};

const getListing = async (
  listPage?: IListPageRequest
): Promise<IListPageResponse<IListedScanner>> => {
  return await conn.getJSON(endpoints.get.listing, 'json', {
    ...listPage,
  });
};

const create = async (scanner: ICreateScanner) => {
  return await conn.postJSON(endpoints.add, 'json', scanner);
};

const update = async (scanner: IUpdateScanner) => {
  return await conn.putJSON(endpoints.update, 'json', scanner);
};

const remove = async (FilterId: string) => {
  return await conn.deleteJSON(endpoints.delete, 'json', { FilterId });
};

const activate = async (FilterId: string) => {
  return await conn.patchJSON(endpoints.activate, 'json', { FilterId });
};

const deactivate = async (FilterId: string) => {
  return await conn.patchJSON(endpoints.deactivate, 'json', { FilterId });
};

const scannerServices = {
  getDetail,
  getListing,
  create,
  update,
  remove,
  activate,
  deactivate,
};

export default scannerServices;
