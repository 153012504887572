import TabContent from 'components/common/tabs/TabContent';
import { useEffect, useState } from 'react';
import AttributesTopbar from './AttributesTopbar';
import AttributesTable from './AttributesTable';
import { TABLE_MODE } from './utils/helpers';
import { IAttribute } from 'types/marketpleace/attribute.interface';
import combinationServices from 'services/marketpleace/combinationServices';
import { useProductDetailTabsContext } from '../../hook/useProductDetailTabsContext';

const ProductAttributes = () => {
  const { data: product, refresh } = useProductDetailTabsContext();

  const [tableMode, setTableMode] = useState<TABLE_MODE>(TABLE_MODE.default);

  const [attributes, setAttributes] = useState<IAttribute[]>([]);

  const [displayCombinationForm, setDisplayCombinationForm] = useState(false);

  const [combinationValuesLength, setCombinationValuesLength] = useState(1);

  const getAttributes = async () => {
    const attributesResponse = await combinationServices.getAttributes();
    setAttributes(attributesResponse.Attributes);
  };

  useEffect(() => {
    getAttributes();
  }, []);

  const handleTableMode = () => {
    if (tableMode === TABLE_MODE.default) {
      setTableMode(TABLE_MODE.edit);
    }
    if (tableMode === TABLE_MODE.edit) {
      setTableMode(TABLE_MODE.default);
    }
  };

  const displayAddForm = () => {
    setDisplayCombinationForm(prev => !prev);
  };

  return (
    <TabContent id='attributes'>
      <div className='flex flex-col gap-1 p-24'>
        <AttributesTopbar
          onEditMode={handleTableMode}
          attributesExists={true}
          editMode={tableMode}
          displayAddForm={displayAddForm}
          addForm={displayCombinationForm}
        />
        <AttributesTable
          attributes={attributes}
          product={product}
          tableMode={tableMode}
          refresh={refresh}
          displayAddForm={displayCombinationForm}
          combinationValuesLength={combinationValuesLength}
          setCombinationValuesLength={setCombinationValuesLength}
        />
      </div>
    </TabContent>
  );
};

export default ProductAttributes;
