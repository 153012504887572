import TabContent from 'components/common/tabs/TabContent';
import React, { useEffect, useState } from 'react';
import passService from 'services/transfer/passServices';
import { IPass } from 'types/transfer/pass.interface';
import { showServerErrors } from 'utils/errorsUtils';
import FormTableHeader from 'components/common/FormTable/FormTableHeader';
import AcademyPassesTable from './table/AcademyPassesTable';
import { PassAppliedProfiles } from './appliedProfiles/PassAppliedProfiles';

interface Props {
  isPassEditPermissionActive?: boolean;
  isPassAddPermissionActive?: boolean;
}

const AcademyPasses:React.FC<Props> = ({isPassEditPermissionActive, isPassAddPermissionActive}) => {
  const [isAddFormDisplayed, displayAddForm] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const [previewedPass, setPreviewedPass] = useState<IPass | null>(null);

  const [passes, setPasses] = useState<IPass[]>([]);

  const getPasses = async () => {
    try {
      const { Items } = await passService.getAll();

      if (!Items.length) {
        setEditMode(false);
        displayAddForm(true);
      }

      setPasses(Items);
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  useEffect(() => {
    getPasses();
  }, []);

  return (
    <TabContent id='passes'>
      <div className='flex flex-col w-full gap-1'>
        {!previewedPass ? (
          <>
            <FormTableHeader
              header='Składki'
              isAddFormDisplayed={isAddFormDisplayed}
              displayAddForm={displayAddForm}
              isEditMode={isEditMode}
              setEditMode={setEditMode}
              editDisabled={!passes.length}
              isPassEditPermissionActive={isPassEditPermissionActive}
              isPassAddPermissionActive={isPassAddPermissionActive}
            />
            <AcademyPassesTable
              isAddFormDisplayed={isAddFormDisplayed}
              displayAddForm={displayAddForm}
              isEditMode={isEditMode}
              passes={passes}
              setPass={setPreviewedPass}
              refreshPasses={getPasses}
            />
          </>
        ) : (
          <PassAppliedProfiles
            pass={previewedPass}
            setPass={setPreviewedPass}
          />
        )}
      </div>
    </TabContent>
  );
};

export default AcademyPasses;
