import React from "react";

interface InputCheckboxProps {
  id: number;
  text: string;
  isChecked: boolean;
  onCheckboxChange: (id: number, isChecked: boolean) => void;
}

const InputCheckbox: React.FC<InputCheckboxProps> = ({ id, text, isChecked, onCheckboxChange }) => {
  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheckboxChange(id, (event.target as HTMLInputElement).checked);
  };


  return (
    <div className="flex py-4">
      <input
        className="w-4"
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
      />
      <div className="mx-2">
        {text.split(/(<a[^>]+>.*?<\/a>)/g).map((item, index) => {
          if (item.includes('<a')) {
            return (
              <a
                key={index}
                href={item.match(/href="(.*?)"/)?.[1]}
                onClick={handleLinkClick}
                style={{ cursor: 'pointer' }}
                dangerouslySetInnerHTML={{ __html: item }}
              />
            );
          } else {
            return <span key={index} style={{ fontSize:'15px'}} className="font-['Inter'] font-light">{item}</span>;
          }
        })}
      </div>
    </div>
  );
};

export default InputCheckbox;
