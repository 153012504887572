import { useField } from 'formik';
import React, { useEffect } from 'react';
import { RangeInputBox, RangeInputValue } from 'styles/styles';

interface IRangeInputProps {
  label: string;
  name: string;
  maxLength?: number;
}

const RangeInput = ({ label, name, maxLength = 3 }: IRangeInputProps) => {
  const [minField] = useField(`${name}Min`);
  const [maxField] = useField(`${name}Max`);

  return (
    <RangeInputBox>
      <div>
        <p>{label}</p>
      </div>
      <div>
        <RangeInputValue
          placeholder='od'
          type='number'
          maxLength={maxLength}
          {...minField}
        />
        <RangeInputValue
          placeholder='do'
          type='number'
          maxLength={maxLength}
          {...maxField}
        />
      </div>
    </RangeInputBox>
  );
};

export default RangeInput;
