import { Dispatch, SetStateAction } from 'react';
import { StyledInfoSelect } from 'styles/styles';
import { ISelectOption } from 'types/globalTypes';
import  UserStatusInfo  from '../../../users/status/UserStatusInfo'

interface BoxProps {
  className?: string;
}

interface InfoProps {
  className?: string;
  label?: string;
  value?: string;
}

interface LinkProps extends InfoProps {
  href: string;
  target?: '_self' | '_blank' | '_parent' | '_top';
}

interface ImageProps {
  className?: string;
  src: string;
}

interface SelectiveItem {
  className?: string;
  label?: string;
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
  values: ISelectOption<string>[];
  disabled?: boolean;
}

interface IBoxComposition {
  Image: React.FC<ImageProps>;
  Items: React.FC<BoxProps>;
  SelectiveItem: React.FC<SelectiveItem>;
  InfoItem: React.FC<InfoProps>;
  LongInfoItem: React.FC<InfoProps>;
  LinkItem: React.FC<LinkProps>;
}

export const infoBoxTestIds = {
  infoBox: 'infoBox',
  infoBoxImage: 'infoBoxImage',
  infoBoxItems: 'infoBoxItems',
  infoBoxItem: 'infoBoxItem',
  infoBoxLinkItem: 'infoBoxLinkItem',
  infoBoxLink: 'infoBoxLink',
  infoBoxCustomChild: 'infoBoxCustomChild',
};

const Image: React.FC<ImageProps> = ({ className = '', src }) => (
  <div
    className={`h-36 w-36 flex-shrink-0 flex-grow  overflow-hidden order-1  md:flex-grow-0  ${className} `}>
    <img
      className='object-cover w-full h-full'
      src={src}
      alt=''
      data-testid={infoBoxTestIds.infoBoxImage}
      loading='lazy'
    />
  </div>
);

const Items: React.FC<BoxProps> = ({ children, className = '' }) => (
  <div
    data-testid={infoBoxTestIds.infoBoxItems}
    className={`md:flex md:flex-wrap gap-1 w-full overflow-y-auto md:max-h-52 self-start max-w-7xl order-2 ${className}`}>
    {children}
  </div>
);

const InfoItem: React.FC<InfoProps> = ({
  className = '',
  label = '',
  value = '',
}) => {

  return (
    <div
      style={{
        width: '24rem',
        background: 'rgba(13,26,40,1)',
        border: '1px solid rgba(26,55,82,1)',
        position: 'relative',
      }}
      data-testid={infoBoxTestIds.infoBoxItem}
      className={`detailInfo ${className} ${!label && 'hidden 2xl:flex'}`}>
      <span
        className='detailInfo__label capitalize-first'
        style={{
          color: '#fff',
          fontSize: '11px',
          position: 'absolute',
          top: '5px',
        }}>
        {label}
      </span>
      <span
        className='flex-1 overflow-ellipsis overflow-hidden opacity-90 text-right whitespace-nowrap'
        style={{ color: '#fff', fontSize: '15px' }}>
        {
        value === '?' ? (
        <div className='flex justify-end'>
          <UserStatusInfo isActive={false}/>
        </div>
        ) : value === 'nie ma' ? (
          '-'
        ) : (
          value
        )
      }
      </span>
    </div>
  );
};

const SelectiveItem = ({
  className = '',
  label = '',
  value,
  setValue,
  values,
  disabled = false,
}: SelectiveItem) => {
  return (
    <div
      style={{
        width: '24rem',
        background: 'rgba(13,26,40,1)',
        border: '1px solid rgba(26,55,82,1)',
        position: 'relative',
      }}
      data-testid={infoBoxTestIds.infoBoxItem}
      className={`detailInfo ${className} ${!label && 'hidden 2xl:flex'}`}>
      <span
        className='detailInfo__label capitalize-first'
        style={{
          color: '#fff',
          fontSize: '11px',
          position: 'absolute',
          top: '5px',
        }}>
        {label}
      </span>
      <StyledInfoSelect
        className='flex-1 overflow-ellipsis overflow-hidden opacity-90 text-right whitespace-nowrap'
        style={{ color: '#fff', fontSize: '15px' }}
        value={value}
        onChange={e => setValue(parseInt(e.target.value))}
        disabled={disabled}>
        {values.map(select => (
          <option value={select.value}>{select.label}</option>
        ))}
      </StyledInfoSelect>
    </div>
  );
};

const LongInfoItem: React.FC<InfoProps> = ({
  className = '',
  label = '',
  value = '',
}) => {
  return (
    <div
      data-testid={infoBoxTestIds.infoBoxItem}
      className={`detailInfo ${className} ${!label && 'hidden 2xl:flex'}`}
      style={{
        width: '25rem',
        background: '#02020F',
        position: 'relative',
      }}>
      <span
        className='detailInfo__label capitalize-first'
        style={{
          color: '#0091FF',
          fontSize: '11px',
          position: 'absolute',
          top: '5px',
        }}>
        {label}
      </span>
      <span
        className='flex-1 overflow-ellipsis overflow-hidden opacity-90 text-right whitespace-nowrap'
        style={{ color: '#A39E9D', fontSize: '11.5px' }}>
        {value}
      </span>
    </div>
  );
};

const LinkItem: React.FC<LinkProps> = ({
  className = '',
  label = '',
  value = '',
  href,
  target = '_self',
}) => {
  return (
    <div
      data-testid={infoBoxTestIds.infoBoxLinkItem}
      className={`${className} detailInfo `}>
      <span className='detailInfo__label capitalize-first'>{label}</span>
      <span className='flex-1 basi overflow-ellipsis overflow-hidden underline opacity-90 text-right whitespace-nowrap'>
        <a data-testid={infoBoxTestIds.infoBoxLink} href={href} target={target}>
          {value}
        </a>
      </span>
    </div>
  );
};

const InfoBox: React.FC<BoxProps> & IBoxComposition = ({
  children,
  className = '',
}) => {
  return (
    <section
      data-testid={infoBoxTestIds.infoBox}
      className={`flex flex-wrap md:flex-nowrap gap-2 w-full text-sm lg:text-sm font-light ${className}`}
      style={{
        padding: '0',
        background: 'rgba(0,0,0,0.5)',
      }}>
      {children}
    </section>
  );
};

InfoBox.Items = Items;
InfoBox.InfoItem = InfoItem;
InfoBox.LongInfoItem = LongInfoItem;
InfoBox.LinkItem = LinkItem;
InfoBox.Image = Image;
InfoBox.SelectiveItem = SelectiveItem;

export default InfoBox;
