import { conn } from 'api/BaseConnection';
import { IListPageRequest, IListPageResponse } from 'types/globalTypes';
import {
  IChangePassPrice,
  ICreateFreePass,
  ICreatePaidPass,
  IPass,
  IPassDetail,
  IPassTransaction,
  IPlayerHistoryPass,
} from 'types/transfer/pass.interface';

const endpoint = conn.endpoints.pass;

const getDetail = async (passId: string): Promise<IPassDetail> => {
  return await conn.getJSON<IPassDetail>(endpoint.get.detail, 'json', {
    PassId: passId,
  });
};

const getAll = async (
  listPage?: IListPageRequest
): Promise<IListPageResponse<IPass>> => {
  return await conn.getJSON<IListPageResponse<IPass>>(
    endpoint.get.all,
    'json',
    { ...listPage }
  );
};

const getTransactions = async (
  listPage?: IListPageRequest
): Promise<IListPageResponse<IPassTransaction>> => {
  return await conn.getJSON<IListPageResponse<IPassTransaction>>(
    endpoint.get.transactions,
    'json',
    { ...listPage }
  );
};

const getPlayerHistory = async (
  PlayerId: string,
  listPage?: IListPageRequest
): Promise<IListPageResponse<IPlayerHistoryPass>> => {
  return await conn.getJSON<IListPageResponse<IPlayerHistoryPass>>(
    endpoint.get.playerHistory,
    'json',
    { PlayerId, ...listPage }
  );
};

const createFree = async (freePass: ICreateFreePass): Promise<{}> => {
  return await conn.postJSON(endpoint.create.free, 'json', freePass);
};

const createPaid = async (paidPass: ICreatePaidPass): Promise<{}> => {
  return await conn.postJSON(endpoint.create.paid, 'json', paidPass);
};

const setForFree = async (passId: string): Promise<{}> => {
  return await conn.patchJSON(endpoint.setForFree, 'json', { PassId: passId });
};

const changePrice = async (changePrice: IChangePassPrice): Promise<{}> => {
  return await conn.putJSON(endpoint.changePrice, 'json', changePrice);
};

const applyPlayer = async (PlayerId: string, PassId: string): Promise<{}> => {
  return await conn.postJSON(endpoint.apply, 'json', {
    PlayerId,
    PassId,
  });
};

const revokePlayer = async (PlayerId: string, PassId: string) => {
  return await conn.deleteJSON(endpoint.revoke, 'json', {
    PassId,
    PlayerId,
  });
};

const remove = async (PassId: string) => {
  return await conn.deleteJSON(endpoint.remove, 'json', {
    PassId,
  });
};

const passService = {
  getDetail,
  getAll,
  getTransactions,
  getPlayerHistory,
  createFree,
  createPaid,
  setForFree,
  changePrice,
  applyPlayer,
  revokePlayer,
  remove,
};

export default passService;
