import HeaderContainer from 'components/common/Containers/HeaderContainer';
import { InfoContainer } from 'styles/styles';
import React from 'react';
import {
  IOrderDelivery,
  IOrderDetailDelivery,
} from 'types/marketpleace/order.interface';

interface IDeliveryDataProps {
  delivery: IOrderDetailDelivery;
}

const DeliveryData = ({ delivery }: IDeliveryDataProps) => {
  return (
    <HeaderContainer title='Dostawa'>
      <InfoContainer>
        <div>
          <p>Imię</p>
          <p>{delivery.FirstName}</p>
        </div>
        <div>
          <p>Nazwisko</p>
          <p>{delivery.LastName}</p>
        </div>
        <div>
          <p>Nazwa</p>
          <p>{delivery.Name}</p>
        </div>
        <div>
          <p>Koszt dostawy</p>
          <p>{delivery.Gross.toFixed(2)} zł</p>
        </div>
        <div>
          <p>Miasto</p>
          <p>{delivery.City}</p>
        </div>
        <div>
          <p>Ulica</p>
          <p>{delivery.Street}</p>
        </div>
        <div>
          <p>Numer budynku</p>
          <p>{delivery.BuildingNumber}</p>
        </div>
        <div>
          <p>Numer mieszkania</p>
          <p>{delivery.FlatNumber}</p>
        </div>
      </InfoContainer>
    </HeaderContainer>
  );
};

export default DeliveryData;
