import { sortProductsByListSort } from 'middleware/product/sortProductsByListSort';
import React from 'react';
import ProductListItem from './ProductListItem';
import { IProductDto } from 'types/marketpleace/product.interface';
import { ITableProps, LIST_SORT } from 'types/globalTypes';

const ProductsList = ({
  containerRef,
  data,
  lastItemRef,
  isDataLoading,
  sortBy,
}: ITableProps<IProductDto, LIST_SORT>) => {
  const sortedProducts = sortProductsByListSort(data, sortBy?.value);

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(550px, 1fr))',
        gap: '4px',
      }}
      className='flex flex-col md:grid'
      ref={containerRef}>
      {sortedProducts.map((product: any, index: any) => {
        const isLast = index === data.length - 1;
        return isLast ? (
          <ProductListItem
            key={product.ProductId}
            product={product}
            innerRef={lastItemRef}
          />
        ) : (
          <ProductListItem key={product.ProductId} product={product} />
        );
      })}
      <div>{isDataLoading && 'Loading...'} </div>
    </div>
  );
};

export default ProductsList;
