import { useContext } from 'react';
import { PrimaryDataFormContext } from './AcademyInformations';

export const usePrimaryDataFormContext = () => {
  const context = useContext(PrimaryDataFormContext);

  if (!context) {
    throw new Error(
      'Containers form context cannot be use outside OrderDetailTabs'
    );
  }

  return context;
};
