import cs from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import { INavbarItem } from "./navbarTypes";

const NavbarItem: React.FC<INavbarItem> = ({
  icon: Icon,
  label,
  items,
  path,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();
  const subpageName = location.pathname.split("/")[1];

  const handleExpand = () => setIsExpanded(!isExpanded);

  const listClasses = cs("overflow-hidden", {
    "h-0": !isExpanded,
    "-mt-1 mb-2 ml-2": items?.length && isExpanded,
  });

  return (
    <div>
      {path ? (
        <NavLink
          activeClassName="text-blue"
          className="navItem"
          to={path}
          exact={path === "/"}
          onClick={handleExpand}
          style={{
            color: "#909090",
            letterSpacing: "-0.5px",
            padding: "6px 18px",
          }}
          activeStyle={{ color: "#fff" }}
        >
          <Icon className="fill-current" />
          <span>{label}</span>
        </NavLink>
      ) : (
        <div
          className="navItem"
          style={{
            color: subpageName === "shop" ? "#fff" : "#909090",
            letterSpacing: "-0.5px",
          }}
          onClick={handleExpand}
        >
          <Icon />
          <span>{label}</span>
        </div>
      )}

      <div
        className={listClasses}
        style={{ overflow: "auto", maxHeight: "100px" }}
      >
        {items?.map(({ label, path, icon: ItemIcon }) => (
          <NavLink
            activeClassName="text-blue"
            exact={path === "/home"}
            style={{ color: "#909090", letterSpacing: "-0.5px" }}
            activeStyle={{ color: "#fff" }}
            key={label}
            to={path}
            className="navItem text-xs py-4"
          >
            <div>
              {ItemIcon && <ItemIcon className="flex justify-center" />}
            </div>
            <span>{label}</span>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default NavbarItem;
