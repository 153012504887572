import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import AuthPage from "components/auth/LoginPage";
import ChangePassword from "components/profile/ChangePassword";
import ContentContainer from "components/layout/ContentContainer";
import Home from "components/home/Home";
import Layout from "components/layout/Layout";
import Profile from "components/profile/Profile";
import RegisterForm from "components/auth/Register/RegisterForm";
import RemindPassword from "components/auth/remindPassword/RemindPassword";
import PrivateRoute from "./PrivateRoute";
import { authorize } from "store/actions/auth";
import { RootState } from "store/store";
import Events from "components/events/Events";
import EventsDetail from "components/events/detail/EventsDetail";
import ChangePasswordForm from "components/auth/ChangePasswordForm";
import jwtDecode from "jwt-decode";
import Users from "components/users/Users";
import UserDetail from "components/users/detail/UserDetail";
import PlayersDetail from "components/players/detail/PlayersDetail";
import ResetPassword from "components/auth/resetPassword/ResetPassword";
import Contact from "components/contact/Contact";
import Files from "components/Files";
import Structure from "components/structure/Structure";
import Trainers from "components/trainers/Trainers";
import TrainersDetail from "components/trainers/detail/TrainersDetail";
import { ProductDetail, Products } from "components/marketpleace/Products";
import Categories from "components/marketpleace/Categories/Categories";
import Attributes from "components/marketpleace/Attributes/Attributes";
import Deliveries from "components/marketpleace/Deliveries/Deliveries";
import { OrderDetail, Orders } from "components/marketpleace/Orders";
import { Passes } from "components/marketpleace/Passes";
import Academy from "components/academy/Academy";
import OutsidePlayers from "components/players/outside/OutsidePlayers";
import TrainerOutside from 'components/trainers/detail/TrainerOutside';
import Players from "components/players/academy/Players";
import Chat from "components/chat/Chat";
import NotificationCenter from "components/notificationCenter/NotificationCenter";
import TrainerOutsideDatail from "components/trainers/detail/TrainerOutsideDetail";
import { startSignalR, stopSignalR } from '../store/actions/startSignalR';
import { userPermissions } from '../store/actions/userPermissions';
import { getUserDetail } from '../services/usersServices';


interface InicialPermissionValue {
  id: number,
  pass: boolean,
}

const initiaPermissionValue: InicialPermissionValue[] = [
  { id: 130, pass: false },
  { id: 131, pass: false },
  { id: 132, pass: false },
  { id: 133, pass: false },
  { id: 134, pass: false },
  { id: 137, pass: false },
  { id: 141, pass: false },
  { id: 140, pass: false },
  { id: 139, pass: false },
  { id: 138, pass: false },
  { id: 136, pass: false },
  { id: 160, pass: false },
  { id: 161, pass: false },
  { id: 162, pass: false },
  { id: 163, pass: false },
  { id: 164, pass: false },
  { id: 165, pass: false },
  { id: 182, pass: false },
  { id: 139, pass: false },
]

const AppRouter: React.FC = () => {
  const { isAuth } = useSelector((state: RootState) => state.auth);
  const location = useLocation<{ from: string }>();
  const dispatch = useDispatch();
  const [decodedToken, setDecodedToken] = useState<any | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const [isTabPermission, setTabPermission] = useState<typeof initiaPermissionValue>(initiaPermissionValue);

  const TAB_PERMISSIONS = [
    130,
    131,
    132,
    133,
    134,
    137,
    140,
    141,
    139,
    135,
    138,
    136,
    160,
    161,
    162,
    163
  ];

  useEffect(() => {
    dispatch(authorize());
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setToken(token);
      setDecodedToken(decodedToken);
      dispatch(startSignalR());

      return () => {
        dispatch(stopSignalR());
      };
    }
  }, [dispatch]);

  useEffect(() => {
    if (decodedToken) {
      if (decodedToken.Role !== '0') {
        getUserDetail(decodedToken.UserId)
          .then((data) => {

            const playerPermissions = data.Data.Permissions.map((perm: { Id: number, Value: string }) => perm.Id);

            const updatedPermissions = TAB_PERMISSIONS.map(id => ({
              id,
              pass: playerPermissions.includes(id),
            }));

            setTabPermission(updatedPermissions);
            dispatch(userPermissions(data.Data.Permissions));
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        dispatch(userPermissions([{ Id: -1, Value: 'Admin' }]));
        setTabPermission(
          [
            { id: 130, pass: true },
            { id: 131, pass: true },
            { id: 132, pass: true },
            { id: 133, pass: true },
            { id: 134, pass: true },
            { id: 135, pass: true },
            { id: 137, pass: true },
            { id: 141, pass: true },
            { id: 140, pass: true },
            { id: 139, pass: true },
            { id: 138, pass: true },
            { id: 136, pass: true },
            { id: 160, pass: true },
            { id: 161, pass: true },
            { id: 162, pass: true },
            { id: 163, pass: true },
            { id: 164, pass: true },
            { id: 165, pass: true },
            { id: 182, pass: true },
            { id: 139, pass: true },
          ]
        )
      }
    }
  }, [decodedToken, dispatch]);

  const isTabActive = (e: number) => {
    return isTabPermission.find(tab => tab.id === e)
  }

  return (
    <Layout>
      <Switch>
        <Route exact path="/login">
          {isAuth ? (
            <Redirect to={location.state?.from || "/"} />
          ) : (
            <AuthPage />
          )}

          {/* {
            // @ts-ignore
            !localStorage.getItem("token")?.IsTemporaryPasswordChanged ? (
              <Redirect to={"/changepassword"} />
            ) : (
              <AuthPage />
            )
          } */}
        </Route>

        <Route exact path="/changepassword">
          {/* {isAuth ? <ChangePasswordForm /> : <AuthPage />} */}
          <ChangePasswordForm />
        </Route>

        <Route exact path="/remindPassword">
          <RemindPassword />
        </Route>

        <Route exact path="/resetPassword">
          <ResetPassword />
        </Route>

        <PrivateRoute path="/">

          <Route exact path="/academy">
            {isTabActive(130)?.pass && <Academy />
            }
          </Route>

          <Route exact path="/files">
            <Files />
          </Route>

          <Route exact path="/contacts">
            <Contact />
          </Route>

          <Route exact path="/structure">
            {isTabActive(139)?.pass && <Structure />}
          </Route>

          <Route exact path="/users/detail/:id">
            <UserDetail />
          </Route>

          <Route exact path="/users">
            <Users />
          </Route>

          <Route exact path="/players">
            {isTabActive(133)?.pass && <Players />
            }
          </Route>

          <Route exact path="/players/detail/:id">
            {isTabActive(134)?.pass && <PlayersDetail />}
          </Route>

          <Route exact path="/outside-players">
            {isTabActive(135)?.pass && <OutsidePlayers />
            }
          </Route>

          <Route exact path="/outside-players/detail/:id">
            {isTabActive(134)?.pass && <PlayersDetail />}
          </Route>

          <Route exact path="/outside-trainers">
            {isTabActive(138)?.pass && <TrainerOutside />}

          </Route>

          <Route exact path="/outside-trainers/detail/:id">
            {isTabActive(182)?.pass && <TrainerOutsideDatail />
            }
          </Route>

          <Route exact path="/trainers">
            {isTabActive(136)?.pass && <Trainers />}
          </Route>

          <Route exact path="/trainers/detail/:id">
            {isTabActive(137)?.pass && <TrainersDetail />}
          </Route>

          <Route exact path="/events">

            {isTabActive(131)?.pass && <Events />
            }
          </Route>

          <Route exact path="/events/detail/:id">
            {isTabActive(132)?.pass && <EventsDetail />}
          </Route>

          <Route exact path="/shop/passes">
            {isTabActive(161)?.pass && <Passes />}
          </Route>

          <Route exact path="/shop/orders">
            {isTabActive(160)?.pass && <Orders />}
          </Route>

          <Route exact path="/shop/orders/detail/:id">
            <OrderDetail />
          </Route>

          <Route exact path="/shop/products">
            {isTabActive(162)?.pass && <Products />}
          </Route>

          <Route exact path="/shop/products/detail/:id">
            <ProductDetail />
          </Route>

          <Route exact path="/shop/categories">
            {isTabActive(163)?.pass && <Categories />}
          </Route>

          <Route exact path="/shop/attributes">
            {isTabActive(164)?.pass && <Attributes />}
          </Route>

          <Route exact path="/shop/delivery">
            {isTabActive(165)?.pass && <Deliveries />}
          </Route>

          <Route exact path="/users/add">
            <RegisterForm />
          </Route>
          <Route exact path="/notifications">
            <NotificationCenter />
          </Route>
          <Route exact path="/chat">
            <Chat />
          </Route>
          <Route path="/profile">
            <Profile />
            <Switch>
              <Route exact path="/profile/changePassword">
                <ContentContainer title="Zmień hasło">
                  <ChangePassword />
                </ContentContainer>
              </Route>
            </Switch>
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </PrivateRoute>
      </Switch>
    </Layout>
  );
};

export default AppRouter;
