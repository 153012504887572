
import DataTable from 'components/common/dataTable/DataTable';
import { getSortedAvailablePlayersByListSort } from 'middleware/player/getSortedOutsidePlayersByListSort';

import React  from 'react';
import { GridColumn, GridTextItem } from 'styles/styles';
// import { ITableProps, LIST_SORT } from 'types/globalTypes';
import { ISelectOption, LIST_SORT } from 'types/globalTypes';
import { ISentTransfer } from 'types/transfer/transfer.interface';
import { IScannerPlayer } from 'types/scanner/scanner.interface';
import logo from 'assets/images/logo.svg';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import playerServices from 'services/transfer/playerServices';

interface IRequestedPlayersTableProps {
  queryString: string;
  sortBy: ISelectOption<LIST_SORT> | null;
}

const ScannerTable = ({
  sortBy,
  queryString
}: IRequestedPlayersTableProps) => {

  const { items, loading, containerRef, lastItemRef, refresh } =
  useInfiniteScroll(playerServices.getAvailable, queryString);

  const players = getSortedAvailablePlayersByListSort(items, sortBy?.value ?? undefined);

  const rows = players.map((player: any) => ({
    data: { link: `/players/detail/${player.PlayerId}` },
    cols: [
      <div className='h-32 flex items-center justify-center p-8'>
        <img
          src={player.PhotoFilePathMin.length ? player.PhotoFilePathMin : logo}
          alt=''
          className='flex h-full w-auto'
          style={{aspectRatio: '1/1', objectFit:'cover'}}
        />
      </div>,
      <div className='h-full flex items-center justify-center text-base'>
        {player.Name}
      </div>,
       <div className='h-full flex items-center justify-center text-base'>
       {player.Distance.toFixed(1)} km
     </div>,
    ],
  }));

  return (
    <div>
      <DataTable
        rows={rows}
        headers={['Zdjęcie', 'Imię i nazwisko', 'Aktualna odległość']}
        isDataLoading={loading}
        containerRef={containerRef}
        lastItemRef={lastItemRef}
        colSizes={[25, 50, 25]}
        emptyListMessage='Brak zawodników'
      />
    </div>
  );
};

export default ScannerTable;
