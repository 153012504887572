import TabContent from 'components/common/tabs/TabContent';
import { useOrderDetailTabsContext } from '../../hook/useOrderDetailTabsContext';
import HeaderContainer from 'components/common/Containers/HeaderContainer';
import DataTable from 'components/common/dataTable/DataTable';
import { ORDER_HISTORY_STATE, PAYMENT_STATUS } from 'types/marketpleace/order.interface';
import OrderHistoryStateHeader from './OrderHistoryStateHeader';
import { showServerErrors } from 'utils/errorsUtils';
import { toast } from 'react-toastify';
import orderService from 'services/marketpleace/orderServices';
import { getFormatedDate } from 'utils/dateUtils';
import { Div } from 'components/navbar/Notifications';
import { useSelector } from 'react-redux';

const OrderHistoryState = () => {
  const { data: order, refresh } = useOrderDetailTabsContext();
  const permission = useSelector((state: any) => state.permissions.permissions);

  const isUpdatePrimaryData = permission[0]?.Id === -1 ? true : !!permission.find((permission: any) => permission.Id === 40 );

  const tableRows = order.Histories.map(state => ({
    cols: [getFormatedDate(state.Updated, 'date-time'), ORDER_HISTORY_STATE[state.State]],
  })).reverse();

  const changeState = async (state: ORDER_HISTORY_STATE) => {
    try {
      await orderService.changeState(order.OrderId, state);

      toast.success(`Zmieniono status na "${ORDER_HISTORY_STATE[state]}"`);

      await refresh();
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  return (
    <TabContent id='history'>
      <div className='flex gap-4 p-24 w-full'>
        <HeaderContainer
          title='Historia statusów'
          headerContent={
            <div style={{
              pointerEvents: isUpdatePrimaryData ? 'auto' : 'none'
            }}>
               <OrderHistoryStateHeader changeState={changeState} />
            </div>

        }
        >
          <DataTable
            headers={['Data', 'Status']}
            rows={tableRows}
            isDataLoading={false}
          />
        </HeaderContainer>
      </div>
    </TabContent>
  );
};

export default OrderHistoryState;
