import NewSelectStatystics from 'components/common/inputs/select/NewSelectStatystics';
import React, { ChangeEvent, useState } from 'react';
import styled from "styled-components";
import { ISortingProps } from 'types/sortType';
import { PASSES_SORT_LIST } from 'types/transfer/pass.interface';
import { SearchBox, SearchInput } from 'styles/styles';
import HomeSearchIco from '../../../assets/icons/home-search-ico.png';
import FilterPass from "components/filters/FilterPass";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import { FilterPassData } from 'types/filterPassData';

const BlurBox = styled.div<{
  isMenuOpen: boolean;
  isAddMenuOpen: boolean;
  isDraftMenuOpen: boolean;
}>`
  position: absolute;
  display: ${(props) =>
    props.isMenuOpen || props.isAddMenuOpen || props.isDraftMenuOpen
      ? "unset"
      : "none"};
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(3px);
  z-index: 99;
`;

const SideMenu = styled.div<{ isMenuOpen: boolean }>`
  position: absolute;
  height: calc(100vh - 48px);
  top: 48px;
  padding: ${(props) => (props.isMenuOpen ? "23px 44px" : "23px 0px")};
  right: 0;
  width: ${(props) => (props.isMenuOpen ? "369px" : "0px")};
  background: rgba(2, 2, 15, 1);
  transition: all 0.25s ease-in-out;
  color: rgba(163, 158, 157, 1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  & > * {
    opacity: ${(props) => (props.isMenuOpen ? "1" : "0")};
    transition: all 0.125s ease-in-out;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    & > p {
      text-align: center;
      font-size: 15px;
      font-weight: 400;
    }
  }
`;

const SideAddMenu = styled.div<{ isAddMenuOpen: boolean }>`
  position: absolute;
  height: calc(100vh - 48px);
  top: 48px;
  /* padding: ${(props) => (props.isAddMenuOpen ? "23px 44px" : "0")}; */
  padding: ${(props) => (props.isAddMenuOpen ? "23px 0" : "23px 0")};
  right: 0;
  width: ${(props) => (props.isAddMenuOpen ? "917px" : "0px")};
  background: rgba(2, 2, 15, 1);
  transition: all 0.4s ease-in-out;
  color: rgba(163, 158, 157, 1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  & > * {
    opacity: ${(props) => (props.isAddMenuOpen ? "1" : "0")};
    transition: all 0.2s ease-in-out;
  }

  & > div {
    width: 100%;
    display: flex;
    height: 1100px;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    & > form {
      & > div:first-child {
        & > div {
          & > p {
            text-align: left;
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
`;

interface IPassesTopbarProps extends ISortingProps<PASSES_SORT_LIST> {
  handleQueryChange: (value: string) => void;
  refresh: () => Promise<void>;
  dataForFilterTable?: (value: FilterPassData ) => void
}

const PassesTopbar = ({
  handleQueryChange,
  defaultSort,
  setSortBy,
  sortBy,
  sorts,
  refresh,
  dataForFilterTable
}: IPassesTopbarProps) => {
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    handleQueryChange(e.target.value);
  };

  const [isMenuOpen, toggleMenuOpen] = useState(false);
  const [isAddMenuOpen, toggleAddMenuOpen] = useState(false);
  const [isDraftMenuOpen, toggleDraftMenuOpen] = useState(false);

  const filters = [
    {
      id: 0,
      type: "select",
      name: "Type",
      values: [
        { backend: 0, pl: "Zapłacony" },
        { backend: 1, pl: "Oczekuje na zapłatę" },
        { backend: 2, pl: "Zweryfikowany" },
        { backend: 3, pl: "Niezapłacony" },
        { backend: 4, pl: "Zaległy" },
        { backend: 5, pl: "Anulowany" },

      ],
      pl: "Status",
    },

  ];

  const filterData = (value: FilterPassData) => {
    if ( value !== undefined && dataForFilterTable) {
      dataForFilterTable(value)
    }

  }

  return (
    <div
      className='flex flex-wrap items-center justify-end gap-y-2'
      style={{ gap: '3px' }}>
      <Button className="px-24 xl:order-2"
        variant={ButtonVariant.Submit}
        style={{
          padding: "15px 41.4064px",
          background: "rgba(163,158,157,0.38)",
          width: "120px",
          fontSize: "15px",
          fontWeight: 400,
        }}
        onClick={() => {
          if (isAddMenuOpen) {
            toggleAddMenuOpen(false);
          } else if (!isMenuOpen && !isAddMenuOpen) {
            toggleAddMenuOpen(true);
          } else {
            toggleMenuOpen(false);
            setTimeout(() => {
              toggleAddMenuOpen(true);
            }, 250);
          }
        }}
      >
        Filtruj
      </Button>
      <BlurBox

        isMenuOpen={isMenuOpen}
        isAddMenuOpen={isAddMenuOpen}
        isDraftMenuOpen={isDraftMenuOpen}
        onClick={() => {
          toggleAddMenuOpen(false);
          toggleMenuOpen(false);
          /* toggleDraftMenuOpen(false); */
        }}
      />

      {/*  <SideMenu isMenuOpen={isMenuOpen}>
    <FilterPass filters={filters} />
  </SideMenu> */}

      <SideAddMenu
         isAddMenuOpen={isAddMenuOpen}
         style={{
           maxWidth: isAddMenuOpen ? "500px" : '0px',
           width: isAddMenuOpen ? "100%" : '0%'
         }}
      >
        <div >
          <FilterPass filters={filters} filterData={filterData} />
        </div>
      </SideAddMenu>

      {sorts?.length ? (
        <NewSelectStatystics<PASSES_SORT_LIST>
          name='Sort'
          items={sorts}
          label='Sortowanie'
          selectedItem={sortBy}
          setSelectedItem={setSortBy}
          defaultValue={defaultSort}
        />
      ) : null}
      <SearchBox>
        <SearchInput
          className='w-full md:w-36 xl:w-72'
          placeholder={'Szukaj...'}
          autoComplete='off'
          name='search'
          onChange={handleSearch}
        />
        <div>
          <img src={HomeSearchIco} alt='ico' />
        </div>
      </SearchBox>
    </div>
  );
};

export default PassesTopbar;
