import React, { ReactNode, ReactText } from 'react';
import { GridItem, GridTextItem } from 'styles/styles';

export interface IColumnTableRowProps {
  header: string;
  children: ReactNode | ReactText;
  disableUppercase?: boolean;
}

const ColumnTableRow = ({
  header,
  children,
  disableUppercase,
}: IColumnTableRowProps) => {
  return (
    <>
      <GridTextItem uppercase title={header}>
        {header}
      </GridTextItem>
      {typeof children === ('string' || 'number') ? (
        <GridTextItem uppercase={!disableUppercase} title={`${children}`}>
          {children}
        </GridTextItem>
      ) : (
        <GridItem>{children}</GridItem>
      )}
    </>
  );
};

export default ColumnTableRow;
