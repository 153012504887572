import HeaderContainer from 'components/common/Containers/HeaderContainer';
import InfoContainerItem from 'components/common/Containers/InfoContainerItem';
import { InfoContainer } from 'styles/styles';
import { IAcademyRepresentingPerson } from 'types/academy.interface';

interface IAcademyRepresentativeProps {
  representing: IAcademyRepresentingPerson;
}

const AcademyRepresentative = ({
  representing,
}: IAcademyRepresentativeProps) => {
  return (
    <HeaderContainer title='Osoba reprezentująca firmę'>
      <InfoContainer className='w-full'>
        <InfoContainerItem
          header='Imię i nazwisko'
          content={representing.Name}
        />
        <InfoContainerItem
          header='Telefon'
          content={representing.PhoneNumber}
        />
        <InfoContainerItem header='Pesel' content={representing.PESEL} />
      </InfoContainer>
    </HeaderContainer>
  );
};

export default AcademyRepresentative;
