import { GridTextItem } from 'styles/styles';

interface IAttributesHeaderProps {
  headers: string[];
}

const AttributesHeader = ({ headers }: IAttributesHeaderProps) => {
  return (
    <>
      {headers.map((header, key) => (
        <GridTextItem key={key} isHeader title={header} uppercase>
          {header}
        </GridTextItem>
      ))}
    </>
  );
};

export default AttributesHeader;
