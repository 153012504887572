import HeaderContainer from 'components/common/Containers/HeaderContainer';
import TabContent from 'components/common/tabs/TabContent';
import { InfoContainer } from 'styles/styles';
import { IAddress } from 'types/address.interface';
import { Country } from 'utils/constants/countires';

interface IPlayerAddressProps {
  address: IAddress | null;
}

const PlayerAddress = ({ address }: IPlayerAddressProps) => {
  return (
    <TabContent id='address'>
      <div className='flex w-full max-w-xl'>
        <HeaderContainer title='Adres' withContainer={!address}>
          {!address ? (
            'Brak dostępu'
          ) : (
            <InfoContainer className='w-full'>
              <div>
                <p>Kraj</p>
                <p>
                  <div className='flex justify-end'>
                    {address.Country === Country['Poland']
                      ? 'Polska'
                      : Country[address.Country]}
                  </div>

                </p>
              </div>
              <div>
                <p>Kod pocztowy</p>
                  <p>
                    <div className='flex justify-end'>
                     {address.PostCode}
                    </div>
                  </p>
              </div>
              <div>
                <p>Województwo</p>
                  <p>
                  <div className='flex justify-end'>
                    {address.Region}
                  </div>
                  </p>
              </div>
              <div>
                <p>Miasto</p>
                  <p>
                    <div className='flex justify-end'>
                      {address.City}
                    </div>
                  </p>
              </div>
              <div>
                <p>Ulica</p>
                  <p>
                    <div className='flex justify-end'>
                      {address.Street}
                    </div>
                  </p>
              </div>
              <div>
                <p>Numer budynku</p>
                  <p>
                    <div className='flex justify-end'>
                      {address.BuildingNumber}
                    </div>
                  </p>
              </div>
              <div>
                <p>Numer mieszkania</p>
                  <p>
                    <div className='flex justify-end'>
                      {address.FlatNumber}
                    </div>
                  </p>

              </div>
            </InfoContainer>
          )}
        </HeaderContainer>
      </div>
    </TabContent>
  );
};

export default PlayerAddress;
