import Tabs from 'components/common/tabs/Tabs';
import TabsView from 'components/common/tabs/TabsView';
import React from 'react';
import { orderDetailTabs } from '../../utils/helpers';
import OrderProducts from './Products/OrderProducts';
import OrderDelivery from './Delivery/OrderDelivery';
import OrderDocuments from './Documents/OrderDocuments';
import OrderHistoryState from './HistoryState/OrderHistoryState';
import OrderMessages from './Messages/OrderMessages';
import OrderRefunds from './Refunds/OrderRefunds';
import { useSelector } from 'react-redux';
import { getDetailTabsByEnum } from 'utils/objectUtils';

enum ORDER_TABS {
  products = 'Produkty',
  delivery = 'Dostawa',
  documents = 'Dokumenty',
  history = 'Historia statusów',
  comments = 'Uwagi',
  returns =  "Zwroty"
}

const PERMISSIONS: Record<string, string> = {
  "166": "Produkty",
  "167": "Dostawa",
  "168": "Dokumenty",
  "169": "Historia statusów",
  "170": "Uwagi",
  "171": "Zwroty",
};

const OrderDetailTabs = () => {

  const orderTabs = getDetailTabsByEnum(ORDER_TABS);

  const permission = useSelector((state: any) => state.permissions.permissions);

  const orderTabsWithPermissions = orderTabs.map((tab) => {
    return {
      ...tab,
      permission: !!permission.find(
        (permission: any) => PERMISSIONS[permission.Id] === tab.label
      ),
    };
  });

  const tabs = permission[0]?.Id === -1 ? orderTabs :  orderTabsWithPermissions;

  return (
    <TabsView>
      <Tabs tabs={tabs} />
      <OrderProducts />
      <OrderDelivery />
      <OrderDocuments />
      <OrderHistoryState />
      <OrderMessages />
      <OrderRefunds />
    </TabsView>
  );
};

export default OrderDetailTabs;
