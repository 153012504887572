
import HeaderContainer from 'components/common/Containers/HeaderContainer'
import InfoContainerItem from 'components/common/Containers/InfoContainerItem';
import { InfoContainer } from 'styles/styles';
import { IAcademyRepresentingPerson } from 'types/academy.interface';

// interface IAcademyRepresentativeProps {
//   representing: IAcademyRepresentingPerson;
// }

const AcademyLicenceData = (
//     {
//   representing,
// }: IAcademyRepresentativeProps


) => {
  return (
    <div className='w-96'>
      <HeaderContainer title='Licencja'>
        <InfoContainer className='w-full'>
        <InfoContainerItem
          header='Typ'
          content={'Academiq'}
        />
        <InfoContainerItem
          header='Ważność'
          content={'31.12.2024'}
        />
        <InfoContainerItem
          header='Nr'
          content={'1e25/0105'}
        />
        </InfoContainer>
      </HeaderContainer>
    </div>
  );
};

export default AcademyLicenceData;
