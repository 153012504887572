import { ChangeEvent, useState, useEffect } from "react";
import { useHistory } from "react-router";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import NewSelectStatystics from "components/common/inputs/select/NewSelectStatystics";
import styled from "styled-components";
import NewFilterPanel from "components/filters/NewFilterPanel";
import HomeSearchIco from "../../assets/icons/home-search-ico.png";
import GridIco from "../../assets/icons/home-grid-ico.png";
import ListIco from "../../assets/icons/home-list-ico.png";
import AddDigitalRelease from "./form/AddDigitalRelease";
import EditDraftEvent from "./form/EditDraftEvent";
import StatisticsTextInput from "components/common/inputs/textInput/StatisticsTextInput";
import SelectWithoutFilter from "components/common/inputs/select/SelectWithoutFilter";
import { useUrlQuery } from "hooks/useUrlQuery";
import { toast } from "react-toastify";

const TimeAndDateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TimeInput = styled.input`
  height: 48px;
  width: 84px;
  line-height: 1rem;
  background-color: transparent;
  border: 1px solid lightgray;
  padding: 5px 12px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background: rgba(0, 0, 0, 0.1); */
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-left: unset;
  color: white;

  &::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
`;

const DateInput = styled(StatisticsTextInput)`
  width: 193px;
  height: 48px;
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  background-color: transparent;
  /* background: rgba(0, 0, 0, 0.1); */
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;

  &::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
`;

const SortSquare = styled.div<{ viewType: string; view: string }>`
  height: 48px;
  width: 54px;
  background: ${(props) =>
    props.viewType === props.view
      ? "rgba(163, 158, 157, 0.11)"
      : "rgba(163, 158, 157, 0.38)"};

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    filter: brightness(90%);
  }

  /* filter: ${(props) =>
    props.viewType === props.view ? "brightness(50%)" : "brightness(100%)"}; */

  & > * {
    /* filter: brightness(50%); */
    pointer-events: none;
  }
`;

const BlurBox = styled.div<{
  isMenuOpen: boolean;
  isAddMenuOpen: boolean;
  isDraftMenuOpen: boolean;
}>`
  position: absolute;
  display: ${(props) =>
    props.isMenuOpen || props.isAddMenuOpen || props.isDraftMenuOpen
      ? "unset"
      : "none"};
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(3px);
  z-index: 99;
`;

const SideMenu = styled.div<{ isMenuOpen: boolean }>`
  position: absolute;
  height: calc(100vh - 48px);
  top: 48px;
  padding: ${(props) => (props.isMenuOpen ? "23px 44px" : "23px 0px")};
  right: 0;
  width: ${(props) => (props.isMenuOpen ? "369px" : "0px")};
  background: rgba(2, 2, 15, 1);
  transition: all 0.25s ease-in-out;
  color: rgba(163, 158, 157, 1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  & > * {
    opacity: ${(props) => (props.isMenuOpen ? "1" : "0")};
    transition: all 0.125s ease-in-out;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    & > p {
      text-align: center;
      font-size: 15px;
      font-weight: 400;
    }
  }
`;

const SideAddMenu = styled.div<{ isAddMenuOpen: boolean }>`
  position: absolute;
  height: calc(100vh - 48px);
  top: 48px;
  /* padding: ${(props) => (props.isAddMenuOpen ? "23px 44px" : "0")}; */
  padding: ${(props) => (props.isAddMenuOpen ? "23px 0" : "23px 0")};
  right: 0;
  width: ${(props) => (props.isAddMenuOpen ? "917px" : "0px")};
  background: rgba(2, 2, 15, 1);
  transition: all 0.4s ease-in-out;
  color: rgba(163, 158, 157, 1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  & > * {
    opacity: ${(props) => (props.isAddMenuOpen ? "1" : "0")};
    transition: all 0.2s ease-in-out;
  }

  & > div {
    width: 100%;
    display: flex;
    height: 1100px;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    & > form {
      & > div:first-child {
        & > div {
          & > p {
            text-align: left;
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
`;

const SearchBox = styled.div`
  height: 48px;
  background: rgba(163, 158, 157, 0.11);
  color: white;
  width: 280px;
  display: flex;
  align-items: center;

  & > input {
    width: calc(100% - 45px);
  }

  & > div {
    width: 45px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      width: 21px;
      height: 21px;
    }
  }
`;

const SearchInput = styled.input`
  background: transparent;
  height: 100%;
  width: 100%;
  font-size: 15px;
  font-weight: 300;
  padding: 14px 23px;
`;

interface IDigitalReleasesTopbarProps {
  handleQueryChange: (value: string) => void;
  sortBy: any;
  setSortBy: any;
  sorts: any;
  defaultSort: any;
  viewType: any;
  setViewType: any;
  isDraftMenuOpen: any;
  toggleDraftMenuOpen: any;
  clickedCyclicalEventId: any;
  setClickedCyclicalEventId: any;
  displayType: any;
  setDisplayType: any;
  displayTypes: any;
}

const EventsTopbar: React.FC<IDigitalReleasesTopbarProps> = ({
  handleQueryChange,
  sortBy,
  setSortBy,
  sorts,
  defaultSort,
  setViewType,
  viewType,
  isDraftMenuOpen,
  toggleDraftMenuOpen,
  clickedCyclicalEventId,
  setClickedCyclicalEventId,
  displayType,
  displayTypes,
  setDisplayType,
}) => {
  const { push } = useHistory();
  const [benchmarkTimeFrom, setBenchmarkTimeFrom] = useState("");
  const [benchmarkTimeTo, setBenchmarkTimeTo] = useState("");
  const [isMenuOpen, toggleMenuOpen] = useState(false);
  const [isAddMenuOpen, toggleAddMenuOpen] = useState(false);
  const { query, changeQuery } = useUrlQuery();
  const [dateTypes, setDateTypes] = useState<
    { value: string; label: string }[]
  >([]);

  const [activeFilters, setActiveFilters] = useState<Array<any>>([]);

  const [dateType, setDateType] =
    useState<{
      value: string;
      label: string;
    } | null>(null);

  const [benchmarkDateFrom, setBenchmarkDateFrom] = useState("");
  const [benchmarkDateTo, setBenchmarkDateTo] = useState("");

  const handleAddDigitalRelease = () => {
    push("/shop/products/add");
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    handleQueryChange(e.target.value);
  };

  const filters = [
    {
      id: 0,
      type: "select",
      name: "Type",
      values: [
        { backend: 0, pl: "Trening" },
        { backend: 1, pl: "Trening aerobowy" },
        { backend: 2, pl: "Trening personalny" },
        { backend: 3, pl: "Badania okresowe" },
        { backend: 4, pl: "Mecz piłkarski" },
      ],
      pl: "Typ",
    },
    {
      id: 1,
      type: "select",
      name: "ActivityLevel",
      values: [
        { backend: 0, pl: "Klub" },
        { backend: 1, pl: "Reprezentacja" },
        { backend: 2, pl: "Własne" },
      ],
      pl: "Poziom",
    },
    {
      id: 2,
      type: "select",
      name: "Visibility",
      values: [
        { backend: 0, pl: "Wszyscy" },
        { backend: 1, pl: "Tylko zaproszeni" },
        { backend: 2, pl: "Grupa" },
      ],
      pl: "Uczestnicy",
    },
  ];

  const getAllDateTypes = async () => {
    try {
      setDateTypes([
        {
          label: "+/- 3 dni",
          value: "3days",
        },
        {
          label: "Dzień dzisiejszy",
          value: "today",
        },
        {
          label: "7 dni wstecz",
          value: "7daysago",
        },
        {
          label: "14 dni wstecz",
          value: "14daysago",
        },
        {
          label: "Zaplanowane wydarzenia na 7 dni",
          value: "7days",
        },

        {
          label: "Zaplanowane wydarzenia na 30 dni",
          value: "month",
        },
        {
          label: "Niestandardowe",
          value: "custom",
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllDateTypes();

    if (displayType === null) {
      setDisplayType({ label: "Zajęcia", value: 0 });
    }
  }, []);

  useEffect(() => {
    if (dateType?.value === "today") {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      setBenchmarkDateFrom(formattedDate);
      setBenchmarkTimeFrom("00:01");
      setBenchmarkDateTo(formattedDate);
      setBenchmarkTimeTo("23:59");
    }
    if (dateType?.value === "3days") {
      const today = new Date();

      const threeDaysAgo = new Date();
      threeDaysAgo.setDate(today.getDate() - 3);

      const startYear = threeDaysAgo.getFullYear();
      const startMonth = (threeDaysAgo.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const startDay = threeDaysAgo.getDate().toString().padStart(2, "0");

      const startDate = `${startYear}-${startMonth}-${startDay}`;
      setBenchmarkDateFrom(startDate);
      setBenchmarkTimeFrom("00:01");

      const threeDaysLater = new Date();
      threeDaysLater.setDate(today.getDate() + 3);

      const endYear = threeDaysLater.getFullYear();
      const endMonth = (threeDaysLater.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const endDay = threeDaysLater.getDate().toString().padStart(2, "0");

      const endDate = `${endYear}-${endMonth}-${endDay}`;
      setBenchmarkDateTo(endDate);
      setBenchmarkTimeTo("23:59");
    }

    if (dateType?.value === "7days") {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() + 7);

      const startYear = sevenDaysAgo.getFullYear();
      const startMonth = (sevenDaysAgo.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      const startDate = `${startYear}-${startMonth}-${day}`;
      setBenchmarkDateFrom(startDate);
      setBenchmarkTimeFrom("00:01");

      const endDay = sevenDaysAgo.getDate().toString().padStart(2, "0");
      const endDate = `${year}-${month}-${endDay}`;
      setBenchmarkDateTo(endDate);
      setBenchmarkTimeTo("23:59");
    }

    if (dateType?.value === "7daysago") {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

      const startYear = sevenDaysAgo.getFullYear();
      const startMonth = (sevenDaysAgo.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      const startDay = sevenDaysAgo.getDate().toString().padStart(2, "0");
      const startDate = `${startYear}-${startMonth}-${startDay}`;
      setBenchmarkDateFrom(startDate);
      setBenchmarkTimeFrom("00:01");

      const endDate = `${year}-${month}-${day}`;
      setBenchmarkDateTo(endDate);
      setBenchmarkTimeTo("23:59");
    }

    if (dateType?.value === "14daysago") {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 14);

      const startYear = sevenDaysAgo.getFullYear();
      const startMonth = (sevenDaysAgo.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      const startDay = sevenDaysAgo.getDate().toString().padStart(2, "0");
      const startDate = `${startYear}-${startMonth}-${startDay}`;
      setBenchmarkDateFrom(startDate);
      setBenchmarkTimeFrom("00:01");

      const endDate = `${year}-${month}-${day}`;
      setBenchmarkDateTo(endDate);
      setBenchmarkTimeTo("23:59");
    }

    if (dateType?.value === "month") {
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() + 30);
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      const startYear = thirtyDaysAgo.getFullYear();
      const endMonth = (thirtyDaysAgo.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const startDay = thirtyDaysAgo.getDate().toString().padStart(2, "0");

      const startDate = `${startYear}-${month}-${startDay}`;
      setBenchmarkDateFrom(startDate);
      setBenchmarkTimeFrom("00:01");

      const endDate = `${year}-${endMonth}-${day}`;
      setBenchmarkDateTo(endDate);
      setBenchmarkTimeTo("23:59");
    }
    if (dateType?.value === "custom") {
      setBenchmarkDateFrom("");
      setBenchmarkTimeFrom("");
      setBenchmarkDateTo("");
      setBenchmarkTimeTo("");
    }
  }, [dateType]);

  const changeDateQuery = () => {
    if (benchmarkDateFrom && benchmarkDateTo) {
      changeQuery([
        {
          name: "OccurenceDate",
          value: `${benchmarkDateFrom}T${
            benchmarkTimeFrom ? benchmarkTimeFrom : "00:01"
          }:00Z`,
        },

        {
          name: "EndDate",
          value: `${benchmarkDateTo}T${
            benchmarkTimeTo ? benchmarkTimeTo : "23:59"
          }:00Z`,
        },
      ]);
    } else if (benchmarkDateFrom) {
      changeQuery([
        {
          name: "OccurenceDate",
          value: `${benchmarkDateFrom}T${
            benchmarkTimeFrom ? benchmarkTimeFrom : "00:01"
          }:00Z`,
        },
      ]);
    } else if (benchmarkDateTo) {
      changeQuery([
        {
          name: "EndDate",
          value: `${benchmarkDateTo}T${
            benchmarkTimeTo ? benchmarkTimeTo : "23:59"
          }:00Z`,
        },
      ]);
    } else toast.error("Wybierz date!");
  };

  return (
    <div
      // className="flex flex-wrap items-center justify-end gap-y-2"
      style={{
        gap: "5px",
        minHeight: "99px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <BlurBox
        isMenuOpen={isMenuOpen}
        isAddMenuOpen={isAddMenuOpen}
        isDraftMenuOpen={isDraftMenuOpen}
        onClick={() => {
          toggleAddMenuOpen(false);
          toggleMenuOpen(false);
          toggleDraftMenuOpen(false);
        }}
      />

      <SideMenu isMenuOpen={isMenuOpen}>
        <NewFilterPanel filters={filters} />
      </SideMenu>

      <SideAddMenu isAddMenuOpen={isAddMenuOpen}>
        <div>
          <AddDigitalRelease isAddMenuOpen={isAddMenuOpen} />
        </div>
      </SideAddMenu>

      <SideAddMenu isAddMenuOpen={isDraftMenuOpen}>
        {clickedCyclicalEventId && (
          <div>
            <EditDraftEvent
              clickedCyclicalEventId={clickedCyclicalEventId}
              isAddMenuOpen={isDraftMenuOpen}
              setClickedCyclicalEventId={setClickedCyclicalEventId}
            />
          </div>
        )}
      </SideAddMenu>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "5px",
          flexWrap: "wrap",
          paddingLeft: "10px",
        }}
      >
        {/* <NewSelectStatystics
          name="DisplayType"
          items={displayTypes}
          label="Wyświetlanie"
          // @ts-ignore
          selectedItem={displayType}
          setSelectedItem={setDisplayType}
          defaultValue={0}
        /> */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "5px",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              backgroundColor:
                displayType !== null
                  ? "#222c38"
                  : displayType === null
                  ? "#222c38"
                  : "#2a323a",
              border: "2px solid #323a44",
              borderRadius: "2px",
              padding: "2px",
            }}
          >
            <div
              style={{
                border: "1px solid #222c38",
                borderRadius: "2px",
              }}
            >
              {displayTypes.length
                ? displayTypes.map(
                    (
                      types: { label: string; value: number },
                      index: number,
                    ) => (
                      <button
                        style={{
                          color: "white",
                          height: "38px",
                          width: "188px",
                          backgroundColor:
                            displayType !== null &&
                            displayType.value === types.value
                              ? "rgba(163, 158, 157, 0.38)"
                              : index === 0 && displayType === null
                              ? "rgba(163, 158, 157, 0.38)"
                              : "#222c38",
                          pointerEvents:
                            displayType !== null &&
                            displayType.value === types.value
                              ? "none"
                              : "auto",
                          transition: "background-color 0.6s ease",
                          borderRadius: "2px",
                        }}
                        disabled={
                          displayType !== null &&
                          displayType.value === types.value
                        }
                        key={types.value}
                        onClick={() =>
                          setDisplayType({
                            label: types.label,
                            value: types.value,
                          })
                        }
                      >
                        {types.label}
                      </button>
                    ),
                  )
                : null}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "5px",
            flexWrap: "wrap",
          }}
        >
          <NewSelectStatystics
            name="Sort"
            items={sorts}
            label="Sortowanie"
            selectedItem={sortBy}
            setSelectedItem={setSortBy}
            defaultValue={defaultSort}
          />

          <SearchBox>
            <SearchInput
              className="w-full md:w-36 xl:w-72"
              placeholder="Szukaj..."
              autoComplete="off"
              name="search"
              onChange={handleSearch}
              // @ts-ignore
              defaultValue={localStorage.getItem("searchQuery")}
            />
            <div>
              <img src={HomeSearchIco} alt="ico" />
            </div>
          </SearchBox>

          <Button
            className="px-24 xl:order-2"
            variant={ButtonVariant.Submit}
            style={{
              padding: "15px 41.4064px",
              background: "rgba(163,158,157,0.38)",
              width: "120px",
              fontSize: "15px",
              fontWeight: 400,
            }}
            onClick={() => {
              if (isMenuOpen) {
                toggleMenuOpen(false);
              } else if (!isMenuOpen && !isAddMenuOpen) {
                toggleMenuOpen(true);
              } else {
                toggleAddMenuOpen(false);
                setTimeout(() => {
                  toggleMenuOpen(true);
                }, 400);
              }
            }}
          >
            Filtruj
          </Button>
          <Button
            onClick={() => {
              if (isAddMenuOpen) {
                toggleAddMenuOpen(false);
              } else if (!isMenuOpen && !isAddMenuOpen) {
                toggleAddMenuOpen(true);
              } else {
                toggleMenuOpen(false);
                setTimeout(() => {
                  toggleAddMenuOpen(true);
                }, 250);
              }
            }}
            className="px-24 xl:order-2"
            variant={ButtonVariant.Submit}
            style={{
              fontSize: "13px",
              fontWeight: 400,
              color: "white",
              background: "rgb(0, 160, 255)",
            }}
          >
            Dodaj zajęcie
          </Button>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "5px",
          flexWrap: "wrap",
        }}
      >
        <SelectWithoutFilter
          name=""
          items={dateTypes}
          className={"min-w-160"}
          style={{ minWidth: "360px" }}
          label="Okres"
          selectedItem={dateType}
          setSelectedItem={setDateType}
          defaultValue={"3days"}
        />

        {dateType?.value === "custom" && (
          <>
            <TimeAndDateWrapper>
              <DateInput
                name="AvailableFrom"
                label={"Od"}
                type="date"
                value={benchmarkDateFrom}
                className={"min-w-120"}
                onChange={(e) => setBenchmarkDateFrom(e.target.value)}
                max={benchmarkDateTo}
              />

              <TimeInput
                type="time"
                value={benchmarkTimeFrom}
                onChange={(e) => {
                  setBenchmarkTimeFrom(e.target.value);
                }}
              />
            </TimeAndDateWrapper>
            <TimeAndDateWrapper>
              <DateInput
                name="AvailableTo"
                label={"Do"}
                type="date"
                className={"min-w-120"}
                value={benchmarkDateTo}
                onChange={(e) => setBenchmarkDateTo(e.target.value)}
                max={new Date().toISOString().slice(0, 10)}
              />
              <TimeInput
                type="time"
                value={benchmarkTimeTo}
                onChange={(e) => setBenchmarkTimeTo(e.target.value)}
              />
            </TimeAndDateWrapper>
          </>
        )}
        <Button
          className="px-24 xl:order-2"
          variant={ButtonVariant.Submit}
          onClick={() => changeDateQuery()}
          style={{
            fontSize: "13px",
            fontWeight: 400,
            color: "white",
            background: "rgb(0, 160, 255)",
            width: "157.7px",
          }}
        >
          Zastosuj
        </Button>
      </div>
    </div>
  );
};

export default EventsTopbar;
