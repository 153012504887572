import { LIST_SORT } from 'types/globalTypes';
import { IAcademyPlayer } from 'types/transfer/player.interface';
import { compareArray } from 'utils/baseUtils';

export const getSortedPlayersByListSort = (
    array: IAcademyPlayer[],
    type?: LIST_SORT
  ) => {
    switch (type) {
      case 0:
        return array.slice().reverse();
      case 1:
        return array;
      case 2:
        return array
          .slice()
          .sort((a, b) => compareArray<IAcademyPlayer>(a, b, 'Name'))
          .reverse();
      case 3:
        return array
          .slice()
          .sort((a, b) => compareArray<IAcademyPlayer>(a, b, 'Name'));
      default:
        return array;
    }
  };
  