import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import {
  TableHeaderType,
  TableRowType,
} from 'components/common/table/tableTypes';
import TabContent from 'components/common/tabs/TabContent';
import { StyledInput } from 'styles/styles';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useProductDetailTabsContext } from '../../hook/useProductDetailTabsContext';
import Table from 'components/common/table/Table';
import { showServerErrors } from 'utils/errorsUtils';
import { productServices } from 'services/marketpleace';
import { toast } from 'react-toastify';
import { IAdditionalFile } from 'types/fileType';
import systemServices from 'services/systemServices';
import { handleNewBase64Change } from 'utils/fileUtils';
import DocumentActionContent from 'components/common/Documents/DocumentActionContent';

const ProductDocuments = () => {
  const { data: product, refresh } = useProductDetailTabsContext();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [newBase64String, setNewBase64String] = useState<string>('');
  const [fileName, setFileName] = useState('');

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const sendFile = async () => {
    if (newBase64String && fileName) {
      const fileUrl = await systemServices.uploadFile(
        newBase64String.split(',')[1]
      );
      await productServices.addAdditionalFile(
        product.ProductId,
        fileName,
        fileUrl
      );
      refresh();
      toast.success(`Dodano dokument ${fileName}`);
      setNewBase64String('');
      setFileName('');
    }
  };

  useEffect(() => {
    if (newBase64String) {
      sendFile();
    }
  }, [newBase64String]);

  const handleRemove = async (additionalFile: IAdditionalFile) => {
    try {
      await productServices.removeAdditionalFile(
        product.ProductId,
        additionalFile.AdditionalFileId
      );
      refresh();
      toast.success(`Usunięto dokument ${additionalFile.Name}`);
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  const tableHeaders: TableHeaderType[] = [
    {
      key: 'name',
      label: 'Nazwa pliku',
      sortable: false,
    },
    {
      key: 'action',
      label: 'Akcja',
    },
  ];

  const tableRows = product.AdditionalFiles.map(
    additionalFile =>
      ({
        cols: [
          {
            key: 'name',
            title: additionalFile.Name,
            content: additionalFile.Name,
          },
          {
            key: 'action',
            title: 'Akcja',
            content: (
              <DocumentActionContent
                document={additionalFile}
                onRemoveButton={() => handleRemove(additionalFile)}
              />
            ),
          },
        ],
      } as TableRowType)
  );

  return (
    <TabContent id='documents'>
      <div className='flex flex-col gap-4 p-24 '>
        <div className='flex items-start gap-4'>
          <input
            type='file'
            onChange={e => handleNewBase64Change(e, setNewBase64String)}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />

          <Button
            className='px-24 xl:product-2 rounded-sm'
            variant={ButtonVariant.Submit}
            disabled={fileName.length > 0 ? false : true}
            style={{
              padding: '15px 11.4064px',
              background: 'rgb(0, 160, 255)',
              width: '180px',
              fontSize: '15px',
              fontWeight: 400,
            }}
            onClick={handleButtonClick}>
            Wyślij dokument
          </Button>

          <StyledInput
            style={{ background: 'rgba(0,0,0,0.04)' }}
            type='text'
            placeholder='Nazwa pliku'
            value={fileName}
            onChange={e => setFileName(e.target.value)}
          />
        </div>
        <Table headers={tableHeaders} rows={tableRows} />
      </div>
    </TabContent>
  );
};

export default ProductDocuments;
