import TabsView from 'components/common/tabs/TabsView';
import React from 'react';
import AcademyInformations from './Informations/AcademyInformations';
import { getDetailTabsByEnum } from 'utils/objectUtils';
import Tabs from 'components/common/tabs/Tabs';
import AcademyConsents from './Consents/AcademyConsents';
import AcademyPasses from './Passes/AcademyPasses';
import AcademyLicence from './Licence/AcademyLicence';
import AcademyLocalizations from './Localizations/AcademyLocalizations';
import AcademyMerchant from './Merchant/AcademyMerchant';

import { useSelector } from 'react-redux';

export enum ACADEMY_TABS {
  informations = 'Dane',
  licence = 'Licencja',
  merchant = 'Sprzedawca',
  passes = 'Składki',
  localizations = 'Ulubione lokalizacje',
}

const PERMISSIONS: Record<string, string> = {
  "123": "Dane",
  "124": "Licencja",
  "125": "Sprzedawca",
  "126": "Składki",
  "127": "Ulubione lokalizacje",
  "118": "Zaktualizuj podstawowe dane Akademii",
  "20": "Aktywuj sprzedawcę",
  "12": "Zmień cenę karnetu",
  "129": "Edytuj lokalizację",
};

const AcademyTabs = () => {
  const academyTabs = getDetailTabsByEnum(ACADEMY_TABS);

  const permission = useSelector((state: any) => state.permissions.permissions);

  const academyTabsWithPermissions = academyTabs.map((tab) => {
    return {
      ...tab,
      permission: !!permission.find(
        (permission: any) => PERMISSIONS[permission.Id] === tab.label
      ),
    };
  });

  const isUpdatePrimaryData = permission[0]?.Id === -1 ? true : !!permission.find((permission: any) => permission.Id === 118 );

  const tabs = permission[0]?.Id === -1 ? academyTabs :  academyTabsWithPermissions;

  const isSellerActiveted = permission[0]?.Id === -1 ? true : !!permission.find((permission: any) => permission.Id === 20 );

  const isPassEditPermissionActive = permission[0]?.Id === -1 ? true : !!permission.find((permission: any) => permission.Id === 12 );

  const isLocalizationPermissionActive = permission[0]?.Id === -1 ? true : !!permission.find((permission: any) => permission.Id === 129 );

  const isPassAddPermissionActive =  permission[0]?.Id === -1 ? true : !!permission.find((permission: any) => permission.Id === 13 );

  return (
    <TabsView>
      <Tabs tabs={tabs} />
      <div className='flex gap-4 p-24 text-white'>
        <AcademyInformations isUpdatePrimaryData={isUpdatePrimaryData} />
        <AcademyPasses
          isPassEditPermissionActive={isPassEditPermissionActive}
          isPassAddPermissionActive={isPassAddPermissionActive}
          />
        <AcademyLicence />
        <AcademyLocalizations isLocalizationPermissionActive={isLocalizationPermissionActive} />
        <AcademyMerchant isSellerActiveted={isSellerActiveted} />
      </div>
    </TabsView>
  );
};

export default AcademyTabs;
