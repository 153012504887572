import { ChangeEvent, useState } from "react";
import { useHistory } from "react-router";

import Button from "../common/buttons/basicButton/Button";
import FilterButton from "../common/buttons/filterButton/FilterButton";
import InputSearch from "../common/inputs/searchInput/InputSearch";
import ToggleDataViewMode from "../common/toggleDataView/ToggleDataViewMode";

import { ButtonVariant } from "../common/buttons/buttonTypes";
import { DataViewType } from "../../types/globalTypes";
import SelectStatystics from "components/common/inputs/select/SelectStatystics";
import NewSelectStatystics from "components/common/inputs/select/NewSelectStatystics";
import GridIco from "../../assets/icons/home-grid-ico.png";
import ListIco from "../../assets/icons/home-list-ico.png";
import HomeSearchIco from "../../assets/icons/home-search-ico.png";
import styled from "styled-components";
import NewFilterPanel from "components/filters/NewFilterPanel";
import RegisterForm from "components/auth/Register/RegisterForm";

const SortSquare = styled.div<{ viewType: string; view: string }>`
  height: 48px;
  width: 54px;
  background: ${(props) =>
    props.viewType === props.view
      ? "rgba(163, 158, 157, 0.11)"
      : "rgba(163, 158, 157, 0.38)"};

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    filter: brightness(90%);
  }

  /* filter: ${(props) =>
    props.viewType === props.view ? "brightness(50%)" : "brightness(100%)"}; */

  & > * {
    /* filter: brightness(50%); */
    pointer-events: none;
  }
`;

const BlurBox = styled.div<{ isMenuOpen: boolean; isAddMenuOpen: boolean }>`
  position: absolute;
  display: ${(props) =>
    props.isMenuOpen || props.isAddMenuOpen ? "unset" : "none"};
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(3px);
  z-index: 99;
`;

const SideMenu = styled.div<{ isMenuOpen: boolean }>`
  position: absolute;
  height: calc(100vh - 48px);
  top: 48px;
  padding: ${(props) => (props.isMenuOpen ? "23px 44px" : "23px 0px")};
  right: 0;
  width: ${(props) => (props.isMenuOpen ? "369px" : "0px")};
  background: rgba(2, 2, 15, 1);
  transition: all 0.25s ease-in-out;
  color: rgba(163, 158, 157, 1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  & > * {
    opacity: ${(props) => (props.isMenuOpen ? "1" : "0")};
    transition: all 0.125s ease-in-out;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    & > p {
      text-align: center;
      font-size: 15px;
      font-weight: 400;
    }
  }
`;

const SideAddMenu = styled.div<{ isAddMenuOpen: boolean }>`
  position: absolute;
  height: calc(100vh - 48px);
  top: 48px;
  /* padding: ${(props) => (props.isAddMenuOpen ? "23px 44px" : "0")}; */
  padding: ${(props) => (props.isAddMenuOpen ? "23px 0" : "23px 0")};
  right: 0;
  width: ${(props) => (props.isAddMenuOpen ? "917px" : "0px")};
  background: rgba(2, 2, 15, 1);
  transition: all 0.4s ease-in-out;
  color: rgba(163, 158, 157, 1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  & > * {
    opacity: ${(props) => (props.isAddMenuOpen ? "1" : "0")};
    transition: all 0.2s ease-in-out;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    & > form {
      & > div {
        & > p {
          /* text-align: center; */
          text-transform: uppercase;
          font-size: 15px;
          font-weight: 400;
          margin-bottom: 10px;
        }
      }
    }
  }
`;

const SearchBox = styled.div`
  height: 48px;
  background: rgba(163, 158, 157, 0.11);
  color: white;
  width: 280px;
  display: flex;
  align-items: center;

  & > input {
    width: calc(100% - 45px);
  }

  & > div {
    width: 45px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      width: 21px;
      height: 21px;
    }
  }
`;

const SearchInput = styled.input`
  background: transparent;
  height: 100%;
  width: 100%;
  font-size: 15px;
  font-weight: 300;
  padding: 14px 23px;
`;

interface IUsersTopbarProps {
  handleQueryChange: (value: string) => void;
  sortBy: any;
  setSortBy: any;
  sorts: any;
  defaultSort: any;
  viewType: any;
  setViewType: any;
}

const ContactTopbar: React.FC<IUsersTopbarProps> = ({
  handleQueryChange,
  sortBy,
  setSortBy,
  sorts,
  defaultSort,
  setViewType,
  viewType,
}) => {
  const { push } = useHistory();
  const [isMenuOpen, toggleMenuOpen] = useState(false);
  const [isAddMenuOpen, toggleAddMenuOpen] = useState(false);
  const filters = [
    {
      id: 0,
      type: "checkbox",
      name: "IsActivated",
      values: [
        { backend: "True", pl: "Aktywny" },
        { backend: "False", pl: "Nieaktywny" },
      ],
      pl: "Status",
    },
  ];

  const handleAddUser = () => {
    push("/users/add");
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    handleQueryChange(e.target.value);
  };
  return (
    <div
      className="flex flex-wrap items-center justify-end gap-y-2"
      style={{ gap: "3px" }}
    >
      <BlurBox
        isMenuOpen={isMenuOpen}
        isAddMenuOpen={isAddMenuOpen}
        onClick={() => {
          toggleAddMenuOpen(false);
          toggleMenuOpen(false);
        }}
      />

      <SideMenu isMenuOpen={isMenuOpen}>
        <NewFilterPanel filters={filters} />
      </SideMenu>

      <SideAddMenu isAddMenuOpen={isAddMenuOpen}>
        <div>
          <RegisterForm isAddMenuOpen={isAddMenuOpen} />
        </div>
      </SideAddMenu>

      <NewSelectStatystics
        name="Sort"
        items={sorts}
        label="Sortowanie"
        selectedItem={sortBy}
        setSelectedItem={setSortBy}
        defaultValue={defaultSort}
      />

      <SearchBox>
        <SearchInput
          className="w-full md:w-36 xl:w-72"
          placeholder="Szukaj..."
          autoComplete="off"
          name="search"
          onChange={handleSearch}
        />
        <div>
          <img src={HomeSearchIco} alt="ico" />
        </div>
      </SearchBox>

      <SortSquare
        viewType={viewType}
        view="list"
        onClick={() => setViewType("list")}
      >
        <img src={ListIco} alt="ico" />
      </SortSquare>

      <SortSquare
        viewType={viewType}
        view="grid"
        onClick={() => setViewType("grid")}
      >
        <img src={GridIco} alt="ico" />
      </SortSquare>

      <Button
        className="px-24 xl:order-2"
        variant={ButtonVariant.Submit}
        style={{
          padding: "15px 41.4064px",
          background: "rgba(163,158,157,0.38)",
          width: "120px",
          fontSize: "15px",
          fontWeight: 400,
        }}
        onClick={() => {
          if (isMenuOpen) {
            toggleMenuOpen(false);
          } else if (!isMenuOpen && !isAddMenuOpen) {
            toggleMenuOpen(true);
          } else {
            toggleAddMenuOpen(false);
            setTimeout(() => {
              toggleMenuOpen(true);
            }, 400);
          }
        }}
      >
        Filtruj
      </Button>
      {/* <FilterButton /> */}
      <Button
        onClick={() => {
          if (isAddMenuOpen) {
            toggleAddMenuOpen(false);
          } else if (!isMenuOpen && !isAddMenuOpen) {
            toggleAddMenuOpen(true);
          } else {
            toggleMenuOpen(false);
            setTimeout(() => {
              toggleAddMenuOpen(true);
            }, 250);
          }
        }}
        className="px-24 xl:order-2"
        variant={ButtonVariant.Submit}
        style={{
          fontSize: "13px",
          fontWeight: 400,
          color: "white",
          background: "rgb(0, 160, 255)",
        }}
      >
        Dodaj pracownika
      </Button>
    </div>
  );
};

export default ContactTopbar;
