import HeaderContainer from 'components/common/Containers/HeaderContainer';
import TabContent from 'components/common/tabs/TabContent';
import React from 'react';
import { useOrderDetailTabsContext } from '../../hook/useOrderDetailTabsContext';

const OrderMessages = () => {
  const { data: order, refresh } = useOrderDetailTabsContext();

  return (
    <TabContent id='comments'>
      <div className='flex gap-4 p-24 w-full max-w-4xl'>
        <HeaderContainer title='UWAGI DO ZAMÓWIENIA' withContainer>
          {order.Comments.length ? order.Comments : 'Brak'}
        </HeaderContainer>
      </div>
    </TabContent>
  );
};

export default OrderMessages;
