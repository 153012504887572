import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "store/store";

import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { showServerErrors } from "utils/errorsUtils";
import styled from "styled-components";
import { useState } from "react";
import EditDigitalRelease from "../form/EditDigitalRelease";
import axios from "axios";
import { deleteEvent } from "services/eventServices";
import { utcToLocalDate } from "./EventsDetail";
import { getEventsPathWithDate } from "components/navbar/navbarUtils";

const BlurBox = styled.div<{ isEditMenuOpen: boolean }>`
  position: absolute;
  display: ${(props) => (props.isEditMenuOpen ? "unset" : "none")};
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(3px);
  z-index: 99;
`;

const SideEditMenu = styled.div<{ isEditMenuOpen: boolean }>`
  position: absolute;
  height: calc(100vh - 48px);
  top: 48px;
  /* padding: ${(props) => (props.isEditMenuOpen ? "23px 44px" : "0")}; */
  padding: ${(props) => (props.isEditMenuOpen ? "23px 0" : "23px 0")};
  right: 0;
  width: ${(props) => (props.isEditMenuOpen ? "917px" : "0px")};
  background: rgba(2, 2, 15, 1);
  transition: all 0.4s ease-in-out;
  color: rgba(163, 158, 157, 1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  & > * {
    opacity: ${(props) => (props.isEditMenuOpen ? "1" : "0")};
    transition: all 0.2s ease-in-out;
  }

  & > div {
    width: 100%;
    display: flex;
    height: 1100px;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    & > form {
      & > div:first-child {
        & > div {
          & > p {
            text-align: left;
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
`;

interface IDigitalReleaseDetailTopbarProps {
  event: any;
}

const EventsDetailTopbar: React.FC<IDigitalReleaseDetailTopbarProps> = ({
  event,
}) => {
  const { push } = useHistory();
  const [isEditMenuOpen, toggleEditMenuOpen] = useState(false);
  //TODO: const permissions = useSelector((state: RootState) => state.userPermissions?.DigitalReleases);
  const permissions = useSelector(
    (state: RootState) => state.userPermissions?.Artist,
  );

  // if (!permissions) {
  //   return null;
  // }

  const handleEdit = (id: string) => {
    push(`/shop/products/edit/${id}`);
  };

  const { id } = useParams<{ id: string }>();
  const endDateLocal = utcToLocalDate(event?.OccurenceDate);
  const backendDate = new Date(endDateLocal);

  const today = new Date();

  const isDateExpired = today > backendDate;
  // const handleRemove = async (ProductId: string) => {
  //   try {
  //     await productServices.removeProduct(ProductId);
  //     toast.success("Usunięto produkt!");
  //     push("/shop/products");
  //   } catch (errors: any) {
  //     showServerErrors(errors);
  //   }
  // };

  const handleRemove = async (EventId: string) => {
    try {
      await deleteEvent(EventId);

      push(getEventsPathWithDate());
      toast.success("Usunięto zajęcie!");
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  console.log("eventxaxaxa", event);

  return (
    <div
      className="flex justify-between px-8 text-sm"
      style={{ alignItems: "center" }}
    >
      {/* <div className="flex" style={{ gap: "4px" }}> */}
      <BlurBox
        isEditMenuOpen={isEditMenuOpen}
        onClick={() => toggleEditMenuOpen(false)}
      />
      <SideEditMenu isEditMenuOpen={isEditMenuOpen}>
        <div>
          <EditDigitalRelease event={event} />
        </div>
      </SideEditMenu>
      {/* { <p style={{ color: "white" }}>Status: {event.Status}</p> */}
      <div></div>
      <div className="flex" style={{ gap: "4px" }}>
        <Button
          // onClick={handleEdit}
          // disabled={!permissions.Edit}
          variant={ButtonVariant.Remove}
          className="flex-1 md:flex-grow-0 py-8 px-18"
          onClick={() => handleRemove(event.EventId)}
          style={{
            padding: "15px 41.4064px",
            background: "rgba(163,158,157,0.38)",
            width: "120px",
            fontSize: "15px",
            fontWeight: 400,
          }}
        >
          Usuń
        </Button>
        {!isDateExpired && (
          <Button
            // onClick={handleEdit}
            onClick={() => {
              toggleEditMenuOpen((prev) => !prev);
            }}
            variant={ButtonVariant.Submit}
            className="flex-1 md:flex-grow-0 py-8 px-18"
            style={{
              padding: "15px 41.4064px",
              background: "rgb(0, 160, 255)",
              width: "120px",
              fontSize: "15px",
              fontWeight: 400,
            }}
          >
            Edytuj
          </Button>
        )}

        {/* </div> */}
      </div>
    </div>
  );
};

export default EventsDetailTopbar;
