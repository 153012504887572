import TabContent from 'components/common/tabs/TabContent';
import { useOrderDetailTabsContext } from '../../hook/useOrderDetailTabsContext';
import HeaderContainer from 'components/common/Containers/HeaderContainer';
import OrderDocumentsTopbar from './OrderDocumentsTopbar';
import OrderDocumentsTable from './OrderDocumentsTable';

const OrderDocuments = () => {
  const { data: order, refresh } = useOrderDetailTabsContext();

  return (
    <TabContent id='documents'>
      <div className='flex gap-4 p-24 w-full'>
        <HeaderContainer
          title='Dokumenty'
          headerContent={
            <OrderDocumentsTopbar orderId={order.OrderId} refresh={refresh} />
          }>
          <OrderDocumentsTable
            orderId={order.OrderId}
            documents={order.Documents}
            refresh={refresh}
          />
        </HeaderContainer>
      </div>
    </TabContent>
  );
};

export default OrderDocuments;
