import jwtDecode, { JwtPayload } from "jwt-decode";
import { conn } from "../api/BaseConnection";
import axios from "axios";
import { IChangePasswordValues, IUser } from "../types/userTypes";
import { showServerErrors } from "utils/errorsUtils";

export const getUserFromToken = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const decodedToken: JwtPayload = jwtDecode(token);
    try {
      const resp = await axios.get(
        `https://adminapi.justwin.pl/api/User/${decodedToken.jti}`,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        },
      );

      const {
        FirstName,
        LastName,
        Email,
        UserName,
        Id,
        Position,
        PhoneNumber,
      } = resp.data.Data;
      const user = {
        firstName: FirstName,
        lastName: LastName,
        fullName: `${FirstName} ${LastName}`,
        userId: Id,
        email: Email,
        role: 1,
        userName: UserName,

        isActive: true,
        permissions: [],
        phoneNumber: PhoneNumber,
        position: Position,
      };

      return user;
    } catch (errors: any) {
      showServerErrors(errors);
    }
  }
};

const getCurrentUser = (): Promise<IUser> => {
  return new Promise(async (resolve, reject) => {
    const userData = await getUserFromToken();
    if (userData) {
      resolve(userData);
    }

    reject({ error: ["User not found"] });
  });
};

const changePassword = (
  userId: string,
  passwords: IChangePasswordValues,
): Promise<any> => {
  return conn.postJSON("/api/Auth/ChangePassword", "json", {
    userId,
    ...passwords,
  });
};

const currentUser = {
  changePassword,
  getCurrentUser,
};

export default currentUser;
