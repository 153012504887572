import TabContent from 'components/common/tabs/TabContent';
import React, { useEffect, useState } from 'react';
import DiscountTemplate from './DiscountTemplate';
import ApplyDiscount from './ApplyDiscount';
import { IDiscountTemplate } from 'types/marketpleace/discount.interface';
import discountServices from 'services/marketpleace/discountServices';

const ProductConditions = () => {
  const [discountTemplates, setDiscountTemplates] = useState<
    IDiscountTemplate[]
  >([]);

  const getDiscountTemplates = async () => {
    const discounts = await discountServices.get();
    setDiscountTemplates(discounts);
  };

  useEffect(() => {
    getDiscountTemplates();
  }, []);

  return (
    <TabContent id='conditions'>
      <div className='flex gap-4 p-24'>
        <DiscountTemplate
          discountTemplates={discountTemplates}
          refreshDiscountTemplates={getDiscountTemplates}
        />
        <ApplyDiscount
          discountTemplates={discountTemplates}
          refreshDiscountTemplates={getDiscountTemplates}
        />
      </div>
    </TabContent>
  );
};

export default ProductConditions;
