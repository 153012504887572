import { SET_NOTIFICATION_PAGE_NUMBER } from '../actions/notificationPageNumber';

const initialState = {
  notificationPageNumber: 1,
};

const notificationPageNumberReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_NOTIFICATION_PAGE_NUMBER:
      return {
        ...state,
        notificationPageNumber: action.payload,
      };
    default:
      return state;
  }
};

export default notificationPageNumberReducer;
