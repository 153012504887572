import TabContent from 'components/common/tabs/TabContent';
import React from 'react';
import { useOrderDetailTabsContext } from '../../hook/useOrderDetailTabsContext';
import { GridColumn, GridTextItem } from 'styles/styles';
import OrderProductItem from './OrderProductItem';

const OrderProducts = () => {
  const { data: order, refresh } = useOrderDetailTabsContext();

  const headers = [
    'Zdjęcie',
    'Nazwa produktu',
    'Atrybuty',
    'EAN',
    'Cena',
    'Ilość',
    'Łącznie',
  ];

  return (
    <TabContent id='products'>
      <div className='flex gap-4 p-24 w-full'>
        <GridColumn cols={headers.length}>
          {headers.map((header: string, idx: number) => (
            <GridTextItem title={header} uppercase isHeader key={idx}>
              {header}
            </GridTextItem>
          ))}
          {order.Items.map(item => (
            <OrderProductItem item={item} key={item.ItemId} />
          ))}
        </GridColumn>
      </div>
    </TabContent>
  );
};

export default OrderProducts;
