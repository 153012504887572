import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import ContentContainer from "components/layout/ContentContainer";
import InfoBoxPlaceholder from "components/common/boxes/InfoBox/InfoBoxPlaceholder";
import { IPlayerDetail } from "types/transfer/player.interface";
import PlayerDetailInfo from "./PlayerDetailInfo";
import PlayerDetailTabs from "./tabs/PlayerDetailTabs";
import playerService from "services/transfer/playerServices";
import PlayerDetailTopbar from "./PlayerDetailTopbar";

interface IPlayerDetailProps {
  isStructure?: boolean;
  playerId?: string;
  closePlayerModalOpen?: any;
}

const PlayersDetail = ({
  isStructure,
  playerId,
  closePlayerModalOpen,
}: IPlayerDetailProps) => {
  const { id } = useParams<{ id: string }>();

  const [player, setPlayer] = useState<IPlayerDetail | null>(null);

  const getPlayerDetail = async () => {
    if (isStructure && playerId) {
      const detail = await playerService.getDetail(playerId);

      setPlayer(detail);
      return;
    }
    const detail = await playerService.getDetail(id);
    setPlayer(detail);
  };

  useEffect(() => {
    getPlayerDetail();
  }, []);

  if (!player) {
    return <InfoBoxPlaceholder />;
  }

  return (
    <ContentContainer
      title={player.Name}
      TopBar={
        <PlayerDetailTopbar
          appliedPasses={player.AppliedPasses}
          playerId={playerId}
        />
      }
      noArrow={isStructure ? true : false}
      fn={() => closePlayerModalOpen()}
      path={player.Level === null ? "/outside-players" : "/players"}
    >
      <div>
        <PlayerDetailInfo player={player} />
        <PlayerDetailTabs player={player} refresh={getPlayerDetail} />
      </div>
    </ContentContainer>
  );
};

export default PlayersDetail;
