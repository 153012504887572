import { useState } from 'react';
import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import { ISelectOption } from 'types/globalTypes';
import { SingleColumn } from 'styles/styles';
import styled from "styled-components";
import SelectOption from 'components/common/inputs/select/SelectOption';
import { IFilterSelectOption } from 'types/filterPassData';

const AgeInputBox = styled.div`
  width: 100%;
  height: 48px;
  min-height: 48px;
  background: rgba(163, 158, 157, 0.11);
  padding: 0 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div:nth-of-type(1) {
    width: 50%;
    height: 100%;
    padding: 5px 6px;
    display: flex;
    & > p {
      font-size: 11px;
      font-weight: 400;
      color: rgba(163, 158, 157, 1);
    }
  }

  & > div:nth-of-type(2) {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
  }
`;

const AgeInput = styled.input`
  height: 100%;
  width: 100%;
  border-left: 5px solid rgba(163, 158, 157, 0.15);
  background: transparent;
  color: white;
  font-size: 15px;
  text-align: center;
  font-weight: 400;
  padding: 0 5px;
`;

interface IScannerFormProps {
  refreshScanner: () => Promise<void>;
  refreshFilters: () => Promise<void>;
  filter: ISelectOption<string> | null;
  setFilter: React.Dispatch<React.SetStateAction<ISelectOption<string> | null>>;
  filters: ISelectOption<string>[];
  handleFilterData?:(obj: any ) => void;
}

const ScannerTrainerForm = ({
  refreshScanner,
  refreshFilters,
  filter,
  filters,
  setFilter,
  handleFilterData,
}: IScannerFormProps) => {
  const [selectedType, setSelectedType] = useState<any[]>([]);
  const [selectedFunction, setSelectedFunction] = useState<any[]>([]);
  const [distanceValue, setDistanceValue] = useState('');

  const filtersType = [
    {
      id: 0,
      type: "select",
      name: "Type",
      values: [
        { backend: 0, pl: "UEFA" },
        { backend: 1, pl: "UEFA_PRO" },
      ],
      pl: "Typ licencji",
    },
  ];

  const filtersFunction = [
    {
      id: 0,
      type: "select",
      name: "Type",
      values: [
        { backend: 0, pl: "Trener" },
        { backend: 1, pl: "Skaut"},
        { backend: 2, pl: "Menager"},
        { backend: 3, pl: "Analityk"},
      ],
      pl: "Funkcja",
    },
  ];

  const [filterType, setFilterType] = useState(filtersType)
  const [filterFunction, setFilterFunction] = useState(filtersFunction)

  const handleSelectType = (value: IFilterSelectOption) => {
    setSelectedType([value]);
  };

  const handleSelectFunction = (value: IFilterSelectOption) => {
    setSelectedFunction([value]);
  };

  const handleDistance = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDistanceValue(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const obj = {
      LicenseType: selectedType.length > 0 ? +selectedType.flat().map(elem => elem.value).join('') : '' ,
      Type: selectedFunction.length > 0 ? +selectedFunction.flat().map(elem => elem.value).join('') : '',
      Distance: distanceValue,
    };

    if (handleFilterData) {
      handleFilterData(obj)
    }
  };

  const handleDeleteType = () => {
    setSelectedType([])
    setFilterType([])
    setFilterType(filtersType)

    if (handleFilterData) {
      handleFilterData({
        LicenseType:  '' ,
        Type: '',
        Distance: '',
      })
    }
  }

  const handleDeleteFunction = () => {
    setSelectedFunction([]);
    setFilterFunction(filtersFunction);

    if (handleFilterData) {
      handleFilterData({
        LicenseType:  '' ,
        Type: '',
        Distance: '',
      })
    }
  }

  const handleDeleteDistance = () => {
    setDistanceValue('');

    if (handleFilterData) {
      handleFilterData({
        LicenseType:  '' ,
        Type: '',
        Distance: '',
      })
    }
  }

  const isDisabled = selectedType.length === 0 && selectedFunction.length === 0 && distanceValue.length === 0;

  return (
    <div className='mx-auto'>
      <div className='flex justify-between w-full gap-1'>
        <div style={{display: selectedType.length > 0 ? 'block' : 'none' }}
        >
            <span style={{fontSize: '16px', color: 'white'}}>
            Typ Licencji
            </span>
            <button
              style={{fontSize: '17px', color: 'white', paddingLeft:'7px' }}
              onClick={handleDeleteType}
            >
              X
            </button>
        </div>
        <div style={{display: selectedFunction.length > 0 ? 'block' : 'none' }}>
            <span style={{fontSize: '16px', color: 'white' }}>
            Funkcja
            </span>
            <button
              style={{fontSize: '17px', color: 'white' , paddingLeft:'7px'}}
              onClick={handleDeleteFunction}
            >
              X
            </button>
        </div>
        <div style={{display: distanceValue.length > 0 ? 'block' : 'none' }}>
            <span style={{fontSize: '16px', color: 'white'}}>
            Dystans
            </span>
            <button
              style={{fontSize: '17px', color: 'white', paddingLeft:'7px'}}
              onClick={handleDeleteDistance}
            >
              X
            </button>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div>
          <SingleColumn style={{height: '65vh'}}>
              <SelectOption
                filters={filterType}
                selectedItem={handleSelectType}
              />
              <SelectOption
                filters={filterFunction}
                selectedItem={handleSelectFunction}
              />
              <div className='-mt-5' style={{ paddingTop: '30px' }}>
                <AgeInputBox>
                  <div>
                    <p>Promień</p>
                  </div>
                  <AgeInput
                    value={distanceValue}
                    onChange={handleDistance}
                    type="number"
                    maxLength={5}
                  />
                  <div className='text-white flex justify-end pr-4'>
                    <div>KM</div>
                  </div>
                </AgeInputBox>
              </div>

              <div
                style={{ marginTop: '60vh' }}
              >
              <Button
                variant={ButtonVariant.Submit}
                style={{ backgroundColor: 'rgb(0, 160, 255)', color: '#fff', width: '280px' }}
                type='submit'
                disabled={isDisabled}
              >
                Filtruj
              </Button>
            </div>

          </SingleColumn>
        </div>
      </form>
    </div>
  );
};

export default ScannerTrainerForm;
