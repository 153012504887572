import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { StyledInput, StyledSelect } from './../../../styles';
import {
  GridItem,
  GridTextItem,
  StyledTableCurrencyInput,
} from 'styles/styles';
import { IAttribute } from 'types/marketpleace/attribute.interface';
import {
  IAddCombination,
  IAddCombinationValue,
  ICombination,
  IRemoveCombination,
  IUpdateCombination,
} from 'types/marketpleace/combination.interface';
import { TABLE_MODE, ROW_MODE, validateCombination } from '../utils/helpers';
import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import { ReactComponent as AddIcon } from '../../../../../../../assets/icons/add-circle-white.svg';
import { ReactComponent as EditIcon } from '../../../../../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../../assets/icons/delete.svg';
import { ReactComponent as SaveIcon } from '../../../../../../../assets/icons/save.svg';
import { showServerErrors } from 'utils/errorsUtils';
import { combinationServices } from 'services/marketpleace';
import { toast } from 'react-toastify';

interface IAddAttributesProps {
  attributes: IAttribute[];
  tableMode: TABLE_MODE;
  refresh: () => void;
  productId: string;
  combinationValues: IAddCombinationValue[];
  setCombinationValues: Dispatch<SetStateAction<IAddCombinationValue[]>>;
  longestCombinationValuesLength: number;
  productCombination?: ICombination;
  rowMode?: ROW_MODE;
  combinationValuesLength: number;
  setCombinationValuesLength: Dispatch<SetStateAction<number>>;
}

const AttributesTableRow = ({
  attributes,
  combinationValues,
  setCombinationValues,
  rowMode,
  tableMode,
  productCombination,
  refresh,
  productId,
  longestCombinationValuesLength,
  combinationValuesLength,
  setCombinationValuesLength,
}: IAddAttributesProps) => {
  const [mode, setMode] = useState<ROW_MODE>(rowMode ?? ROW_MODE.default);

  const [EAN, setEAN] = useState<number | undefined>(
    productCombination?.EAN ? parseInt(productCombination.EAN) : undefined
  );
  const [amount, setAmount] = useState<number>(productCombination?.Amount ?? 0);
  const [netto, setNetto] = useState<number>(productCombination?.Netto ?? 0);
  const [nettoValue, setNettoValue] = useState<string>(
    productCombination ? productCombination.Netto.toString() : ''
  );
  const [tax, setTax] = useState<number>(productCombination?.Tax ?? 0);

  const attributesSelectChange = (value: string, index: number) => {
    if (value === 'delete') {
      setCombinationValues(prev => prev.filter((c, i) => i !== index));
      if (longestCombinationValuesLength <= combinationValues.length) {
        setCombinationValuesLength(prev => prev - 1);
      }
      return;
    }

    setCombinationValues(prev => [
      ...prev.map((c, i) => (i === index ? { ...c, ValueId: value } : c)),
    ]);
  };

  const addAttributeToCombination = (id: string, index: number) => {
    if (id === 'delete') {
      setCombinationValues(prev => prev.filter((c, i) => i !== index));
      if (longestCombinationValuesLength === combinationValues.length) {
        setCombinationValuesLength(prev => prev - 1);
      }
      return;
    }
    setCombinationValues(prev => [
      ...prev.map((c, i) => (i === index ? { ...c, AttributeId: id } : c)),
    ]);
  };

  const addNewCombination = () => {
    setCombinationValues(prev => [
      ...prev,
      {
        AttributeId: '',
        ValueId: '',
      },
    ]);

    if (
      attributes.length > combinationValuesLength &&
      combinationValuesLength === combinationValues.length + 1
    ) {
      setCombinationValuesLength(prev => prev + 1);
    }
  };

  useEffect(() => {
    console.log('combinationValues useState', combinationValues);
  }, [combinationValues]);

  const handleEanChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value.length >= 10) {
      setEAN(parseInt(value.slice(0, 10)));
      return;
    }

    setEAN(parseInt(value));
  };

  const handleAddCombination = async () => {
    try {
      const validation = validateCombination(EAN, combinationValues);

      if (!validation) {
        return;
      }

      const combination: IAddCombination = {
        ProductId: productId,
        Tax: tax,
        Netto: netto,
        Amount: amount,
        EAN: validation.EAN,
        Values: validation.Values,
      };

      await combinationServices.add(combination);

      console.log('Created combination', combination);
      toast.success(`Stworzono kombinację. EAN: ${validation.EAN}`);

      refresh();

      setCombinationValues([]);
      setCombinationValuesLength(
        longestCombinationValuesLength < validation.Values.length
          ? validation.Values.length
          : longestCombinationValuesLength
      );
      setEAN(0);
      setAmount(0);
      setNetto(0);
      setTax(0);
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  const handleSaveUpdate = async (combinationId: string) => {
    try {
      const combination: IUpdateCombination = {
        Amount: amount,
        Netto: netto,
        ProductId: productId,
        Tax: tax,
        CombinationId: combinationId,
      };

      await combinationServices.update(combination);

      console.log('Updated combination', combination);
      toast.success(`Zaktualizowano kombinację. EAN: ${EAN ?? '-'}`);

      refresh();

      setMode(ROW_MODE.default);
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  const handleRemoveCombination = async (combinationId: string) => {
    try {
      const combination: IRemoveCombination = {
        ProductId: productId,
        CombinationId: combinationId,
      };

      await combinationServices.remove(combination);

      console.log('Removed combination', combination);
      toast.success(`Usunięto kombinację. EAN: ${EAN ?? '-'}`);

      refresh();

      setCombinationValuesLength(longestCombinationValuesLength);
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  const handleCancelUpdate = ({ Netto, Tax, Amount }: ICombination) => {
    setMode(ROW_MODE.default);
    setNetto(Netto);
    setTax(Tax);
    setAmount(Amount);
  };

  const handleUpdateCombination = () => {
    setMode(ROW_MODE.edit);
  };

  return (
    <>
      {!!combinationValuesLength &&
        mode === ROW_MODE.add &&
        [...Array(combinationValuesLength)].map((value, i) => {
          const combinationValue = combinationValues[i];
          const attributeIndex = combinationValue
            ? attributes.findIndex(
                a => a.AttributeId === combinationValue.AttributeId
              )
            : 0;
          const attribute = attributes[attributeIndex];

          return (
            <GridItem key={i} uppercase>
              {combinationValue ? (
                !combinationValue.AttributeId.length ? (
                  <StyledSelect
                    value={combinationValue.AttributeId ?? ''}
                    onChange={e =>
                      addAttributeToCombination(e.target.value, i)
                    }>
                    <option disabled value=''>
                      Wybierz atrybut
                    </option>
                    {attributes.map(attribute => (
                      <option
                        key={attribute.AttributeId}
                        value={attribute.AttributeId}
                        disabled={
                          !!combinationValues.find(
                            c => c.AttributeId === attribute.AttributeId
                          )
                        }>
                        {attribute.Name}
                      </option>
                    ))}
                    <option value='delete'> - Usuń - </option>
                  </StyledSelect>
                ) : (
                  <StyledSelect
                    value={combinationValue.ValueId ?? ''}
                    onChange={e => attributesSelectChange(e.target.value, i)}>
                    <option disabled value=''>
                      Wybierz {attribute?.Name ?? ''}
                    </option>
                    {attribute.Values.map(value => (
                      <option key={value.ValueId} value={value.ValueId}>
                        {value.Name}
                      </option>
                    ))}
                    <option value='delete'> - Usuń - </option>
                  </StyledSelect>
                )
              ) : (
                <Button
                  onClick={() =>
                    attributes.length >= combinationValuesLength &&
                    addNewCombination()
                  }
                  disabled={combinationValues.length !== i}
                  variant={ButtonVariant.Abort}
                  className='w-full p-0 h-10'>
                  <AddIcon />
                </Button>
              )}
            </GridItem>
          );
        })}

      {!!combinationValuesLength &&
        !!productCombination &&
        [...Array(combinationValuesLength)].map((value, i) => {
          const combinationValue = productCombination.Values[i];
          return (
            <GridTextItem
              uppercase
              key={i}
              title={
                combinationValue
                  ? `${combinationValue.AttributeName}: ${combinationValue.ValueName}`
                  : '-'
              }>
              {combinationValue?.ValueName ?? '-'}
            </GridTextItem>
          );
        })}

      {tableMode === TABLE_MODE.edit && mode === ROW_MODE.add ? (
        <GridItem>
          <StyledInput type='number' value={EAN} onChange={handleEanChange} />
        </GridItem>
      ) : (
        <GridTextItem uppercase title={`EAN: ${EAN}`}>
          {EAN}
        </GridTextItem>
      )}
      {tableMode === TABLE_MODE.edit && mode !== ROW_MODE.default ? (
        <GridItem>
          <StyledInput
            type='number'
            value={amount}
            onChange={e => setAmount(parseInt(e.target.value))}
          />
        </GridItem>
      ) : (
        <GridTextItem uppercase title={`Zapas: ${amount}`}>
          {amount}
        </GridTextItem>
      )}

      {tableMode === TABLE_MODE.edit && mode !== ROW_MODE.default ? (
        <GridItem>
          <StyledTableCurrencyInput
            suffix=' zł'
            placeholder='0 zł'
            decimalSeparator='.'
            value={nettoValue}
            onValueChange={(value, name, values) => {
              setNettoValue(value ?? '');
              setNetto(values?.float ?? 0);
            }}
          />
        </GridItem>
      ) : (
        <GridTextItem uppercase title={`Cena: ${netto.toFixed(2)} zł`}>
          {netto.toFixed(2)} zł
        </GridTextItem>
      )}

      {tableMode === TABLE_MODE.edit && mode !== ROW_MODE.default ? (
        <GridItem>
          <StyledSelect
            value={tax}
            onChange={e => setTax(parseInt(e.target.value))}>
            <option value={0}>0%</option>
            <option value={8}>8%</option>
            <option value={23}>23%</option>
          </StyledSelect>
        </GridItem>
      ) : (
        <GridTextItem uppercase title={`${tax} %`}>
          {tax}%
        </GridTextItem>
      )}

      <GridItem>
        {tableMode === TABLE_MODE.edit ? (
          <>
            {mode === ROW_MODE.add && (
              <div className='flex gap-1 w-full'>
                <Button
                  onClick={handleAddCombination}
                  variant={ButtonVariant.Submit}
                  className='w-full p-0 h-10 bg-green text-sm'>
                  Dodaj
                </Button>
              </div>
            )}

            {mode === ROW_MODE.edit && (
              <div className='flex gap-1 w-full'>
                <Button
                  onClick={() =>
                    !!productCombination &&
                    handleSaveUpdate(productCombination.CombinationId)
                  }
                  variant={ButtonVariant.Submit}
                  className='w-2/3 p-0 h-10 '>
                  <SaveIcon className='h-1/2' />
                </Button>
                <Button
                  onClick={() =>
                    !!productCombination &&
                    handleCancelUpdate(productCombination)
                  }
                  variant={ButtonVariant.Abort}
                  className='w-1/3 p-0 h-10 '>
                  <AddIcon
                    className='h-1/2 '
                    style={{ transform: 'rotate(45deg)' }}
                  />
                </Button>
              </div>
            )}
            {mode === ROW_MODE.default && (
              <div className='flex gap-1 w-full'>
                <Button
                  onClick={handleUpdateCombination}
                  variant={ButtonVariant.Submit}
                  className='w-2/3 p-0 h-10 '>
                  <EditIcon className='h-1/2' />
                </Button>
                <Button
                  onClick={() =>
                    !!productCombination &&
                    handleRemoveCombination(productCombination.CombinationId)
                  }
                  variant={ButtonVariant.Abort}
                  className='w-1/3 p-0 h-10'>
                  <DeleteIcon className='h-1/2' />
                </Button>
              </div>
            )}
          </>
        ) : null}
      </GridItem>
    </>
  );
};

export default AttributesTableRow;
