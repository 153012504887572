import HeaderContainer from 'components/common/Containers/HeaderContainer';
import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import React, { Dispatch, SetStateAction } from 'react';

interface IMerchantRegistryAcceptanceProps {
  confirmConsents: boolean;
  setConformConsents: Dispatch<SetStateAction<boolean>>;
  setMerchantRegistrationDisplay: Dispatch<SetStateAction<boolean>>;
}

const MerchantRegistryAcceptance = ({
  confirmConsents,
  setConformConsents,
  setMerchantRegistrationDisplay,
}: IMerchantRegistryAcceptanceProps) => {

  return (
    <HeaderContainer title='Rejestracja' withContainer>
      <div className='flex flex-col gap-8'>
      <a
        href='https://www.przelewy24.pl/regulamin'
        target="_blank"
        rel="noreferrer"
        className='underline'
      >
          Regulamin Przelewy 24
      </a>
        <div className='w-full flex align-top gap-4'>
          <input
            id='PrivacyConsents'
            type='checkbox'
            checked={confirmConsents}
            className='w-12 h-12 p-0 m-0'
            onChange={e => setConformConsents(prev => !prev)}
          />
          <label htmlFor='PrivacyConsents'>
            Oświadczam, że zapoznałam/łem się z Regulaminem oraz Polityką
            prywatności i akceptuję ich postanowienia
          </label>
        </div>
        <div className='w-full flex justify-end'>
          <Button
            variant={ButtonVariant.Submit}
            disabled={!confirmConsents}
            className='m-2'
            onClick={() => setMerchantRegistrationDisplay(false)}>
            Zarejestruj
          </Button>
        </div>
      </div>
    </HeaderContainer>
  );
};

export default MerchantRegistryAcceptance;
