import Tabs from "components/common/tabs/Tabs";
import TabsView from "components/common/tabs/TabsView";
import { getTabsByStrictLevel, getDetailTabsByEnum } from "utils/objectUtils";
import PlayerAddress from "./Address/PlayerAddress";
import PlayerPasses from "./Passes/PlayerPasses";
import { IPlayerDetail, TRANSFER_LEVEL } from "types/transfer/player.interface";
import PlayerCard from "./Card/PlayerCard";
import PlayerEvents from "./Events/PlayerEvents";
import PlayerManager from "./Manager/PlayerManager";
import PlayerStatistics from "./Statistics/PlayerStatistics";
import PlayerCareer from "./Career/PlayerCareer";
import { playerDetailStrictTabs } from "./PlayerDetailStrictTabs";
import PlayerHistoryState from "./HistoryState/PlayerHistoryState";
import StatisticsTab from "components/shop/Orders/detail/Tabs/PressMaterials/StatisticsTab";
import playerService from 'services/transfer/playerServices';
import { useState, useEffect } from "react";
import {
  IPlayerEvent,
} from 'types/events/event.interface';
import { useSelector } from 'react-redux';

interface IPlayerDetailTabsProps {
  player: IPlayerDetail;
  refresh: () => Promise<void>;
}

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: any[];
    backgroundColor: string[];
    borderColor: string;
  }[];
  height: string;
  weight: string;
  condition: string;
  distance: number;
  dynamics: number;
  energy?: string | null | undefined;
  form: number;
  heartRate: number;
  hmld: number;
  hsr: number;
  speed: number;
}

export enum PLAYER_TABS {
  Address =  "Adres",
  PlayerCard = "Karta zawodnika",
  Trainner =  "Trener",
  Career = "Kariera",
  Statistics =  "Statystyki",
  Passes = "Składki",
  Events = "Zajęcia",
  HistoryStatus = "Historia statusów",
}

const PERMISSIONS: Record<string, string> = {
  "147": "Adres",
  "148": "Karta zawodnika",
  "149": "Trener",
  "150": "Kariera",
  "151": "Statystyki",
  "152": "Składki",
  "146": "Zajęcia",
  "153": "Historia statusów",

  "183": "Zajęcia",
  "184": "Adres",
  "185": "Karta zawodnika",
  "186": "Trener",
  "187": "Kariera",
  "188": "Statystyki",
  "189": "Składki",
  "190": "Historia statusów",
};

const PlayerDetailTabs = ({ player, refresh }: IPlayerDetailTabsProps) => {
  const tabs = getTabsByStrictLevel<TRANSFER_LEVEL | null>(
    playerDetailStrictTabs,
    player.Level,
  );
  const [lineData, setLineData] = useState<ChartData | null>(null);
  const [traingsDate, setTrainingsDate] = useState<string[]>([]);
  const [eventsData, setEventsData] = useState<IPlayerEvent[]>([]);
  const [isLoading, setLoading] = useState(true);

  const permission = useSelector((state: any) => state.permissions.permissions);

  const getTrainingsData = (value: string[]) => {
    setTrainingsDate(value);
  }

  const getEvents = async () => {
    const { Items } = await playerService.getEvents(player.PlayerId);

    setEventsData(Items);
    setLoading(false);
    };

    useEffect(() => {
      getEvents();
    }, []);


  // const getUserStats = () => {

  //   axios
  //     .get(
  //       `https://justwin.pl/statisticsdistributor/GetProfileStatistics?profileId=${player.PlayerId}`
  //     )
  //     .then((response) => {

  //       const lineChartData: ChartData = {
  //         labels: ['Wzrost','Waga', 'Kondycja', 'Dystans', 'Dynamika', 'Tętno', 'HMLD', 'HSR', 'Sprint'],
  //         datasets: [
  //           {
  //             label: 'Statystyki zawodnika',
  //             data: [
  //               response.data.Data.Height, response.data.Data.Weight,response.data.Data.Condition, response.data.Data.Distance, response.data.Data.Dynamics, response.data.Data.HeartRate, response.data.Data.Hmld, response.data.Data.Hsr, response.data.Data.Speed
  //             ],
  //             backgroundColor: [
  //               'rgba(255, 99, 132, 0.2)',
  //               'rgba(54, 162, 235, 0.2)',
  //               'rgba(255, 206, 86, 0.2)',
  //               'rgba(75, 192, 192, 0.2)',
  //               'rgba(153, 102, 255, 0.2)',
  //               'rgba(255, 159, 64, 0.2)'
  //             ],
  //             borderColor: "rgb(255, 99, 132)",
  //           }
  //         ],
  //         height: response.data.Data.Height,
  //         weight: response.data.Data.Height.Weight,
  //         condition: response.data.Data.Condition,
  //         distance: response.data.Data.Distance,
  //         dynamics: response.data.Data.Dynamics,
  //         form: response.data.Data.Form,
  //         heartRate: response.data.Data.HeartRate,
  //         hmld: response.data.Data.Hmld,
  //         hsr: response.data.Data.Hsr,
  //         speed: response.data.Data.Speed,
  //       };
  //       setLineData(lineChartData);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // useEffect(() => {
  //   getUserStats()
  // }, [])

  //flex gap-4 p-24 text-white

  const playerTabs = getDetailTabsByEnum(PLAYER_TABS);

  const playerTabsWithPermissions = playerTabs.map((tab) => {
    return {
      ...tab,
      permission: !!permission.find(
        (permission: any) => PERMISSIONS[permission.Id] === tab.label
      ),
    };
  });

  const tabsPlayer = permission[0]?.Id === -1 ? tabs :  playerTabsWithPermissions;

  return (
    <TabsView>
      <Tabs tabs={tabsPlayer} />
      <div className=" gap-4 p-4 md:p-24 text-white">
        <PlayerAddress address={player.Address} />
        <PlayerPasses player={player} refresh={refresh} />
        <PlayerCard card={player.Card} />
        <PlayerEvents
          eventsData={eventsData}
          isLoading={isLoading}
        />
        <PlayerManager
          designateTrainers={player.DesignateTrainers}
          refresh={refresh}
        />
        {/* <PlayerStatistics userStats={lineData}/> */}
        <StatisticsTab
          playerProfile={lineData}
          ownerId={player.PlayerId}
          getTrainingsData={getTrainingsData}
        />
        <PlayerCareer
          PlayerId={player.PlayerId}
          PlayerCardId={player.Card?.PlayerCardId}
        />
        <PlayerHistoryState player={player} refresh={refresh} />
      </div>
    </TabsView>
  );
};

export default PlayerDetailTabs;
