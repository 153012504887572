import { conn } from 'api/BaseConnection';
import { IFile } from 'types/fileType';
import { IListPageRequest, IListPageResponse } from 'types/globalTypes';
import {
  IOrder,
  IOrderDetail,
  ORDER_HISTORY_STATE,
} from 'types/marketpleace/order.interface';

const endpoint = conn.endpoints.marketpleace.order;

const getAll = async (
  listPage?: IListPageRequest
): Promise<IListPageResponse<IOrder>> => {
  return await conn.getJSON(endpoint.getAll, 'json', { ...listPage });
};

const getDetail = async (OrderId: string): Promise<IOrderDetail> => {
  return await conn.getJSON(endpoint.getDetail, 'json', { OrderId });
};

const changeState = async (
  OrderId: string,
  CurrentState: ORDER_HISTORY_STATE
) => {
  return await conn.postJSON(endpoint.changeState, 'json', {
    OrderId,
    CurrentState,
  });
};

const addDocument = async (OrderId: string, Name: string, FilePath: string) => {
  return await conn.postJSON(endpoint.document.add, 'json', {
    OrderId,
    Name,
    FilePath,
  });
};

const removeDocument = async (OrderId: string, DocumentId: string) => {
  return await conn.deleteJSON(endpoint.document.remove, 'json', {
    OrderId,
    DocumentId,
  });
};

const orderService = {
  getAll,
  getDetail,
  changeState,
  addDocument,
  removeDocument,
};

export default orderService;
