import React, { ReactNode } from 'react';

interface IInfoContainerItemProps {
  header: string;
  content?: ReactNode;
}

const InfoContainerItem = ({ header, content }: IInfoContainerItemProps) => {
  const text =
    typeof content === 'string' || content === null || content === undefined
      ? content
        ? content
        : '-'
      : null;

  return (
    <div title={text ? `${header}: ${content}` : header}>
      <p>{header}</p>
      {text ? <p style={{ display: "flex", justifyContent: "flex-end", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: 'nowrap' , width: '55%'}}>{text}</p> : <div>{content}</div>}
    </div>
  );
};

export default InfoContainerItem;
