import HeaderContainer from 'components/common/Containers/HeaderContainer';
import TabContent from 'components/common/tabs/TabContent';
import React from 'react';
import { GridColumn, GridTextItem } from 'styles/styles';
import { useOrderDetailTabsContext } from '../../hook/useOrderDetailTabsContext';
import OrderRefoundItem from './OrderRefoundItem';
import { IOrderDetailRefound } from 'types/marketpleace/order.interface';

const OrderRefunds = () => {
  const { data: order, refresh } = useOrderDetailTabsContext();

  const headers = [
    'Nazwa',
    'Zdjęcie',
    'Atrybuty',
    'EAN',
    'Cena',
    'Ilość',
    'Łącznie',
    'Status', // string but should be ENUM
  ];

  return (
    <TabContent id='returns'>
      <div className='flex gap-4 p-24 w-full'>
        <HeaderContainer title='Zwroty' withContainer={!order.Refunds.length}>
          {!order.Refunds.length ? (
            'Brak zwrotów'
          ) : (
            <GridColumn cols={headers.length}>
              {headers.map(header => (
                <GridTextItem title={header} uppercase isHeader>
                  {header}
                </GridTextItem>
              ))}
              {order.Refunds.map(refound => (
                <OrderRefoundItem refound={refound} key={refound.RefundId} />
              ))}
            </GridColumn>
          )}
        </HeaderContainer>
      </div>
    </TabContent>
  );
};

export default OrderRefunds;
