import { format } from 'date-fns';

export const parseTimeToSeconds = (time: string) => {
  try {
    if (!time) {
      throw new Error('Time is required');
    }

    const [hours, minutes, seconds] = time.split(':');
    const secondsFromHours = parseInt(hours, 10) * 3600;
    const secondsFromMinutes = parseInt(minutes, 10) * 60;

    return secondsFromHours + secondsFromMinutes + parseInt(seconds, 10);
  } catch (error: any) {
    throw new Error('Invalid time format');
  }
};

export const parseSecondsToTime = (sec: number) => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = sec - hours * 3600 - minutes * 60;

  return [hours, minutes, seconds]
    .map(item => String(item).padStart(2, '0'))
    .join(':');
};

export const getFormatedDate = (
  date: Date,
  formatBy: 'date' | 'date-time' | 'time' | string
) => {
  const formatString =
    formatBy === 'date'
      ? 'dd.MM.yyyy'
      : formatBy === 'date-time'
      ? 'dd.MM.yyyy,  kk:mm'
      : formatBy === 'time'
      ? 'kk:mm'
      : formatBy;

  const timezoneOffset = new Date(date).getTimezoneOffset();

  const timezonedDate = new Date(date).setUTCSeconds(timezoneOffset * 60);

  return format(timezonedDate, formatString);
};

export const adjustDateStringToInput = (date: string, withTime?: boolean) => {
  return date.replaceAll('"', '').slice(0, withTime ? 16 : 10);
};

export const isValidDate = (d: Date) => {
  return d instanceof Date && !isNaN(d.getTime());
};

export const isValidDateRange = (start: string | Date, end: string | Date): boolean => {
  return new Date(start).getTime() <= new Date(end).getTime();
};

export const getMonthDifference = (startDate: Date, endDate: Date) => {
  return (
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear())
  );
};
