import { Dispatch, SetStateAction } from 'react';
import { IPlayerManagerProps } from '../PlayerManager';
import { GridColumn } from 'styles/styles';
import PlayerManagerHeaders from './PlayerManagerHeaders';
import { ITrainer, TRAINER_TYPE } from 'types/transfer/trainer.interface';
import PlayerManagerAddForm from './PlayerManagerAddForm';
import PlayerManagerRow from './PlayerManagerRow';
import DataTable from 'components/common/dataTable/DataTable';
import { format } from 'date-fns';
import { getFormatedDate } from 'utils/dateUtils';
import { OBSERVATION_LEVEL } from 'types/transfer/transfer.interface';

interface IPlayerManagerTableProps extends IPlayerManagerProps {
  isAddFormDisplayed: boolean;
  displayAddForm: Dispatch<SetStateAction<boolean>>;
  isEditMode: boolean;
  academyUnassignedTrainers: ITrainer[];
}

const PlayerManagerTable = ({
  displayAddForm,
  isAddFormDisplayed,
  isEditMode,
  refresh,
  designateTrainers,
  academyUnassignedTrainers,
}: IPlayerManagerTableProps) => {
  const rows = designateTrainers.map(trainer => ({
    data: { link: `/trainers/detail/${trainer.TrainerId}` },
    cols: [
      trainer.Name,
      TRAINER_TYPE[trainer.TrainerType],
      OBSERVATION_LEVEL[trainer.Level],
      getFormatedDate(trainer.DesignatedAt, 'date'),
    ],
  }));

  return (
    <div>
      {/* <GridColumn cols={2}>
        <PlayerManagerHeaders />
         {isAddFormDisplayed && (
          <PlayerManagerAddForm
            displayAddForm={displayAddForm}
            academyUnassignedTrainers={academyUnassignedTrainers}
            refresh={refresh}
          />
        )}
        {designateTrainers.map(trainer => (
          <PlayerManagerRow
            key={trainer.TrainerId}
            isEditMode={isEditMode}
            trainer={trainer}
            refresh={refresh}
          />
        ))} 
        
      </GridColumn>*/}
      <DataTable
        headers={[
          'Imię i nazwisko',
          'Funkcja',
          'Stan obserwacji',
          'Przypisany od',
        ]}
        isDataLoading={false}
        rows={rows}
        emptyListMessage='Brak przypisanych trenerów'
      />
    </div>
  );
};

export default PlayerManagerTable;
