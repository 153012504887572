import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import SubmitButton from 'components/common/buttons/submitButton/SubmitButton';
import TextField from 'components/common/inputs/textInput/TextField';
import ProductTextAreaField from 'components/common/inputs/textArea/ProductTextAreaField';
import { SingleColumn } from 'styles/styles';
import { toast } from 'react-toastify';
import { getProductTypes } from '../utils/helpers';
import NewSelectStatystics from 'components/common/inputs/select/NewSelectStatystics';
import NewImageField from 'components/common/inputs/imageInput/NewImageField';
import {
  IProductForm,
  PRODUCT_TYPES,
} from 'types/marketpleace/product.interface';
import { ISelectOption } from 'components/common/inputs/inputTypes';
import { getCategoriesSelectOptions } from 'middleware/category/getCategories';
import { isSellerHasPhysicalDelivery } from 'middleware/seller/isSellerHasPhysicalDelivery';
import { isDeliveryExists } from 'middleware/delivery/deliveryExists';

interface ProductFormProps {
  initialValues: IProductForm;
  isMenuOpen: boolean;
  handleSubmit: (
    values: IProductForm,
    categoryId: string,
    base64: string,
    productType?: PRODUCT_TYPES,
    bannerPhotoFilePath?: string
  ) => Promise<void>;
  defaultProductType?: PRODUCT_TYPES;
  defaultCategoryId?: string;
}

const ProductForm = ({
  initialValues,
  handleSubmit,
  isMenuOpen,
  defaultProductType,
  defaultCategoryId,
}: ProductFormProps) => {
  const [base64, setBase64] = useState<string>('');
  const [categories, setCategories] = useState<ISelectOption<string>[]>([]);
  const [category, setCategory] = useState<ISelectOption<string> | null>(null);
  const [productType, setProductType] =
    useState<ISelectOption<PRODUCT_TYPES> | null>(null);

  const onSubmit = async (product: IProductForm) => {
    console.log('base64 exists', !!base64.length);
    console.log(
      'initial BannerPhotoFilePath',
      initialValues.BannerPhotoFilePath
    );

    if (!category) return toast.error('Wybierz kategorie!');
    if (!productType) return toast.error('Wybierz typ produktu!');
    if (!product.Name) return toast.error('Wpisz nazwę produktu!');
    if (!product.Description) return toast.error('Wpisz opis produktu!');
    if (!product.ShortDescription)
      return toast.error('Wpisz krótki opis produktu!');
    if (!product.Tag) return toast.error('Wpisz tagi produktu!');

    if (productType.value === PRODUCT_TYPES['Fizyczny']) {
      const isPhysicalDeliveryExists = await isDeliveryExists(
        PRODUCT_TYPES['Fizyczny']
      );
      if (!isPhysicalDeliveryExists) {
        return toast.error('Nie posiadasz dostawy dla produktów fizycznych');
      }
    }

    if (productType.value === PRODUCT_TYPES['Elektroniczny']) {
      const isDigitalDeliveryExists = await isDeliveryExists(
        PRODUCT_TYPES['Elektroniczny']
      );
      if (!isDigitalDeliveryExists) {
        return toast.error(
          'Nie posiadasz dostawy dla produktów elektronicznych'
        );
      }
    }

    if (!initialValues.BannerPhotoFilePath && !base64)
      return toast.error('Dodaj zdjęcie!');

    if (initialValues.BannerPhotoFilePath && !base64)
      return handleSubmit(
        product,
        category.value,
        '',
        productType.value,
        initialValues.BannerPhotoFilePath
      );

    return handleSubmit(product, category.value, base64, productType.value);
  };

  const setCategoriesSelectOptions = async () => {
    const categoriesSelectOptions = await getCategoriesSelectOptions();
    setCategories(categoriesSelectOptions);
  };

  useEffect(() => {
    isMenuOpen && setCategoriesSelectOptions();
  }, [isMenuOpen]);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnChange>
      {({ errors, isSubmitting, values }) => (
        <Form
          className='flex'
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
          }}>
          <SingleColumn style={{ padding: '0 15px' }}>
            <p>dane podstawowe</p>

            <NewImageField
              name='BannerPhotoFilePath'
              className='mx-auto md:mx-0 mb-8'
              imgSrc={values.BannerPhotoFilePath}
              base64={base64}
              setBase64={setBase64}
            />

            {!categories.length ? null : (
              <NewSelectStatystics<string>
                name='CategoryId'
                style={{ width: '280px', marginBottom: '20px' }}
                items={categories}
                label='Kategoria'
                selectedItem={category}
                setSelectedItem={setCategory}
                defaultValue={defaultCategoryId}
              />
            )}

            <NewSelectStatystics<PRODUCT_TYPES>
              name='Type'
              items={getProductTypes}
              label='Typ produktu'
              style={{ width: '280px', marginBottom: '20px' }}
              selectedItem={productType}
              setSelectedItem={setProductType}
              disabled={initialValues.Type === undefined}
              defaultValue={defaultProductType}
            />

            <TextField name='Name' label='Nazwa' helperText={errors.Name} />
          </SingleColumn>

          <SingleColumn>
            <p>Informacje</p>
            <ProductTextAreaField name='Description' label='Opis' />
            <TextField
              name='ShortDescription'
              label={'Krótki opis'}
              helperText={errors.ShortDescription}
            />
            <TextField name='Tag' label={'Tag'} helperText={errors.Tag} />
            <SubmitButton
              isSubmitting={isSubmitting}
              className='mt-6'
              style={{
                borderRadius: 0,
                width: '280px',
                fontSize: '15px',
                fontWeight: 'bold',
                marginTop: '164px',
                color: 'white',
                background: 'rgb(0, 160, 255)',
              }}>
              Zapisz
            </SubmitButton>
          </SingleColumn>
        </Form>
      )}
    </Formik>
  );
};

export default ProductForm;
