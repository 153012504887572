import React from 'react';
import { GridTextItem } from 'styles/styles';

const AcademyPassesHeaders = () => {
  return (
    <>
      <GridTextItem isHeader>Nazwa</GridTextItem>
      <GridTextItem isHeader>Typ</GridTextItem>
      <GridTextItem isHeader>Od</GridTextItem>
      <GridTextItem isHeader>Do</GridTextItem>
      <GridTextItem isHeader>Płatność</GridTextItem>
      <GridTextItem isHeader>Netto</GridTextItem>
      <GridTextItem isHeader>Podatek</GridTextItem>
      <GridTextItem isHeader>Brutto</GridTextItem>
      <GridTextItem isHeader>Działanie</GridTextItem>
    </>
  );
};

export default AcademyPassesHeaders;
