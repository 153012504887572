import ContentContainer from 'components/layout/ContentContainer';
import { useContext, useState } from 'react';
import ProductsTopbar from './ProductsTopbar';
import { DefaultSortContext } from 'contexts/defaultSortContext';
import { DataViewMode, ISelectOption, LIST_SORT } from 'types/globalTypes';
import { IProductDto } from 'types/marketpleace/product.interface';
import { getSorts } from './utils/helpers';
import ProductsTable from './table/ProductsTable';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import productServices from 'services/marketpleace/productServices';
import ProductsList from './table/ProductsList';
import { useDebounce } from 'use-debounce';

const Products = () => {
  const [defaultSort, setDefaultSort] = useContext(DefaultSortContext);

  const [sortBy, setSortBy] = useState<ISelectOption<LIST_SORT> | null>(null);

  const [viewType, setViewType] = useState<keyof typeof DataViewMode>('table');

  const [queryString, setQueryString] = useState('');

  const [debounceSearchInput] = useDebounce(queryString.trim(), 500);

  const {
    items: sellerProducts,
    loading,
    containerRef,
    lastItemRef,
    refresh,
  } = useInfiniteScroll<IProductDto>(
    productServices.getFiltered,
    debounceSearchInput
  );

  const handleQueryChange = (query: string) => {
    if (!query.length || query.length > 2) {
      setQueryString(query);
    }
  };

  return (
    <ContentContainer
      title={'Produkty'}
      TopBar={
        <ProductsTopbar
          refresh={refresh}
          sorts={getSorts}
          sortBy={sortBy}
          setSortBy={setSortBy}
          handleQueryChange={handleQueryChange}
          defaultSort={defaultSort}
          viewType={viewType}
          setViewType={setViewType}
        />
      }>
      <div>
        {viewType === 'table' ? (
          <ProductsTable
            data={sellerProducts}
            isDataLoading={loading}
            sortBy={sortBy}
            containerRef={containerRef}
            lastItemRef={lastItemRef}
          />
        ) : (
          <ProductsList
            data={sellerProducts}
            isDataLoading={loading}
            sortBy={sortBy}
            containerRef={containerRef}
            lastItemRef={lastItemRef}
          />
        )}
      </div>
    </ContentContainer>
  );
};

export default Products;
