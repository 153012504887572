import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "store/store";

import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { showServerErrors } from "utils/errorsUtils";
import styled from "styled-components";
import EditDigitalRelease from "../form/EditDigitalRelease";
import axios from "axios";
import UserStatusInfo from "components/users/status/UserStatusInfo";
import { getEventsPathWithDate } from "components/navbar/navbarUtils";

const BlurBox = styled.div<{ isEditMenuOpen: boolean }>`
  position: absolute;
  display: ${(props) => (props.isEditMenuOpen ? "unset" : "none")};
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(3px);
  z-index: 99;
`;

const SideEditMenu = styled.div<{ isEditMenuOpen: boolean }>`
  position: absolute;
  height: calc(100vh - 48px);
  top: 48px;
  /* padding: ${(props) => (props.isEditMenuOpen ? "23px 44px" : "0")}; */
  padding: ${(props) => (props.isEditMenuOpen ? "23px 0" : "23px 0")};
  right: 0;
  width: ${(props) => (props.isEditMenuOpen ? "917px" : "0px")};
  background: rgba(2, 2, 15, 1);
  transition: all 0.4s ease-in-out;
  color: rgba(163, 158, 157, 1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  & > * {
    opacity: ${(props) => (props.isEditMenuOpen ? "1" : "0")};
    transition: all 0.2s ease-in-out;
  }

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    & > form {
      & > div {
        & > p {
          /* text-align: center; */
          text-transform: uppercase;
          font-size: 15px;
          font-weight: 400;
          margin-bottom: 10px;
        }
      }
    }
  }
`;

interface IDigitalReleaseDetailTopbarProps {
  event: any;
  isStructure?: boolean;
  trainerStatus?: number;
}

const TrainersDetailTopbar: React.FC<IDigitalReleaseDetailTopbarProps> = ({
  event,
  isStructure,
  trainerStatus,
}) => {
  const { push } = useHistory();
  const [isEditMenuOpen, toggleEditMenuOpen] = useState(false);
  const [trainersStatus, setTrainersStatus] = useState(false);
  //TODO: const permissions = useSelector((state: RootState) => state.userPermissions?.DigitalReleases);
  const permissions = useSelector(
    (state: RootState) => state.userPermissions?.Artist,
  );

  // if (!permissions) {
  //   return null;
  // }

  const handleEdit = (id: string) => {
    push(`/shop/products/edit/${id}`);
  };

  const { id } = useParams<{ id: string }>();
  const backendDate = new Date(event.End);

  const today = new Date();

  const isDateExpired = today > backendDate;
  // const handleRemove = async (ProductId: string) => {
  //   try {
  //     await productServices.removeProduct(ProductId);
  //     toast.success("Usunięto produkt!");
  //     push("/shop/products");
  //   } catch (errors: any) {
  //     showServerErrors(errors);
  //   }
  // };

  const handleRemove = async (EventId: string) => {
    const token = localStorage.getItem("token");

    try {
      await axios.delete("https://adminapi.justwin.pl/api/Event", {
        headers: {
          Authorization: `bearer ${token}`,
        },
        data: {
          EventId,
          AuthorId: event?.AuthorProfile?.ProfileId,
          AuthorType: 0,
        },
      });

      push(getEventsPathWithDate());
      toast.success("Usunięto zajęcie!");
    } catch (error: any) {
      showServerErrors(error);
    }
  };
  console.log("eventxaxaxa", event);

  useEffect(() => {
    setTrainersStatus(trainerStatus === 1);
  }, [trainerStatus]);

  return (
    <div className="flex justify-between text-sm">
      {/* <div className="flex" style={{ gap: "4px" }}> */}
      {/* <BlurBox
        isEditMenuOpen={isEditMenuOpen}
        onClick={() => toggleEditMenuOpen(false)}
      />
      <SideEditMenu isEditMenuOpen={isEditMenuOpen}>
        <div></div>
      </SideEditMenu> */}
      {!isStructure && (
        <>
          <div></div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "48px",
              width: "200px",
            }}
          >
            <UserStatusInfo isActive={trainersStatus} />
          </div>
        </>
      )}
    </div>
  );
};

export default TrainersDetailTopbar;
