import { FormikHelpers, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import React, { useState } from "react";
import TextArea from "components/common/inputs/textArea/TextArea";
import DropdownPanel, { Modes } from "components/common/panels/DropdownPanel";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { showServerErrors } from "utils/errorsUtils";
import styled from "styled-components";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import eventsServices from "services/eventsServices";

interface Props {
  event: any;
  getEventDetailFn: any;
}

const EventComment: React.FC<Props> = ({ event, getEventDetailFn }) => {
  const presspack = useSelector((state: RootState) => state.artist.presspack);
  const permissions = useSelector(
    (state: RootState) => state.userPermissions?.Artist,
  );
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [editNote, setEditNote] = useState(event?.Note);

  const handleEditNoteChange = (e: any) => {
    setEditNote(e.target.value);
  };

  const editNoteFn = async () => {
    await eventsServices.editNote(id, editNote);
    setIsEditing(false);
    toast.success("Zmieniono uwagi do zajęcia!");
    await getEventDetailFn();
  };

  const backendDate = new Date(event.EndDate);

  const today = new Date();

  const isDateExpired = today > backendDate;

  const handleSubmit = async () => {
    if (
      formik.values.biography.length < 5 ||
      formik.values.biography.length > 700
    )
      return toast.error("Opis musi mieć od 5 do 700 znaków!");

    try {
      // await productServices.updateDescription(id, formik.values.biography);
      toast.success("Zmieniono opis produktu!");
      push("/shop/products/");
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  const formik = useFormik({
    initialValues: { biography: event.Note || "" },
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ width: "100%", paddingLeft: "24px", paddingRight: "24px" }}>
        <div
          className="flex justify-between pt-8 pb-8 pr-8 border-solid border-l-4 border-orange items-center"
          style={{ backgroundColor: "#02020f4c" }}
        >
          <div
            className="text-white pl-8 font-regular uppercase"
            style={{ fontSize: "15px" }}
          >
            Uwagi
          </div>

          {!isDateExpired && (
            <Button
              onClick={() => {
                setIsEditing((prev) => !prev);
                if (isEditing) {
                  editNoteFn();
                }
              }}
              variant={ButtonVariant.Submit}
              style={{
                cursor: "pointer",
                userSelect: "none",
              }}
            >
              {isEditing ? "Zapisz" : "Edytuj"}
            </Button>
          )}
        </div>
        <br />
        <DropdownPanel
          canAdd={false}
          canEdit={false}
          label="Uwagi"
          // editable
          initialExpanded
          onSubmit={formik.handleSubmit}
          onClear={() => {
            formik.setFieldValue("biography", "");
          }}
          render={({ mode }) =>
            mode === Modes.View ? (
              <div style={{ width: "100%" }}>
                {isEditing ? (
                  <StyledTextArea
                    value={editNote}
                    onChange={handleEditNoteChange}
                  />
                ) : (
                  <p
                    className="text-sm opacity-70 py-24 px-36"
                    style={{
                      overflowWrap: "break-word",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {event?.Note ? event.Note : "-"}
                  </p>
                )}
              </div>
            ) : (
              // <TextAreaContainer className="p-12">
              //   <TextArea
              //     maxLength={700}
              //     autoFocus
              //     label=""
              //     name="biography"
              //     value={formik.values.biography}
              //     onChange={formik.handleChange}
              //   />
              // </TextAreaContainer>
              <p
                className="text-sm opacity-70 py-24 px-36"
                style={{
                  overflowWrap: "break-word",
                  width: "100%",
                }}
              >
                {event.Note}
              </p>
            )
          }
        />
      </div>
    </div>
  );
};

export default EventComment;

const TextAreaContainer = styled.div`
  width: 100%;

  & > div {
    & > div {
      & > div {
        background: transparent !important;
        & > div {
          background: transparent !important;
        }
      }
    }
  }
`;

const StyledTextArea = styled.textarea`
  background: transparent;
  resize: none;
  width: 100%;
  outline: none;
  padding-top: 20px;
  width: 100%;
  height: 300px;
`;
