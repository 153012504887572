import { IDataTableRows } from 'components/common/dataTable/DataTable';
import {
  IAddDelivery,
  IDelivery,
  IDeliveryInitialValues,
} from 'types/marketpleace/delivery.interface';
import { PRODUCT_TYPES } from 'types/marketpleace/product.interface';

export const deliveryHeaders = [
  'logo',
  'nazwa',
  'opis',
  // 'typ',
  'termin',
  'netto',
  'vat',
  'kwota brutto',
  'darmowa od',
  'Działanie',
];

export const deliveryInitialValues: IDeliveryInitialValues = {
  ConditionForFree: '',
  Deadline: '',
  Description: '',
  Name: '',
  Netto: '',
  PhotoFilePath: '',
  Tax: '',
};
