import { ACADEMY_VISIBILITY, IAcademy } from 'types/academy.interface';
import Switch from 'react-switch';
import { useState } from 'react';
import { showServerErrors } from 'utils/errorsUtils';
import { changeAcademyVisibility } from 'services/academyServices';

interface IAcademyTopbarProps {
  academy: IAcademy;
  refresh: () => Promise<void>;
}

const AcademyTopbar = ({ academy, refresh }: IAcademyTopbarProps) => {
  const [visibility, setVisibility] = useState(academy.Visibility);

  const changeVisibility = async (checked: boolean) => {
    try {
      const changedVisibility = checked
        ? ACADEMY_VISIBILITY['Publiczny']
        : ACADEMY_VISIBILITY['Prywatny'];

      await changeAcademyVisibility(changedVisibility);

      setVisibility(changedVisibility);

      await refresh();

    } catch (error: any) {
      showServerErrors(error);
    }
  };

  return (
    <div className='flex w-full h-full justify-between items-center px-24 '>
      <div></div>
      <div className='flex h-full gap-2 items-center text-white text-opacity-50 font-light'>
        {ACADEMY_VISIBILITY[visibility]}
        <Switch
          checked={visibility === ACADEMY_VISIBILITY['Publiczny']}
          onChange={checked => changeVisibility(checked)}
          disabled={!academy}
          uncheckedIcon={false}
          checkedIcon={false}
        />
      </div>
    </div>
  );
};

export default AcademyTopbar;
