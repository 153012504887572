import { GridItem, GridTextItem } from 'styles/styles';
import React from 'react';
import { IOrderDetailItem } from 'types/marketpleace/order.interface';

interface IOrderProductItemProps {
  item: IOrderDetailItem;
}

const OrderProductItem = ({ item }: IOrderProductItemProps) => {
  const itemCost = item.Quantity * item.Gross;

  return (
    <>
      <GridItem title='BannerPhoto'>
        <img
          src={item.BannerPhotoFilePath}
          alt='BannerPhoto'
          loading='lazy'
          className='max-h-28'
        />
      </GridItem>
      <GridItem title={item.Name}>{item.Name}</GridItem>
      <GridItem title={item.AttributeName} className='flex flex-col'>
        {item.Values.length
          ? item.Values.map(value => (
              <div key={value.AttributeId}>
                {value.AttributeName}: {value.ValueName}
              </div>
            ))
          : item.AttributeName}
      </GridItem>
      <GridItem title={item.EAN}>{item.EAN}</GridItem>
      <GridItem title={item.Gross.toFixed(2)}>
        {item.Gross.toFixed(2)} zł
      </GridItem>
      <GridItem title={item.Quantity.toString()}>{item.Quantity}</GridItem>
      <GridItem title={itemCost.toFixed(2)}>{itemCost.toFixed(2)} zł</GridItem>
    </>
  );
};

export default OrderProductItem;
