import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";

import SubmitButton from "../../common/buttons/submitButton/SubmitButton";
import SwitchBlock from "../../common/inputs/switch/SwitchBlock";
import AssignToProfileSelect from "./AssignToProfileSelect";
import PermissionSection from "./PermissionSection";

import permissionService from "../../../services/permissionServices";
import {
  PermissionsLabels,
  permissoinsFromDTO,
} from "../../../utils/permissionsUtils";
import { ICheckboxValue } from "../../../types/globalTypes";
import {
  IPermissions,
  PermissionsDomainsShort,
  IPermissionTransformDTO,
  IPermissionsActionTypes,
} from "../../../types/permissionsTypes";
import { useDispatch } from "react-redux";
import { changePermissions } from "../../../store/actions/permissions";
import permissionsServices from "services/permissionsServices";
import FormSection from "components/common/forms/FormSection";
import SelectProfiles from "components/common/inputs/select/SelectProfiles";
import { Permission, UserInterface } from "types/userTypes";
import {
  translateDomainNames,
  translateFlagNames,
} from "./PermissionsTranslate";
import NotificationSwitchBlock from "components/common/inputs/switch/NotificationSwitchBlock";
import { permissions } from "store/reducers/Permissions";
import { Div } from "components/navbar/Notifications";

interface Props {
  user: UserInterface;
}

const EditNotifications: React.FC<Props> = ({ user }) => {
  const [initState, setInitState] =
    useState<IPermissions<ICheckboxValue<number>> | null>(null);
  const [changed, setChanged] = useState<IPermissionTransformDTO>({});

  const [selectedItem, setSelectedItem] =
    useState<{ value: number; label: string } | null>(null);
  const [profiles, setProfiles] = useState([]);
  const [domainNames, setDomainNames] = useState([]);

  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const handleChange = (
    domain: keyof IPermissions,
    action: keyof IPermissionsActionTypes,
    value: ICheckboxValue<number>,
  ) => {
    const key = `${domain}_${action}`;
    if (!changed[key]) {
      setChanged((prev) => ({
        ...prev,
        [key]: {
          permissionDomainName: PermissionsDomainsShort[domain],
          permissionFlagName: action,
          permissionFlagValue: value.value,
          value,
        },
      }));
    } else {
      setChanged((prev) => {
        const { [key]: deleted, ...rest } = prev;
        return rest;
      });
    }
  };

  const handleSubmit = async (values: typeof initValues, bag: any) => {
    try {
      if (values) {
        dispatch(changePermissions(changed, id));
        setChanged({});
        toast.success(`Zmieniono uprawnienia`);
      }
    } catch (errors: any) {
      toast.error("Błąd: " + errors.join(" | "));
    }
  };

  const getAllProfiles = async () => {
    try {
      const resp: any = await permissionsServices.getAllProfiles();

      const profilesArray: any = [];

      const convertedObiect = Object.values(resp);

      convertedObiect.map((single, index) => {
        return profilesArray.push({
          label: single,
          value: index,
        });
      });
      setProfiles(profilesArray);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProfiles();
  }, []);

  useEffect(() => {
    const domainNamesArray: any = [];
    user.Permissions.forEach((Permission: Permission) => {
      if (
        domainNamesArray.filter(
          (el: any) => Permission.PermissionDomainName === el,
        ).length > 0
      ) {
        return;
      } else {
        domainNamesArray.push(Permission.PermissionDomainName);
      }
    });

    // @ts-ignore
    setDomainNames(domainNamesArray);

  }, []);

  const initValues = {
    Auth: {
      CreateUser: 1,
      EditUser: 1,
      DeleteUser: 1,
      ViewManagementList: 1,
      ViewContactList: 1,
      ChangeOwnPassword: 1,
      ManagePermissions: 1,
      ChangeRole: 1,
      ChangePassword: 1,
      RevokePermission: 1,
      GrantPermission: 1,
    },
  };

  const sectionPermissonsElems = {
    Data: [
      {
        header: "Moja Akademia",
        permissions: [
          { NotificationName: "Wyświetl detal Akademii", PermissionAccess: 130 },
          { NotificationName: "Wyświetl detal podstawowych danych Akademii", PermissionAccess: 123 },
          { NotificationName: "Zaktualizuj podstawowe dane Akademii", PermissionAccess: 118 },
          { NotificationName: "Wyświetl detal Licencji Akademii", PermissionAccess: 124 },
          { NotificationName: "Wyświetl detal sprzedawcy", PermissionAccess: 125 },
          { NotificationName: "Możliwość aktywacji sprzedawcy", PermissionAccess: 20 },
          { NotificationName: "Wyświetl składki Akademii", PermissionAccess: 126 },
          { NotificationName: "Wyświetl edycję składki", PermissionAccess: 13 },
          { NotificationName: "Zmiena ceny składki Akademii", PermissionAccess: 12 },
          { NotificationName: "Wyświetl ulubione lokalizacje", PermissionAccess: 127 },
          { NotificationName: "Edytuj ulubione lokalizacje", PermissionAccess: 129 },
        ]
      },
      {
        header: 'Sekcja Zajęcia',
        permissions: [
          { NotificationName: "Wyświetl zakładkę eventu", PermissionAccess: 131 },
          { NotificationName: "Wyświetl szczegóły eventu", PermissionAccess: 132 },
          { NotificationName: "Dostęp to tworzenia eventu", PermissionAccess: 172 },
          { NotificationName: "Dostęp do aktualizacji eventu", PermissionAccess: 208 },
          { NotificationName: "Dostęp do usunięcia eventu", PermissionAccess: 175 },
          { NotificationName: "Dostęp do usunięcia postu", PermissionAccess: 176 },
          { NotificationName: "Dostęp do edycji eventu", PermissionAccess: 177 },
          { NotificationName: "Utwórz cykliczny event", PermissionAccess: 210 },
          { NotificationName: "Aktualizuj cykliczny event", PermissionAccess: 174 },
          { NotificationName: "Dostęp do detalu uczestnicy", PermissionAccess: 142 },
          { NotificationName: "Dostęp do detalu adres", PermissionAccess: 143 },
          { NotificationName: "Dostęp do detalu Post", PermissionAccess: 144 },
          { NotificationName: "Dostęp do detalu uwagi", PermissionAccess: 145 },
        ]
      },
      {
        header: 'Sekcja Zawodników',
        permissions: [
          { NotificationName: "Wyświetl listę zawodników", PermissionAccess: 133 },
          { NotificationName: "Dostęp do detalu zawodnika", PermissionAccess: 134 },
          { NotificationName: "Wyświetl adres zawodnika", PermissionAccess: 147 },
          { NotificationName: "Wyświetl zadania zawodnika", PermissionAccess: 146 },
          { NotificationName: "Wyświetl kartę zawodnika", PermissionAccess: 148 },
          { NotificationName: "Wyświetl trenera zawodnika", PermissionAccess: 149 },
          { NotificationName: "Wyświetl karierę zawodnika", PermissionAccess: 150 },
          { NotificationName: "Wyświetl statystyki zawodnika", PermissionAccess: 151 },
          { NotificationName: "Wyświetl składki zawodnika", PermissionAccess: 152 },
          {
            NotificationName: "Wyświetl historię statusów zawodnika", PermissionAccess: 153
          },
          {
            NotificationName: "Zmień status zawodnika", PermissionAccess: 181
          },
          {
            NotificationName: "Dodaj składkę do zawodnika", PermissionAccess: 179
          },
          {
            NotificationName: "Edytuj składkę zawodnika", PermissionAccess: 180
          },

        ]
      },
      {
        header: "Radar Zawodników",
        permissions: [
          { NotificationName: "Wyświetl radar zawodników", PermissionAccess: 135 },
          { NotificationName: "Wyświetl detal zawodnika z pozycji radaru", PermissionAccess: 205 },
          { NotificationName: "Wyświetl filtrowanie zawonika", PermissionAccess: 154 },
        ]
      },
      {
        header: 'Sekcja Trenerów',
        permissions: [
          { NotificationName: "Wyświetl zakładkę trenerów", PermissionAccess: 136 },
          { NotificationName: "Wyświetl detal trenera", PermissionAccess: 137 },
          { NotificationName: "Wyświetl biografię trenera", PermissionAccess: 155 },
          { NotificationName: "Wyświetl adres trenera", PermissionAccess: 156 },
          { NotificationName: "Wyświetl płatności trenera", PermissionAccess: 157 },
          { NotificationName: "Wyświetl obserwatorów trenera", PermissionAccess: 158 },
          { NotificationName: "Wyświetl historę statusów trenera", PermissionAccess: 178 },
        ]
      },
      {
        header: 'Radar Trenerów',
        permissions: [
          { NotificationName: "Wyświetl radar trenerów", PermissionAccess: 138 },
          { NotificationName: "Wyświetl detal trenera z pozycji radaru", PermissionAccess: 182 },
          { NotificationName: "Wyświetl filtrowanie trenerów", PermissionAccess: 159 },
        ]
      },
      {
        header: "Sekcja Grupy",
        permissions: [
          { NotificationName: "Wyświetl zakładkę grup", PermissionAccess: 139 },
          { NotificationName: "Wyświetl w grupach detal zawodnika", PermissionAccess: 56 },
          { NotificationName: "Wyświetl w grupach zajęcia zawodnika", PermissionAccess: 183 },
          { NotificationName: "Wyświetl w grupach adres zawodnika", PermissionAccess: 184 },
          { NotificationName: "Wyświetl w grupach kartę zawodnika", PermissionAccess: 185 },
          { NotificationName: "Wyświetl  trenera zawodnika", PermissionAccess: 186 },
          { NotificationName: "Wyświetl w grupach zawodnika kartę kariera", PermissionAccess: 187 },
          { NotificationName: "Wyświetl w grupach zawodnika kartę statystyk", PermissionAccess: 188 },
          { NotificationName: "Wyświetl w grupach zawodnika kartę składki", PermissionAccess: 189 },
          { NotificationName: "Wyświetl w grupach zawodnika kartę historia statusów", PermissionAccess: 190 },
          { NotificationName: "Wyświetl w grupach zawodnika przycisk chatu", PermissionAccess: 191 },
          { NotificationName: "Wyświetl w grupach detal trenerów", PermissionAccess: 111 },
          { NotificationName: "Dostęp do zakładki bio trenerów", PermissionAccess: 192 },
          { NotificationName: "Dostęp do zakładki adres trenera", PermissionAccess: 193 },
          { NotificationName: "Wyświetl w grupach trenerów kartę płatności", PermissionAccess: 194 },

          { NotificationName: "Wyświetl w grupach trenerów  obserwowanych trenera", PermissionAccess: 195 },
          { NotificationName: "Wyświetl w grupach historię statusów trenera", PermissionAccess: 196 },
          { NotificationName: "Wyświetl w grupach w folderze zakładkę statystyki", PermissionAccess: 197 },
          { NotificationName: "Wyświetl w grupach w folderze zakładkę zajęcia", PermissionAccess: 198 },
          { NotificationName: "Wyświetl w grupach w folderze zakładkę trener", PermissionAccess: 199 },
          { NotificationName: "Wyświetl w grupach w folderze zakładkę składki", PermissionAccess: 200 },


          { NotificationName: "Wyświetl w grupach radar trenerów", PermissionAccess: 112 },

          { NotificationName: "Wyświetl w grupach detal zawodnika", PermissionAccess: 96 },
        ]
      },
      {
        header: 'Sekcja Sklep',
        permissions: [
          { NotificationName: "Wyświetl dostęp do zakładki sklep", PermissionAccess: 140 },
          { NotificationName: "Wyświetl szczegóły zamówienia", PermissionAccess: 160 },
          { NotificationName: "Wyświetl składki zamówienia", PermissionAccess: 161 },
          { NotificationName: "Wyświetl produkty sklepowe", PermissionAccess: 162 },
          { NotificationName: "Wyświetl kategorie sklepowe", PermissionAccess: 163 },
          { NotificationName: "Wyświetl sklepowe atrybuty", PermissionAccess: 164 },
          { NotificationName: "Wyświetl sklepowe dostawy", PermissionAccess: 165 },
          { NotificationName: "Wyświetl zamówione produkty", PermissionAccess: 166 },
          { NotificationName: "Wyświetl dostawę zamówienia", PermissionAccess: 167 },
          { NotificationName: "Wyświetl dokumenty zamówienia", PermissionAccess: 168 },
          { NotificationName: "Wyświetl historię statusu zamówienia", PermissionAccess: 169 },
          { NotificationName: "Zmień status zamówienia", PermissionAccess: 40 },
          { NotificationName: "Wyświetl notatkę zamówienia", PermissionAccess: 170 },
          { NotificationName: "Wyświetl zwroty zamówienia", PermissionAccess: 171 },
          { NotificationName: "Dodaj dokumenty zamówienia", PermissionAccess: 21 },
          { NotificationName: "Usuń dokumenty zamówienia", PermissionAccess: 22 }
        ]
      },
    ]
  };

  return (
    <>
      {user?.Position !== 'Admin' && (
        <>
          <header className="p-8 text-lg text-white-dirty">
            <h2>Dostępy</h2>
          </header>
          {sectionPermissonsElems.Data.map((section, sectionIdx) => (
            <div >
              <PermissionSection
                label={section.header}
                key={sectionIdx}
                initialExpanded
              >
                <ul
                  className="grid gap-x-14 2xl:grid-cols-5 xl:grid-cols-4  md:grid-cols-3 sm:grid-cols-2 grid-cols-1"
                >
                  {section.permissions.map((permission, permIdx) => (
                    <li key={permIdx}>
                      <NotificationSwitchBlock
                        domainName={permission.NotificationName}
                        flagName={permission.NotificationName}
                        flagValue={permission.PermissionAccess}
                        name={permission.NotificationName}
                        label={permission.NotificationName}
                        checked={
                          user?.Permissions?.some(
                            (perm: any) => perm.Id === permission.PermissionAccess
                          )
                        }
                      />
                    </li>
                  ))}
                </ul>
              </PermissionSection>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default EditNotifications;
