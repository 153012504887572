import { GridItem, GridTextItem } from 'styles/styles';
import React from 'react';
import {
  IOrderDetailItem,
  IOrderDetailRefound,
  ORDER_REFOUND_STATUS,
} from 'types/marketpleace/order.interface';

interface IOrderRefoundItemProps {
  refound: IOrderDetailRefound;
}

const OrderRefoundItem = ({ refound }: IOrderRefoundItemProps) => {
  const refoundCost = refound.Quantity * refound.Item.Gross;

  return (
    <>
      <GridItem title={refound.Item.Name}>{refound.Item.Name}</GridItem>
      <GridItem title='BannerPhoto'>
        <img
          src={refound.Item.BannerPhotoFilePath}
          alt='BannerPhoto'
          loading='lazy'
          className='max-h-28'
        />
      </GridItem>
      <GridItem title={refound.Item.AttributeName} className='flex flex-col'>
        {refound.Item.Values.length ? refound.Item.Values.map(value => (
          <div key={value.AttributeId}>
            {value.AttributeName}: {value.ValueName}
          </div>
        )) : refound.Item.AttributeName}
      </GridItem>
      <GridItem title={refound.Item.EAN}>{refound.Item.EAN}</GridItem>
      <GridItem title={refound.Item.Gross.toFixed(2)}>
        {refound.Item.Gross.toFixed(2)} zł
      </GridItem>
      <GridItem title={refound.Quantity.toString()}>
        {refound.Quantity}
      </GridItem>
      <GridItem title={refoundCost.toFixed(2)}>
        {refoundCost.toFixed(2)} zł
      </GridItem>
      <GridItem title={ORDER_REFOUND_STATUS[refound.RefundStatus]}>{ORDER_REFOUND_STATUS[refound.RefundStatus]}</GridItem>
    </>
  );
};

export default OrderRefoundItem;
