import HeaderContainer from 'components/common/Containers/HeaderContainer';
import { InfoContainer, StyledTableInput } from 'styles/styles';
import InfoContainerItem from 'components/common/Containers/InfoContainerItem';
import { useAcademyTabsContext } from 'components/academy/hook/useAcademyTabsContext';
import { adjustDateStringToInput, getFormatedDate } from 'utils/dateUtils';
import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import { usePrimaryDataFormContext } from '../usePrimaryDataFormContext';

const AcademyUnion = () => {
  const { data: academy, refresh } = useAcademyTabsContext();
  const { editEnable, form, setForm } = usePrimaryDataFormContext();

  if (!academy.Union.IsInUnion && !editEnable) return <></>;

  return (
    <HeaderContainer title='Zrzeszenie w związku'>
      <InfoContainer className='w-full'>
        {form.union.IsInUnion ? (
          <>
            <InfoContainerItem
              header='Związek'
              content={
                !editEnable ? (
                  form.union?.Name
                ) : (
                  <StyledTableInput
                    type='text'
                    value={form.union?.Name}
                    onChange={e =>
                      setForm(prev => ({
                        ...prev,
                        union: { ...prev.union, Name: e.target.value },
                      }))
                    }
                  />
                )
              }
            />
            <InfoContainerItem
              header='Numer'
              content={
                !editEnable ? (
                  form.union?.Number
                ) : (
                  <StyledTableInput
                    type='text'
                    value={form.union?.Number}
                    onChange={e =>
                      setForm(prev => ({
                        ...prev,
                        union: { ...prev.union, Number: e.target.value },
                      }))
                    }
                  />
                )
              }
            />
            <InfoContainerItem
              header='Data wpisu'
              content={
                !editEnable ? (
                  form.union?.EntryDate ? (
                    getFormatedDate(form.union.EntryDate, 'date')
                  ) : (
                    '-'
                  )
                ) : (
                  <StyledTableInput
                    type='date'
                    value={adjustDateStringToInput(
                      JSON.stringify(form.union?.EntryDate)
                    )}
                    onChange={e =>
                      setForm(prev => ({
                        ...prev,
                        union: {
                          ...prev.union,
                          EntryDate: new Date(e.target.value),
                        },
                      }))
                    }
                  />
                )
              }
            />
          </>
        ) : (
          <Button
            variant={ButtonVariant.Submit}
            onClick={() =>
              setForm(prev => ({
                ...prev,
                union: { ...prev.union, IsInUnion: true },
              }))
            }>
            Dodaj związek
          </Button>
        )}
      </InfoContainer>
    </HeaderContainer>
  );
};

export default AcademyUnion;
