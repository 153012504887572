import HeaderContainer from 'components/common/Containers/HeaderContainer';
import { TextArea } from 'styles/styles';
import { usePrimaryDataFormContext } from '../usePrimaryDataFormContext';

const AcademyDescription = () => {
  const { editEnable, form, setForm } = usePrimaryDataFormContext();

  return (
    <HeaderContainer title='Opis' withContainer>
      <div style={{height: '355px'}}>
      {!editEnable ? (
        form.primaryData.Description
      ) : (
          <TextArea
          name='academyDescription'
          cols={30}
          rows={10}
          value={form.primaryData.Description}
          onChange={e =>
            setForm(prev => ({
              ...prev,
              primaryData: { ...prev.primaryData, Description: e.target.value },
            }))
          }
        />
      )}
      </div>
    </HeaderContainer>
  );
};

export default AcademyDescription;
