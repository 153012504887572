import HeaderContainer from 'components/common/Containers/HeaderContainer';
import InfoContainerItem from 'components/common/Containers/InfoContainerItem';
import { InfoContainer } from 'styles/styles';
import { IAcademyInformationsProps } from '../informations.interface';
import { IAcademyContact } from 'types/academy.interface';

interface IAcademyContactProps extends IAcademyInformationsProps {
  contact: IAcademyContact;
}

const AcademyContact = ({ contact, header }: IAcademyContactProps) => {
  return (
  <HeaderContainer title={header ?? 'Kontakt'}>
      <InfoContainer className='w-full'>
        <InfoContainerItem header='Imię i nazwisko' content={contact.Name} />
        <InfoContainerItem header='Telefon' content={contact.PhoneNumber} />
        <InfoContainerItem header='Email' content={contact.Email} />
      </InfoContainer>
    </HeaderContainer>
  );
};

export default AcademyContact;
