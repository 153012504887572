import { LegacyRef, ReactNode } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import TableHeader from "./DataTableHeader";
import TableRow from "./DataTableRow";

import "overlayscrollbars/css/OverlayScrollbars.css";
import AttendancesDataTableHeader from "./AttendancesDataTableHeader";
import AttendancesDataTableRow from "./AttendancesDataTableRow";

export interface IDataTableRows {
  data?: { link?: string };
  cols: Array<ReactNode>;
  clickedIndex: any;
  setClickedIndex: any;
}

interface ITableProps {
  colSizes?: Array<number>;
  containerRef?: LegacyRef<HTMLTableSectionElement> | any;
  headers: Array<ReactNode>;
  lastItemRef?: LegacyRef<HTMLTableSectionElement> | any;
  rows: Array<any>;
  isDataLoading?: boolean;
  emptyListMessage?: string;
  clickedIndex: any;
  setClickedIndex: any;
  selectedPlayerId: any;
  setSelectedPlayerId: any;
  selectedParam: any;
  setSelectedParam: any;
  selectedParamValueName: any;
  setSelectedParamValueName: any;
  selectedTableParam: any;
  setSelectedTableParam: any;
}

const AttendancesDataTable: React.FC<ITableProps> = ({
  colSizes = [],
  headers,
  rows,
  containerRef,
  isDataLoading = false,
  lastItemRef,
  emptyListMessage,
  clickedIndex,
  setClickedIndex,
  selectedPlayerId,
  setSelectedPlayerId,
  selectedParam,
  setSelectedParam,
  selectedParamValueName,
  setSelectedParamValueName,
  selectedTableParam,
  setSelectedTableParam,
}) => {
  return (
    <OverlayScrollbarsComponent
      className="pb-12 pr-4"
      style={{ width: "100%" }}
    >
      <table
        className="table"
        cellSpacing={12}
        // cellPadding={10}
        style={{ minWidth: "800px" }}
      >
        <colgroup>
          {colSizes.map((size, idx) => (
            <col key={idx} style={{ width: size + "%" }} />
          ))}
        </colgroup>

        <AttendancesDataTableHeader
          headers={headers}
          clickedIndex={clickedIndex}
          setClickedIndex={setClickedIndex}
          selectedParam={selectedParam}
          setSelectedParam={setSelectedParam}
          selectedTableParam={selectedTableParam}
          setSelectedTableParam={setSelectedTableParam}
          selectedParamValueName={selectedParamValueName}
          setSelectedParamValueName={setSelectedParamValueName}
        />
        <tbody ref={containerRef}>
          {!isDataLoading &&
            rows.map((row, index) => {
              const isLast = index === rows.length - 1;
              return isLast ? (
                <AttendancesDataTableRow
                  key={index}
                  row={row}
                  innerRef={lastItemRef}
                  clickedIndex={clickedIndex}
                  setClickedIndex={setClickedIndex}
                  isLast={true}
                  selectedPlayerId={selectedPlayerId}
                  setSelectedPlayerId={setSelectedPlayerId}
                  selectedParam={selectedParam}
                  setSelectedParam={setSelectedParam}
                  selectedParamValueName={selectedParamValueName}
                  setSelectedParamValueName={setSelectedParamValueName}
                />
              ) : (
                <AttendancesDataTableRow
                  key={index}
                  row={row}
                  clickedIndex={clickedIndex}
                  setClickedIndex={setClickedIndex}
                  isLast={false}
                  selectedPlayerId={selectedPlayerId}
                  setSelectedPlayerId={setSelectedPlayerId}
                  selectedParam={selectedParam}
                  setSelectedParam={setSelectedParam}
                  selectedParamValueName={selectedParamValueName}
                  setSelectedParamValueName={setSelectedParamValueName}
                />
              );
            })}
        </tbody>
      </table>
      {isDataLoading && (
        <div className="flex w-full justify-center items-center text-center text-white opacity-70 h-12 font-light bg-white bg-opacity-5 ">
          Wczytuję dane...
        </div>
      )}
      {!isDataLoading && !rows.length && (
        <div className="flex w-full justify-center items-center text-center text-white opacity-70 h-12 font-medium bg-white bg-opacity-5 uppercase ">
          {emptyListMessage ?? "brak danych"}
        </div>
      )}
    </OverlayScrollbarsComponent>
  );
};

export default AttendancesDataTable;
