export const SET_RELATION_REQUEST_ID = 'SET_RELATION_REQUEST_ID';

export const setRelationRequestId = (relationRequestIds: any[]) => ({
  type: SET_RELATION_REQUEST_ID,
  payload: relationRequestIds,
});

interface SetRelationRequestIdAction {
  type: typeof SET_RELATION_REQUEST_ID;
  payload: any[];
}

export type TrainerReqIdActionTypes = SetRelationRequestIdAction;
