import React, { useEffect } from 'react';
import { GridColumn, GridTextItem } from 'styles/styles';
import {
  IPlayerHistoryPass,
  PASS_TRANSACTION_STATUS,
} from 'types/transfer/pass.interface';
import { getFormatedDate } from 'utils/dateUtils';

interface IAppliedPassesHistoryTableProps {
  passesHistory: IPlayerHistoryPass[];
}

const AppliedPassesHistoryTable = ({
  passesHistory,
}: IAppliedPassesHistoryTableProps) => {

  const sortByUserByDate = (passesHistory: IPlayerHistoryPass[]) => {
    return passesHistory.sort((a, b) => {
      const startAtA = a.Period.StartAt ? new Date(a.Period.StartAt) : null;
      const startAtB = b.Period.StartAt ? new Date(b.Period.StartAt) : null;
      const endAtA = a.Period.EndAt ? new Date(a.Period.EndAt) : null;
      const endAtB = b.Period.EndAt ? new Date(b.Period.EndAt) : null;

      if (startAtA && startAtB) {
        if (startAtA.getTime() > startAtB.getTime()) return -1;
        if (startAtA.getTime() < startAtB.getTime()) return 1;
      }

      if (endAtA && endAtB) {
        if (endAtA.getTime() < endAtB.getTime()) return -1;
        if (endAtA.getTime() > endAtB.getTime()) return 1;
      }

      return 0;
    });
  };

  const sortedPasses = sortByUserByDate(passesHistory);

  return (
    <GridColumn cols={6} >
      <GridTextItem isHeader>Nazwa</GridTextItem>
      <GridTextItem isHeader>Od</GridTextItem>
      <GridTextItem isHeader>Do</GridTextItem>
      <GridTextItem isHeader>Cena</GridTextItem>
      <GridTextItem isHeader>Zakupiono</GridTextItem>
      <GridTextItem isHeader>Status</GridTextItem>
      {sortedPasses.length ? sortedPasses.map((history, idx) => (
        <React.Fragment key={idx}>
          <GridTextItem>{history.Pass.Name}</GridTextItem>
          <GridTextItem>
            {history.Period.StartAt
              ? getFormatedDate(history.Period.StartAt, 'date')
              : '-'}
          </GridTextItem>
          <GridTextItem>
            {history.Period.EndAt
              ? getFormatedDate(history.Period.EndAt, 'date')
              : '-'}
          </GridTextItem>
          <GridTextItem>{history.Price.Gross} zł</GridTextItem>
            <GridTextItem>
            {new Date(history.PaidAt).getTime() <= 0
              ? '-'
              : getFormatedDate(history.PaidAt, 'date')}
          </GridTextItem>
          <GridTextItem>{PASS_TRANSACTION_STATUS[history.Status]}</GridTextItem>
        </React.Fragment>
      )): (<div>brak danych</div>)}
    </GridColumn>
  );
};

export default AppliedPassesHistoryTable;
