import DropdownPanel from 'components/common/panels/DropdownPanel';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useProductDetailTabsContext } from '../../hook/useProductDetailTabsContext';
import { IProductPhoto } from 'types/marketpleace/product.interface';
import ImageInput from 'components/common/inputs/imageInput/ImageInput';
import ProductImageInput from 'components/common/inputs/imageInput/ProductImageInput';
import systemServices from 'services/systemServices';
import { productServices } from 'services/marketpleace';
import { toast } from 'react-toastify';
import { showServerErrors } from 'utils/errorsUtils';

const ProductImages = () => {
  const { data: product, refresh } = useProductDetailTabsContext();

  const [addedImageBase64, setAddedImageBase64] = useState('');
  const [removedImageId, setRemovedImageId] = useState('');
  const [base64, setBase64] = useState('');

  const handleSubmit = async () => {
    if (addedImageBase64) {
      try {
        const imageUrl = await systemServices.uploadFile(base64);
        await productServices.addPhotoFiles(product.ProductId, [imageUrl]);
        refresh();
        toast.success('Dodano zdjęcie!');
      } catch (errors: any) {
        showServerErrors(errors);
      }
    }
  };

  const removeImage = async () => {
    if (removedImageId) {
      try {
        await productServices.removePhotoFile(
          product.ProductId,
          removedImageId
        );
        refresh();
        toast.success('Usunięto zdjęcie!');
      } catch (errors: any) {
        showServerErrors(errors);
      }
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [addedImageBase64]);

  useEffect(() => {
    removeImage();
  }, [removedImageId]);

  const photoForm = useFormik<{
    photos: IProductPhoto[];
    removed: any;
    added: any;
  }>({
    initialValues: {
      photos: product.Photos,
      removed: [],
      added: [],
    },
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    console.log(photoForm.values.photos);
  }, [photoForm]);

  return (
    <DropdownPanel
      label='Zdjęcia'
      hasChanged={photoForm.dirty}
      onSubmit={photoForm.handleSubmit}>
      <div className='py-30 px-18 gap-8 flex flex-wrap justify-center xl:justify-start '>
        {photoForm.values.photos.map((photo: IProductPhoto, idx: number) => (
          <ImageInput
            key={photoForm.values.photos[idx].ProductPhotoFileId}
            disabled={true}
            onChange={e => {
              setRemovedImageId(photo.ProductPhotoFileId);
            }}
            name={photoForm.values.photos[idx].ProductPhotoFileId}
            imgSrc={photoForm.values.photos[idx].FilePath}
            withRemoveIcon={photo.FilePath !== product.BannerPhotoFilePath}
            // @ts-ignore
            setBase64={setBase64}
          />
        ))}

        <ProductImageInput
          key={''}
          disabled={false}
          onChange={e => {
            const { value } = e.target;
            setAddedImageBase64(value.base64String);
          }}
          name={''}
          imgSrc={''}
          withRemoveIcon={false}
          // @ts-ignore
          setBase64={setBase64}
        />
      </div>
    </DropdownPanel>
  );
};

export default ProductImages;
