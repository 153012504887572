import React, { useState } from "react";
import cs from "classnames";
import { IInputProps } from "./inputTypes";
import InputBlockOverlay from "./InputOverlays/InputBlockOverlay";
import ErrorMessage from "./ErrorMessage";
import InputOutline from "./InputOutline";
import styled from "styled-components";

interface IInputWrapperProps extends IInputProps {
  render: React.FC<IInputProps>;
}

const InputLogin: React.FC<IInputWrapperProps> = ({
  children,
  error,
  helperText = "",
  isBlocked = false,
  isError,
  label,
  showErrors = true,
  wrapperClassName = "",
  render,
  ...props
}) => {
  const { className = "", name, style, touched = true, value, type } = props;
  const [isFocused, setIsFocused] = useState(false);
  const showErrorMessage = Boolean(touched && error);

  const inputContainerClasses = cs("formControl", {
    "formControl--hasValue": !!value,
    "formControl--hasError": error && touched,
    [`formControl--${type}`]: type,
    [className]: className,
  });

  return (
    <Container
      isFocused={isFocused}
      style={{ ...style }}
      className={`relative flex-1 md:flex-none ${wrapperClassName}`}
    >
      <div
        className={inputContainerClasses}
        // style={{ background: "rgba(2,2,15,0.6)", color: "white" }}
        style={{
          background: "rgba(0,0,0,0.1)",
          color: "white",
          minHeight: "48px",
          fontSize: "15px",
          fontWeight: 500,
          letterSpacing: "0.15px",
          opacity: 1,
        }}
      >
        <InputBlockOverlay show={isBlocked} />
        <InputOutline label={label} name={name} />
        {/* {render({ ...props, id: name })} */}
        <input
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          style={{ textAlign: "right" }}
          placeholder="Uzupełnij"
          {...props}
          id={name}
          autoComplete="off"
        />
      </div>

      {showErrors && isError && (
        <ErrorMessage
          helperText={helperText}
          message={error}
          show={showErrorMessage}
        />
      )}
    </Container>
  );
};

export default InputLogin;

const Container = styled.div<{ isFocused: any }>`
  width: 280px;

  & > div:nth-of-type(1) {
    /* background: ${(props) =>
      props.isFocused
        ? "rgba(0,145,255,0.3) !important"
        : "rgba(163,158,157,0.11) !important"}; */
    background: rgba(163, 158, 157, 0.11) !important;

    &:focus-within {
      background: rgba(0, 145, 255, 0.3) !important;
      border: 1px solid #0091ff !important;

      & > input::placeholder {
        /* background: purple !important; */
        color: rgba(255, 255, 255, 1);
      }

      & > div:nth-of-type(2) {
        background: yellow;

        & > div:nth-of-type(2) {
          & > label {
            color: #0091ff !important;
          }
        }
      }
    }

    & > input::placeholder {
      /* background: purple !important; */
      color: rgba(255, 255, 255, 0.5);
    }

    & > div:nth-of-type(2) {
      background: yellow;

      & > div:nth-of-type(2) {
        & > label {
          color: #f8f8f8 !important;
        }
      }
    }
  }
`;
