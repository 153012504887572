import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  IDigitalRelease,
  Product,
  IAddProduct,
  DigitalReleaseRequest,
} from "types/digitalReleaseTypes";

import { showServerErrors } from "utils/errorsUtils";

import { useTranslation } from "react-i18next";
import EditDigitalReleaseForm from "./EditDigitalReleaseForm";
import eventsServices from "services/eventsServices";
import { editUser } from "services/usersServices";

const EditDigitalRelease: React.FC<any> = ({ user }) => {
  const [product, setProduct] = useState<IAddProduct | null>(null);
  const { goBack } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const handleSubmit = async (digitalReleaseData: any, UserId: any) => {
    try {
      const newUser = digitalReleaseData;

      await editUser(newUser, UserId);
      toast.success("Edytowano użytkownika!");
      goBack();
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  return (
    user && (
      <>
        <EditDigitalReleaseForm
          user={user}
          onSubmit={handleSubmit}
          isEdit={true}
        />
      </>
    )
  );
};

export default EditDigitalRelease;
