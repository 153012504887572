import React, { ChangeEvent, useEffect, useState } from 'react';
import { StyledSelect } from '../../styles';
import { GridColumn } from 'styles/styles';
import { useProductDetailTabsContext } from '../../hook/useProductDetailTabsContext';
import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import { useHistory } from 'react-router-dom';
import { ICombination } from 'types/marketpleace/combination.interface';
import { IDiscountTemplate } from 'types/marketpleace/discount.interface';
import { showServerErrors } from 'utils/errorsUtils';
import discountServices from 'services/marketpleace/discountServices';
import HeaderContainer from 'components/common/Containers/HeaderContainer';
import ColumnTableRow from 'components/common/FormTable/ColumnTableRow';

interface IApplyDiscountProps {
  discountTemplates: IDiscountTemplate[];
  refreshDiscountTemplates: () => Promise<void>;
}

const ApplyDiscount = ({
  discountTemplates,
  refreshDiscountTemplates,
}: IApplyDiscountProps) => {
  const { push } = useHistory();

  const { data: product, refresh } = useProductDetailTabsContext();

  const [combination, setCombination] = useState<ICombination | null>(null);

  const [discount, setDiscount] = useState<IDiscountTemplate | null>(null);

  const handleCombinationChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCombination(
      product.Combinations.find(c => c.CombinationId === e.target.value) ?? null
    );

    setDiscount(null);
  };

  const applyDiscountToCombination = async (
    combinationId: string,
    discountId: string
  ) => {
    try {
      await discountServices.apply(
        product.ProductId,
        combinationId,
        discountId
      );

      refresh();
      setDiscount(null);
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  const discardTemplateFromCombination = async (id: string) => {
    try {
      await discountServices.discard(product.ProductId, id);

      refresh();
      setDiscount(null);
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  useEffect(() => {
    setCombination(
      prev =>
        product.Combinations.find(
          c => c.CombinationId === prev?.CombinationId
        ) ?? null
    );
  }, [product]);

  useEffect(() => {
    setDiscount(null);
  }, [discountTemplates]);

  return (
    <HeaderContainer title='przypisz promocję'>
      <GridColumn cols={2}>
        <ColumnTableRow header='kombinacje'>
          {!!product.Combinations.length ? (
            <StyledSelect
              value={combination?.CombinationId ?? ''}
              onChange={handleCombinationChange}>
              <option disabled value=''>
                Wybierz kombinację
              </option>
              {product.Combinations.map(combination => (
                <option value={combination.CombinationId}>
                  {combination.Values.map((value, index, array) =>
                    index === array.length - 1
                      ? `${value.ValueName}`
                      : `${value.ValueName}, `
                  )}
                </option>
              ))}
            </StyledSelect>
          ) : (
            <Button
              onClick={() => push(`${product.ProductId}?tab=attributes`)}
              variant={ButtonVariant.Submit}
              style={{ height: '100%', width: '100%' }}>
              Dodaj kombinację
            </Button>
          )}
        </ColumnTableRow>
        {!!combination &&
          (!combination.Discount ? (
            <>
              <ColumnTableRow header='Cena netto'>
                {`${combination.Netto.toFixed(2)} Zł`}
              </ColumnTableRow>
              <ColumnTableRow header='Podatek'>
                {`${combination.Tax} %`}
              </ColumnTableRow>
              <ColumnTableRow header='Cena brutto'>
                {`${combination.Gross.toFixed(2)} Zł`}
              </ColumnTableRow>
              <ColumnTableRow header='Promocja'>
                <StyledSelect
                  value={discount?.DiscountTemplateId ?? ''}
                  onChange={e =>
                    setDiscount(
                      discountTemplates.find(
                        d => d.DiscountTemplateId === e.target.value
                      ) ?? null
                    )
                  }>
                  <option disabled value=''>
                    Wybierz promocję
                  </option>
                  {!!discountTemplates.length &&
                    discountTemplates.map(discount => (
                      <option value={discount.DiscountTemplateId}>
                        {discount.Name}
                      </option>
                    ))}
                </StyledSelect>
              </ColumnTableRow>
              <ColumnTableRow header='Operacja'>
                <Button
                  onClick={() =>
                    !!discount &&
                    applyDiscountToCombination(
                      combination.CombinationId,
                      discount.DiscountTemplateId
                    )
                  }
                  disabled={!!!discount}
                  variant={ButtonVariant.Submit}
                  style={{ height: '100%', width: '100%' }}>
                  Przypisz
                </Button>
              </ColumnTableRow>
            </>
          ) : (
            <>
              <ColumnTableRow header='Cena netto'>
                {`${combination.Discount.Netto.toFixed(2)} Zł`}
              </ColumnTableRow>
              <ColumnTableRow header='Podatek'>
                {`${combination.Discount.Tax} %`}
              </ColumnTableRow>
              <ColumnTableRow header='Cena brutto'>
                {`${combination.Discount.Gross.toFixed(2)} Zł`}
              </ColumnTableRow>
              <ColumnTableRow header='Przecena'>
                {`${combination.Discount.Percent} %`}
              </ColumnTableRow>
              <ColumnTableRow header='Operacja'>
                <Button
                  variant={ButtonVariant.Abort}
                  onClick={() =>
                    discardTemplateFromCombination(combination.CombinationId)
                  }
                  style={{ height: '100%', width: '100%' }}>
                  Usuń promocje
                </Button>
              </ColumnTableRow>
            </>
          ))}
      </GridColumn>
    </HeaderContainer>
  );
};

export default ApplyDiscount;
