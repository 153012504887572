import TabContent from 'components/common/tabs/TabContent';
import React, { useContext, useEffect, useState } from 'react';
import { productServices } from 'services/marketpleace';
import { IProductStatstics } from 'types/marketpleace/product.interface';
import { useProductDetailTabsContext } from '../../hook/useProductDetailTabsContext';
import SalesStatement from './SalesStatement';
import AttributesStatement from './AttributesStatement';
import ProductOrders from './ProductOrders';
import StatisticsTopbar from './StatisticsTopbar';
import { useDateInputRange } from 'hooks/useDateInputRange';
import { isValidDateRange } from 'utils/dateUtils';
import { IOrderDetailStatstics } from 'types/marketpleace/order.interface';

const ProductStatisticts = () => {
  const { data: product, refresh } = useProductDetailTabsContext();

  const [statistics, setStatistics] = useState<IProductStatstics | null>(null);

  const [minDate, setMinDate] = useState<Date | undefined>(undefined);

  const dateRange = useDateInputRange({
    minDate: minDate,
    maxDate: new Date(),
  });

  const getStatistics = async () => {
    const stats = await productServices.getStatistics(
      product.ProductId,
      new Date(dateRange.start.value),
      new Date(dateRange.end.value)
    );

    setStatistics(stats);
  };

  useEffect(() => {
    getStatistics();
  }, [product, dateRange.start.value, dateRange.end.value]);

  useEffect(() => {
    if (statistics) {
      const oldestStats =
        !!statistics.OrderDetails.length &&
        statistics.OrderDetails.reduce((prev, curr) =>
          prev.Created < curr.Created ? prev : curr
        );

      setMinDate(oldestStats ? oldestStats.Created : undefined);
    }
  }, [statistics]);

  return (
    <TabContent id='raport'>
      <div className='flex flex-col gap-4 p-24 '>
        <StatisticsTopbar dateRange={dateRange} />
        <SalesStatement statistics={statistics} />
        <AttributesStatement
          attributesSoldDetail={statistics?.AttributeSoldDetails}
        />
        <ProductOrders productOrders={statistics?.OrderDetails} />
      </div>
    </TabContent>
  );
};

export default ProductStatisticts;
