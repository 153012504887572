import { useContext } from 'react';
import { ProductDetailTabsContext } from '../ProductDetail';

export const useProductDetailTabsContext = () => {
  const context = useContext(ProductDetailTabsContext);

  if (!context.data) {
    throw new Error(
      'Product detail tab context cannot be use outside ProductDetailTabs'
    );
  }

  return context;
};
