import React, { ChangeEvent } from 'react';
import { SearchBox, SearchInput } from 'styles/styles';
import HomeSearchIco from '../../../assets/icons/home-search-ico.png';
import { ISortingProps } from 'types/sortType';
import { ORDER_SORT_LIST } from 'types/marketpleace/order.interface';
import NewSelectStatystics from 'components/common/inputs/select/NewSelectStatystics';

interface IOrdersTopbar extends ISortingProps<ORDER_SORT_LIST> {
  handleQueryChange: (value: string) => void;
}

const OrdersTopbar = ({
  handleQueryChange,
  defaultSort,
  setSortBy,
  sortBy,
  sorts,
}: IOrdersTopbar) => {
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    handleQueryChange(e.target.value);
  };

  return (
    <div
      className='flex flex-wrap items-center justify-end gap-y-2'
      style={{ gap: '3px' }}>
      {sorts?.length ? (
        <NewSelectStatystics<ORDER_SORT_LIST>
          name='Sort'
          items={sorts}
          label='Sortowanie'
          selectedItem={sortBy}
          setSelectedItem={setSortBy}
          defaultValue={defaultSort}
        />
      ) : null}
      <SearchBox>
        <SearchInput
          className='w-full md:w-36 xl:w-72'
          placeholder={'Szukaj...'}
          autoComplete='off'
          name='search'
          onChange={handleSearch}
        />
        <div>
          <img src={HomeSearchIco} alt='ico' />
        </div>
      </SearchBox>
    </div>
  );
};

export default OrdersTopbar;
