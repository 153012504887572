import { Dispatch } from "redux";
import { useHistory } from "react-router";
import authService from "../../services/authServices";
import { getCurrentUser } from "./currentUser";
import { getProfiles, getUserPermissions } from "./permissions";

import { ActionType, IAction } from "./actionTypes";
import { RootState } from "../store";
import { Values } from "../../components/auth/LoginForm";

import { getErrorsArray } from "../../utils/errorsUtils";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import { refreshToken } from "../../services/authServices";

const signInSuccess = (): IAction => ({
  type: ActionType.SIGN_IN_SUCCESS,
});

const signInFailure = (): IAction => ({
  type: ActionType.SIGN_IN_FAILURE,
});

const signOutSuccess = (): IAction => ({
  type: ActionType.SIGN_OUT_SUCCESS,
});

const signOutFailure = (): IAction => ({
  type: ActionType.SIGN_OUT_FAILURE,
});

const destroySession = (): IAction => ({
  type: ActionType.DESTROY_SESSION,
});

const getInitData = () => async (dispatch: Dispatch<any>) => {
  // await dispatch(getCurrentUser());
  await dispatch(getUserPermissions());
  await dispatch(getProfiles());
};

// export const signIn = (auth: Values) => (dispatch: Dispatch<any>) =>
//   new Promise<string>(async (resolve, reject) => {
//     try {
//       const { push } = useHistory();
//       try {
//         const response = await authService.login(auth.Email, auth.Password);
//         authService.setToken(response.Token);

//         const decodedToken: any = jwtDecode(response.Token);
//         if (!decodedToken.IsTemporaryPasswordChanged) {
//           // Przekieruj użytkownika na /changepassword
//           push("/changepassword");
//           return;
//         }
//         dispatch(signInSuccess());
//         dispatch(getInitData());
//         authService.keepSession();

//         resolve("ok");
//       } catch (error: any) {
//         toast.error(error.Errors.Message); // Obsłuż ewentualne błędy logowania (opcjonalne)
//         dispatch(signInFailure());
//         toast.error("Niepoprawne dane logowania!");

//         const errorsArray = getErrorsArray(error);
//         reject(errorsArray);
//       }
//     } catch (error: any) {
//       console.error(error); // Obsłuż ewentualne błędy ogólne
//       reject(error);
//     }
//   });

export const signIn = (auth: Values) => async (dispatch: Dispatch<any>) => {
  try {
    const response = await authService.login(auth.Email, auth.Password);
    authService.setToken(response.Token);

    const decodedToken: any = jwtDecode(response.Token);

    if (decodedToken.IsTemporaryPasswordChanged === "False") {
      dispatch(signInFailure());
      window.location.href = "/changepassword";
      // return Promise.reject("Przekierowanie na /changepassword");
    }

    dispatch(signInSuccess());
    dispatch(getInitData());
    authService.keepSession();
    window.location.reload();
    return Promise.resolve("ok");
  } catch (error: any) {
    handleSignInError(dispatch, error);
    return Promise.reject(error);
  }
};

export const changePassword =
  (auth: any) => async (dispatch: Dispatch<any>) => {
    try {
      const resp = await authService.changePassword(
        auth.CurrentPassword,
        auth.NewPassword,
      );
      // authService.setToken(response.Token);

      // const decodedToken: any = jwtDecode(response.Token);

      // if (decodedToken.IsTemporaryPasswordChanged === "False") {
      //   dispatch(signInFailure());
      //   window.location.href = "/changepassword";
      //   // return Promise.reject("Przekierowanie na /changepassword");
      // }
      signOut();
      // dispatch(destroySession);
      // dispatch(signInSuccess());
      // dispatch(getInitData());
      // authService.keepSession();

      return resp;
    } catch (error: any) {
      handleSignInError(dispatch, error);
      return Promise.reject(error);
    }
  };

const handleSignInError = (dispatch: Dispatch<any>, error: any) => {
  toast.error(error.Errors.Message || "Niepoprawne dane logowania!");
  dispatch(signInFailure());
};

export const signOut =
  () => (dispatch: Dispatch<any>, getState: () => RootState) =>
    new Promise<string>(async (resolve, reject) => {
      try {
        await authService.logout("");
        authService.revokeToken();

        dispatch(signOutSuccess());
        dispatch(destroySession());
        resolve("ok");
      } catch (error: any) {
        dispatch(signOutFailure());
        console.error({ error });
        const errorsArray = getErrorsArray(error);
        reject(errorsArray);
      }
    });

export const authorize =
  () => (dispatch: Dispatch<any>, getState: () => RootState) => {
    const { isAuth } = getState().auth;
    const { token, milisecondsToExpire } = authService.getTokenInfoFromLocal();

    if (milisecondsToExpire > 0 && token) {
      if (!isAuth) {
        authService.setToken(token);
        authService.keepSession();
        dispatch(signInSuccess());
        dispatch(getInitData());
      }
    } else {
      dispatch(signInFailure());
    }
  };

export const remindPassword = (email: string) =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await authService.remindPassword(email);
      resolve(res);
    } catch (error: any) {
      //TODO: remove cond after implement
      if (error.Errors.UnhandledException) {
        reject(["Ok until sender is not implemeted"]);
      }

      console.error({ error });
      const errorsArray = getErrorsArray(error);
      reject(errorsArray);
    }
  });

export const resetPassword = (
  UserId: string,
  Token: string,
  NewPassword: string,
) =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await authService.resetPassword(UserId, Token, NewPassword);
      resolve(res);
    } catch (error: any) {
      //TODO: remove cond after implement
      if (error.Errors.UnhandledException) {
        reject(["Ok until sender is not implemeted"]);
      }

      console.error({ error });
      const errorsArray = getErrorsArray(error);
      reject(errorsArray);
    }
  });
