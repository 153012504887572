import React, { Dispatch, SetStateAction } from 'react';
import DeliveryForm, { DeliveryType } from './DeliveryForm';
import { deliveryInitialValues } from '../utils/helpers';
import { IAddDelivery } from 'types/marketpleace/delivery.interface';
import { toast } from 'react-toastify';
import systemServices from 'services/systemServices';
import { deliveryServices } from 'services/marketpleace';

interface IAddDeliveryProps {
  refresh: () => void;
  toggleAddMenuOpen: Dispatch<SetStateAction<boolean>>;
}

const AddDelivery = ({ refresh, toggleAddMenuOpen }: IAddDeliveryProps) => {
  const addPhysicalDelivery = async (
    values: DeliveryType,
    base64: string,
    PhotoFilePath?: string
  ) => {
    let delivery = values as IAddDelivery;
    try {
      const fileUrl = await systemServices.uploadFile(base64);

      delivery.PhotoFilePath = fileUrl;

      await deliveryServices.addPhysical(delivery);
      console.log('Add delivery object: ', delivery);
      toast.success('Dodano nową fizyczną dostawę');

      toggleAddMenuOpen(false);
      refresh();
    } catch (error) {
      console.error(error);
      toast.error('Wystąpił nieoczekiwany problem. Spróbuj ponownie później.');
    }
  };
  return (
    <DeliveryForm
      initialValues={deliveryInitialValues}
      handleSubmit={addPhysicalDelivery}
    />
  );
};

export default AddDelivery;
