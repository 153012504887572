import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import cs from 'classnames';
import DragOverlay from '../FileInput/DragOverlay';
import {
  convertImageToOptimizedBase64,
  getImageSize,
} from '../../../../utils/fileUtils';
import { useDragAndDrop } from '../../../../hooks/useDragAndDrop';
import { IInputProps } from '../inputTypes';
import placeholderImage from '../../../../assets/images/placeholder.svg';
import ErrorMessage from '../ErrorMessage';

interface IImageInputProps extends IInputProps {
  imgSrc?: string;
  placeholder?: string;
  showButtons?: boolean;
  withRemoveIcon?: boolean;
  base64: string;
  setBase64: Dispatch<SetStateAction<string>>;
  titleName: string;
}

const NewImageInput: React.FC<IImageInputProps> = ({
  className = '',
  disabled,
  error,
  imgSrc,
  name,
  placeholder,
  showButtons,
  touched,
  titleName,
  withRemoveIcon,
  onChange,
  setBase64,
  base64,
  ...props
}) => {
  const [previewSrc, setPreviewSrc] = useState('');
  const { dragStatus, ...inputHandlers } = useDragAndDrop();

  const labelRef = useRef<HTMLLabelElement>(null);

  const showError = Boolean(touched && error);

  const openFileDialog = () => {
    if (!labelRef?.current) return;
    labelRef.current.click();
  };

  const removeFile = () => {
    setPreviewSrc('');
    setBase64('');
    if (onChange) {
      onChange({
        target: {
          name,
          value: '',
        },
      });
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files, name } = e.target;

    if (files?.length) {
      try {
        const file = files[0];
        const { base64String } = await convertImageToOptimizedBase64(file);
        const imgPath = URL.createObjectURL(file);
        const sizes = await getImageSize(imgPath);
        setBase64(base64String);
        setPreviewSrc(imgPath);

        if (onChange) {
          onChange({
            target: {
              name,
              value: base64String,
            },
          });
        }
      } catch (error: any) {
        toast.error(`Image upload error: ${error}`);
      }
    }
  };

  useEffect(() => {
    if (!previewSrc && imgSrc) {
      setPreviewSrc(imgSrc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgSrc]);

  const classNames = cs('flex flex-col items-center justify-center relative', {
    [className]: className,
  });
  return (
    <div
      style={{
        width: '280px',
        height: '137px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        marginBottom: '10px',
      }}>
      <div
        style={{
          height: '100%',
          width: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {previewSrc || imgSrc ? (
          <img
            src={previewSrc}
            alt=''
            className='block object-cover'
            style={{ width: '100%', height: '100%' }}
            loading='lazy'
          />
        ) : (
          placeholder || (
            <img
              src={placeholderImage}
              alt=''
              className='block object-cover'
              style={{ width: '100%', height: '100%' }}
              loading='lazy'
            />
          )
        )}
      </div>

      <div
        style={{
          height: '100%',
          width: '50%',
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        }}>
       <div
          style={{
            background: "transparent",
            color: "rgba(163,158,157,1)",
            fontSize: "11px",
            fontWeight: 400,
            height: "30px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p>{titleName}</p>
        </div>

        <div
          className={classNames}
          style={{
            width: '100%',
            height: '48px',
            margin: '0',
            cursor: 'pointer',
          }}>
          <label
            htmlFor={name}
            ref={labelRef}
            // className=" relative overflow-hidden w-full h-full"
            className=' relative overflow-hidden w-full h-full'
            style={{
              background: 'rgba(0,160,255,1)',
              opacity: 1,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}>
            {/* <img src={photoIco} alt="" /> */}
            <p
              style={{
                fontSize: '15px',
                fontWeight: 'bold',
                color: 'white',
                cursor: 'pointer',
              }}>
              Wgraj plik
            </p>
            {!disabled && (
              <>
                <DragOverlay dragStatus={dragStatus} />
                <input
                  type='file'
                  name={name}
                  id={name}
                  style={{ cursor: 'pointer', pointerEvents: 'none' }}
                  className='absolute inset-0 opacity-0 cursor-pointer'
                  onChange={handleFileChange}
                  {...inputHandlers}
                />
              </>
            )}
          </label>
          {error && <ErrorMessage message={error} show={showError} />}
        </div>

        <button
          className=''
          type='button'
          onClick={removeFile}
          style={{
            width: '100%',
            height: '48px',
            background: 'rgba(255,146,38,1)',
            opacity: 1,
          }}>
          <p style={{ fontSize: '15px', fontWeight: 'bold', color: 'white' }}>
            Usuń
          </p>
        </button>
      </div>

      {/* {!disabled && showButtons && (
        <div className="flex gap-x-4">
          <Button onClick={openFileDialog} className=" mt-4 px-18 rounded-sm">
            {!imgSrc ? `Dodaj zdjęcie` : `Zmień zdjęcie`}
          </Button>
          {imgSrc && (
            <Button onClick={removeFile} className=" mt-4 px-18 rounded-sm">
              Usuń zdjęcie
            </Button>
          )}
        </div>
      )} */}
    </div>
  );
};

export default NewImageInput;
