import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { adjustDateStringToInput, isValidDateRange } from 'utils/dateUtils';

interface IUseDateRangeProps {
  minDate?: Date;
  maxDate?: Date;
  time?: boolean;
}

export interface IUseInputDateRange {
  start: IUseDateInputProps;
  end: IUseDateInputProps;
  resetDates: () => void;
  isStartAfterOrEqual: boolean;
}

interface IUseDateInputProps {
  value: string;
  min: string;
  max: string;
  onChange: (date: string) => void;
}

export const useDateInputRange = ({
  minDate,
  maxDate,
  time,
}: IUseDateRangeProps): IUseInputDateRange => {
  const [min, setMin] = useState(JSON.stringify(minDate) ?? '');
  const [max, setMax] = useState(JSON.stringify(maxDate) ?? '');

  const [startValue, setStartValue] = useState<string>(min);
  const [startMin, setStartMin] = useState<string>(min);
  const [startMax, setStartMax] = useState<string>(max);

  const [endValue, setEndValue] = useState<string>(max);
  const [endMin, setEndMin] = useState<string>(min);
  const [endMax, setEndMax] = useState<string>(max);

  const changeStart = (date: string) => {
    const isValid = isValidDateRange(date, endValue);

    if (!endValue.length || isValid) {
      setStartValue(date);
      setEndMin(date);
    }
    if (!!endValue.length && !isValid) {
      setStartValue(endValue);
    }
  };

  const changeEnd = (date: string) => {
    const isValid = isValidDateRange(startValue, date);

    if (!startValue.length || isValid) {
      setEndValue(date);
      setStartMax(date);
    }
    if (!!startValue.length && !isValid) {
      setEndValue(startValue);
    }
  };

  const resetDates = () => {
    setStartValue(min);
    setStartMin(min);
    setStartMax(max);

    setEndValue(max);
    setEndMin(min);
    setEndMax(max);
  };

  const isStartAfterOrEqual = !isValidDateRange(startValue, endValue);

  useEffect(() => {
    setStartValue(prev => adjustDateStringToInput(prev, time));
  }, [startValue]);

  useEffect(() => {
    setStartMin(prev => adjustDateStringToInput(prev, time));
  }, [startMin]);

  useEffect(() => {
    setStartMax(prev => adjustDateStringToInput(prev, time));
  }, [startMax]);

  useEffect(() => {
    setEndValue(prev => adjustDateStringToInput(prev, time));
  }, [endValue]);

  useEffect(() => {
    setEndMin(prev => adjustDateStringToInput(prev, time));
  }, [endMin]);

  useEffect(() => {
    setEndMax(prev => adjustDateStringToInput(prev, time));
  }, [endMax]);

  useEffect(() => {
    console.log(minDate);

    const minDateString = JSON.stringify(minDate);

    if (
      (!!minDate && !!min.length && isValidDateRange(minDateString, min)) ||
      (!!minDate && !min.length)
    ) {
      console.log('min changed');
      setMin(minDateString);
      if (isValidDateRange(startValue, minDateString) || !startValue.length) {
        console.log('start changed');
        setStartValue(minDateString);
        setStartMin(minDateString);
      }
    }
  }, [minDate]);

  useEffect(() => {
    if (!!maxDate && isValidDateRange(JSON.stringify(maxDate), max)) {
      setMax(JSON.stringify(maxDate));
    }
  }, [maxDate]);

  const start: IUseDateInputProps = {
    max: startMax,
    min: startMin,
    onChange: changeStart,
    value: startValue,
  };

  const end: IUseDateInputProps = {
    max: endMax,
    min: endMin,
    onChange: changeEnd,
    value: endValue,
  };

  return {
    start,
    end,
    resetDates,
    isStartAfterOrEqual,
  };
};
