import Navbar from "../navbar/Navbar";

import Bg from "../../assets/images/bg.svg";

type Props = {
  children: JSX.Element;
};

const Layout: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-grow flex-col md:flex-row relative z-0">
        {/* <div className='absolute -left-40 lg:left-0 h-screen opacity-30 overflow-hidden pointer-events-none -z-10'>
          <img className='w-full h-full' src={Bg} alt='bg' />
        </div> */}

        <div className="flex-shrink-0 w-full md:w-56">
          <Navbar />
        </div>

        <div
          className="flex flex-col flex-1  md:pl-0 min-w-0"
          style={{
            // backgroundImage:
            //   "linear-gradient(rgba(0, 0, 0, 0) 28%, rgba(0, 0, 0, 0) 28%, rgba(0, 145, 255,0.1) 100%)",
            backgroundImage:
              "linear-gradient(0deg,rgba(0, 0, 0, 0) 0%, rgba(0, 145, 255, 0.10) 100%)",
          }}
        >
          <div className="flex flex-1 shadow-sm flex-col  from-gray-ghost to-transparent relative">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
