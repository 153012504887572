import React, { useEffect, useState, useMemo } from 'react';
import DataTable from 'components/common/dataTable/DataTable';
import { getSortedAvailablePlayersByListSort } from 'middleware/player/getSortedOutsidePlayersByListSort';
import { ITableProps, LIST_SORT, ISelectOption } from 'types/globalTypes';
import { ISentTransfer, TRAINER_TYPE, LICENSE_TYPE } from 'types/transfer/transfer.interface';
import { IScannerPlayer } from 'types/scanner/scanner.interface';
import logo from 'assets/images/logo.svg';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { getAvailableTrainer } from 'services/transferServices';
import { IFilterPlayerValue } from 'types/filterPassData';

interface IRequestedPlayersTableProps {
  queryString: string;
  sortBy: ISelectOption<LIST_SORT> | null;
  appliedFilter?: IFilterPlayerValue;
}

function filterTransaction(data: any[], filters: any, query: string) {
  return data.filter((obj: any) => {
    const isLicenseTypeMatch = filters.LicenseType === '' ||  obj.LicenseType === filters.LicenseType;

    const isTypeMatch = filters.Type === '' ||  obj.Type === filters.Type;
    const distanceValue = filters.Distance === '' || obj.Distance <= filters.Distance;
    const searchTrainer = query ? obj.Name.toLowerCase().includes(query.toLowerCase().trim()) : true;
      return isLicenseTypeMatch && isTypeMatch && distanceValue && searchTrainer
  });
}

const ScannerTrainersTable = ({
  sortBy,
  queryString,
  appliedFilter
}: IRequestedPlayersTableProps) => {
  const [filteredTransactions, setFilteredTransactions] = useState<any[]>([]);
  const { items, loading, containerRef, lastItemRef, refresh } = useInfiniteScroll<ISentTransfer>(getAvailableTrainer, queryString);

  const trainers = useMemo(() => getSortedAvailablePlayersByListSort(items, sortBy?.value), [items, sortBy?.value]);

  useEffect(() => {
    if (appliedFilter !== undefined && appliedFilter !== null && (Object.keys(appliedFilter).length !== 0 || queryString)) {

      const filteredData = filterTransaction(trainers, appliedFilter, queryString);

      setFilteredTransactions(filteredData);

    } else {
      setFilteredTransactions(trainers);
    }

  }, [appliedFilter, queryString, trainers]);

  const rows = (filteredTransactions.length > 0 ? filteredTransactions : trainers).map((trainer: any) => ({
    data: { link: `/trainers/detail/${trainer.TrainerId}` },
    cols: [
      <div className='h-32 flex items-center justify-center p-8'>
        <img src={trainer.PhotoFilePath ? trainer.PhotoFilePath : logo} alt='' className='flex h-full w-auto ' />
      </div>,
      <div className='h-full flex items-center justify-center text-base'>
        {trainer.Name}
      </div>,
      <div className='h-full flex items-center justify-center text-base'>
        {TRAINER_TYPE[trainer.Type]}
      </div>,
      <div className='h-full flex items-center justify-center text-base'>
        {LICENSE_TYPE[trainer.LicenseType]}
      </div>,
      <div className='h-full flex items-center justify-center text-base'>
        {trainer.Distance.toFixed(1)} km
      </div>,
    ],
  }));

  return (
    <div>
      {filteredTransactions.length === 0 && !queryString.length && !loading ?
        (
        <div
          className="pl-8 text-base pt-8 flex w-full justify-center items-center text-center text-white opacity-70 h-12 font-medium bg-white bg-opacity-5 uppercase"
        >
          Brak trenerów
        </div>) : (
          <DataTable
          rows={rows}
          headers={['Zdjęcie', 'Imię i nazwisko', 'Funkcja', 'Typ licencji', 'Aktualna odległość']}
          isDataLoading={loading}
          containerRef={containerRef}
          lastItemRef={lastItemRef}
          colSizes={[20, 20, 20, 20, 20]}
          emptyListMessage='Brak trenerów'
          />
        )
      }
    </div>
  );
};

export default ScannerTrainersTable;
