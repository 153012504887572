import DropdownPanel from 'components/common/panels/DropdownPanel';
import ContentContainer from 'components/layout/ContentContainer';
import React, { useEffect, useState } from 'react';
import combinationServices from 'services/marketpleace/combinationServices';
import { IAttribute } from 'types/marketpleace/attribute.interface';

const Attributes = () => {
  const [attributes, setAttributes] = useState<IAttribute[]>([]);

  const getArrtibutes = async () => {
    const response = await combinationServices.getAttributes();

    setAttributes(response.Attributes);
  };

  useEffect(() => {
    getArrtibutes();
  }, []);

  return (
    <ContentContainer title='Atrybuty'>
      <div className='flex flex-col gap-4 p-24'>
        {attributes.length
          ? attributes.map(attribute => (
              <DropdownPanel
                key={attribute.AttributeId}
                canAdd={false}
                canEdit={false}
                label={attribute.Name}
                render={() => (
                  <div className='flex flex-col px-12 py-24 gap-4 opacity-70'>
                    {attribute.Values.map(value => (
                      <div className='flex items-center gap-3'>
                        <span className='flex h-1 w-1 bg-white rounded-full ' />
                        <p className='flex text-sm '>{value.Name}</p>
                      </div>
                    ))}
                  </div>
                )}
              />
            ))
          : null}
      </div>
    </ContentContainer>
  );
};

export default Attributes;
