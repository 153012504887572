import axios from "axios";

const createConfig = () => {
  const api = axios.create({
    baseURL: "https://api.academiq.pl",
  });

  const token = localStorage.getItem("token");

  api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  return api;
};

const api = createConfig();

export default api;
