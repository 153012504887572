import React from 'react';
import { GridColumn, GridTextItem } from 'styles/styles';
import { IOrderDocument } from 'types/marketpleace/order.interface';
import OrderDocumentsRow from './OrderDocumentsRow';
import orderService from 'services/marketpleace/orderServices';
import { toast } from 'react-toastify';
import { showServerErrors } from 'utils/errorsUtils';

interface IOrderDocumentsTableProps {
  orderId: string;
  refresh: () => Promise<void>;
  documents: IOrderDocument[];
}

const OrderDocumentsTable = ({
  documents,
  refresh,
  orderId,
}: IOrderDocumentsTableProps) => {
  return (
    <GridColumn cols={2}>
      <GridTextItem uppercase isHeader>
        Nazwa
      </GridTextItem>
      <GridTextItem uppercase isHeader>
        Akcja
      </GridTextItem>
      {documents.map((document, idx) => (
        <OrderDocumentsRow
          orderId={orderId}
          document={document}
          refresh={refresh}
          key={idx}
        />
      ))}
    </GridColumn>
  );
};

export default OrderDocumentsTable;
