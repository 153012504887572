import { useContext } from 'react';
import { AcademyTabsContext } from '../Academy';

export const useAcademyTabsContext = () => {
  const context = useContext(AcademyTabsContext);

  if (!context.data) {
    throw new Error(
      'Order detail tab context cannot be use outside OrderDetailTabs'
    );
  }

  return context;
};
