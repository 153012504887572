import AcademyAddress from './DataFields/AcademyAddress';
import AcademyRepresentative from './DataFields/AcademyRepresentative';
import AcademyContact from './DataFields/AcademyContact';
import AcademyCompanyData from './DataFields/AcademyCompanyData';
import { useAcademyTabsContext } from 'components/academy/hook/useAcademyTabsContext';

const AcademyMerchantData = () => {
  const { data: academy, refresh } = useAcademyTabsContext();
  return (
    <div className='grid w-full grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 auto-rows-max'>
      <AcademyCompanyData academy={academy} />
      <AcademyAddress address={academy.Address} />
      <AcademyAddress
        address={academy.Address}
        header='Adres korespondencyjny'
      />
      <AcademyRepresentative representing={academy.RepresentingPerson} />
      <AcademyContact contact={academy.Contact} />
      <AcademyContact
        contact={academy.TechnicalContact}
        header='Kontakt techniczny'
      />
    </div>
  );
};

export default AcademyMerchantData;
