import { ChangeEvent, useState } from "react";
import { ISortingProps } from "types/sortType";
import HomeSearchIco from "assets/icons/home-search-ico.png";
import { DataViewMode, LIST_SORT } from "types/globalTypes";
import { SearchBox, SearchInput } from "styles/styles";
import NewSelectStatystics from "components/common/inputs/select/NewSelectStatystics";

import styled from "styled-components";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import NewFilterPanel from "components/filters/NewFilterPanel";
import { enumToSelectOptions } from "utils/baseUtils";

import { LIST_FILTER } from "types/globalTypes";

import {
  PLAYER_STATE,
  TRANSFER_LEVEL,
  TRANSACTION_STATUS,
} from "types/transfer/player.interface";

const BlurBox = styled.div<{ isMenuOpen: boolean; isAddMenuOpen: boolean }>`
  position: absolute;
  display: ${(props) =>
    props.isMenuOpen || props.isAddMenuOpen ? "unset" : "none"};
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(3px);
  z-index: 99;
`;

const SideMenu = styled.div<{ isMenuOpen: boolean }>`
  position: absolute;
  height: calc(100vh - 48px);
  top: 48px;
  padding: ${(props) => (props.isMenuOpen ? "23px 24px" : "23px 0px")};
  right: 0;
  width: ${(props) => (props.isMenuOpen ? "350px" : "0px")};
  background: rgba(2, 2, 15, 1);
  transition: all 0.25s ease-in-out;
  color: rgba(163, 158, 157, 1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  & > * {
    opacity: ${(props) => (props.isMenuOpen ? "1" : "0")};
    transition: all 0.125s ease-in-out;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    & > p {
      text-align: center;
      font-size: 15px;
      font-weight: 400;
    }
  }
`;

const SideAddMenu = styled.div<{ isAddMenuOpen: boolean }>`
  position: absolute;
  height: calc(100vh - 48px);
  top: 48px;
  /* padding: ${(props) => (props.isAddMenuOpen ? "23px 44px" : "0")}; */
  padding: ${(props) => (props.isAddMenuOpen ? "23px 0" : "23px 0")};
  right: 0;
  width: ${(props) => (props.isAddMenuOpen ? "917px" : "0px")};
  background: rgba(2, 2, 15, 1);
  transition: all 0.4s ease-in-out;
  color: rgba(163, 158, 157, 1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  & > * {
    opacity: ${(props) => (props.isAddMenuOpen ? "1" : "0")};
    transition: all 0.2s ease-in-out;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    & > form {
      & > div:first-child {
        & > div {
          & > p {
            /* text-align: center; */
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
`;

type GroupName = { backend: number; pl: string };

interface IFilterValue<T> {
  backend: T;
  pl: string;
}

interface Filter {
  id: number;
  type: string;
  name: string;
  values: IFilterValue<any>[];
  pl: string;
}

interface IPlayersTopbarProps extends ISortingProps<LIST_SORT> {
  handleQueryChange: (value: string) => void;
  refresh: () => Promise<void>;
  getUniqueGroupName: GroupName[];
}

const getSelectOptionValues = (enums: any) =>
  enumToSelectOptions(enums).map(({ label, ...rest }) => ({
    backend: rest.value,
    pl: label,
  }));

const PlayersTopbar = ({
  defaultSort,
  setSortBy,
  sortBy,
  sorts,
  handleQueryChange,
  getUniqueGroupName,
}: IPlayersTopbarProps) => {
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    handleQueryChange(e.target.value);
  };

  const [isMenuOpen, toggleMenuOpen] = useState(false);
  const [isAddMenuOpen, toggleAddMenuOpen] = useState(false);

  const filters: Filter[] = [
    {
      id: 0,
      type: "select",
      name: "Level",
      values: getSelectOptionValues(TRANSFER_LEVEL),
      pl: "Relacja",
    },
    {
      id: 1,
      type: "select",
      name: "Status",
      values: getSelectOptionValues(TRANSACTION_STATUS),
      pl: "Składki",
    },

    {
      id: 2,
      type: "select",
      name: "GroupName",
      values: getUniqueGroupName,
      pl: "Grupa",
    },
    {
      id: 3,
      type: "select",
      name: "State",
      values: getSelectOptionValues(PLAYER_STATE),
      pl: "Status",
    },
  ];

  return (
    <div
      className="flex flex-wrap items-center justify-end gap-y-2"
      style={{ gap: "3px" }}
    >
      <BlurBox
        isMenuOpen={isMenuOpen}
        isAddMenuOpen={isAddMenuOpen}
        onClick={() => {
          toggleAddMenuOpen(false);
          toggleMenuOpen(false);
        }}
      />

      <SideMenu isMenuOpen={isMenuOpen}>
        <NewFilterPanel filters={filters} />
      </SideMenu>

      <Button
        className="px-24 xl:order-2"
        variant={ButtonVariant.Submit}
        style={{
          padding: "15px 41.4064px",
          background: "rgba(163,158,157,0.38)",
          width: "120px",
          fontSize: "15px",
          fontWeight: 400,
        }}
        onClick={() => {
          if (isMenuOpen) {
            toggleMenuOpen(false);
          } else if (!isMenuOpen && !isAddMenuOpen) {
            toggleMenuOpen(true);
          } else {
            toggleAddMenuOpen(false);
            setTimeout(() => {
              toggleMenuOpen(true);
            }, 400);
          }
        }}
      >
        Filtruj
      </Button>

      {sorts.length ? (
        <NewSelectStatystics<LIST_SORT>
          name="Sort"
          items={sorts}
          label="Sortowanie"
          selectedItem={sortBy}
          setSelectedItem={setSortBy}
          defaultValue={defaultSort}
        />
      ) : null}

      <SearchBox>
        <SearchInput
          className="w-full md:w-36 xl:w-72"
          placeholder="Szukaj..."
          autoComplete="off"
          name="search"
          onChange={handleSearch}
        />
        <div>
          <img src={HomeSearchIco} alt="ico" />
        </div>
      </SearchBox>
    </div>
  );
};

export default PlayersTopbar;
