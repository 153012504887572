import styled from "styled-components";
import arrow from "../../assets/icons/chat-arrow.svg";

export const Container = styled.div`
  max-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;

  /* flex-direction: column; */
`;

export const SideNav = styled.div`
  height: 100%;
  position: relative;
  /* min-width: 210px; */
  width: 210px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 5px;
  width: 412px;
  min-width: 280px;
  & > hr {
    border-top: 1px solid rgba(144, 144, 144, 0.5);
  }

  & > div:nth-of-type(4) {
    width: 100%;
    height: calc(100% - 48px - 48px - 48px);
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 13px;
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      width: 13px;
      background-color: #807e7e;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: #f1f1f1;
      display: none;
    }
  }
`;

export const MessagesBox = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  padding-left: 64px;
  padding-left: 52px;
  padding-right: 164px;
  padding-right: 5px;
  padding-bottom: 100px;
  padding-top: 7px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 13px;
  }
  &::-webkit-scrollbar-thumb {
    width: 13px;
    background-color: #807e7e;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    display: none;
  }
`;

export const VoiceBox = styled.div`
  width: calc(100%);
  min-height: 48px;
  height: 48px;
  display: flex;
  align-items: flex-end;
  /* padding: 0 7px 5px 9px; */

  & > div:first-of-type {
    width: 48px;
    height: 48px;
    background: rgba(163, 158, 157, 0.11);
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
      fill: white;
      stroke: white;
    }
  }

  & > div:last-of-type {
    width: calc(100% - 48px);
    height: 48px;
    background: rgba(163, 158, 157, 0.09);
  }
`;

export const MessagesBoxTopBar = styled.div`
  width: 100%;
  min-height: 53px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 7px 5px 9px; */
  gap: 7px;
  background: rgba(60, 119, 164, 0.32);

  & > div > div {
    & > img {
      height: 48px;
      width: 60px;
      border-radius: 0;
    }
  }
`;

export const MessagesBoxTopBarButtons = styled.div`
  display: flex;
  gap: 5px;
  height: 100%;
  align-items: center;
`;

export const Test = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  /* width: 100%; */

  /* padding-left: 20px; */
  height: 100%;
  font-weight: 400;
  font-size: 18px;
  color: rgb(248, 248, 248);
  letter-spacing: -0.126px;

  & > img {
    height: 48px;
    width: 48px;
    border-radius: 0;
  }

  & > p {
    font-weight: 400;
    font-size: 15px;
    color: rgb(248, 248, 248);

    letter-spacing: -0.126px;
    padding-left: 20px;
  }
`;

export const ButtonsBox = styled.div`
  position: relative;
  width: 100%;
  /* min-height: 48px; */
  height: auto;
  display: flex;
  align-items: flex-end;
  /* padding: 0 7px 5px 9px; */
  gap: 7px;
  background: rgba(60, 119, 164, 0.32);
  & > textarea {
    width: 100%;
    padding: 13px 20px;
    color: white;
    font-size: 15px;
    height: 48px;
    /* border-radius: 10px; */
    letter-spacing: 0.15px;
    font-weight: 500;

    background: rgba(163, 158, 157, 0.11);
    outline: none;
    max-height: 500px;
    overflow-y: hidden;
    resize: none;
    &::placeholder {
      opacity: 1;
      color: white;
    }
  }
`;

export const ContentBox = styled.div`
  background: rgba(2, 2, 15, 0.15);
  background: rgba(2, 2, 15, 1);
  /* background: blue; */
  width: calc(100% - 210px);
  height: calc(100% - 63px);
  display: flex;
  flex-direction: column;
  /* gap: 4px; */
  width: 865px;
  /* align-items: center; */
`;

export const SerachInputBox = styled.div`
  width: 100%;
  min-height: 48px;
  background: rgba(163, 158, 157, 0.11);
  padding: 4.5px 18px 4.5px 9.5px;
  display: flex;
  align-items: center;

  & > div:first-child {
    height: 100%;
    width: 25px;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  & > input {
    width: 100%;
    height: 100%;
    font-size: 15px;
    color: rgb(248, 248, 248);
    background: transparent;
    font-weight: 300;
    padding-left: 14.5px;
    letter-spacing: 0.15px;

    &::placeholder {
      color: rgb(163, 158, 157);
      opacity: 1;
    }
  }
`;

export const IcoBox = styled.div<{ isClicked: boolean }>`
  /* border-radius: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 48px;
  min-width: 48px;
  user-select: none;
  height: 48px;
  background-color: rgba(163, 158, 157, 0.11);
  border: ${(props) => (props.isClicked ? "1px solid #0091FF" : "none")};
`;

export const ReplyMessageBox = styled.div<{ position: string }>`
  /* height: 78px; */
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  margin-top: 25px;
  gap: 5px;
  justify-content: ${(props) =>
    props.position === "left" ? "flex-start" : "flex-end"};

  & > img {
    width: auto;
    cursor: pointer;
    height: 94px;
  }

  & > p {
    position: absolute;
    bottom: -20px;
    color: #0091ff;
    font-size: 14px;
    font-weight: 400;
  }

  & > div {
    position: relative;
    background: ${(props) =>
      props.position === "left"
        ? "rgba(163,158,157, 0.3)"
        : "rgba(0,145,255,0.3)"};
    height: 100%;
    min-width: 94px;
    max-width: 49%;
    /* overflow-wrap: break-word; */
    flex-wrap: wrap;
    padding: 12px 14px 9px 14px;
    color: #f8f8f8;
    /* border-radius: 10px; */
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.15px;
    white-space: pre-wrap;
    /* max-height: 300px; */

    & > div:nth-of-type(2) {
      cursor: pointer;
      height: 43.5px;
      width: 48px;
      background: #0091ff;
      position: absolute;
      top: 0;
      right: ${(props) => (props.position === "left" ? "-48px" : "unset")};
      left: ${(props) => (props.position === "right" ? "-48px" : "unset")};
      display: flex;
      justify-content: center;
      align-items: center;

      & > svg {
        width: 18px;
        height: 22px;
      }
    }
    & > div:nth-of-type(1) {
      cursor: pointer;
      height: 23.5px;
      min-width: 20px;
      width: 100%;
      max-width: 100%;
      background: rgba(163, 158, 157, 0.2);
      font-size: 9px;
      position: absolute;
      top: -23.5px;
      left: 0;
      display: flex;
      align-items: center;
      padding: 0 5px;

      & > svg {
        width: 18px;
        height: 22px;
      }
      & > p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    & > pre {
      font-family: "inter";
      overflow-wrap: break-word;
      white-space: pre-wrap;
      width: 100%;
    }
  }
`;

export const AnnouncementsBox = styled.div`
  /* height: 78px; */
  height: auto;
  width: calc(100% - 52px);
  display: flex;
  justify-content: center;
  /* background: red; */
  gap: 5px;
  border-bottom: 5px solid rgb(54, 54, 54);
  padding-top: 10px;
  padding-bottom: 15px;

  & > div {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 9px;
    /* padding-right: 52px; */

    & > p:nth-of-type(1) {
      font-size: 13px;
      color: #707070;
      font-weight: 400;
      text-transform: uppercase;
      text-align: center;
    }

    & > p:nth-of-type(2) {
      font-size: 15px;
      font-weight: 400;
      color: white;
    }
  }
`;

export const MessageAudioBox = styled.div<{ position: string }>`
  /* height: 78px; */
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  gap: 5px;
  justify-content: ${(props) =>
    props.position === "left" ? "flex-start" : "flex-end"};
`;

export const SenderNameBox = styled.div<{ position: string }>`
  position: absolute !important;
  right: unset !important;
  top: -15px !important;
  left: 0 !important;
  bottom: unset !important;
  color: white;
  background: transparent !important;
  padding: 0 !important;
  font-size: 10px !important;
  height: unset !important;
  min-height: unset !important;
`;

export const SenderImageBox = styled.div<{ position: string }>`
  position: absolute !important;
  right: unset !important;
  top: 0 !important;
  left: -43.5px !important;
  bottom: unset !important;
  color: white;
  background: transparent !important;
  padding: 0 !important;
  font-size: 10px !important;
  width: 28px !important;
  height: 28px !important;
  height: unset !important;
  width: unset !important;
  min-height: unset !important;
  min-width: unset !important;

  & > img {
    width: 43.5px;
    height: 43.5px;
    /* border-radius: 100%; */
  }
`;

export const MessageBox = styled.div<{ position: string }>`
  margin-top: 10px;
  audio {
    width: 600px;
    border-radius: 0px;
    border: none;
    background: #0091ff;
  }

  audio::-webkit-media-controls-play-button,
  audio::-webkit-media-controls-pause-button,
  audio::-webkit-media-controls-stop-button {
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 5px;
    height: 30px;
    margin: 0 10px;
    height: 100%;
    width: 100%;
  }

  audio::-webkit-media-controls-timeline {
    background-color: #0091ff;
    height: 10px;
    border-radius: 5px;
  }

  audio::-webkit-media-controls-timeline::-webkit-media-slider-thumb {
    background-color: #0091ff;
    border: none;
    border-radius: 5px;
    width: 10px;
    height: 10px;
  }

  /* height: 78px; */
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  gap: 5px;
  justify-content: ${(props) =>
    props.position === "left" ? "flex-start" : "flex-end"};

  & > img {
    width: auto;
    cursor: pointer;
    height: 94px;
  }

  & > p {
    position: absolute;
    bottom: -20px;
    color: #0091ff;
    font-size: 14px;
    font-weight: 400;
  }

  & > div {
    position: relative;
    background: ${(props) =>
      props.position === "left"
        ? "rgba(163,158,157, 0.3)"
        : "rgba(0,145,255,0.3)"};
    height: 100%;
    min-width: 94px;
    max-width: 49%;
    /* overflow-wrap: break-word; */
    flex-wrap: wrap;
    padding: 12px 14px 9px 14px;
    color: #f8f8f8;
    /* border-radius: 10px; */
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.15px;
    white-space: pre-wrap;
    /* max-height: 300px; */

    & > div {
      cursor: pointer;
      height: 43.5px;
      width: 48px;
      background: #0091ff;
      position: absolute;
      top: 0;
      right: ${(props) => (props.position === "left" ? "-48px" : "unset")};
      left: ${(props) => (props.position === "right" ? "-48px" : "unset")};
      display: flex;
      justify-content: center;
      align-items: center;

      & > svg {
        width: 18px;
        height: 22px;
      }
    }

    & > pre {
      font-family: "inter";
      overflow-wrap: break-word;
      white-space: pre-wrap;
      width: 100%;
    }
  }
`;

export const FilterSelect = styled.select`
  width: 100%;
  padding: 5px;
  height: 48px;
  outline: none;
  min-height: 48px;
  cursor: pointer;
  appearance: none;
  background-color: rgba(163, 158, 157, 0.11);
  text-align: right;
  padding-right: 40px;
  color: white;
  font-size: 14px;
  font-weight: 400;

  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-position: right 5px bottom;
  background-size: 30px 43px;

  &::-ms-expand {
    display: none;
    border: none;
  }

  & > * {
    border: none;
  }
  & > option {
    border: none;
    height: 50px;
    color: black;
  }
`;

export const ChatButton = styled.div`
  cursor: pointer;
  letter-spacing: 0.15px;
  font-weight: 500;
  font-size: 15px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  min-height: 48px;
  background-color: rgb(41, 42, 49);
`;

export const TopBar = styled.div`
  height: 60px;
  width: 100%;
  background: rgba(2, 2, 15, 1);
  display: flex;
  align-items: center;
  border-left: 5px solid rgb(163, 158, 157);

  & > div:nth-of-type(1) {
    width: 210px;
    padding-left: 20px;
    font-weight: 400;
    font-size: 18px;
    color: rgb(248, 248, 248);
    height: 100%;
    letter-spacing: -0.126px;
    display: flex;
    align-items: center;
  }

  & > div:nth-of-type(2) {
    align-items: center;
    justify-content: center;
    display: flex;
    /* padding-left: 20px; */
    height: 100%;
    font-weight: 400;
    font-size: 18px;
    color: rgb(248, 248, 248);
    letter-spacing: -0.126px;

    & > img {
      height: 100%;
      width: 60px;
      border-radius: 0;
    }

    & > p {
      font-weight: 400;
      font-size: 18px;
      color: rgb(248, 248, 248);

      letter-spacing: -0.126px;
      padding-left: 20px;
    }
  }
`;

export const InputWithSearchResultsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    font-size: 11px;
    color: white;
    width: 80px;
  }

  & > input {
    color: white;
    font-size: 11px;
    width: 100%;
    outline: none;
    background: transparent;
    border: none;
    color: white;
  }
`;

export const RightSectionAddressAndTitleWriteMode = styled.div`
  display: flex;
  width: 100%;
  /* background: rgba(60, 60, 60, 1); */
  height: 60px;
  /* padding: 5px 11.5px; */
  /* border-radius: 5px; */
  /* border: 1px solid rgba(112, 112, 112, 0.3); */
  align-items: center;
  & > p {
    font-size: 11px;
    color: white;
    font-weight: 500;
    width: 80px;
  }
  & > img {
    width: 48px;
    height: 48px;
    /* background: white; */
  }

  & > input {
    color: white;
    font-size: 15px;
    font-weight: 400;
    padding-left: 20px;
    width: 100%;
    outline: none;
    background: transparent;
    border: none;

    &::placeholder {
      color: white;
      opacity: 1;
    }
  }
`;
export const SingleSearchResultWrapper = styled.div`
  width: 100%;
  height: 20px;
  /* border-radius: 5px; */
  padding-left: 20px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  z-index: 5;
  cursor: pointer;

  & > p {
    font-size: 11px;
    color: white;
  }

  &:hover {
    /* background: #16265c; */
    background: #89bfff;
  }
`;

export const Drag = styled.div`
  /* background: rgba(144, 144, 144, 0.1); */
  background: none;
  /* border-top: 1px solid rgba(2, 2, 15, 0.5); */
  /* border-right: 1px solid rgba(2, 2, 15, 0.5); */
  /* border-bottom: 1px solid rgba(2, 2, 15, 0.5); */
  user-select: none;
  /* cursor: col-resize; */
  height: 100%;
  width: 2px;
  position: absolute;
  /* top: calc(50% - 100px); */
  top: 0;
  right: 0px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  /* user-drag: auto; */
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
`;
export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;

  & > img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;
  }
`;

export const SearchResultWrapper = styled.div<{
  isFocused: boolean;
  autoCompleted: boolean;
}>`
  width: 100%;
  position: absolute;
  background: rgba(2, 2, 15, 1);
  /* border-radius: 5px; */
  /* border: 1px solid #e1e1e1; */
  backdrop-filter: blur(3px);
  color: white;
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 51px;
  /* padding: 5px 0; */
  z-index: 5;
  visibility: ${({ autoCompleted, isFocused }) =>
    autoCompleted && isFocused ? "visible" : "hidden"};

  @media (max-width: 1045px) {
    width: 90%;
  }
`;
