import HeaderContainer from 'components/common/Containers/HeaderContainer';
import InfoContainerItem from 'components/common/Containers/InfoContainerItem';
import { InfoContainer } from 'styles/styles';
import { useAcademyTabsContext } from 'components/academy/hook/useAcademyTabsContext';
import { USER_TYPE } from 'types/userTypes';

const AcademyAdministratorContact = () => {
  const { data: academy } = useAcademyTabsContext();

  const academyAdministrator = academy.Users.find(
    user => user.Type === USER_TYPE['Administrator']
  );

  return (
    <HeaderContainer title={'Administrator systemu'}>
      <InfoContainer className='w-full'>
        <InfoContainerItem
          header='Imię i nazwisko'
          content={
            academyAdministrator &&
            `${academyAdministrator.FirstName} ${academyAdministrator.LastName}`
          }
        />
        <InfoContainerItem
          header='Telefon'
          content={academyAdministrator?.Contact.PhoneNumber}
        />
        <InfoContainerItem
          header='Email'
          content={academyAdministrator?.Contact.Email}
        />
        <div style={{height: '45px'}}></div>
      </InfoContainer>
    </HeaderContainer>
  );
};

export default AcademyAdministratorContact;
