import React from "react";
import cs from "classnames";
import { IInputProps } from "./inputTypes";
import InputBlockOverlay from "./InputOverlays/InputBlockOverlay";
import ErrorMessage from "./ErrorMessage";
import InputOutline from "./InputOutline";

interface IInputWrapperProps extends IInputProps {
  render: React.FC<IInputProps>;
}

const Input: React.FC<IInputWrapperProps> = ({
  children,
  error,
  helperText = "",
  isBlocked = false,
  isError,
  label,
  showErrors = true,
  wrapperClassName = "",
  render,
  ...props
}) => {
  const { className = "", name, style, touched = true, value, type } = props;

  const showErrorMessage = Boolean(touched && error);
  
  const inputContainerClasses = cs("formControl", {
    "formControl--hasValue": !!value,
    "formControl--hasError": error && touched,
    [`formControl--${type}`]: type,
    [className]: className,
  });

  return (
    <div
      style={{ ...style }}
      className={`relative flex-1 md:flex-none ${wrapperClassName}`}
    >
      <div
        className={inputContainerClasses}
        // style={{ background: "rgba(2,2,15,0.6)", color: "white" }}
        style={{
          background: "rgba(0,0,0,0.1)",
          color: "white",
          minHeight: "48px",
          fontSize: "15px",
          fontWeight: 500,
          letterSpacing: "0.15px",
          opacity: 1,
        }}
      >
        <InputBlockOverlay show={isBlocked} />
        <InputOutline label={label} name={name} />
        {render({ ...props, id: name })}
      </div>

      {showErrors && isError && (
        <ErrorMessage
          helperText={helperText}
          message={error}
          show={showErrorMessage}
        />
      )}
    </div>
  );
};

export default Input;
