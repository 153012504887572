import { useField } from 'formik';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { IInputProps } from '../inputTypes';
import ImageInput from './ImageInput';
import NewImageInput from './NewImageInput';

interface IImageFieldProps extends IInputProps {
  imgSrc?: string;
  base64: string;
  setBase64: Dispatch<SetStateAction<string>>;
  titleName?: string;
}

const NewImageField: React.FC<IImageFieldProps> = ({
  name,
  base64,
  setBase64,
  titleName = "Zdjęcie profilowe",
  ...props
}) => {
  const [field, { error, touched }, { setTouched }] = useField(name);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    field.onChange(e);
  };

  return (
    <NewImageInput
      {...field}
      {...props}
      titleName={titleName}
      setBase64={setBase64}
      base64={base64}
      onChange={handleChange}
      error={error}
      touched={touched}
    />
  );
};

export default NewImageField;
