import { TrainerReqIdActionTypes, SET_RELATION_REQUEST_ID } from 'store/actions/trainerReqId';

interface TrainerRequestIdState {
  relationRequestIds: any[];
}

const initialState: TrainerRequestIdState = {
  relationRequestIds: [],
};

const trainerReqIdReducer = (state = initialState, action: TrainerReqIdActionTypes): TrainerRequestIdState => {
  switch (action.type) {
    case SET_RELATION_REQUEST_ID:
      return { ...state, relationRequestIds: action.payload };
    default:
      return state;
  }
};

export default trainerReqIdReducer;
