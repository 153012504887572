import { conn } from 'api/BaseConnection';
import jwtDecode from 'jwt-decode';
import {
  IDelivery,
  IDeliveriesResponse,
  IAddDelivery,
  IUpdateDelivery,
  IRemoveDelivery,
} from 'types/marketpleace/delivery.interface';

const endpoint = conn.endpoints.marketpleace.delivery;

interface IToken {
  SellerEnable: string;
  SellerId: string;
}

const token = localStorage.getItem('token');
const decodedToken = token && jwtDecode<IToken>(token);
const sellerId = decodedToken ? decodedToken.SellerId : '';

const get = async (): Promise<IDelivery[]> => {
  const response = await conn.getJSON<IDeliveriesResponse>(
    endpoint.get,
    'json',
    {
      SellerId: sellerId,
    }
  );

  return response.Deliveries;
};

const addPhysical = async (delivery: IAddDelivery) => {
  return await conn.postJSON(endpoint.addPhysical, 'json', delivery);
};
const addDigital = async () => {
  return await conn.postJSON(endpoint.addDigital, 'json', {});
};

const update = async (delivery: IUpdateDelivery) => {
  return await conn.putJSON(endpoint.update, 'json', delivery);
};

const remove = async (delivery: IRemoveDelivery) => {
  return await conn.deleteJSON(endpoint.delete, 'json', delivery);
};

const deliveryServices = {
  get,
  addPhysical,
  addDigital,
  update,
  remove,
};

export default deliveryServices;
