import DataTable from "components/common/dataTable/DataTable";
import { getSortedPlayersByListSort } from "middleware/player/getSortedPlayersByListSort";
import { ITableProps, LIST_SORT } from "types/globalTypes";
import {
  IAcademyPlayer,
  PLAYER_STATE,
  TRANSFER_LEVEL,
  TRANSACTION_STATUS,
} from "types/transfer/player.interface";
import { getFormatedDate } from "utils/dateUtils";

const PlayersTable = ({
  data,
  containerRef,
  isDataLoading,
  lastItemRef,
  sortBy,
}: ITableProps<IAcademyPlayer, LIST_SORT>) => {
  const headers = [
    "Imię i nazwisko",
    "Rocznik",
    "Relacja",
    "Status",
    "Grupy",
    "Składki",
  ];

  const sortedArray = getSortedPlayersByListSort(data, sortBy?.value);

  const rows = sortedArray.map((player) => ({
    data: {
      link: `/players/detail/${player.PlayerId}?tab=events`,
    },
    cols: [
      player.Name,
      player?.Birthday ? getFormatedDate(player.Birthday, "yyyy") : "-",
      player.Level !== null ? TRANSFER_LEVEL[player.Level] : "-",
      typeof player.CurrentState === "number" &&
      player.CurrentState in PLAYER_STATE
        ? PLAYER_STATE[player.CurrentState]
        : "-",
      player.GroupName,
      TRANSACTION_STATUS[player.Status as keyof typeof TRANSACTION_STATUS],
    ],
  }));

  return (
    <DataTable
      rows={rows}
      headers={headers}
      isDataLoading={isDataLoading}
      containerRef={containerRef}
      lastItemRef={lastItemRef}
    />
  );
};

export default PlayersTable;
