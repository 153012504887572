import HeaderContainer from 'components/common/Containers/HeaderContainer';
import InfoContainerItem from 'components/common/Containers/InfoContainerItem';
import React from 'react';
import { InfoContainer } from 'styles/styles';
import { ISellerDetail } from 'types/seller.interface';

interface IMerchantConditionsProps {
  seller?: ISellerDetail;
}

const MerchantConditions = ({ seller }: IMerchantConditionsProps) => {
  return (
    <HeaderContainer title='Warunki'>
      <InfoContainer>
        <InfoContainerItem
          header='Procent opłaty'
          content={seller ? `${seller.EventFeeProcent} %` : '-'}
        />
        <InfoContainerItem
          header='Opłata eventu'
          content={seller ? `${seller.FeeProcent} %` : '-'}
        />
      </InfoContainer>
    </HeaderContainer>
  );
};

export default MerchantConditions;
