import React, { useEffect, useState } from 'react';
import { StyledInput } from '../../styles';
import { IUseInputDateRange, useDateInputRange } from 'hooks/useDateInputRange';

interface IStatisticsTopbarProps {
  dateRange: IUseInputDateRange;
}

const StatisticsTopbar = ({ dateRange }: IStatisticsTopbarProps) => {
  return (
    <div
      className='p-12 flex justify-end gap-3 w-full text-white '
      style={{ background: 'rgba(2, 2, 15, 0.3)' }}>
      <div className='flex gap-6 '>
        <div className='flex items-center gap-3 text-center'>
          Od:
          <StyledInput
            type='date'
            min={dateRange.start.min}
            max={dateRange.start.max}
            value={dateRange.start.value}
            onChange={e => dateRange.start.onChange(e.target.value)}
          />
        </div>
        <div className='flex items-center gap-3 text-center'>
          Do:
          <StyledInput
            type='date'
            min={dateRange.end.min}
            max={dateRange.end.max}
            value={dateRange.end.value}
            onChange={e => dateRange.end.onChange(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default StatisticsTopbar;
