import { useTranslation } from "react-i18next";
import DataTable from "components/common/dataTable/DataTable";
import EventListingDataTable from "components/common/dataTable/EventListingDataTable";
import { getFormatedDate } from "utils/dateUtils";
import { utcToLocalDate } from "./detail/EventsDetail";
import styled from "styled-components";

interface IDigitalReleaseTableProps {
  events: Array<any>;
  containerRef: any;
  lastItemRef: any;
  isDataLoading: boolean;
  sortBy: any;
  setClickedCyclicalEventId: any;
  toggleDraftMenuOpen: any;
}

const EventsTable: React.FC<IDigitalReleaseTableProps> = ({
  events,
  containerRef,
  isDataLoading,
  lastItemRef,
  sortBy,
  setClickedCyclicalEventId,
  toggleDraftMenuOpen,
}) => {
  const { t } = useTranslation();
  const headers = [
    "Typ",
    "Poziom",
    "Nazwa",
    "Uczestnicy",
    "Data",
    // "Godzina",
    "Lokalizacja",
  ];
  console.log("events", events);
  const compare = (a: any, b: any, sort: any) => {
    if (a[sort] < b[sort]) {
      return 1;
    }
    if (a[sort] > b[sort]) {
      return -1;
    }
    return 0;
  };

  const sortingFunction = (type: number, array: any) => {
    switch (type) {
      case 0:
        return array.slice().reverse();
      case 1:
        return array;
      case 2:
        return array
          .slice()
          .sort((a: any, b: any) => compare(a, b, "Name"))
          .reverse();
      case 3:
        return array.slice().sort((a: any, b: any) => compare(a, b, "Name"));
      default:
        return array;
    }
  };

  const organizerSwitch = (organizer: number) => {
    switch (organizer) {
      case 0:
        return "Justwin";
      case 1:
        return "Trener";
      case 2:
        return "Zawodnik";
      case 3:
        return "Klub";
      case 4:
        return "Reprezentacja";
      default:
        return organizer;
    }
  };

  const visibilitySwitch = (visibility: number) => {
    switch (visibility) {
      // case 0:
      //   return "Nikt";
      // case 0:
      //   return "Wszyscy";
      // case 1:
      //   return "Przyjaciele";
      // case 2:
      //   return "Przyjaciele przyjaciół";
      // case 3:
      //   return "Wszyscy";
      // case 4:
      //   return "Tylko zaproszeni";

      case 0:
        return "Wszyscy";
      case 1:
        return "Tylko zaproszeni";
      case 2:
        return "Grupa";
      case 3:
        return "-";

      default:
        return visibility;
    }
  };

  const typeSwitch = (type: number) => {
    switch (type) {
      case 0:
        return "T";
      // case 0:
      //   return "Trening";
      case 1:
        return "TA";
      case 2:
        return "TP";
      // case 1:
      //   return "Trening aerobowy";
      // case 2:
      //   return "Trening personalny";
      case 3:
        return "BO";
      case 4:
        return "M";
      // case 3:
      //   return "Badania okresowe";
      // case 4:
      //   return "Mecz piłkarski";
      default:
        return type;
    }
  };
  const levelSwitch = (level: number) => {
    switch (level) {
      case 0:
        return "K";
      case 1:
        return "R";
      // case 0:
      //   return "Klub";
      // case 1:
      //   return "Reprezentacja";
      case 2:
        return "Własne";
      case 3:
        return "-";

      default:
        return level;
    }
  };

  const processLocalization = (localization: any) => {
    const partsArray = localization.split(",");

    if (partsArray.length > 2) {
      return partsArray.slice(0, -1).join(",").trim();
    } else {
      return partsArray[0].split(" ")[1];
    }
  };

  const sortedArray = sortingFunction(sortBy?.value, events);

  const rows = sortedArray.map((event: any) => {
    const localizationParts = event?.Localization?.split(",");
    localizationParts?.pop();
    const eventLocalizatioin = localizationParts?.join(",");

    const occurenceDateLocal = utcToLocalDate(event?.OccurenceDate);
    const endDateLocal = utcToLocalDate(event?.EndDate);

    return {
      data: { link: `/events/detail/${event.EventId}?tab=attendances` },
      isCyclical: event?.IsDraft ? true : false,
      cols: [
        typeSwitch(event.Type),
        levelSwitch(event.Level),
        event.Name,
        // organizerSwitch(event.Organizer),

        <div>
          {event?.Groups?.length > 0 ? (
            event?.Groups?.slice(0, 3)?.map((group: any) => (
              <p>{group?.GroupName}</p>
            ))
          ) : (
            <p>{visibilitySwitch(event.Visibility)}</p>
          )}
        </div>,
        <div>
          {!event?.OccurenceDate && !event?.EndDate ? (
            <>
              <p>-</p>
            </>
          ) : occurenceDateLocal.slice(0, 10) === endDateLocal.slice(0, 10) ? (
            <>
              <p>
                {`${getFormatedDate(
                  occurenceDateLocal.slice(0, 10),
                  "dd.MM.yyyy",
                )} ${occurenceDateLocal.slice(11, 16)} - ${endDateLocal.slice(
                  11,
                  16,
                )}` ?? "-"}
              </p>
            </>
          ) : (
            <>
              <p>
                {event?.OccurenceDate !== null
                  ? `
                ${getFormatedDate(
                  occurenceDateLocal.slice(0, 10),
                  "dd.MM.yyyy",
                )} ${occurenceDateLocal.slice(11, 16)}

                `
                  : "-"}
              </p>
              <p>
                {" "}
                {`${getFormatedDate(
                  endDateLocal.slice(0, 10),
                  "dd.MM.yyyy",
                )} ${endDateLocal.slice(11, 16)}` ?? "-"}
              </p>
            </>
          )}
        </div>,

        // eventLocalizatioin,
        // processLocalization(event?.Localization),
        event?.Localization,
      ],
    };
  });

  return (
    <Container className="w-full">
      <EventListingDataTable
        setClickedCyclicalEventId={setClickedCyclicalEventId}
        toggleDraftMenuOpen={toggleDraftMenuOpen}
        rows={rows}
        headers={headers}
        isDataLoading={isDataLoading}
        containerRef={containerRef}
        lastItemRef={lastItemRef}
      />
    </Container>
  );
};

export default EventsTable;
const Container = styled.div`
  height: 100%;

  /* max-height: 650px;
  overflow-y: auto;
  position: relative; */

  & > div {
    & > div:nth-of-type(4) {
      & > div {
        & > div {
          max-height: 900px;
          /* overflow-y: auto; */
        }
      }
    }
  }

  & table {
    height: 100%;
    max-height: 100%;
    /* opacity: 1 !important; */
    & thead {
      position: sticky;
      top: 0;
      z-index: 99;
    }

    & * {
      /* opacity: 1 !important; */
    }
  }
`;
