import Resizer from 'react-image-file-resizer';
import { filesize } from 'filesize';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

export const getImageSize = (imgPath: string) =>
  new Promise((resolve, reject) => {
    try {
      const img = new Image();
      img.src = imgPath;
      img.onload = () => {
        const { height, width } = img;
        const ratio = width / height;
        resolve({
          ratio,
          height,
          width,
        });
      };
    } catch (error: any) {
      reject(error);
    }
  });

export const convertToBase64 = (
  file: File
): Promise<{ header: string; base64String: string }> =>
  new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          const [header, base64String] = reader.result.split(',');
          resolve({ header, base64String });
        } else {
          reject('Invalid file');
        }
      };
    } catch (error: any) {
      reject(error);
    }
  });

export const convertImageToOptimizedBase64 = (
  file: File
): Promise<{ header: string; base64String: string }> =>
  new Promise((resolve, reject) => {
    console.log('unoptimized image size: ', filesize(file.size));
    Resizer.imageFileResizer(
      file,
      800,
      800,
      'jpeg',
      80,
      0,
      uri => {
        const optimizedFile = uri as File;
        console.log('optimized image size: ', filesize(optimizedFile.size));
        resolve(convertToBase64(optimizedFile));
      },
      'file'
    );
  });

export const handleNewBase64Change = (
  event: ChangeEvent<HTMLInputElement>,
  setNewBase64String: Dispatch<SetStateAction<string>>
) => {
  const file = event.target.files;
  const reader = new FileReader();

  reader.onloadend = () => {
    const base64String = reader.result;
    setNewBase64String(base64String?.toString() ?? '');
  };

  if (file) {
    reader.readAsDataURL(file[0]);
  }
};
