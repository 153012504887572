interface Permissions {
  Id: number;
  Value: string
}

export const PERMISSIONS = 'PERMISSIONS';

export const userPermissions = (permission: Permissions[]) => ({
  type: PERMISSIONS,
  payload: permission,
});
