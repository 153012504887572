import ContentContainer from 'components/layout/ContentContainer';
import React, { useContext, useState } from 'react';
import OrdersTopbar from './OrdersTopbar';
import OrdersTable from './OrdersTable';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { useDebounce } from 'use-debounce';
import { ISelectOption } from 'types/globalTypes';
import { getOrderSorts } from './utils/helpers';
import { IOrder, ORDER_SORT_LIST } from 'types/marketpleace/order.interface';
import { DefaultSortContext } from 'contexts/defaultSortContext';
import orderServices from 'services/marketpleace/orderServices';

const Orders = () => {
  const [defaultSort, setDefaultSort] = useContext(DefaultSortContext);

  const [sortBy, setSortBy] =
    useState<ISelectOption<ORDER_SORT_LIST> | null>(null);

  const [queryString, setQueryString] = useState('');

  const [debounceSearchInput] = useDebounce(queryString.trim(), 500);

  const {
    items: sellerOrders,
    loading,
    containerRef,
    lastItemRef,
    refresh,
  } = useInfiniteScroll<IOrder>(orderServices.getAll, debounceSearchInput);

  const handleQueryChange = (query: string) => {
    if (!query.length || query.length > 2) {
      setQueryString(query);
    }
  };

  return (
    <ContentContainer
      title={'Zamówienia'}
      TopBar={
        <OrdersTopbar
          sortBy={sortBy}
          setSortBy={setSortBy}
          defaultSort={defaultSort}
          sorts={getOrderSorts}
          handleQueryChange={handleQueryChange}
        />
      }>
      <OrdersTable
        containerRef={containerRef}
        data={sellerOrders}
        isDataLoading={loading}
        lastItemRef={lastItemRef}
        sortBy={sortBy}
      />
    </ContentContainer>
  );
};

export default Orders;
