import TabContent from 'components/common/tabs/TabContent';
import ProductDescription from './ProductDescription';
import ProductImages from './ProductImages';

const ProductSupplementaryData = () => {
  return (
    <TabContent id='supplementaryData'>
      <div className='grid grid-cols-2 gap-4 p-24'>
        <ProductDescription />
        <ProductImages />
      </div>
    </TabContent>
  );
};

export default ProductSupplementaryData;
