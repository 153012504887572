import api from "./axiosConfig";

export const getAllFolders = async () => {
  try {
    const response = await api.get("/api/Document/GetFolders");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getFolderDetail = async (DocumentFolderId: string) => {
  try {
    const response = await api.get(
      `/api/Document/GetFolderDetail?DocumentFolderId=${DocumentFolderId}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteDocument = async (FolderId: string, DocumentId: string) => {
  try {
    const response = await api.delete(`/api/Document/DeleteDocument`, {
      data: { FolderId, DocumentId },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteFolder = async (FolderId: string) => {
  try {
    const response = await api.delete(`/api/Document/DeleteFolder`, {
      data: { FolderId },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createFolder = async (Name: string) => {
  try {
    const response = await api.post(`/api/Document/CreateFolder`, { Name });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createDocument = async (
  Name: string,
  FilePath: string,
  FolderId: string,
) => {
  try {
    const response = await api.post(`/api/Document/CreateDocument`, {
      Name,
      FilePath,
      FolderId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateFolderName = async (Name: string, FolderId: string) => {
  try {
    const response = await api.put(`/api/Document/UpdateFolderName`, {
      Name,
      FolderId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateDocumentName = async (
  Name: string,
  DocumentId: string,
  FolderId: string,
) => {
  try {
    const response = await api.put(`/api/Document/UpdateDocumentName`, {
      Name,
      FolderId,
      DocumentId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
