import styled from "styled-components";
import Placeholder from "assets/icons/structure-placeholder.png";
import { useRef } from "react";

interface Props {
  name: string;
  id: string;
  onContextMenu: any;
  photoFilePath: any;
  setIsPlayerModalOpen: any;
  selectedPlayerId: string;
  setSelectedPlayerId: any;
}

const SingleUser: React.FC<Props> = ({
  name,
  id,
  onContextMenu,
  photoFilePath,
  setIsPlayerModalOpen,
  selectedPlayerId,
  setSelectedPlayerId,
}) => {
  const openUserDetails = () => {
    setIsPlayerModalOpen(true);
  };
  const containerRef = useRef<HTMLDivElement>(null);
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === " " && selectedPlayerId === id) {
      openUserDetails();
    }
  };

  const handleContainerClick = () => {
    containerRef.current?.focus();
    setSelectedPlayerId(id);
  };

  return (
    <Container
      ref={containerRef}
      onContextMenu={onContextMenu}
      // onClick={openUserDetails}
      onKeyDown={handleKeyPress}
      onClick={handleContainerClick}
      selectedPlayerId={selectedPlayerId}
      id={id}
      tabIndex={0}
    >
      <div>
        <img
          src={photoFilePath ? photoFilePath : Placeholder}
          alt="userimage"
        />
      </div>
      <UserInfoBox>
        <p>{name ? name : "-"}</p>
        <p>Zawodnik</p>
      </UserInfoBox>
    </Container>
  );
};

export default SingleUser;

const Container = styled.div<{ selectedPlayerId: any; id: any }>`
  width: calc(100% - 33px);
  height: 50px;
  margin-left: 16px;
  background: rgba(156, 176, 196, 0.11);
  /* background: rgb(10, 63, 97);
  background: linear-gradient(
    0deg,
    rgba(10, 63, 97, 0.5) 0%,
    rgba(156, 176, 196, 0.5) 100%
  ); */
  border: ${(props) =>
    props.selectedPlayerId === props.id
      ? "1px solid #0091FF"
      : "1px solid rgba(0,145,255,0.3)"};
  &:hover {
    border: 1px solid #0091ff;
  }
  border-radius: 7px;
  display: flex;
  align-items: center;
  gap: 15px;

  & > div:nth-of-type(1) {
    width: 50px;
    height: 50px;
    padding-left: 1px;
    height: 95%;
    user-select: none;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(2, 2, 15);

    & > img {
      height: 100%;
      border-radius: 7px;
      /* -webkit-box-shadow: -3px 3px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -3px 3px 5px 0px rgba(0, 0, 0, 0.5);
    box-shadow: -3px 3px 5px 0px rgba(0, 0, 0, 0.5); */
    }
  }

  & > p {
    color: rgba(248, 248, 248, 0.7);
    font-weight: lighter;
  }
`;

const UserInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;

  & > p:nth-of-type(1) {
    font-size: 12px;
    color: white;
    line-height: 10px;
    margin-top: 5px;
  }

  & > p:nth-of-type(2) {
    font-size: 12px;
    line-height: 13px;
    color: rgb(0, 145, 255);
  }
`;
