import { IAddress } from "../address.interface";
import { IPass } from "./pass.interface";
import { TRAINER_TYPE } from './trainer.interface';
import { OBSERVATION_LEVEL } from './transfer.interface';

export interface IAvaliablePlayer {
  PlayerId: string;
  Name: string;
  Birthday: Date;
  Address: IAddress;
}

export interface IAcademyPlayer {
  PlayerId: string;
  Name: string;
  Birthday: Date;
  Address: IAddress;
  Level: TRANSFER_LEVEL | null;
  State: PLAYER_STATE;
  CurrentState: string;
  GroupName: string;
  Status: string,
}

export interface IPlayerCardTarget {
  TargetId: string;
  Name: string;
}

export interface IPlayerCardDiscipline {
  DisciplineId: string;
  Name: string;
}

export interface IPlayerCard {
  IsPlayerAssociated: boolean;
  FifaId: string;
  PlayerId: string;
  PlayerCardId: string;
  Characteristics: string;
  DominantLeg: DOMINANT_LEG;
  PreferredPosition: PREFERRED_POSITION;
  Target: IPlayerCardTarget;
  Discipline: IPlayerCardDiscipline;
}

export interface IDesignateTrainer {
  TrainerId: string;
  Name: string;
  DesignatedAt: Date;
  Level: OBSERVATION_LEVEL;
  TrainerType: TRAINER_TYPE;
}

export interface IAppliedPass extends IPass {}

export interface IPlayerHistory {
  PlayerHistoryId: string;
  State: PLAYER_STATE;
  UpdatedAt: Date;
}

export interface ITransferRequest {
  DisciplineId: string;
  Level: TRANSFER_LEVEL;
  Receiver: TRANSFER_RECIEVER;
}

export interface IPlayerDetail {
  PlayerId: string;
  Name: string;
  PhotoFilePath: string;
  Birthday: Date;
  Gender: GENDER;
  Level: TRANSFER_LEVEL | null;
  State: PLAYER_STATE | null;
  IsExclude: boolean;
  Address: IAddress | null;
  AppliedPasses: IAppliedPass[];
  DesignateTrainers: IDesignateTrainer[];
  Card: IPlayerCard | null;
  PlayerHistories: IPlayerHistory[];
  TransferRequest: ITransferRequest | null;
}

export interface IPlayerClubHistory {
  PlayerHistoryId: string;
  Academy: string;
  Trainer: string;
  Group: string;
  From: Date;
  To: Date;
  Achievements: string;
}

export interface IOutsidePlayer {
  RequestId?: string;
  PlayerId: string;
  Name: string;
  Birthday?: Date;
  Address: IAddress;
  Receiver?: TRANSFER_RECIEVER;
  Level?: TRANSFER_LEVEL;
}

export enum LIST_VIEW {
  "Zaproszenia",
  "Dostępni zawodnicy",
}

export enum DOMINANT_LEG {
  "Prawa",
  "Lewa",
  "Obunożny",
}

export enum PREFERRED_POSITION {
  "Bramkarz",
  "Obrońca",
  "Pomocnik",
  "Strzelec",
}

export enum GENDER {
  "Mężczyzna",
  "Kobieta",
}

export enum TRANSFER_LEVEL {
  "Podstawowa",
  "Pełna",
  "Członkowska",
}

export enum TRANSFER_RECIEVER {
  "Zawodnik",
  "Akademia",
}

export enum PLAYER_STATE {
  "Prośba o podstawową obserwację",
  "Prośba o pełną obserwację",
  "Wysłana prośba o podstawową obserwację",
  "Akademia wysłała prośbę o pełną obserwację zawodnika",
  "Prośba o członkostwo",
  "Zawodnik wysłał prośbę o dołączenie do Akademii",
  "Zawodnik obserwowany",
  "Zawodnik pod obserwacją",
  "Nowy zawodnik",
  "Odrzucony wniosek",
  "Nieaktywny",
  "Zawodnik obserwowany w przeszłości",
  "Podstawowo obserwowany były członek",
  "W pełni obserwowany były członek",
  "Zawodnik z ograniczonym dostępem",
  "Brak opłaconej składki",
  "Członek wykluczony",
  "Aktywny",
  "Dołączony",
  "Członek bez karnetu",
}

export enum PLAYER_STATE_CHANGE {
  "Prośba o obserwacje podstawową",
  "Prośba o obserwacje pełną",
  "Prośba o członkostwo",
  "Podstawowa obserwacja",
  "Pełna obserwacja",
  "Aktywny",
  "Odmowa",
  "Anuluj",
  "Odwołaj",
  "Były zawodnik częściowo obserwowany przez Akademię",
  "Były zawodnik w pełni obserwowany przez Akademię",
  "Wykluczony",
  "Dołączony",
}

export  enum TRANSACTION_STATUS
{
    'Zapłacono',
    'Oczekiwanie na zapłatę',
    'Weryfikacja',
    'Błąd',
    'Rezygnacja',
}
