import styled from "styled-components";
import Input from "../Input";

import { IInputProps } from "../inputTypes";

const BlockedDateTextInput: React.FC<IInputProps> = (props) => {
  return (
    <Input
      {...props}
      render={(inputProps) => (
        <>
          <StyledInput
            style={{ textAlign: "right" }}
            placeholder="Uzupełnij"
            {...inputProps}
            autoComplete="off"
          />
        </>
      )}
    />
  );
};

export default BlockedDateTextInput;

const StyledInput = styled.input`
  pointer-events: none;
  &[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
    pointer-events: all;
    scale: 1.2;
  }
`;
