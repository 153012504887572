import { PERMISSIONS } from '../actions/userPermissions';

interface Permission {
  Id: number;
  Value: string;
}

interface PermissionsState {
  permissions: Permission[];
}

const initialState: PermissionsState = {
  permissions: [],
};

interface PermissionsAction {
  type: typeof PERMISSIONS;
  payload: Permission[];
}

export const permissions = (
  state = initialState,
  action: PermissionsAction
): PermissionsState => {
  switch (action.type) {
    case PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    default:
      return state;
  }
};
