import DataTable, {
  IDataTableRows,
} from 'components/common/dataTable/DataTable';
import DropdownPanel from 'components/common/panels/DropdownPanel';
import React from 'react';
import { IOrderDetailStatstics } from 'types/marketpleace/order.interface';

interface IProductOrdersProps {
  productOrders?: IOrderDetailStatstics[];
}

const ProductOrders = ({ productOrders }: IProductOrdersProps) => {
  const productOrserRows: IDataTableRows[] = productOrders
    ? productOrders.map(order => ({
        data: { link: `/shop/orders/detail/${order.OrderId}?tab=products` },
        cols: [`${order.Created}`.substring(0, 10), order.Number],
      }))
    : [];

  return (
    <DropdownPanel
      label='Towar na zamówieniach'
      dropdownStyles={{ padding: '4px 0px', background: 'none' }}>
      <div className='w-full'>
        <DataTable
          headers={['Data', 'Numer']}
          rows={productOrserRows}
          isDataLoading={false}
        />
      </div>
    </DropdownPanel>
  );
};

export default ProductOrders;
