import React from 'react';
import { PRODUCT_VERIFICATION_STATUS } from 'types/marketpleace/product.interface';
import { getIconSrcByProductStatus } from '../utils/helpers';

interface IProductStatusProps {
  status: PRODUCT_VERIFICATION_STATUS;
}

const ProductStatus = ({ status }: IProductStatusProps) => {
  const productStatus = PRODUCT_VERIFICATION_STATUS[status];

  return (
    <div
      className={`flex flex-wrap items-center text-white gap-x-2`}
      style={{
        width: '100%',
        display: 'flex',
        gap: '10px',
        padding: '0',
        justifyContent: 'center',
      }}
      title={productStatus}>
      <img src={getIconSrcByProductStatus(status)} alt={productStatus} />
      <span className='truncate text-sm text-white'>{productStatus}</span>
    </div>
  );
};

export default ProductStatus;
