import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import TileViewLinkItem from "components/common/tileView/TileViewLinkItem";

const SingleLabel = styled.div`
  margin-top: 8px;

  & > span {
    &:nth-of-type(1) {
      width: 270px;
      letter-spacing: -0.375px;
    }

    &:nth-of-type(2) {
      width: calc(100% - 270px);
      color: white;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.45px;
    }
  }
`;

const StyledTile = styled(Link)`
  background: rgba(2, 2, 15, 0.3);
  color: white;
  transition: all ease-in 0.1s;

  &:hover {
    background: rgba(2, 2, 15, 0.4);
    transition: all ease-in 0.1s;
  }
`;

interface IDigitalReleaseListItemProps {
  event: any;
  innerRef?: any;
}

const DigitalReleaseListItem: React.FC<IDigitalReleaseListItemProps> = ({
  event,
  innerRef,
}) => {
  const { t } = useTranslation();

  const organizerSwitch = (organizer: number) => {
    switch (organizer) {
      case 0:
        return "Justwin";
      case 1:
        return "Trener";
      case 2:
        return "Zawodnik";
      case 3:
        return "Klub";
      case 4:
        return "Reprezentacja";
      default:
        return organizer;
    }
  };

  const visibilitySwitch = (visibility: number) => {
    switch (visibility) {
      // case 0:
      //   return "Nikt";
      // case 1:
      //   return "Przyjaciele";
      // case 2:
      //   return "Przyjaciele przyjaciół";
      // case 3:
      //   return "Wszyscy";
      // case 4:
      //   return "Tylko zaproszeni";
      case 0:
        return "Wszyscy";
      case 1:
        return "Tylko zaproszeni";
      case 2:
        return "Grupa";
      default:
        return visibility;
    }
  };

  const typeSwitch = (type: number) => {
    switch (type) {
      case 0:
        return "Trening";
      case 1:
        return "Trening aerobowy";
      case 2:
        return "Trening personalny";
      case 3:
        return "Badania okresowe";
      case 4:
        return "Mecz piłkarski";
      default:
        return type;
    }
  };
  const levelSwitch = (level: number) => {
    switch (level) {
      case 0:
        return "Klub";
      case 1:
        return "Reprezentacja";
      case 2:
        return "Własne";

      default:
        return level;
    }
  };

  return (
    <div ref={innerRef}>
      {/* @ts-ignore */}
      <TileViewLinkItem
        link={`/trainers/detail/${event.TrainerId}`}
        title={""}
        img=""
        content={
          <>
            <div className="tileInfo">
              <SingleLabel className="my-1">
                <span className="tileInfo__label">{"Typ zadania"}</span>
                <span>{typeSwitch(event.Type)}</span>
              </SingleLabel>

              <SingleLabel className="my-1" style={{ margin: 0 }}>
                <span className="tileInfo__label">Poziom</span>
                <span>{levelSwitch(event.Level)}</span>
              </SingleLabel>

              <SingleLabel className="my-1" style={{ margin: 0 }}>
                <span className="tileInfo__label">Nazwa</span>
                <span>{event.Name}</span>
              </SingleLabel>

              <SingleLabel className="my-1" style={{ margin: 0 }}>
                <span className="tileInfo__label">Organizator</span>
                <span>{organizerSwitch(event.Organizer)}</span>
              </SingleLabel>

              <SingleLabel className="my-1" style={{ margin: 0 }}>
                <span className="tileInfo__label">Zaproszeni</span>
                <span>{visibilitySwitch(event.Visibility)}</span>
              </SingleLabel>

              <SingleLabel className="my-1" style={{ margin: 0 }}>
                <span className="tileInfo__label">Data startu</span>
                <span>{event?.DateStart?.slice(0, 10) ?? "-"}</span>
              </SingleLabel>

              <SingleLabel className="my-1" style={{ margin: 0 }}>
                <span className="tileInfo__label">Godzina startu</span>
                <span>{event?.HourStart?.slice(0, 8) ?? "-"}</span>
              </SingleLabel>

              <SingleLabel className="my-1" style={{ margin: 0 }}>
                <span className="tileInfo__label">Kod pocztowy</span>
                <span>{event.PostCode}</span>
              </SingleLabel>

              <SingleLabel className="my-1" style={{ margin: 0 }}>
                <span className="tileInfo__label">Lokalizacja</span>
                <span>{event.Localization}</span>
              </SingleLabel>
            </div>
          </>
        }
      ></TileViewLinkItem>
    </div>
  );
};

export default DigitalReleaseListItem;
