import React from 'react';
import {
  IOrder,
  ORDER_HISTORY_STATE,
  ORDER_SORT_LIST,
} from 'types/marketpleace/order.interface';
import DataTable, {
  IDataTableRows,
} from 'components/common/dataTable/DataTable';
import { sortOrdersByListSort } from 'middleware/order/sortOrdersByListSort';
import { headers } from './utils/helpers';
import { adjustDateStringToInput } from 'utils/dateUtils';
import { ITableProps } from 'types/globalTypes';

const OrdersTable = ({
  containerRef,
  isDataLoading,
  lastItemRef,
  data,
  sortBy,
}: ITableProps<IOrder, ORDER_SORT_LIST>) => {
  const sortedOrders = sortOrdersByListSort(data, sortBy?.value);

  const rows = sortedOrders.map(
    (order: IOrder) =>
      ({
        data: { link: `/shop/orders/detail/${order.OrderId}` },
        cols: [
          order.Number,
          order.Buyer.Name,
          adjustDateStringToInput(JSON.stringify(order.Created)),
          `${order.Amount.toFixed(2)} zł`,
          'Przelewy24',
          order.Delivery.Name,
          ORDER_HISTORY_STATE[order.State],
        ],
      } as IDataTableRows)
  );

  return (
    <DataTable
      rows={rows}
      headers={headers}
      isDataLoading={isDataLoading}
      containerRef={containerRef}
      lastItemRef={lastItemRef}
    />
  );
};

export default OrdersTable;
