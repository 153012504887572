import { conn } from "../api/BaseConnection";
import { IListPageRequest, IListPageResponse } from "../types/globalTypes";
import {
  IUserManagement,
  IUserManagementEditData,
  UserInterface,
  UserManagmentEditInterface,
} from "../types/userTypes";

const notificationEndpoint = conn.endpoints.notification;

const get = (userId: string): Promise<UserInterface> => {
  return conn.getJSON(`${notificationEndpoint}/${userId}`, "json");
};

const edit = (user: UserManagmentEditInterface): Promise<IUserManagement> => {
  return conn.putJSON(`${notificationEndpoint}`, "json", user);
};

const activate = (userId: string): Promise<IUserManagement> => {
  return conn.patchJSON(`${notificationEndpoint}/Activate`, "json", { userId });
};

const remove = (NotificationId: string) => {
  const body = {
    NotificationId
  };

  return conn.deleteJSON(
    `${notificationEndpoint}/`,
    "json",
    body,
  );
};

const getAllNotifications = (SearchQuery: string, PageNumber: number): Promise<any> => {

  const body = {
    PageSize: 99,
    PageNumber: PageNumber,
    SearchQuery: SearchQuery
  };
  return conn.getJSON(
    `${notificationEndpoint}`,
    "json",
    body,
  );
};

const seeNotification = (NotificationId: string) => {
  const body = {
    NotificationId
  };
  return conn.patchJSON(`${notificationEndpoint}/See`, "json", body);
};

const completeNotification = (NotificationId: string) => {
  const body = {
    NotificationId
  };
  return conn.patchJSON(
    `${notificationEndpoint}/Complete`,
    "json",
    body,
  );
};

const clearAllNotifications = (userId: string) => {
  return conn.deleteJSON(
    `${notificationEndpoint}/ClearNotifications/${userId}`,
  );
};

const getNotificationCounter = () => {
    return conn.getJSON(
      `${notificationEndpoint}/GetUnreadNotificationsCount`,
    )
}

const notificationsServices = {
  activate,
  remove,
  edit,
  get,
  getAllNotifications,
  seeNotification,
  completeNotification,
  clearAllNotifications,
  getNotificationCounter
};

export default notificationsServices;
