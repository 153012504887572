import {
  IPassForm,
  PASS_PAYMENT_TYPE,
  PASS_TYPE,
} from 'types/transfer/pass.interface';

export const setPassType = (
  e: React.ChangeEvent<HTMLSelectElement>,
  pass: IPassForm,
  setPass: React.Dispatch<React.SetStateAction<IPassForm>>,
  resetDates: () => void
) => {
  const selectedType = parseInt(e.target.value);

  setPass(prev => ({
    ...prev,
    Type: selectedType,
  }));

  if (selectedType === PASS_TYPE['Nieokreślony']) {
    resetDates();
    setPass(prev => ({
      ...prev,
      Period: { StartAt: null, EndAt: null },
    }));

    if (pass.PaymentType !== PASS_PAYMENT_TYPE['Miesięcznie']) {
      setPass(prev => ({
        ...prev,
        PaymentType: PASS_PAYMENT_TYPE['Miesięcznie'],
      }));
    }
  }

  if (selectedType === PASS_TYPE['Określony']) {
    if (pass.PaymentType === PASS_PAYMENT_TYPE['Miesięcznie']) {
      setPass(prev => ({
        ...prev,
        PaymentType: PASS_PAYMENT_TYPE['Ratalnie'],
      }));
    }

  
  }
};

export const setPriceType = (
  e: React.ChangeEvent<HTMLSelectElement>,
  pass: IPassForm,
  setPass: React.Dispatch<React.SetStateAction<IPassForm>>
) => {
  const selectedPriceType = parseInt(e.target.value);

  setPass(prev => ({
    ...prev,
    PriceType: selectedPriceType,
  }));


  
};

export const paymentTypeDisabled = (pass: IPassForm, optionValue: number) =>
  (optionValue === PASS_PAYMENT_TYPE['Miesięcznie'] &&
    pass.Type === PASS_TYPE['Określony']) ||
  (optionValue !== PASS_PAYMENT_TYPE['Miesięcznie'] &&
    pass.Type === PASS_TYPE['Nieokreślony']);
