import React from 'react';
import ProductForm from './ProductForm';
import { productInitialValues } from '../utils/helpers';
import {
  IAddProduct,
  IProductForm,
  IUpdateProduct,
  PRODUCT_TYPES,
} from 'types/marketpleace/product.interface';
import systemServices from 'services/systemServices';
import { toast } from 'react-toastify';
import { productServices } from 'services/marketpleace';

interface IEditProductProps {
  isEditMenuOpen: boolean;
  productInitialValues: IUpdateProduct;
  updatedProductType: PRODUCT_TYPES;
  productId: string;
  reload: () => Promise<void>;
  toggleEditMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditProduct = ({
  productInitialValues,
  isEditMenuOpen,
  updatedProductType,
  productId,
  reload,
  toggleEditMenuOpen,
}: IEditProductProps) => {
  const handleSubmit = async (
    values: IProductForm,
    categoryId: string,
    base64: string,
    productType?: PRODUCT_TYPES,
    bannerPhotoFilePath?: string
  ) => {
    let product = values as IUpdateProduct;

    try {
      product.ProductId = productId;
      product.CategoryId = categoryId;

      product.BannerPhotoFilePath = bannerPhotoFilePath ?? '';

      if (base64) {
        product.BannerPhotoFilePath = await systemServices.uploadFile(base64);
        console.log('uploaded File: ', product.BannerPhotoFilePath);
      }

      console.log('Edit product object: ', product);

      await productServices.update(product);

      toast.success(`Pomyślnie edytowano ${product.Name}`);

      toggleEditMenuOpen(false);

      await reload();
    } catch (error) {
      console.error(error);
      toast.error('Wystąpił nieoczekiwany problem. Spróbuj ponownie później.');
    }
  };

  return (
    <ProductForm
      initialValues={productInitialValues}
      handleSubmit={handleSubmit}
      isMenuOpen={isEditMenuOpen}
      defaultProductType={updatedProductType}
      defaultCategoryId={productInitialValues.CategoryId}
    />
  );
};

export default EditProduct;
