interface IInputOutlineProps {
  label?: string;
  name: string;
}

const InputOutline: React.FC<any> = ({
  label,
  name,
  backgroundWidth,
  selectedItem,
}) => {
  return (
    <div
      className="formControl__outline"
      style={{ background: "rgba(163,158,157,0.11)" }}
    >
      <div
        className="formControl__outline--item1"
        style={{ paddingLeft: "9px" }}
      ></div>
      {label && (
        <div className="formControl__outline--item2" style={{ flex: "1 0 0%" }}>
          <label
            data-testid=""
            htmlFor={name}
            style={{
              position: "absolute",
              top: "7px",
              left: "9px",
              fontSize: "11px",
              letterSpacing: 0,
              fontWeight: 400,
              color: "rgba(163,158,157,1)",
            }}
          >
            {label}
          </label>
        </div>
      )}{" "}
      <div
        className="formControl__outline--item3"
        style={{
          flex: "unset",
        }}
      ></div>
    </div>
  );
};

export default InputOutline;
