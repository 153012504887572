import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router";

import { RootState } from "../store/store";
import { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { isAuth } = useSelector((state: RootState) => state.auth);

  if (isAuth == null) {
    return null;
  }

  if (
    isAuth &&
    localStorage.getItem("token") &&
    // @ts-ignore
    jwtDecode(localStorage.getItem("token"))?.IsTemporaryPasswordChanged !==
      "True"
  ) {
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: "/changepassword",
              state: { from: location.pathname },
            }}
          />
        )}
      />
    );
  }

  if (
    isAuth &&
    localStorage.getItem("token") &&
    // @ts-ignore
    jwtDecode(localStorage.getItem("token"))?.IsTemporaryPasswordChanged ===
      "True"
  ) {
    return <Route {...rest} render={({ location }) => children} />;
  } else
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location.pathname },
            }}
          />
        )}
      />
    );

  // return (
  //   // <Route
  //   //   {...rest}
  //   //   render={({ location }) =>
  //   //     isAuth ? children : <Redirect to={{ pathname: '/login', state: { from: location.pathname } }} />
  //   //   }
  //   // />
  //   <Route
  //     {...rest}
  //     render={({ location }) =>
  //       // @ts-ignore
  //       jwtDecode(localStorage.getItem("token")).IsTemporaryPasswordChanged ===
  //       "True" ? (
  //         children
  //       ) : (
  //         <Redirect
  //           to={{
  //             pathname: "/changepassword",
  //             state: { from: location.pathname },
  //           }}
  //         />
  //       )
  //     }
  //   />
  // );
};

export default PrivateRoute;
