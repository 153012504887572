import React from 'react';
import { MERCHANT_REGISTRATION_STATUS } from 'types/merchant.interface';
import ReadyForRegistrationIco from 'assets/icons/status/readyForRegistration.svg';
import RegisteredIco from 'assets/icons/status/registered.svg';
import ProcessingIco from 'assets/icons/status/processing.svg';

interface IMerchantStatusProps {
  status: MERCHANT_REGISTRATION_STATUS;
}

const MerchantStatus = ({ status }: IMerchantStatusProps) => {
  const merchantStatus = MERCHANT_REGISTRATION_STATUS[status];

  const getIconSrcByMerchantStatus = (status: MERCHANT_REGISTRATION_STATUS) => {
    switch (status) {
      case MERCHANT_REGISTRATION_STATUS['Gotowy do rejestracji']:
        return ReadyForRegistrationIco;

      case MERCHANT_REGISTRATION_STATUS['Przetwarzany']:
        return ProcessingIco;

      case MERCHANT_REGISTRATION_STATUS['Zarejestrowany']:
        return RegisteredIco;

      default:
        return '';
    }
  };

  return (
    <div
      className='flex flex-wrap items-center text-white gap-3 p-0 content-center'
      title={merchantStatus}>
      <span className='truncate text-sm text-white'>{merchantStatus}</span>
      <img src={getIconSrcByMerchantStatus(status)} alt={merchantStatus} />
    </div>
  );
};

export default MerchantStatus;
