import { LegacyRef } from 'react';
import Input from '../Input';

import { IInputProps } from '../inputTypes';
import { useField } from 'formik';

interface ITextInputWithUnitProps extends IInputProps {
  unit: string;
  unitPosition?: 'left' | 'right';
  buttonRef?: LegacyRef<HTMLInputElement>;
}

const TextInputWithUnit = (props: ITextInputWithUnitProps) => {
  const [field, { error, touched }] = useField(props.name);

  return (
    <Input
      {...props}
      {...field}
      error={error}
      touched={touched}
      render={inputProps => (
        <>
          {props.unitPosition === 'left' ? (
            <>
              <p
                style={{
                  marginRight: '8px',
                  textAlign: 'right',
                  width: '100%',
                }}>
                {props.unit}
              </p>
              <input
                ref={props.buttonRef}
                style={{
                  textAlign: 'right',
                  padding: '12px 5px',
                  maxWidth: '50%',
                  minWidth: '20px',
                  width: '46px',
                }}
                {...inputProps}
                maxLength={4}
              />
            </>
          ) : (
            <>
              <input
                ref={props.buttonRef}
                style={{ textAlign: 'right', padding: '12px 5px' }}
                {...inputProps}
              />
              <p style={{ marginRight: '8px' }}>{props.unit}</p>
            </>
          )}
        </>
      )}
    />
  );
};

export default TextInputWithUnit;
