import TileViewLinkItem from 'components/common/tileView/TileViewLinkItem';
import { SingleLabel } from 'styles/styles';
import React, { LegacyRef } from 'react';
import { IProductDto, PRODUCT_TYPES } from 'types/marketpleace/product.interface';

interface IProductListItemProps {
  product: IProductDto;
  innerRef?: LegacyRef<HTMLTableSectionElement>;
}

const ProductListItem = ({ product, innerRef }: IProductListItemProps) => {
  return (
    <div ref={innerRef}>
      <TileViewLinkItem
        link={`/shop/products/detail/${product.ProductId}`}
        title={''}
        img={product.BannerPhotoFilePath}
        content={
          <>
            <div className='tileInfo'>
              <SingleLabel className='my-1' style={{ margin: 0 }}>
                <span className='tileInfo__label'>Nazwa produktu</span>
                <span>{product.Name}</span>
              </SingleLabel>
              <SingleLabel className='my-1'>
                <span className='tileInfo__label'>{'Kategoria'}</span>
                <span>{product.CategoryName}</span>
              </SingleLabel>

              <SingleLabel className='my-1'>
                <span className='tileInfo__label'>{'Typ produktu'}</span>
                <span>{PRODUCT_TYPES[product.Type]}</span>
              </SingleLabel>

              <SingleLabel className='my-1'>
                <span className='tileInfo__label'>{'Zapas'}</span>
                <span>{product.Amount === 0 ? 'Brak' : product.Amount}</span>
              </SingleLabel>

              <SingleLabel className='my-1'>
                <span className='tileInfo__label'>{'Dostawca'}</span>
                <span>{product.SellerName}</span>
              </SingleLabel>
            </div>
          </>
        }></TileViewLinkItem>
    </div>
  );
};

export default ProductListItem;
