import React from "react";
import { Container } from "./style";
import xIco from "../../../assets/icons/xIco.svg";
import { toast } from "react-toastify";
import folderIco from "../../../assets/icons/folder.png";
import { deleteFolder, getFolderDetail } from "services/documentServices";

interface Props {
  name: string;
  id: string;
  setActiveFolderId: any;
  activeFolderId: string;
  setActiveFolder: any;
  getAllFoldersFn: any;
  setEditedFolderId: any;
  setIsFolderEdited: any;
  showOptions: any;
}

export const SingleFolder: React.FC<Props> = ({
  name,
  id,
  setActiveFolderId,
  activeFolderId,
  setActiveFolder,
  getAllFoldersFn,
  setEditedFolderId,
  setIsFolderEdited,
  showOptions,
}) => {
  const handleClick = async (id: string) => {
    await deleteFolder(id);
    toast.success("Usunięto folder!");
    getAllFoldersFn();
    getFolderDetail(activeFolderId);
  };

  const handleEditClick = async (id: string) => {
    setEditedFolderId(id);
    setIsFolderEdited(true);
  };

  return (
    <Container
      onClick={() => {
        if (activeFolderId === id) {
          setActiveFolderId("");
          setActiveFolder({});
        } else {
          setActiveFolderId(id);
        }
      }}
    >
      <td>
        <img src={folderIco} alt="folder" />
      </td>
      <td>{name}</td>
      <td>{""}</td>
      <td>
        {showOptions && (
          <div
            style={{
              fontSize: "15px",
              textTransform: "uppercase",
              fontWeight: 400,
              width: "100%",
              height: "100%",
              background: "#f5be0c",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "4px 12px",
              color: "white",
            }}
            onClick={(e: any) => {
              e.stopPropagation();
              handleEditClick(id);
            }}
          >
            EDYTUJ
          </div>
        )}
      </td>
      <td>
        {showOptions && (
          <div
            style={{
              fontSize: "15px",
              textTransform: "uppercase",
              fontWeight: 400,
              width: "100%",
              height: "100%",
              background: "rgb(220,33,33)",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "4px 12px",
            }}
            onClick={(e: any) => {
              e.stopPropagation();
              handleClick(id);
            }}
          >
            USUŃ
          </div>
        )}
      </td>
    </Container>
  );
};

export default SingleFolder;
