import DigitalReleaseListItem from "./DigitalReleaseListItem";

interface IDigitalReleasesListProps {
  players: Array<any>;
  isDataLoading: boolean;
  lastItemRef: any;
  containerRef: any;
  sortBy: any;
}

const EventsList: React.FC<IDigitalReleasesListProps> = ({
  players,
  isDataLoading,
  lastItemRef,
  containerRef,
  sortBy,
}) => {
  const compare = (a: any, b: any, sort: any) => {
    if (a[sort] < b[sort]) {
      return 1;
    }
    if (a[sort] > b[sort]) {
      return -1;
    }
    return 0;
  };

  const sortingFunction = (type: number, array: any) => {
    switch (type) {
      case 0:
        return array.slice().reverse();
      case 1:
        return array;
      case 2:
        return array
          .slice()
          .sort((a: any, b: any) => compare(a, b, "Name"))
          .reverse();
      case 3:
        return array.slice().sort((a: any, b: any) => compare(a, b, "Name"));
      default:
        return array;
    }
  };

  const sortedArray = sortingFunction(sortBy?.value, players);
  return (
    <div
      style={{
        gridTemplateColumns: "repeat(auto-fit, minmax(550px, 1fr))",
        // gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "min-content",
        gap: "4px",
      }}
      className="flex flex-col md:grid"
      ref={containerRef}
    >
      {sortedArray.map((event: any, index: any) => {
        const isLast = index === players.length - 1;
        return isLast ? (
          <DigitalReleaseListItem
            key={event.eventId}
            // @ts-ignore
            event={event}
            innerRef={lastItemRef}
          />
        ) : (
          <DigitalReleaseListItem
            key={event.eventId}
            // @ts-ignore
            event={event}
          />
        );
      })}
      <div>{isDataLoading && "Loading..."} </div>
    </div>
  );
};

export default EventsList;
