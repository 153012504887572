import React, { Dispatch, SetStateAction } from 'react';
import DeliveryForm, { DeliveryType } from './DeliveryForm';
import {
  IDelivery,
  IUpdateDelivery,
} from 'types/marketpleace/delivery.interface';
import { toast } from 'react-toastify';
import systemServices from 'services/systemServices';
import { deliveryServices } from 'services/marketpleace';

interface IEditDeliveryProps {
  editDelivery: IUpdateDelivery;

  refresh: () => void;
  toggleEditMenuOpen: Dispatch<SetStateAction<boolean>>;
}

const EditDelivery = ({
  editDelivery,
  refresh,
  toggleEditMenuOpen,
}: IEditDeliveryProps) => {
  const addPhysicalDelivery = async (
    values: DeliveryType,
    base64: string,
    PhotoFilePath?: string
  ) => {
    let delivery = values as IUpdateDelivery;
    console.log('updated delivery', delivery);
    try {
      delivery.PhotoFilePath = PhotoFilePath ?? '';

      if (base64) {
        delivery.PhotoFilePath = await systemServices.uploadFile(base64);
        console.log('uploaded File: ', delivery.PhotoFilePath);
      }

      await deliveryServices.update(delivery);

      console.log('Updated delivery object: ', delivery);
      toast.success('Edytowano fizyczną dostawę');

      toggleEditMenuOpen(false);
      refresh();
    } catch (error) {
      console.error(error);
      toast.error('Wystąpił nieoczekiwany problem. Spróbuj ponownie później.');
    }
  };

  const getInitialValuesByDelivery: IUpdateDelivery = {
    ConditionForFree: editDelivery.ConditionForFree,
    Deadline: editDelivery.Deadline,
    DeliveryId: editDelivery.DeliveryId,
    Description: editDelivery.Description,
    Name: editDelivery.Name,
    Netto: editDelivery.Netto,
    PhotoFilePath: editDelivery.PhotoFilePath,
    Tax: editDelivery.Tax,
  };

  return (
    <DeliveryForm
      initialValues={getInitialValuesByDelivery}
      handleSubmit={addPhysicalDelivery}
    />
  );
};

export default EditDelivery;
