import { conn } from 'api/BaseConnection';
import {
  IDiscountTemplate,
  IDiscountTemplateResponse,
  ISaveDiscountTemplate,
} from 'types/marketpleace/discount.interface';

const endpoints = conn.endpoints.marketpleace.product.discountTemplate;

const get = async (): Promise<IDiscountTemplate[]> => {
  const response = await conn.getJSON<IDiscountTemplateResponse>(
    endpoints.get,
    'json'
  );

  return response.Templates;
};

const save = async (discount: ISaveDiscountTemplate): Promise<{}> => {
  return conn.postJSON(endpoints.save, 'json', discount);
};

const remove = async (DiscountTemplateId: string) => {
  return conn.deleteJSON(endpoints.delete, 'json', {
    DiscountTemplateId,
  });
};

const discard = async (ProductId: string, CombinationId: string) => {
  return conn.deleteJSON(endpoints.discard, 'json', {
    ProductId,
    CombinationId,
  });
};

const apply = async (
  ProductId: string,
  CombinationId: string,
  DiscountTemplateId: string
): Promise<{}> => {
  return conn.postJSON(endpoints.apply, 'json', {
    ProductId,
    CombinationId,
    DiscountTemplateId,
  });
};

const discountServices = {
  get,
  save,
  remove,
  apply,
  discard,
};

export default discountServices;
