import DropdownPanel from 'components/common/panels/DropdownPanel';
import React from 'react';
import { useProductDetailTabsContext } from '../../hook/useProductDetailTabsContext';

const ProductDescription = () => {
  const { data: product } = useProductDetailTabsContext();

  return (
    <DropdownPanel
      canAdd={false}
      canEdit={false}
      label='Opis produktu'
      render={() => (
        <p
          className='text-sm opacity-70 py-24 px-36'
          style={{
            overflowWrap: 'break-word',
            width: '100%',
          }}>
          {product.Description}
        </p>
      )}
    />
  );
};

export default ProductDescription;
