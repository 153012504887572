import { conn } from 'api/BaseConnection';
import { IListPageRequest, IListPageResponse } from 'types/globalTypes';
import {
  IAddProduct,
  IProductDto,
  IProductDetail,
  IUpdateProduct,
  IProductStatstics,
  PRODUCT_ACTIVATION_STATUS,
} from 'types/marketpleace/product.interface';
import { isValidDate } from 'utils/dateUtils';

const endpoint = conn.endpoints.marketpleace.product;

const add = async (product: IAddProduct) => {
  return await conn.postJSON(endpoint.create, 'json', product);
};

const getAll = async (listPage?: IListPageRequest) => {
  return await conn.getJSON<IListPageResponse<IProductDto>>(
    endpoint.getAll,
    'json',
    { ...listPage }
  );
};

const getFiltered = async (listPage?: IListPageRequest) => {
  const filteredProducts = await conn.getJSON<IListPageResponse<IProductDto>>(
    endpoint.getFiltered,
    'json',
    { ...listPage }
  );
  console.log(filteredProducts);
  return filteredProducts;
};

const getDetailById = async (productId: string) => {
  return await conn.getJSON<IProductDetail>(
    `${endpoint.getDetail}?ProductId=${productId}`,
    'json'
  );
};

const update = async (product: IUpdateProduct) => {
  return await conn.putJSON(endpoint.update, 'json', product);
};

const remove = async (ProductId: string) => {
  return await conn.deleteJSON(endpoint.delete, 'json', {
    ProductId,
  });
};

const addPhotoFiles = async (
  ProductId: string,
  PhotoFilesPath: string[]
): Promise<{}> => {
  return await conn.postJSON(endpoint.photo.add, 'json', {
    ProductId,
    PhotoFilesPath,
  });
};

const changeState = async (ProductId: string, Status: PRODUCT_ACTIVATION_STATUS) => {
  return await conn.patchJSON(endpoint.changeState, 'json', {ProductId, Status});
};

const removePhotoFile = async (
  ProductId: string,
  ProductPhotoFileId: string
) => {
  return await conn.deleteJSON(endpoint.photo.delete, 'json', {
    ProductId,
    ProductPhotoFileId,
  });
};

const addAdditionalFile = async (
  ProductId: string,
  Name: string,
  FilePath: string
): Promise<{}> => {
  return await conn.postJSON(endpoint.additionalFile.add, 'json', {
    ProductId,
    Name,
    FilePath,
  });
};

const removeAdditionalFile = async (
  ProductId: string,
  AdditionalFileId: string
) => {
  return await conn.deleteJSON(endpoint.additionalFile.delete, 'json', {
    ProductId,
    AdditionalFileId,
  });
};

const getStatistics = async (
  productId: string,
  from?: Date,
  to?: Date
): Promise<IProductStatstics> => {
  console.log(from);
  return await conn.getJSON(endpoint.getStatistics, 'json', {
    ProductId: productId,
    From: from && isValidDate(from) ? from : new Date(0),
    To: to && isValidDate(to) ? to : new Date(),
  });
};

const productServices = {
  add,
  getAll,
  getDetailById,
  getFiltered,
  getStatistics,
  update,
  changeState,
  remove,
  addPhotoFiles,
  removePhotoFile,
  addAdditionalFile,
  removeAdditionalFile,
};

export default productServices;
