import { useContext, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import React from "react";
import { RootState } from "../../store/store";
import ContentContainer from "../layout/ContentContainer";
import { DefaultSortContext } from "contexts/defaultSortContext";
import StructureTopbar from "./StructureTopBar";
import styled from "styled-components";
import SingleFolder from "./SingleFolder";
import SingleUser from "./SingleUser";
import ColumnBackground from "assets/images/structure-column.png";
import ColumnFolder from "assets/images/column-folder.png";
import Arrow from "assets/icons/structure-arrow.png";
import Folders from "assets/icons/structure-folders.png";
// @ts-ignore
import Modal from "react-modal";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import {
  addItemToFolder,
  copyItemToFolder,
  createFolder,
  createSubFolder,
  deleteFolder,
  deleteFolderItem,
  getAvailablePlayerFolders,
  getFolderDetail,
  getRootFolder,
  getSearchFolderPath,
  getSearchFolders,
  grantFolderToTrainer,
  replaceItem,
  revokeFolderToTrainer,
  transferItemToFolder,
  updateFolderName,
} from "services/folderServices";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import { showServerErrors } from "utils/errorsUtils";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import PlayersDetail from "components/players/detail/PlayersDetail";
import StructureFolderDetail from "components/players/detail/StructureFolderDetail";
import AddDigitalRelease from "components/events/form/AddDigitalRelease";
import {
  getAcademyTrainers,
  getAvailableTrainers,
} from "services/transferServices";
import SingleTrainer from "./SingleTrainer";
import SearchSingleUser from "./SearchSingleUser";
import SearchSingleTrainer from "./SearchSingleTrainer";
import SearchSingleFolder from "./SearchSingleFolder";
import TrainersDetail from "components/trainers/detail/TrainersDetail";
import chatServices from "services/chatServices";
import { AllConversationsContext } from "contexts/allConversationsContext";
import playerService from "services/transfer/playerServices";

export const StyledMenu = styled.div`
  position: fixed;
  /* border: 1px solid #ccc; */
  padding: 5px;
  background: rgba(17, 27, 35, 0.76);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px;

  & > div {
    color: white;
    cursor: pointer;
    transition: all 0.05s ease-in-out;
    font-size: 12px;
    height: 38px;
    background: rgb(12 74 110);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > div:hover {
    background: rgba(0, 145, 255, 0.3);
    transition: all 0.05s ease-in-out;
  }
`;

const ContextMenu = ({ x, y, onClose, setIsFolderAdded }: any) => {
  const [clickedInside, setClickedInside] = useState(false);

  const handleClick = () => {
    setClickedInside(true);
  };

  useEffect(() => {
    const closeMenu = () => {
      if (!clickedInside) {
        onClose();
      }
    };

    document.addEventListener("click", closeMenu);

    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, [clickedInside, onClose]);

  return (
    <StyledMenu
      style={{
        position: "fixed",
        top: y,
        left: x,
        width: "200px",
      }}
      onClick={handleClick}
    >
      <div onClick={() => setIsFolderAdded(true)}>Dodaj grupę</div>
      {/* <div>Menu Item 2</div> */}
    </StyledMenu>
  );
};

const TrainerContextMenu = ({
  x,
  y,
  onClose,
  isRootFolder,
  clickedColumnId,
  clickedTrainerId,
  columns,
  getFolderDetailWithoutIndex,
  setActiveConversationId,
}: any) => {
  const [trainerClickedInside, setTrainerClickedInside] = useState(false);
  const { push } = useHistory();
  const handleClick = () => {
    setTrainerClickedInside(true);
  };

  const createConversationFn = async (clickedTrainerId: string) => {
    const resp2: any = await chatServices.getPrivateConversationById(
      clickedTrainerId,
      2,
    );
    console.log("RESP2>>>", resp2);

    if (resp2 === null) {
      await chatServices.createNewConversation(clickedTrainerId, 2);
      await toast.success("Stworzono konwersacje!");
      await push("/chat");
    } else {
      await setActiveConversationId(resp2?.ConversationId);

      await push("/chat");
    }
  };

  const revokeTrainer = async () => {
    await revokeFolderToTrainer(clickedColumnId, clickedTrainerId);
    columns.map((column: any) => {
      getFolderDetailWithoutIndex(column.id);
    });
  };

  useEffect(() => {
    const closeFolderMenu = () => {
      if (!trainerClickedInside) {
        onClose();
      }
    };

    document.addEventListener("click", closeFolderMenu);

    return () => {
      document.removeEventListener("click", closeFolderMenu);
    };
  }, [trainerClickedInside, onClose]);

  return (
    <StyledMenu
      style={{
        position: "fixed",
        top: y,
        left: x,
        width: "200px",
      }}
      onClick={handleClick}
    >
      <div onClick={() => createConversationFn(clickedTrainerId)}>
        Napisz wiadomość
      </div>
      <div>Menadżer</div>

      {!isRootFolder && (
        <div onClick={() => revokeTrainer()}>Usuń z folderu</div>
      )}
    </StyledMenu>
  );
};
const PlayerContextMenu = ({
  x,
  y,
  clickedPlayer,
  onClose,
  clickedPlayerId,
  setIsColumnFolderAdded,
  clickedItemId,
  clickedColumnId,
  getFolderDetailWithoutIndex,
  getAvailablePlayers,
  getRootFolderFn,
  isRootFolder,
  setCopyFolderFrom,
  setCopyItemId,
  setCopyPlayerId,
  setActiveConversationId,
  setActiveConversationImageUrl,
  setActiveConversationName,
  setActiveConversationType,
  setConversation,
  setTempMemberId,
  clickedRootPlayer,
  setClickedRootPlayer,
}: any) => {
  const [playerClickedInside, setPlayerClickedInside] = useState(false);
  const { push } = useHistory();
  const handleClick = () => {
    setPlayerClickedInside(true);
  };

  const createConversationFn = async (clickedPlayerId: string) => {
    const resp2: any = await chatServices.getPrivateConversationById(
      clickedPlayerId ? clickedPlayerId : clickedRootPlayer,
      1,
    );
    console.log("ASDCLICKEDPLAYERID", clickedPlayerId);
    console.log("SDclickedRootPlayer", clickedRootPlayer);
    console.log("RESP2>>>", resp2);
    const detail = await playerService.getDetail(
      clickedPlayerId ? clickedPlayerId : clickedRootPlayer,
    );

    console.log("Playerdeatails", detail);
    if (resp2 === null) {
      console.log("XAXAAX", detail);
      // await chatServices.createNewConversation(playerId? playerId :id, 1);

      setActiveConversationId("");
      setActiveConversationImageUrl(detail.PhotoFilePath);
      setActiveConversationName(detail.Name);
      setActiveConversationType(1);
      setConversation([]);
      setTempMemberId(clickedPlayerId ? clickedPlayerId : clickedRootPlayer);

      // await toast.success("Stworzono konwersacje!");
      await push("/chat");
    } else {
      await setActiveConversationId(resp2?.ConversationId);

      await push("/chat");
    }
  };

  const deleteFolderItemFn = async (FolderId: string, FolderItemId: string) => {
    await deleteFolderItem(FolderId, FolderItemId);
    await getFolderDetailWithoutIndex(FolderId);
    getAvailablePlayers();
    getRootFolderFn();
    toast.error("Usunięto zawodnika!");
  };

  const copyPlayer = (folderFrom: string, ItemId: String, PlayerId: string) => {
    setCopyFolderFrom(folderFrom);
    setCopyItemId(ItemId);
    setCopyPlayerId(PlayerId);
    toast.success("Skopiowano zawodnika!");
  };

  useEffect(() => {
    const closeFolderMenu = () => {
      if (!playerClickedInside) {
        onClose();
      }
    };

    document.addEventListener("click", closeFolderMenu);

    return () => {
      document.removeEventListener("click", closeFolderMenu);
    };
  }, [playerClickedInside, onClose]);

  return (
    <StyledMenu
      style={{
        position: "fixed",
        top: y,
        left: x,
        width: "200px",
      }}
      onClick={handleClick}
    >
      <div onClick={() => createConversationFn(clickedPlayerId)}>
        Napisz wiadomość
      </div>
      {!isRootFolder && (
        <div
          onClick={() =>
            copyPlayer(clickedColumnId, clickedItemId, clickedPlayerId)
          }
        >
          Kopiuj
        </div>
      )}
      {!isRootFolder && (
        <div onClick={() => deleteFolderItemFn(clickedColumnId, clickedItemId)}>
          Usuń z folderu
        </div>
      )}

      <div
        onClick={() => push(`/players/detail/${clickedPlayerId}?tab=passes`)}
      >
        Przypisz składkę
      </div>
    </StyledMenu>
  );
};
const FolderContextMenu = ({
  x,
  y,
  onClose,
  setIsFolderNameEdited,
  clickedFolderId,
  getRootFolderFn,
  getFolderDetailWithoutIndex,
  columns,
  getAvailablePlayers,
  setColumns,
  getFolderDetailFn,
  setIsEventModalOpen,
  clickedFolderIndex,
  clickedFolderPlayers,
  getSearchFolderDetailFn,
  isSearching,
  setIsFolderModalOpen,
  setSelectedFolderId,
  setChangeNameType,
  setActiveConversationId,
  setActiveConversationType,
  setActiveConversationImageUrl,
  setActiveConversationName,
  setConversation,
  setTempMemberId,
  setAllInterlocutors,
  auxFolderName,
}: any) => {
  const [folderClickedInside, setFolderClickedInside] = useState(false);
  const { push } = useHistory();
  const handleClick = () => {
    setFolderClickedInside(true);
  };

  const deleteFolderFn = async (FolderId: string) => {
    await deleteFolder(FolderId);
    getRootFolderFn();

    const columnIndex = columns.findIndex((column: any) =>
      column.folders.some((folder: any) => folder.id === FolderId),
    );

    if (columnIndex !== -1) {
      const filteredColumns = columns.filter(
        (_: any, index: any) => index <= columnIndex,
      );

      setColumns(filteredColumns);

      filteredColumns.forEach((column: any) => {
        getFolderDetailWithoutIndex(column?.id);
      });
    }

    getAvailablePlayers();
    toast.error("Usunięto folder!");
  };

  useEffect(() => {
    const closeFolderMenu = () => {
      if (!folderClickedInside) {
        onClose();
      }
    };

    document.addEventListener("click", closeFolderMenu);

    return () => {
      document.removeEventListener("click", closeFolderMenu);
    };
  }, [folderClickedInside, onClose]);

  const createGroupConversationFn = async (FolderId: string) => {
    const resp: any = await getFolderDetail(FolderId);
    const resp2: any = await chatServices.getGroupConversationById(FolderId);
    console.log("RESP2>>>", resp2);

    if (resp2 === null) {
      console.log("respGetFolderDetail", resp.Data);
      const itemsInterlocutors = resp.Data.Items.map((item: any) => ({
        InterlocutorId: item.PlayerId,
        ProfileType: 1,
      }));

      const trainersInterlocutors = resp.Data.GrantTrainers.map(
        (trainer: any) => ({
          InterlocutorId: trainer.TrainerId,
          ProfileType: 2,
        }),
      );

      const allInterlocutorsArray = [
        ...itemsInterlocutors,
        ...trainersInterlocutors,
      ];
      if (resp.Data.Items.length === 0 && resp.Data.GrantTrainers.length === 0)
        return toast.error("Folder jest pusty!");

      setActiveConversationId("");
      setActiveConversationType(2);
      setActiveConversationImageUrl("");
      setActiveConversationName(auxFolderName);
      setConversation([]);
      setTempMemberId(FolderId);
      setAllInterlocutors(allInterlocutorsArray);

      // await chatServices.createNewGroupConversation(
      //   resp.Data.Name,
      //   "",
      //   allInterlocutors,
      //   FolderId,
      // );
      // await toast.success("Stworzono grupową konwersacje!");
      await push("/chat");
    } else {
      await setActiveConversationId(FolderId);

      await push("/chat");
    }
  };

  return (
    <StyledMenu
      style={{
        position: "fixed",
        top: y,
        left: x,
        width: "250px",
      }}
      onClick={handleClick}
    >
      <div
        // onClick={() => getFolderDetailFn(clickedFolderId, clickedFolderIndex) }
        onClick={() =>
          isSearching
            ? getSearchFolderDetailFn(clickedFolderId, clickedFolderIndex)
            : getFolderDetailFn(clickedFolderId, clickedFolderIndex)
        }
      >
        Otwórz folder
      </div>
      <div
        onClick={() => {
          setIsFolderNameEdited(true);
          setChangeNameType("folder");
        }}
      >
        Zmień nazwę
      </div>
      <div onClick={() => deleteFolderFn(clickedFolderId)}>Usuń</div>
      {/* <div>Kopiuj</div> */}
      <div onClick={() => createGroupConversationFn(clickedFolderId)}>
        Utwórz konwersacje grupową
      </div>
      <div
        onClick={() => {
          setIsFolderModalOpen(true);
          setSelectedFolderId(clickedFolderId);
          push("/structure?tab=contributions");
        }}
      >
        Przypisz składki
      </div>
      <div
        onClick={() => {
          setIsFolderModalOpen(true);
          setSelectedFolderId(clickedFolderId);
          push("/structure?tab=manager");
        }}
      >
        Przypisz trenera
      </div>
      <div
        onClick={() => {
          setIsFolderModalOpen(true);
          setSelectedFolderId(clickedFolderId);
          push("/structure");
        }}
      >
        Info
      </div>
      {clickedFolderPlayers.length > 0 && (
        <div onClick={() => setIsEventModalOpen(true)}>Utwórz zajęcie</div>
      )}
    </StyledMenu>
  );
};

const ColumnContextMenu = ({
  x,
  y,
  onClose,
  setIsColumnFolderAdded,
  copyFolderFrom,
  copyFolderTo,
  copyItemId,
  copyPlayerId,
  clickedColumnId,
  columns,
  getFolderDetailWithoutIndex,
  setIsFolderNameEdited,
  setIsFolderModalOpen,
  clickedFolderPlayers,
  setSelectedFolderId,
  setIsEventModalOpen,
  clickedFolderId,
  getRootFolderFn,
  setColumns,
  getAvailablePlayers,
  activeColumnId,
  setChangeNameType,
  setActiveConversationId,
  setActiveConversationType,
  setActiveConversationImageUrl,
  setActiveConversationName,
  setConversation,
  setTempMemberId,
  setAllInterlocutors,
  auxFolderName,
}: any) => {
  const [columnClickedInside, setColumnClickedInside] = useState(false);
  const { push } = useHistory();

  const handleClick = () => {
    setColumnClickedInside(true);
  };

  const isPlayerInFolder = (folderId: any, playerId: any) => {
    const folder = columns.find((column: any) => column.id === folderId);

    if (folder) {
      const isPlayerExists = folder.items.some(
        (item: any) => item.playerId === playerId,
      );
      return isPlayerExists;
    }

    return false;
  };

  const copyItemToFolderFn = async (
    FolderFromId: string,
    FolderToId: string,
    CopyItemId: string,
    PlayerId: string,
  ) => {
    const isPlayerInDestinationFolder = isPlayerInFolder(FolderToId, PlayerId);

    if (!isPlayerInDestinationFolder) {
      await copyItemToFolder(FolderFromId, FolderToId, CopyItemId, PlayerId);
      toast.success("Wklejono zawodnika!");

      columns.map((column: any) => {
        getFolderDetailWithoutIndex(column.id);
      });
    } else {
      toast.error("Ten zawodnik już istnieje w folderze!");
    }
  };

  const createGroupConversationFn = async (FolderId: string) => {
    const resp: any = await getFolderDetail(FolderId);
    const resp2: any = await chatServices.getGroupConversationById(FolderId);
    console.log("RESP2>>>", resp2);

    if (resp2 === null) {
      console.log("respGetFolderDetail", resp.Data);

      const itemsInterlocutors = resp.Data.Items.map((item: any) => ({
        InterlocutorId: item.PlayerId,
        ProfileType: 1,
      }));

      const trainersInterlocutors = resp.Data.GrantTrainers.map(
        (trainer: any) => ({
          InterlocutorId: trainer.TrainerId,
          ProfileType: 2,
        }),
      );

      const allInterlocutorsArray = [
        ...itemsInterlocutors,
        ...trainersInterlocutors,
      ];

      if (resp.Data.Items.length === 0 && resp.Data.GrantTrainers.length === 0)
        return toast.error("Folder jest pusty!");

      setActiveConversationId("");
      setActiveConversationType(2);
      setActiveConversationImageUrl("");
      setActiveConversationName(auxFolderName);
      setConversation([]);
      setTempMemberId(FolderId);
      setAllInterlocutors(allInterlocutorsArray);

      // await chatServices.createNewGroupConversation(
      //   resp.Data.Name,
      //   "",
      //   allInterlocutors,
      //   FolderId,
      // );
      // await toast.success("Stworzono grupową konwersacje!");
      await push("/chat");
    } else {
      await setActiveConversationId(FolderId);

      await push("/chat");
    }
  };

  const deleteFolderFn = async (FolderId: string) => {
    await deleteFolder(FolderId);
    getRootFolderFn();

    if (columns.length === 1) {
      setColumns([]);
    }

    const columnIndex = columns.findIndex((column: any) =>
      column.folders.some((folder: any) => folder.id === FolderId),
    );

    if (columnIndex !== -1) {
      const filteredColumns = columns.filter(
        (_: any, index: any) => index <= columnIndex,
      );

      setColumns(filteredColumns);

      filteredColumns.forEach((column: any) => {
        getFolderDetailWithoutIndex(column?.id);
      });
    }

    getAvailablePlayers();
    toast.error("Usunięto folder!");
    setColumns([]);
  };

  useEffect(() => {
    const closeMenu = () => {
      if (!columnClickedInside) {
        onClose();
      }
    };

    document.addEventListener("click", closeMenu);

    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, [columnClickedInside, onClose]);

  return (
    <StyledMenu
      style={{
        position: "fixed",
        top: y,
        left: x,
        width: "200px",
      }}
      onClick={handleClick}
    >
      <div onClick={() => setIsColumnFolderAdded(true)}>Dodaj grupę</div>
      <div
        onClick={() => {
          setIsFolderNameEdited(true);
          setChangeNameType("column");
        }}
      >
        Zmień nazwę
      </div>
      <div onClick={() => deleteFolderFn(activeColumnId)}>Usuń</div>
      {/* <div>Kopiuj</div> */}
      <div onClick={() => createGroupConversationFn(activeColumnId)}>
        Utwórz konwersacje grupową
      </div>

      <div
        onClick={() => {
          setIsFolderModalOpen(true);
          setSelectedFolderId(activeColumnId);
          push("/structure?tab=contributions");
        }}
      >
        Przypisz składki
      </div>

      <div
        onClick={() => {
          setIsFolderModalOpen(true);
          setSelectedFolderId(activeColumnId);
          push("/structure?tab=manager");
        }}
      >
        Przypisz trenera
      </div>
      <div
        onClick={() => {
          setIsFolderModalOpen(true);
          setSelectedFolderId(activeColumnId);
          push("/structure");
        }}
      >
        Info
      </div>
      {clickedFolderPlayers.length > 0 && (
        <div onClick={() => setIsEventModalOpen(true)}>Utwórz zajęcie</div>
      )}
      {copyItemId && (
        <div
          onClick={() =>
            copyItemToFolderFn(
              copyFolderFrom,
              clickedColumnId,
              copyItemId,
              copyPlayerId,
            )
          }
        >
          Wklej
        </div>
      )}
      {/* <div>Menu Item 2</div> */}
    </StyledMenu>
  );
};

const Structure: React.FC = () => {
  const structureContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  let scrollAnimation: any = null;

  const [
    allConversations,
    setAllConversations,
    filter,
    setFilter,
    searchQuery,
    setSearchQuery,
    conversation,
    setConversation,
    conversationMembers,
    setConversationMembers,
    activeConversationImageUrl,
    setActiveConversationImageUrl,
    activeConversationName,
    setActiveConversationName,
    onlineProfiles,
    setOnlineProfiles,
    activeConversationId,
    setActiveConversationId,
    activeConversationType,
    setActiveConversationType,
    tempMemberId,
    setTempMemberId,
    unreadMessagesCounter,
    setUnreadMessagesCounter,
    allInterlocutors,
    setAllInterlocutors,
    auxFolderName,
    setAuxFolderName,
  ] = useContext(AllConversationsContext);

  const handleScroll = (direction: any) => {
    const container: any = structureContainerRef.current;

    if (container) {
      const scrollAmount = direction === "left" ? -5 : 5;

      const scroll = () => {
        container.scrollLeft += scrollAmount;
        scrollAnimation = requestAnimationFrame(scroll);
      };

      cancelAnimationFrame(scrollAnimation);

      scroll();
    }
  };

  const stopScroll = () => {
    cancelAnimationFrame(scrollAnimation);
  };

  const [defaultSort, setDefaultSort] = useContext(DefaultSortContext);
  const [sortBy, setSortBy] =
    useState<{ value: number; label: string } | null>(null);
  const [sorts, setSorts] = useState<{ value: number; label: string }[]>([]);
  const [isFolderAdded, setIsFolderAdded] = useState(false);
  const [isFolderNameEdited, setIsFolderNameEdited] = useState(false);
  const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
  const [isTrainerModalOpen, setIsTrainerModalOpen] = useState(false);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [isColumnFolderAdded, setIsColumnFolderAdded] = useState(false);
  const [activeColumnId, setActiveColumnId] = useState("");
  const [newFolderName, setNewFolderName] = useState("");

  const [copyFolderFrom, setCopyFolderFrom] = useState("");
  const [copyFolderTo, setCopyFolderTo] = useState("");
  const [copyItemId, setCopyItemId] = useState("");
  const [copyPlayerId, setCopyPlayerId] = useState("");

  const [editedFolderName, setEditedFolderName] = useState("");
  const [newColumnFolderName, setNewColumnFolderName] = useState("");
  const [activeFolders, setActiveFolders] = useState<{
    [key: string]: string | null;
  }>({});
  const [activeFoldersName, setActiveFoldersName] = useState<{
    [key: string]: string | null;
  }>({});
  const [currentColumnIndex, setCurrentColumnIndex] = useState<any>(null);
  const [searchFolders, setSearchFolders] = useState([]);
  const [searchFoldersPath, setSearchFoldersPath] = useState([]);
  const [customSearchFoldersPath, setCustomSearchFoldersPath] = useState<any>(
    [],
  );

  const closeFolderModal = () => {
    setIsFolderAdded(false);
  };

  const closeFolderEditNameModal = () => {
    setIsFolderNameEdited(false);
  };

  const closePlayerModalOpen = () => {
    setIsPlayerModalOpen(false);
  };

  const closeTrainerModalOpen = () => {
    setIsTrainerModalOpen(false);
  };

  const closeEventModalOpen = () => {
    setIsEventModalOpen(false);
  };

  const closeFolderModalOpen = () => {
    setIsFolderModalOpen(false);
  };

  const closeColumnFolderModal = () => {
    setIsColumnFolderAdded(false);
  };

  const updateFolderNameFn = async (FolderId: string, NewName: string) => {
    await updateFolderName(FolderId, NewName);
    getRootFolderFn();
    setEditedFolderName("");
    setIsFolderNameEdited(false);
    getFolderDetailWithoutIndex(FolderId);
    toast.success("Zaktualizowano nazwe folderu!");
    columns.map((column: any) => {
      getFolderDetailWithoutIndex(column.id);
    });
  };

  const [columns, setColumns] = useState<any[]>([]);
  const [searchColumns, setSearchColumns] = useState<any[]>([]);
  const [queryString, setQueryString] = useState("");
  const [viewType, setViewType] = useState("list");
  const [rootFolder, setRootFolder] = useState([]);

  const [availablePlayers, setAvailablePlayers] = useState([]);
  const [availableTrainers, setAvailableTrainers] = useState([]);
  const [selectedFolderId, setSelectedFolderId] = useState<any>(null);
  const [selectedPlayerId, setSelectedPlayerId] = useState<any>("");
  const [selectedTrainerId, setSelectedTrainerId] = useState<any>("");
  const [clickedItem, setClickedItem] = useState<any>(null);
  const [clickedTrainer, setClickedTrainer] = useState<any>(null);
  const [clickedFolder, setClickedFolder] = useState<any>(null);
  const [clickedPlayer, setClickedPlayer] = useState<any>(null);
  const [clickedRootPlayer, setClickedRootPlayer] = useState<any>(null);
  const [clickedItemId, setClickedItemId] = useState<any>(null);
  const [clickedTrainerId, setClickedTrainerId] = useState<any>(null);
  const [clickedPlayerId, setClickedPlayerId] = useState<any>(null);
  const [clickedColumnId, setClickedColumnId] = useState<any>(null);
  const [columnClickedItem, setColumnClickedItem] = useState<any>(null);
  const [clickedInside, setClickedInside] = useState(false);
  const [folderClickedInside, setFolderClickedInside] = useState(false);
  const [playerClickedInside, setPlayerClickedInside] = useState(false);
  const [columnClickedInside, setColumnClickedInside] = useState(false);
  const [clickedFolderId, setClickedFolderId] = useState("");
  const [hoverFolderId, setHoverFolderId] = useState("");
  const [clickedFolderPlayers, setClickedFolderPlayers] = useState([]);
  const [clickedFolderIndex, setClickedFolderIndex] = useState<any>(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [changeNameType, setChangeNameType] = useState("");
  const handleNewFolderNameChange = (e: any) => {
    setNewFolderName(e.target.value);
  };

  const handleEditedFolderNameChange = (e: any) => {
    setEditedFolderName(e.target.value);
  };

  const handleNewColumnFolderNameChange = (e: any) => {
    setNewColumnFolderName(e.target.value);
  };

  useEffect(() => {
    console.log("hoverFolderId", hoverFolderId);
  }, [hoverFolderId]);

  useEffect(() => {
    const handleClickOutside = () => {
      if (clickedInside) {
        setClickedInside(false);
      } else {
        setClickedItem(null);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [clickedInside]);

  useEffect(() => {
    const handleColumnClickOutside = () => {
      if (columnClickedInside) {
        setColumnClickedInside(false);
      } else {
        setColumnClickedItem(null);
      }
    };

    document.addEventListener("click", handleColumnClickOutside);

    return () => {
      document.removeEventListener("click", handleColumnClickOutside);
    };
  }, [columnClickedInside]);

  useEffect(() => {
    const handleFolderClickOutside = () => {
      if (folderClickedInside) {
        setFolderClickedInside(false);
      } else {
        setClickedFolder(null);
      }
    };

    document.addEventListener("click", handleFolderClickOutside);

    return () => {
      document.removeEventListener("click", handleFolderClickOutside);
    };
  }, [folderClickedInside]);

  useEffect(() => {
    if (selectedTrainerId) {
      setSelectedPlayerId("");
      setSelectedFolderId("");
    }
  }, [selectedTrainerId]);

  useEffect(() => {
    if (selectedFolderId) {
      setSelectedPlayerId("");
      setSelectedTrainerId("");
    }
  }, [selectedFolderId]);

  useEffect(() => {
    if (selectedPlayerId) {
      setSelectedFolderId("");
      setSelectedTrainerId("");
    }
  }, [selectedPlayerId]);

  useEffect(() => {
    const handlePlayerClickOutside = () => {
      if (playerClickedInside) {
        setPlayerClickedInside(false);
      } else {
        setClickedPlayer(null);
      }
    };

    document.addEventListener("click", handlePlayerClickOutside);

    return () => {
      document.removeEventListener("click", handlePlayerClickOutside);
    };
  }, [playerClickedInside]);

  const getRootFolderFn = async () => {
    try {
      setIsLoading(true);

      const data = await getRootFolder();

      setRootFolder(data.Data.Items);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRootFolderFn();
  }, []);

  const getAvailablePlayers = async () => {
    try {
      setIsLoading(true);
      const data = await getAvailablePlayerFolders();

      setAvailablePlayers(data.Data.Items);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAvailablePlayers();
  }, []);

  const getAvailableTrainersFn = async () => {
    try {
      setIsLoading(true);
      const data = await getAcademyTrainers();

      setAvailableTrainers(data.Data.Items);
      console.log("TRAINERS", data.Data.Items);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAvailableTrainersFn();
  }, []);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "80%",
      maxHeight: "80%",
      background: "rgba(0, 0, 0, 0.8)",
    },
  };

  const customEditFolderStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "80%",
      maxHeight: "80%",
      background: "rgba(17,27,35,0.5)",
      border: "1px solid rgba(0,145,255,0.15)",
      borderRadius: "7px",
    },
  };

  const customStylesForPlayers = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "80%",
      maxHeight: "80%",
      width: "80%",
      height: "80%",
      background: "#1a2530",
      margin: 0,
      padding: 0,
      border: "2px solid lightgray",
      borderTop: "30px solid lightgray",
    },
  };

  const customStylesForEvents = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "917px",
      maxHeight: "80%",
      width: "917px",
      height: "80%",
      background: "#1a2530",
      margin: 0,
      padding: 0,
      border: "2px solid lightgray",
      borderTop: "30px solid lightgray",
    },
  };

  const getFolderDetailWithoutIndexSearch = async (FolderId: string) => {
    try {
      const data = await getFolderDetail(FolderId);

      const columnIndex = searchColumns.findIndex((col) => col.id === FolderId);

      if (columnIndex !== -1) {
        const items = data.Data.Items.map((item: any) => ({
          id: item.FolderItemId,
          name: item.Name,
          playerId: item.PlayerId,
          type: "item",
          order: item.Order,
          photoFilePath: item.PhotoFilePath,
        }));

        const trainers = data.Data.GrantTrainers.map((trainer: any) => ({
          id: trainer.TrainerId,
          type: "trainer",
          name: trainer.Name,
          playerId: trainer.TrainerId,
          order: -1,
          photoFilePath: trainer.PhotoFilePath,
          specialization: trainer.Type,
          isRootFolder: trainer.IsRootFolder,
        }));

        const folders = data.Data.SubFolders.map((folder: any) => ({
          id: folder.FolderId,
          name: folder.Name,
          type: "folder",
          trainers: folder.GrantTrainers.map((folderItem: any) => ({
            id: folderItem.FolderItemId,
            name: folderItem.Name,
            playerId: folderItem.PlayerId,
            type: "trainer",
            photoFilePath: folderItem.PhotoFilePath,
            specialization: folderItem.Type,
            isRootFolder: folderItem.IsRootFolder,
          })),
          items: folder.Items.map((folderItem: any) => ({
            id: folderItem.TrainerId,
            name: folderItem.Name,
            playerId: "",
            type: "item",
            order: folderItem.Order,
            photoFilePath: folderItem.PhotoFilePath,
          })),
        }));

        setSearchColumns((prevColumns) => {
          const updatedColumns = [...prevColumns];
          updatedColumns[columnIndex] = {
            ...updatedColumns[columnIndex],
            folders: folders,
            items: items,
            trainers: trainers,
            // data: {
            //   ...updatedColumns[columnIndex].data,
            //   ...data,
            // },
          };
          return updatedColumns;
        });
      } else {
        console.warn(`Nie znaleziono kolumny o ID: ${FolderId}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFolderDetailWithoutIndex = async (FolderId: string) => {
    try {
      const data = await getFolderDetail(FolderId);

      const columnIndex = columns.findIndex((col) => col.id === FolderId);

      if (columnIndex !== -1) {
        const items = data.Data.Items.map((item: any) => ({
          id: item.FolderItemId,
          name: item.Name,
          playerId: item.PlayerId,
          type: "item",
          order: item.Order,
          photoFilePath: item.PhotoFilePath,
        }));

        const trainers = data.Data.GrantTrainers.map((trainer: any) => ({
          id: trainer.TrainerId,
          type: "trainer",
          name: trainer.Name,
          playerId: trainer.TrainerId,
          order: -1,
          photoFilePath: trainer.PhotoFilePath,
          specialization: trainer.Type,
          isRootFolder: trainer.IsRootFolder,
        }));

        const folders = data.Data.SubFolders.map((folder: any) => ({
          id: folder.FolderId,
          name: folder.Name,
          type: "folder",
          trainers: folder.GrantTrainers.map((folderItem: any) => ({
            id: folderItem.FolderItemId,
            name: folderItem.Name,
            playerId: folderItem.PlayerId,
            type: "trainer",
            photoFilePath: folderItem.PhotoFilePath,
            specialization: folderItem.Type,
            isRootFolder: folderItem.IsRootFolder,
          })),
          items: folder.Items.map((folderItem: any) => ({
            id: folderItem.TrainerId,
            name: folderItem.Name,
            playerId: "",
            type: "item",
            order: folderItem.Order,
            photoFilePath: folderItem.PhotoFilePath,
          })),
        }));

        setColumns((prevColumns) => {
          const updatedColumns = [...prevColumns];
          updatedColumns[columnIndex] = {
            ...updatedColumns[columnIndex],
            folders: folders,
            items: items,
            trainers: trainers,
            // data: {
            //   ...updatedColumns[columnIndex].data,
            //   ...data,
            // },
          };
          return updatedColumns;
        });
      } else {
        console.warn(`Nie znaleziono kolumny o ID: ${FolderId}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const getFolderDetailReplaceItem = async (FolderId: string) => {
  //   try {
  //     const data = await getFolderDetail(FolderId);

  //     const columnIndex = columns.findIndex((col) => col.id === FolderId);

  //     if (columnIndex !== -1) {
  //       const items = data.Data.Items.map((item: any) => ({
  //         id: item.FolderItemId,
  //         name: item.Name,
  //         playerId: item.PlayerId,
  //         type: "item",
  //         order: item.Order,
  //         photoFilePath: item.PhotoFilePath,
  //       }));

  //       setColumns((prevColumns) => {
  //         const updatedColumns = [...prevColumns];
  //         updatedColumns[columnIndex] = {
  //           ...updatedColumns[columnIndex],
  //           items: [],
  //         };
  //         return updatedColumns;
  //       });

  //       setColumns((prevColumns) => {
  //         const updatedColumns = [...prevColumns];
  //         updatedColumns[columnIndex] = {
  //           ...updatedColumns[columnIndex],
  //           items: items,
  //         };
  //         return updatedColumns;
  //       });
  //     } else {
  //       console.warn(`Nie znaleziono kolumny o ID: ${FolderId}`);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getFirstSearchFolderDetailFn = async (
    FolderId: string,
    columnIndex: number,
  ) => {
    try {
      const data = await getFolderDetail(FolderId);

      setCurrentColumnIndex(columnIndex);
      setSearchColumns([]);
      setSearchColumns((prevColumns) => [
        {
          id: FolderId,
          folders: data?.Data?.SubFolders.map((subFolder: any) => ({
            id: subFolder.FolderId,
            type: "folder",
            name: subFolder.Name,
            items: subFolder.Items,
          })),
          items: data?.Data?.Items.map((item: any) => ({
            id: item.FolderItemId,
            type: "item",
            name: item.Name,
            playerId: item.PlayerId,
            order: item.Order,
            photoFilePath: item.PhotoFilePath,
          })),
          trainers: data?.Data?.GrantTrainers.map((trainer: any) => ({
            id: trainer.TrainerId,
            type: "trainer",
            name: trainer.Name,
            playerId: trainer.TrainerId,
            order: -1,
            photoFilePath: trainer.PhotoFilePath,
            specialization: trainer.Type,
            isRootFolder: trainer.IsRootFolder,
          })),
        },
      ]);
    } catch (error) {
      console.error(error);
    }
  };
  const getSearchFolderDetailFn = async (
    FolderId: string,
    columnIndex: number,
  ) => {
    try {
      const data = await getFolderDetail(FolderId);

      setCurrentColumnIndex(columnIndex);

      // setActiveFolders((prevActiveFolders) => {
      //   const updatedActiveFolders = { ...prevActiveFolders };

      //   updatedActiveFolders[columnIndex.toString()] = FolderId;

      //   for (const key in updatedActiveFolders) {
      //     const keyIndex = parseInt(key, 10);
      //     if (keyIndex > columnIndex) {
      //       delete updatedActiveFolders[key];
      //     }
      //   }

      //   return updatedActiveFolders;
      // });

      // setActiveFoldersName((prevActiveFolders) => {
      //   const updatedActiveFolders = { ...prevActiveFolders };

      //   updatedActiveFolders[columnIndex.toString()] = data.Data.Name;

      //   for (const key in updatedActiveFolders) {
      //     const keyIndex = parseInt(key, 10);
      //     if (keyIndex > columnIndex) {
      //       delete updatedActiveFolders[key];
      //     }
      //   }

      //   return updatedActiveFolders;
      // });
      // alert(columnIndex);
      // @ts-ignore
      setCustomSearchFoldersPath((prevSearchFoldersPath) => {
        const updatedSearchFoldersPath: any = [...prevSearchFoldersPath];

        if (columnIndex <= updatedSearchFoldersPath.length) {
          updatedSearchFoldersPath[columnIndex] = {
            FolderId,
            Name: data.Data.Name,
          };

          updatedSearchFoldersPath.splice(columnIndex + 1);
        } else {
          console.error("Invalid columnIndex");
        }

        return updatedSearchFoldersPath;
      });
      // alert(columnIndex);
      // // @ts-ignore
      // setCustomSearchFoldersPath((prevSearchFoldersPath) => {
      //   const updatedSearchFoldersPath = [
      //     ...prevSearchFoldersPath,
      //     {
      //       FolderId,
      //       Name: data.Data.Name,
      //     },
      //   ];

      //   return updatedSearchFoldersPath;
      // });

      setSearchColumns((prevColumns) => [
        ...prevColumns.slice(0, columnIndex + 1),
        {
          id: FolderId,
          folders: data.Data.SubFolders.map((subFolder: any) => ({
            id: subFolder.FolderId,
            type: "folder",
            name: subFolder.Name,
            items: subFolder.Items,
          })),
          items: data.Data.Items.map((item: any) => ({
            id: item.FolderItemId,
            type: "item",
            name: item.Name,
            playerId: item.PlayerId,
            order: item.Order,
            photoFilePath: item.PhotoFilePath,
          })),
          trainers: data.Data.GrantTrainers.map((trainer: any) => ({
            id: trainer.TrainerId,
            type: "trainer",
            name: trainer.Name,
            playerId: trainer.TrainerId,
            order: -1,
            photoFilePath: trainer.PhotoFilePath,
            specialization: trainer.Type,
            isRootFolder: trainer.IsRootFolder,
          })),
        },
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const getFolderDetailFn = async (FolderId: string, columnIndex: number) => {
    try {
      const data = await getFolderDetail(FolderId);

      setCurrentColumnIndex(columnIndex);

      setActiveFolders((prevActiveFolders) => {
        const updatedActiveFolders = { ...prevActiveFolders };

        updatedActiveFolders[columnIndex.toString()] = FolderId;

        for (const key in updatedActiveFolders) {
          const keyIndex = parseInt(key, 10);
          if (keyIndex > columnIndex) {
            delete updatedActiveFolders[key];
          }
        }

        return updatedActiveFolders;
      });

      setActiveFoldersName((prevActiveFolders) => {
        const updatedActiveFolders = { ...prevActiveFolders };

        updatedActiveFolders[columnIndex.toString()] = data.Data.Name;

        for (const key in updatedActiveFolders) {
          const keyIndex = parseInt(key, 10);
          if (keyIndex > columnIndex) {
            delete updatedActiveFolders[key];
          }
        }

        return updatedActiveFolders;
      });

      setColumns((prevColumns) => [
        ...prevColumns.slice(0, columnIndex + 1),
        {
          id: FolderId,
          folders: data.Data.SubFolders.map((subFolder: any) => ({
            id: subFolder.FolderId,
            type: "folder",
            name: subFolder.Name,
            items: subFolder.Items,
            trainers: subFolder.GrantTrainers,
          })),
          items: data.Data.Items.map((item: any) => ({
            id: item.FolderItemId,
            type: "item",
            name: item.Name,
            playerId: item.PlayerId,
            order: item.Order,
            photoFilePath: item.PhotoFilePath,
          })),
          trainers: data.Data.GrantTrainers.map((trainer: any) => ({
            id: trainer.TrainerId,
            type: "trainer",
            name: trainer.Name,
            playerId: trainer.TrainerId,
            order: -1,
            photoFilePath: trainer.PhotoFilePath,
            specialization: trainer.Type,
            isRootFolder: trainer.IsRootFolder,
          })),
        },
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (clickedTrainer) {
      setColumnClickedItem(null);
      setClickedFolder(null);
      setClickedPlayer(null);
      setClickedItem(null);
    }
  }, [clickedTrainer]);

  useEffect(() => {
    if (clickedItem) {
      setColumnClickedItem(null);
      setClickedFolder(null);
      setClickedPlayer(null);
      setClickedTrainer(null);
    }
  }, [clickedItem]);

  useEffect(() => {
    if (columnClickedItem) {
      setClickedItem(null);
      setClickedFolder(null);
      setClickedPlayer(null);
      setClickedTrainer(null);
    }
  }, [columnClickedItem]);

  useEffect(() => {
    if (clickedFolder) {
      setClickedItem(null);
      setColumnClickedItem(null);
      setClickedPlayer(null);
      setClickedTrainer(null);
    }
  }, [clickedFolder]);

  useEffect(() => {
    if (clickedPlayer) {
      setClickedItem(null);
      setColumnClickedItem(null);
      setClickedFolder(null);
      setClickedTrainer(null);
    }
  }, [clickedPlayer]);

  useEffect(() => {
    if (columnClickedItem?.id) {
      setActiveColumnId(columnClickedItem.id);
    }
  }, [columnClickedItem]);

  const createFolderFn = async (name: string) => {
    try {
      await createFolder(name);
      toast.success("Stworzono folder!");
      getRootFolderFn();
      setNewFolderName("");
      setIsFolderAdded(false);
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  const createColumnFolderFn = async (
    folderId: string,
    name: string,
    columnIndex: number,
  ) => {
    try {
      await createSubFolder(folderId, name);
      toast.success("Stworzono folder!");
      getRootFolderFn();
      // getFolderDetailFn(folderId, columnIndex);
      setNewColumnFolderName("");
      setIsColumnFolderAdded(false);
      searchColumns.map((column: any) => {
        getFolderDetailWithoutIndexSearch(column.id);
      });
      columns.map((column: any) => {
        getFolderDetailWithoutIndex(column.id);
      });
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  const handleSearch = (query: string) => {
    setQueryString(query);
    setSearchColumns([]);
    setSearchFoldersPath([]);
    setCustomSearchFoldersPath([]);
  };

  useEffect(() => {
    console.log("columns", columns);
  }, [columns]);

  useEffect(() => {
    console.log("searchColumns", searchColumns);
  }, [searchColumns]);

  useEffect(() => {
    console.log("customSearchFoldersPath", customSearchFoldersPath);
  }, [customSearchFoldersPath]);

  const transferItem = async (
    FolderFromId: string,
    FolderToId: string,
    TransferItemId: string,
    PlayerId: string,
  ) => {
    await transferItemToFolder(
      FolderFromId,
      FolderToId,
      TransferItemId,
      PlayerId,
    );
  };

  const addItem = async (FolderId: string, PlayerId: string) => {
    await addItemToFolder(FolderId, PlayerId);
    getRootFolderFn();
  };

  const replaceItemFn = async (
    FolderId: string,
    FolderItemId: string,
    OrderTo: number,
  ) => {
    await replaceItem(FolderId, FolderItemId, OrderTo);
  };

  const deleteItem = async (FolderId: string, FolderItemId: string) => {
    await deleteFolderItem(FolderId, FolderItemId);
  };

  const onDragEndSearch = async (result: any) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    const sourceColumnIndex = searchColumns.findIndex(
      (col) => col.id === source.droppableId,
    );
    const destinationColumnIndex = searchColumns.findIndex(
      (col) => col.id === destination.droppableId,
    );
    // PRZECIAGANIE DO ROOT
    if (destinationColumnIndex === -1) {
      // PRZECIAGANIE DO FOLDERU W KOLUMNIE ROOT
      if (destination.index === -2 && sourceColumnIndex !== -1) {
        const sourceColumn = searchColumns[sourceColumnIndex];
        const draggedPlayer = sourceColumn.items.find(
          (item: any) => item.id === draggableId,
        );
        const draggedTrainer = sourceColumn.trainers.find(
          (item: any) => item.id === draggableId.slice(36, 77),
        );

        if (draggedPlayer) {
          setSearchColumns((prevColumns) => {
            const updatedColumns = [...prevColumns];
            const updatedSourceColumn = { ...sourceColumn };

            updatedSourceColumn.items = sourceColumn.items.filter(
              (item: any) => item.id !== draggableId,
            );

            updatedColumns[sourceColumnIndex] = updatedSourceColumn;
            return updatedColumns;
          });

          await transferItem(
            sourceColumn.id,
            hoverFolderId,
            draggableId,
            draggedPlayer.playerId,
          );

          searchColumns.map((column: any) => {
            getFolderDetailWithoutIndexSearch(column.id);
          });
          columns.map((column: any) => {
            getFolderDetailWithoutIndex(column.id);
          });
          getRootFolderFn();
        }

        if (draggedTrainer) {
          setSearchColumns((prevColumns) => {
            const updatedColumns = [...prevColumns];
            const updatedSourceColumn = { ...sourceColumn };

            updatedSourceColumn.trainers = sourceColumn.trainers.filter(
              (item: any) => item.id !== draggableId.slice(36, 77),
            );

            updatedColumns[sourceColumnIndex] = updatedSourceColumn;
            return updatedColumns;
          });

          await revokeFolderToTrainer(
            sourceColumn.id,
            draggableId.slice(36, 77),
          );
          await grantFolderToTrainer(hoverFolderId, draggableId.slice(36, 77));
          searchColumns.map((column: any) => {
            getFolderDetailWithoutIndexSearch(column.id);
          });
          columns.map((column: any) => {
            getFolderDetailWithoutIndex(column.id);
          });
          getRootFolderFn();
        }
      }
      if (destination.index !== -2 && sourceColumnIndex !== -1) {
        // PRZECIAGANIE DO ROOT (USUWANIE)
        const sourceColumn = searchColumns[sourceColumnIndex];
        const draggedPlayer = sourceColumn.items.find(
          (item: any) => item.id === draggableId,
        );
        const draggedTrainer = sourceColumn.trainers.find(
          (item: any) => item.id === draggableId.slice(36, 77),
        );

        if (draggedPlayer) {
          const updatedColumns = searchColumns.map((col) => {
            if (col.id === source.droppableId) {
              return {
                ...col,
                items: col.items.filter((item: any) => item.id !== draggableId),
              };
            }
            return col;
          });
          setSearchColumns(updatedColumns);
          await deleteItem(source.droppableId, draggableId);
          getAvailablePlayers();
          getRootFolderFn();
        }

        if (draggedTrainer) {
          const updatedColumns = searchColumns.map((col) => {
            if (col.id === source.droppableId) {
              return {
                ...col,
                trainers: col.trainers.filter(
                  (item: any) => item.id !== draggableId.slice(36, 77),
                ),
              };
            }
            return col;
          });
          setSearchColumns(updatedColumns);

          await revokeFolderToTrainer(
            source.droppableId,
            draggableId.slice(36, 77),
          );
          getRootFolderFn();
        }

        return;
      }
    }

    // IF DO TEJ SAMEJ KOLUMNY
    if (
      sourceColumnIndex !== -1 &&
      destinationColumnIndex !== -1 &&
      sourceColumnIndex === destinationColumnIndex &&
      source.index !== destination.index
    ) {
      const sourceColumn = searchColumns[sourceColumnIndex];

      const draggedPlayer = sourceColumn.items.find(
        (item: any) => item.id === draggableId,
      );
      const draggedTrainer = sourceColumn.trainers.find(
        (item: any) => item.id === draggableId.slice(36, 77),
      );
      console.log("source.index", source.index);
      console.log("destination.index", destination.index);
      console.log("DRAGGEDITEM", draggedPlayer);
      console.log("destination.droppableId", destination.droppableId);
      console.log("destination", destination);
      console.log("sourceColumn", sourceColumn);
      //IF DO TEJ SAMEJ KOLUMNY (WKLADANIE DO FOLDERU)
      if (destination.index === -2) {
        if (draggedPlayer) {
          setSearchColumns((prevColumns) => {
            const updatedColumns = [...prevColumns];
            const updatedSourceColumn = { ...sourceColumn };

            updatedSourceColumn.items = sourceColumn.items.filter(
              (item: any) => item.id !== draggableId,
            );

            updatedColumns[sourceColumnIndex] = updatedSourceColumn;
            return updatedColumns;
          });

          await transferItem(
            sourceColumn.id,
            hoverFolderId,
            draggableId,
            draggedPlayer.playerId,
          );

          // getFolderDetailReplaceItem(sourceColumn.id);
          // getFolderDetailReplaceItem(hoverFolderId);
          searchColumns.map((column: any) => {
            getFolderDetailWithoutIndexSearch(column.id);
          });
          columns.map((column: any) => {
            getFolderDetailWithoutIndex(column.id);
          });
          getRootFolderFn();
        }
        if (draggedTrainer) {
          setSearchColumns((prevColumns) => {
            const updatedColumns = [...prevColumns];
            const updatedSourceColumn = { ...sourceColumn };

            updatedSourceColumn.trainers = sourceColumn.trainers.filter(
              (item: any) => item.id !== draggableId.slice(36, 77),
            );

            updatedColumns[sourceColumnIndex] = updatedSourceColumn;
            return updatedColumns;
          });

          await revokeFolderToTrainer(
            sourceColumn.id,
            draggableId.slice(36, 77),
          );
          await grantFolderToTrainer(hoverFolderId, draggableId.slice(36, 77));
          // getFolderDetailReplaceItem(sourceColumn.id);
          // getFolderDetailReplaceItem(hoverFolderId);
          searchColumns.map((column: any) => {
            getFolderDetailWithoutIndexSearch(column.id);
          });
          columns.map((column: any) => {
            getFolderDetailWithoutIndex(column.id);
          });
          getRootFolderFn();
        }
      } else {
        //IF DO TEJ SAMEJ KOLUMNY ZAMIANA MIEJSC
        if (draggedPlayer) {
          setSearchColumns((prevColumns) => {
            const updatedColumns = [...prevColumns];
            const sourceColumn = updatedColumns[sourceColumnIndex];
            const destinationColumn = updatedColumns[destinationColumnIndex];

            const updatedSourceColumn = {
              ...sourceColumn,
              items: [...sourceColumn.items],
            };
            const [draggedPlayer] = updatedSourceColumn.items.splice(
              source.index,
              1,
            );
            updatedSourceColumn.items.splice(
              destination.index,
              0,
              draggedPlayer,
            );

            updatedSourceColumn.items.forEach((item: any, index: any) => {
              item.order = index + 1;
            });

            if (sourceColumnIndex !== destinationColumnIndex) {
              destinationColumn.items.forEach((item: any, index: any) => {
                item.order = index + 1;
              });
            }

            updatedColumns[sourceColumnIndex] = updatedSourceColumn;

            if (sourceColumnIndex !== destinationColumnIndex) {
              updatedColumns[destinationColumnIndex] = {
                ...destinationColumn,
                items: [...destinationColumn.items],
              };
            }

            return updatedColumns;
          });

          await replaceItemFn(
            sourceColumn.id,
            draggableId,
            destination.index + 1,
          );
          searchColumns.map((column: any) => {
            getFolderDetailWithoutIndexSearch(column.id);
          });
          columns.map((column: any) => {
            getFolderDetailWithoutIndex(column.id);
          });
          getRootFolderFn();
          // getFolderDetailReplaceItem(sourceColumn.id);
        }
      }
    }
    //IF DO PRZECIAGANIA Z ROOT KOLUMNY (DODAWANIE ITEMU)
    if (source.droppableId === "root") {
      const draggedPlayer: any = availablePlayers.find(
        (player: any) => player.PlayerId === draggableId,
      );

      const draggedTrainer: any = availableTrainers.find(
        (trainer: any) => trainer.TrainerId === draggableId,
      );

      //IF DO PRZECIAGANIA Z ROOT KOLUMNY DO FOLDERU W INNEJ KOLUMNIE
      if (destination.index === -2 && draggedPlayer) {
        setAvailablePlayers((prevPlayers) =>
          prevPlayers.filter((player: any) => player.PlayerId !== draggableId),
        );

        await addItem(hoverFolderId, draggableId);
        searchColumns.map((column: any) => {
          getFolderDetailWithoutIndexSearch(column.id);
        });
        columns.map((column: any) => {
          getFolderDetailWithoutIndex(column.id);
        });
        getRootFolderFn();
      }

      if (destination.index === -2 && draggedTrainer) {
        await grantFolderToTrainer(hoverFolderId, draggableId);
        searchColumns.map((column: any) => {
          getFolderDetailWithoutIndexSearch(column.id);
        });
        columns.map((column: any) => {
          getFolderDetailWithoutIndex(column.id);
        });
      }
      //IF DO PRZECIAGANIA Z ROOT KOLUMNY (DODAWANIE ITEMU)
      if (draggedPlayer && destinationColumnIndex !== -1) {
        console.log("draggedPlayer", draggedPlayer);
        setSearchColumns((prevColumns) => {
          const updatedColumns = [...prevColumns];
          const destinationColumn = updatedColumns[destinationColumnIndex];

          if (destinationColumn) {
            addItem(destinationColumn.id, draggableId).then(() => {
              // getFolderDetailWithoutIndex(destinationColumn.id);
              searchColumns.map((column: any) => {
                getFolderDetailWithoutIndexSearch(column.id);
              });
              columns.map((column: any) => {
                getFolderDetailWithoutIndex(column.id);
              });
              getRootFolderFn();
            });
          }

          return updatedColumns;
        });

        setAvailablePlayers((prevPlayers) =>
          prevPlayers.filter((player: any) => player.PlayerId !== draggableId),
        );
      }

      //IF DO PRZECIAGANIA TRENERA Z ROOT KOLUMNY (DODAWANIE TRENERA)
      if (draggedTrainer && destinationColumnIndex !== -1) {
        console.log("draggedTrainer", draggedTrainer);

        setSearchColumns((prevColumns) => {
          const updatedColumns = [...prevColumns];
          const destinationColumn = updatedColumns[destinationColumnIndex];

          if (destinationColumn) {
            grantFolderToTrainer(destinationColumn.id, draggableId).then(() => {
              searchColumns.map((column: any) => {
                getFolderDetailWithoutIndexSearch(column.id);
              });
              columns.map((column: any) => {
                getFolderDetailWithoutIndex(column.id);
              });
            });
          }

          return updatedColumns;
        });
      }
    }
    //IF DO PRZECIAGANIA Z KOLUMNY DO INNEJ KOLUMNY (BEZ ROOT)
    else if (
      sourceColumnIndex !== -1 &&
      destinationColumnIndex !== -1 &&
      sourceColumnIndex !== destinationColumnIndex
    ) {
      const sourceColumn = searchColumns[sourceColumnIndex];
      const destinationColumn = searchColumns[destinationColumnIndex];

      const draggedPlayer = sourceColumn.items.find(
        (item: any) => item.id === draggableId,
      );

      const draggedTrainer = sourceColumn.trainers.find(
        (item: any) => item.id === draggableId.slice(36, 77),
      );
      console.log("sourceColumnIndex", sourceColumnIndex);
      console.log("destinationColumnIndex", destinationColumnIndex);
      console.log("destinationColumn", destinationColumn);

      //IF DO PRZECIAGANIA Z KOLUMNY DO INNEJ KOLUMNY (WKLADANIE DO FOLDERU)
      if (destination.index === -2) {
        if (draggedPlayer) {
          setSearchColumns((prevColumns) => {
            const updatedColumns = [...prevColumns];
            const updatedSourceColumn = { ...sourceColumn };

            updatedSourceColumn.items = sourceColumn.items.filter(
              (item: any) => item.id !== draggableId,
            );

            updatedColumns[sourceColumnIndex] = updatedSourceColumn;
            return updatedColumns;
          });

          await transferItem(
            sourceColumn.id,
            hoverFolderId,
            draggableId,
            draggedPlayer.playerId,
          );

          searchColumns.map((column: any) => {
            getFolderDetailWithoutIndexSearch(column.id);
          });
          columns.map((column: any) => {
            getFolderDetailWithoutIndex(column.id);
          });
          getRootFolderFn();
        }

        if (draggedTrainer) {
          setSearchColumns((prevColumns) => {
            const updatedColumns = [...prevColumns];
            const updatedSourceColumn = { ...sourceColumn };

            updatedSourceColumn.trainers = sourceColumn.trainers.filter(
              (item: any) => item.id !== draggableId.slice(36, 77),
            );

            updatedColumns[sourceColumnIndex] = updatedSourceColumn;
            return updatedColumns;
          });

          await revokeFolderToTrainer(
            sourceColumn.id,
            draggableId.slice(36, 77),
          );
          await grantFolderToTrainer(
            destinationColumn.id,
            draggableId.slice(36, 77),
          );
          searchColumns.map((column: any) => {
            getFolderDetailWithoutIndexSearch(column.id);
          });
          columns.map((column: any) => {
            getFolderDetailWithoutIndex(column.id);
          });
          getRootFolderFn();
        }
      }
      //IF DO PRZECIAGANIA Z KOLUMNY DO INNEJ KOLUMNY (PRZECIAGANIE DO KOLUMNY)
      else {
        if (draggedPlayer) {
          setSearchColumns((prevColumns) => {
            const updatedColumns = [...prevColumns];
            console.log("UPDATEDCOLUMNS", updatedColumns);
            updatedColumns[sourceColumnIndex] = {
              ...sourceColumn,
              items: sourceColumn.items
                .filter((item: any) => item.id !== draggableId)
                .map((item: any, index: number) => ({
                  ...item,
                  order: index + 1,
                })),
            };

            updatedColumns[destinationColumnIndex] = {
              ...destinationColumn,
              items: [
                ...destinationColumn.items,
                { ...draggedPlayer, order: destination.index + 1 },
              ],
            };

            return updatedColumns;
          });

          await transferItem(
            sourceColumn.id,
            destinationColumn.id,
            draggableId,
            draggedPlayer.playerId,
          );
          searchColumns.map((column: any) => {
            getFolderDetailWithoutIndexSearch(column.id);
          });
          columns.map((column: any) => {
            getFolderDetailWithoutIndex(column.id);
          });
          getRootFolderFn();
        }

        if (draggedTrainer) {
          setSearchColumns((prevColumns) => {
            const updatedColumns = [...prevColumns];
            console.log("UPDATEDCOLUMNS", updatedColumns);
            updatedColumns[sourceColumnIndex] = {
              ...sourceColumn,
              trainers: sourceColumn.trainers
                .filter((item: any) => item.id !== draggableId.slice(36, 77))
                .map((item: any, index: number) => ({
                  ...item,
                  order: index + 1,
                })),
            };

            updatedColumns[destinationColumnIndex] = {
              ...destinationColumn,
              trainers: [
                ...destinationColumn.trainers,
                { ...draggedTrainer, order: destination.index + 1 },
              ],
            };

            return updatedColumns;
          });

          await revokeFolderToTrainer(
            sourceColumn.id,
            draggableId.slice(36, 77),
          );
          await grantFolderToTrainer(
            destinationColumn.id,
            draggableId.slice(36, 77),
          );
          searchColumns.map((column: any) => {
            getFolderDetailWithoutIndexSearch(column.id);
          });
          columns.map((column: any) => {
            getFolderDetailWithoutIndex(column.id);
          });
          getRootFolderFn();
        }
      }
    }
  };

  const onDragEnd = async (result: any) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    const sourceColumnIndex = columns.findIndex(
      (col) => col.id === source.droppableId,
    );
    const destinationColumnIndex = columns.findIndex(
      (col) => col.id === destination.droppableId,
    );
    // PRZECIAGANIE DO ROOT
    if (destinationColumnIndex === -1) {
      // PRZECIAGANIE DO FOLDERU W KOLUMNIE ROOT
      if (destination.index === -2 && sourceColumnIndex !== -1) {
        const sourceColumn = columns[sourceColumnIndex];
        const draggedPlayer = sourceColumn.items.find(
          (item: any) => item.id === draggableId,
        );
        const draggedTrainer = sourceColumn.trainers.find(
          (item: any) => item.id === draggableId.slice(36, 77),
        );

        if (draggedPlayer) {
          setColumns((prevColumns) => {
            const updatedColumns = [...prevColumns];
            const updatedSourceColumn = { ...sourceColumn };

            updatedSourceColumn.items = sourceColumn.items.filter(
              (item: any) => item.id !== draggableId,
            );

            updatedColumns[sourceColumnIndex] = updatedSourceColumn;
            return updatedColumns;
          });

          await transferItem(
            sourceColumn.id,
            hoverFolderId,
            draggableId,
            draggedPlayer.playerId,
          );

          columns.map((column: any) => {
            getFolderDetailWithoutIndex(column.id);
          });
          getRootFolderFn();
        }

        if (draggedTrainer) {
          setColumns((prevColumns) => {
            const updatedColumns = [...prevColumns];
            const updatedSourceColumn = { ...sourceColumn };

            updatedSourceColumn.trainers = sourceColumn.trainers.filter(
              (item: any) => item.id !== draggableId.slice(36, 77),
            );

            updatedColumns[sourceColumnIndex] = updatedSourceColumn;
            return updatedColumns;
          });

          await revokeFolderToTrainer(
            sourceColumn.id,
            draggableId.slice(36, 77),
          );
          await grantFolderToTrainer(hoverFolderId, draggableId.slice(36, 77));
          columns.map((column: any) => {
            getFolderDetailWithoutIndex(column.id);
          });
          getRootFolderFn();
        }
      }
      if (destination.index !== -2 && sourceColumnIndex !== -1) {
        // PRZECIAGANIE DO ROOT (USUWANIE)
        const sourceColumn = columns[sourceColumnIndex];
        const draggedPlayer = sourceColumn.items.find(
          (item: any) => item.id === draggableId,
        );
        const draggedTrainer = sourceColumn.trainers.find(
          (item: any) => item.id === draggableId.slice(36, 77),
        );

        if (draggedPlayer) {
          const updatedColumns = columns.map((col) => {
            if (col.id === source.droppableId) {
              return {
                ...col,
                items: col.items.filter((item: any) => item.id !== draggableId),
              };
            }
            return col;
          });
          setColumns(updatedColumns);
          await deleteItem(source.droppableId, draggableId);
          getAvailablePlayers();
          getRootFolderFn();
        }

        if (draggedTrainer) {
          const updatedColumns = columns.map((col) => {
            if (col.id === source.droppableId) {
              return {
                ...col,
                trainers: col.trainers.filter(
                  (item: any) => item.id !== draggableId.slice(36, 77),
                ),
              };
            }
            return col;
          });
          setColumns(updatedColumns);

          await revokeFolderToTrainer(
            source.droppableId,
            draggableId.slice(36, 77),
          );
          getRootFolderFn();
        }

        return;
      }
    }

    // IF DO TEJ SAMEJ KOLUMNY
    if (
      sourceColumnIndex !== -1 &&
      destinationColumnIndex !== -1 &&
      sourceColumnIndex === destinationColumnIndex &&
      source.index !== destination.index
    ) {
      const sourceColumn = columns[sourceColumnIndex];

      const draggedPlayer = sourceColumn.items.find(
        (item: any) => item.id === draggableId,
      );
      const draggedTrainer = sourceColumn.trainers.find(
        (item: any) => item.id === draggableId.slice(36, 77),
      );
      console.log("source.index", source.index);
      console.log("destination.index", destination.index);
      console.log("DRAGGEDITEM", draggedPlayer);
      console.log("destination.droppableId", destination.droppableId);
      console.log("destination", destination);
      console.log("sourceColumn", sourceColumn);
      //IF DO TEJ SAMEJ KOLUMNY (WKLADANIE DO FOLDERU)
      if (destination.index === -2) {
        if (draggedPlayer) {
          setColumns((prevColumns) => {
            const updatedColumns = [...prevColumns];
            const updatedSourceColumn = { ...sourceColumn };

            updatedSourceColumn.items = sourceColumn.items.filter(
              (item: any) => item.id !== draggableId,
            );

            updatedColumns[sourceColumnIndex] = updatedSourceColumn;
            return updatedColumns;
          });

          await transferItem(
            sourceColumn.id,
            hoverFolderId,
            draggableId,
            draggedPlayer.playerId,
          );

          // getFolderDetailReplaceItem(sourceColumn.id);
          // getFolderDetailReplaceItem(hoverFolderId);
          columns.map((column: any) => {
            getFolderDetailWithoutIndex(column.id);
          });
          searchColumns.map((column: any) => {
            getFolderDetailWithoutIndexSearch(column.id);
          });
          getRootFolderFn();
        }
        if (draggedTrainer) {
          setColumns((prevColumns) => {
            const updatedColumns = [...prevColumns];
            const updatedSourceColumn = { ...sourceColumn };

            updatedSourceColumn.trainers = sourceColumn.trainers.filter(
              (item: any) => item.id !== draggableId.slice(36, 77),
            );

            updatedColumns[sourceColumnIndex] = updatedSourceColumn;
            return updatedColumns;
          });

          await revokeFolderToTrainer(
            sourceColumn.id,
            draggableId.slice(36, 77),
          );
          await grantFolderToTrainer(hoverFolderId, draggableId.slice(36, 77));
          // getFolderDetailReplaceItem(sourceColumn.id);
          // getFolderDetailReplaceItem(hoverFolderId);
          columns.map((column: any) => {
            getFolderDetailWithoutIndex(column.id);
          });
          searchColumns.map((column: any) => {
            getFolderDetailWithoutIndexSearch(column.id);
          });
          getRootFolderFn();
        }
      } else {
        //IF DO TEJ SAMEJ KOLUMNY ZAMIANA MIEJSC
        if (draggedPlayer) {
          setColumns((prevColumns) => {
            const updatedColumns = [...prevColumns];
            const sourceColumn = updatedColumns[sourceColumnIndex];
            const destinationColumn = updatedColumns[destinationColumnIndex];

            const updatedSourceColumn = {
              ...sourceColumn,
              items: [...sourceColumn.items],
            };
            const [draggedPlayer] = updatedSourceColumn.items.splice(
              source.index,
              1,
            );
            updatedSourceColumn.items.splice(
              destination.index,
              0,
              draggedPlayer,
            );

            updatedSourceColumn.items.forEach((item: any, index: any) => {
              item.order = index + 1;
            });

            if (sourceColumnIndex !== destinationColumnIndex) {
              destinationColumn.items.forEach((item: any, index: any) => {
                item.order = index + 1;
              });
            }

            updatedColumns[sourceColumnIndex] = updatedSourceColumn;

            if (sourceColumnIndex !== destinationColumnIndex) {
              updatedColumns[destinationColumnIndex] = {
                ...destinationColumn,
                items: [...destinationColumn.items],
              };
            }

            return updatedColumns;
          });

          await replaceItemFn(
            sourceColumn.id,
            draggableId,
            destination.index + 1,
          );
          columns.map((column: any) => {
            getFolderDetailWithoutIndex(column.id);
          });
          searchColumns.map((column: any) => {
            getFolderDetailWithoutIndexSearch(column.id);
          });
          getRootFolderFn();
          // getFolderDetailReplaceItem(sourceColumn.id);
        }
      }
    }
    //IF DO PRZECIAGANIA Z ROOT KOLUMNY (DODAWANIE ITEMU)
    if (source.droppableId === "root") {
      const draggedPlayer: any = availablePlayers.find(
        (player: any) => player.PlayerId === draggableId,
      );

      const draggedTrainer: any = availableTrainers.find(
        (trainer: any) => trainer.TrainerId === draggableId,
      );

      //IF DO PRZECIAGANIA Z ROOT KOLUMNY DO FOLDERU W INNEJ KOLUMNIE
      if (destination.index === -2 && draggedPlayer) {
        setAvailablePlayers((prevPlayers) =>
          prevPlayers.filter((player: any) => player.PlayerId !== draggableId),
        );

        await addItem(hoverFolderId, draggableId);
        columns.map((column: any) => {
          getFolderDetailWithoutIndex(column.id);
        });
        searchColumns.map((column: any) => {
          getFolderDetailWithoutIndexSearch(column.id);
        });
        getRootFolderFn();
      }

      if (destination.index === -2 && draggedTrainer) {
        await grantFolderToTrainer(hoverFolderId, draggableId);
        columns.map((column: any) => {
          getFolderDetailWithoutIndex(column.id);
        });
        searchColumns.map((column: any) => {
          getFolderDetailWithoutIndexSearch(column.id);
        });
      }

      //IF DO PRZECIAGANIA Z ROOT KOLUMNY (DODAWANIE ITEMU)
      if (
        draggedPlayer &&
        destinationColumnIndex !== -1 &&
        destination.index !== -2
      ) {
        console.log("draggedPlayer", draggedPlayer);

        setColumns((prevColumns) => {
          const updatedColumns = [...prevColumns];
          const destinationColumn = updatedColumns[destinationColumnIndex];

          if (destinationColumn) {
            addItem(destinationColumn.id, draggableId).then(() => {
              // getFolderDetailWithoutIndex(destinationColumn.id);
              columns.map((column: any) => {
                getFolderDetailWithoutIndex(column.id);
              });
              searchColumns.map((column: any) => {
                getFolderDetailWithoutIndexSearch(column.id);
              });
              getRootFolderFn();
            });
          }

          return updatedColumns;
        });

        setAvailablePlayers((prevPlayers) =>
          prevPlayers.filter((player: any) => player.PlayerId !== draggableId),
        );
      }

      //IF DO PRZECIAGANIA TRENERA Z ROOT KOLUMNY (DODAWANIE TRENERA)
      if (draggedTrainer && destinationColumnIndex !== -1) {
        console.log("draggedTrainer", draggedTrainer);

        setColumns((prevColumns) => {
          const updatedColumns = [...prevColumns];
          const destinationColumn = updatedColumns[destinationColumnIndex];

          if (destinationColumn) {
            grantFolderToTrainer(destinationColumn.id, draggableId).then(() => {
              columns.map((column: any) => {
                getFolderDetailWithoutIndex(column.id);
              });
              searchColumns.map((column: any) => {
                getFolderDetailWithoutIndexSearch(column.id);
              });
            });
          }

          return updatedColumns;
        });
      }
    }
    //IF DO PRZECIAGANIA Z KOLUMNY DO INNEJ KOLUMNY (BEZ ROOT)
    else if (
      sourceColumnIndex !== -1 &&
      destinationColumnIndex !== -1 &&
      sourceColumnIndex !== destinationColumnIndex
    ) {
      const sourceColumn = columns[sourceColumnIndex];
      const destinationColumn = columns[destinationColumnIndex];

      const draggedPlayer = sourceColumn.items.find(
        (item: any) => item.id === draggableId,
      );

      const draggedTrainer = sourceColumn.trainers.find(
        (item: any) => item.id === draggableId.slice(36, 77),
      );
      console.log("sourceColumnIndex", sourceColumnIndex);
      console.log("destinationColumnIndex", destinationColumnIndex);
      console.log("destinationColumn", destinationColumn);

      //IF DO PRZECIAGANIA Z KOLUMNY DO INNEJ KOLUMNY (WKLADANIE DO FOLDERU)
      if (destination.index === -2) {
        if (draggedPlayer) {
          setColumns((prevColumns) => {
            const updatedColumns = [...prevColumns];
            const updatedSourceColumn = { ...sourceColumn };

            updatedSourceColumn.items = sourceColumn.items.filter(
              (item: any) => item.id !== draggableId,
            );

            updatedColumns[sourceColumnIndex] = updatedSourceColumn;
            return updatedColumns;
          });

          await transferItem(
            sourceColumn.id,
            hoverFolderId,
            draggableId,
            draggedPlayer.playerId,
          );

          columns.map((column: any) => {
            getFolderDetailWithoutIndex(column.id);
          });
          searchColumns.map((column: any) => {
            getFolderDetailWithoutIndexSearch(column.id);
          });
          getRootFolderFn();
        }

        if (draggedTrainer) {
          setColumns((prevColumns) => {
            const updatedColumns = [...prevColumns];
            const updatedSourceColumn = { ...sourceColumn };

            updatedSourceColumn.trainers = sourceColumn.trainers.filter(
              (item: any) => item.id !== draggableId.slice(36, 77),
            );

            updatedColumns[sourceColumnIndex] = updatedSourceColumn;
            return updatedColumns;
          });

          await revokeFolderToTrainer(
            sourceColumn.id,
            draggableId.slice(36, 77),
          );
          await grantFolderToTrainer(
            destinationColumn.id,
            draggableId.slice(36, 77),
          );
          columns.map((column: any) => {
            getFolderDetailWithoutIndex(column.id);
          });
          searchColumns.map((column: any) => {
            getFolderDetailWithoutIndexSearch(column.id);
          });
          getRootFolderFn();
        }
      }
      //IF DO PRZECIAGANIA Z KOLUMNY DO INNEJ KOLUMNY (PRZECIAGANIE DO KOLUMNY)
      else {
        if (draggedPlayer) {
          setColumns((prevColumns) => {
            const updatedColumns = [...prevColumns];
            console.log("UPDATEDCOLUMNS", updatedColumns);
            updatedColumns[sourceColumnIndex] = {
              ...sourceColumn,
              items: sourceColumn.items
                .filter((item: any) => item.id !== draggableId)
                .map((item: any, index: number) => ({
                  ...item,
                  order: index + 1,
                })),
            };

            updatedColumns[destinationColumnIndex] = {
              ...destinationColumn,
              items: [
                ...destinationColumn.items,
                { ...draggedPlayer, order: destination.index + 1 },
              ],
            };

            return updatedColumns;
          });

          await transferItem(
            sourceColumn.id,
            destinationColumn.id,
            draggableId,
            draggedPlayer.playerId,
          );
          columns.map((column: any) => {
            getFolderDetailWithoutIndex(column.id);
          });
          searchColumns.map((column: any) => {
            getFolderDetailWithoutIndexSearch(column.id);
          });
          getRootFolderFn();
        }

        if (draggedTrainer) {
          setColumns((prevColumns) => {
            const updatedColumns = [...prevColumns];
            console.log("UPDATEDCOLUMNS", updatedColumns);
            updatedColumns[sourceColumnIndex] = {
              ...sourceColumn,
              trainers: sourceColumn.trainers
                .filter((item: any) => item.id !== draggableId.slice(36, 77))
                .map((item: any, index: number) => ({
                  ...item,
                  order: index + 1,
                })),
            };

            updatedColumns[destinationColumnIndex] = {
              ...destinationColumn,
              trainers: [
                ...destinationColumn.trainers,
                { ...draggedTrainer, order: destination.index + 1 },
              ],
            };

            return updatedColumns;
          });

          await revokeFolderToTrainer(
            sourceColumn.id,
            draggableId.slice(36, 77),
          );
          await grantFolderToTrainer(
            destinationColumn.id,
            draggableId.slice(36, 77),
          );
          columns.map((column: any) => {
            getFolderDetailWithoutIndex(column.id);
          });
          searchColumns.map((column: any) => {
            getFolderDetailWithoutIndexSearch(column.id);
          });
          getRootFolderFn();
        }
      }
    }
  };

  const handleItemClick = (clickedIndex: any) => {
    setActiveFolders((prevActiveFolders) => {
      const updatedActiveFolders = { ...prevActiveFolders };

      for (const key in updatedActiveFolders) {
        const keyIndex = parseInt(key, 10);
        if (keyIndex > clickedIndex) {
          delete updatedActiveFolders[key];
        }
      }

      return updatedActiveFolders;
    });

    setActiveFoldersName((prevActiveFolders) => {
      const updatedActiveFolders = { ...prevActiveFolders };

      for (const key in updatedActiveFolders) {
        const keyIndex = parseInt(key, 10);
        if (keyIndex > clickedIndex) {
          delete updatedActiveFolders[key];
        }
      }

      return updatedActiveFolders;
    });

    setColumns((prevColumns) => prevColumns.slice(0, clickedIndex + 1));
  };

  const getSearchFoldersFn = async (queryString: string) => {
    const resp: any = await getSearchFolders(queryString);
    setSearchFolders(resp?.Data?.Folders);
    console.log("resp", resp?.Data?.Folders);
  };

  const getSearchFoldersPathFn = async (FolderId: string) => {
    const resp: any = await getSearchFolderPath(FolderId);
    setSearchFoldersPath(resp?.Data?.FoldersPath);
    console.log("respFOLDERPATH", resp?.Data?.FoldersPath);
  };

  useEffect(() => {
    if (queryString.length > 0) {
      getSearchFoldersFn(queryString);
    }
  }, [queryString]);

  useEffect(() => {
    if (queryString.length < 3) {
      setSearchFolders([]);
    }
  }, [queryString]);

  return (
    <ContentContainer
      title="Grupy"
      noArrow={true}
      fn={() => setIsSearching(false)}
      TopBar={
        <StructureTopbar
          setIsSearchOpen={setIsSearchOpen}
          isSearchOpen={isSearchOpen}
          sorts={sorts}
          sortBy={sortBy}
          setSortBy={setSortBy}
          handleQueryChange={handleSearch}
          defaultSort={defaultSort}
          viewType={viewType}
          setViewType={setViewType}
          queryString={queryString}
          searchFolders={searchFolders}
          getSearchFoldersPathFn={getSearchFoldersPathFn}
          setIsSearching={setIsSearching}
        />
      }
    >
      <Modal
        isOpen={isPlayerModalOpen}
        onRequestClose={closePlayerModalOpen}
        style={customStylesForPlayers}
      >
        <PlayersDetail
          isStructure={true}
          playerId={selectedPlayerId}
          closePlayerModalOpen={closePlayerModalOpen}
        />
      </Modal>

      <Modal
        isOpen={isTrainerModalOpen}
        onRequestClose={closeTrainerModalOpen}
        style={customStylesForPlayers}
      >
        <TrainersDetail
          closeTrainerModalOpen={closeTrainerModalOpen}
          isStructure={true}
          playerId={selectedTrainerId}
        />
      </Modal>

      <Modal
        isOpen={isEventModalOpen}
        onRequestClose={closeEventModalOpen}
        style={customStylesForEvents}
      >
        <SideAddMenu>
          <AddDigitalRelease
            isAddMenuOpen={true}
            isStructure={true}
            clickedFolderPlayers={clickedFolderPlayers}
            selectedFolderId={selectedFolderId}
          />
        </SideAddMenu>
      </Modal>

      <Modal
        isOpen={isFolderModalOpen}
        onRequestClose={closeFolderModalOpen}
        style={customStylesForPlayers}
      >
        <StructureFolderDetail
          closeFolderModalOpen={closeFolderModalOpen}
          isStructure={true}
          folderId={selectedFolderId}
          columns={columns}
          getFolderDetailWithoutIndex={getFolderDetailWithoutIndex}
        />
      </Modal>

      <Modal
        isOpen={isFolderNameEdited}
        onRequestClose={closeFolderEditNameModal}
        style={customEditFolderStyles}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "18px",
            alignItems: "center",
            background: "transparent",
            position: "relative",
          }}
        >
          <p
            style={{
              color: "white",
            }}
          >
            Zmiana nazwy
          </p>
          <svg
            onClick={() => closeFolderEditNameModal()}
            style={{
              position: "absolute",
              top: "6px",
              right: "0",
              cursor: "pointer",
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
          >
            <path
              id="Icon_ionic-md-close"
              data-name="Icon ionic-md-close"
              d="M20.523,8.823l-1.3-1.3-5.2,5.2-5.2-5.2-1.3,1.3,5.2,5.2-5.2,5.2,1.3,1.3,5.2-5.2,5.2,5.2,1.3-1.3-5.2-5.2Z"
              transform="translate(-7.523 -7.523)"
              fill="#f8f8f8"
            />
          </svg>

          <CustomInputBox>
            <p>Nowa nazwa</p>
            <input
              type="text"
              placeholder="Uzupełnij"
              value={editedFolderName}
              onChange={handleEditedFolderNameChange}
            />
          </CustomInputBox>

          <Button
            onClick={() => {
              if (changeNameType === "folder") {
                updateFolderNameFn(clickedFolderId, editedFolderName);
              } else {
                updateFolderNameFn(activeColumnId, editedFolderName);
              }
            }}
            className="px-24 xl:order-2"
            variant={ButtonVariant.Submit}
            style={{
              fontSize: "13px",
              fontWeight: 400,
              color: "white",
              background: "rgb(0, 160, 255)",
              width: "100%",
            }}
          >
            Zastosuj
          </Button>
        </div>
      </Modal>

      <Modal
        isOpen={isFolderAdded}
        onRequestClose={closeFolderModal}
        style={customStyles}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "center",
            background: "transparent",
          }}
        >
          <p style={{ color: "white" }}>Dodaj grupę</p>
          <input
            type="text"
            placeholder="Nazwa folderu"
            value={newFolderName}
            onChange={handleNewFolderNameChange}
          />
          <Button
            onClick={() => createFolderFn(newFolderName)}
            className="px-24 xl:order-2"
            variant={ButtonVariant.Submit}
            style={{
              fontSize: "13px",
              fontWeight: 400,
              color: "white",
              background: "rgb(0, 160, 255)",
              width: "100%",
            }}
          >
            Zapisz
          </Button>
        </div>
      </Modal>

      <Modal
        isOpen={isColumnFolderAdded}
        onRequestClose={closeColumnFolderModal}
        style={customStyles}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "center",
            background: "transparent",
          }}
        >
          <p style={{ color: "white" }}>Dodaj grupę</p>
          <input
            type="text"
            placeholder="Nazwa folderu"
            value={newColumnFolderName}
            onChange={handleNewColumnFolderNameChange}
          />
          <Button
            onClick={() =>
              createColumnFolderFn(
                activeColumnId,
                newColumnFolderName,
                currentColumnIndex,
              )
            }
            className="px-24 xl:order-2"
            variant={ButtonVariant.Submit}
            style={{
              fontSize: "13px",
              fontWeight: 400,
              color: "white",
              background: "rgb(0, 160, 255)",
              width: "100%",
            }}
          >
            Zapisz
          </Button>
        </div>
      </Modal>

      {isSearching ? (
        <Container>
          <DragDropContext onDragEnd={onDragEndSearch}>
            <StructureContainer ref={structureContainerRef}>
              <SingleColumn>
                <ColumnNameBox>
                  <img src={ColumnFolder} alt="folder" />
                  <p>Wyszukiwarka</p>
                </ColumnNameBox>
                <div>
                  {searchFolders.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                        width: "calc(100% - 65px)",
                        alignItems: "center",
                      }}
                    >
                      {searchFolders
                        .slice()
                        .sort((a: any, b: any) => {
                          if (a.Type === 1 && b.Type !== 1) return -1;
                          if (a.Type === 2 && b.Type !== 1 && b.Type !== 2)
                            return -1;
                          return 0;
                        })
                        .map((folder: any) => {
                          const { FolderId, FolderName, Name, Type, ItemId } =
                            folder;
                          if (Type === 0) {
                            return (
                              <SearchSingleUser
                                setIsPlayerModalOpen={setIsPlayerModalOpen}
                                id={ItemId}
                                selectedPlayerId={selectedPlayerId}
                                setSelectedPlayerId={setSelectedPlayerId}
                                click={async () => {
                                  await getSearchFoldersPathFn(FolderId);
                                  getFirstSearchFolderDetailFn(FolderId, 0);
                                }}
                                name={Name}
                              />
                            );
                          }

                          if (Type === 1) {
                            return (
                              <SearchSingleFolder
                                click={async () => {
                                  await getSearchFoldersPathFn(FolderId);
                                  getFirstSearchFolderDetailFn(FolderId, 0);
                                }}
                                name={Name}
                              />
                            );
                          }
                          if (Type === 2) {
                            return (
                              <SearchSingleTrainer
                                setIsTrainerModalOpen={setIsTrainerModalOpen}
                                id={ItemId}
                                selectedTrainerId={selectedTrainerId}
                                setSelectedTrainerId={setSelectedTrainerId}
                                click={async () => {
                                  await getSearchFoldersPathFn(FolderId);
                                  getFirstSearchFolderDetailFn(FolderId, 0);
                                }}
                                name={Name}
                              />
                            );
                          }
                        })}
                    </div>
                  ) : (
                    <div>
                      <div>
                        <p style={{ color: "white" }}>Brak wyników</p>
                      </div>
                    </div>
                  )}
                </div>
              </SingleColumn>

              {searchColumns.map((column, columnIndex) => (
                <Droppable key={column.id} droppableId={column.id}>
                  {(provided) => (
                    <SingleColumn
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        setClickedColumnId(column.id);
                        setColumnClickedItem({
                          id: column.id,
                          x: e.clientX,
                          y: e.clientY,
                          columnIndex,
                        });
                      }}
                    >
                      <StyledSvg
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setClickedColumnId(column.id);
                          setColumnClickedItem({
                            id: column.id,
                            x: e.clientX + 25,
                            y: e.clientY,
                            columnIndex,
                          });
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="3.563"
                        viewBox="0 0 18 3.563"
                      >
                        <g
                          id="Group_17670"
                          data-name="Group 17670"
                          transform="translate(-321 -48)"
                        >
                          <path
                            id="Path_5620"
                            data-name="Path 5620"
                            d="M17.318,15.328a1.9,1.9,0,0,0-2,1.781,1.9,1.9,0,0,0,2,1.781,1.9,1.9,0,0,0,2-1.781,1.9,1.9,0,0,0-2-1.781Z"
                            transform="translate(312.673 32.672)"
                            fill="#9cb0c4"
                          />
                          <path
                            id="Path_5621"
                            data-name="Path 5621"
                            d="M6.5,15.328a1.9,1.9,0,0,0-2,1.781,1.9,1.9,0,0,0,2,1.781,1.9,1.9,0,0,0,2-1.781,1.9,1.9,0,0,0-2-1.781Z"
                            transform="translate(316.5 32.672)"
                            fill="#9cb0c4"
                          />
                          <path
                            id="Path_5622"
                            data-name="Path 5622"
                            d="M28.16,15.328a1.9,1.9,0,0,0-2,1.781,2.018,2.018,0,0,0,4.008,0,1.9,1.9,0,0,0-2-1.781Z"
                            transform="translate(308.836 32.672)"
                            fill="#9cb0c4"
                          />
                        </g>
                      </StyledSvg>
                      <ColumnNameBox>
                        <>
                          <img src={ColumnFolder} alt="folder" />
                          <p>
                            {columnIndex === 0 ? (
                              <>
                                {searchFoldersPath.length > 0 &&
                                  // @ts-ignore
                                  searchFoldersPath[0]?.Name}
                              </>
                            ) : (
                              <>
                                {" "}
                                {customSearchFoldersPath.length > 0 &&
                                  customSearchFoldersPath[columnIndex - 1]
                                    ?.Name}
                              </>
                            )}
                          </p>
                        </>
                      </ColumnNameBox>
                      <div>
                        {column.folders.length === 0 &&
                          column.items.length === 0 &&
                          column.trainers.length === 0 && (
                            <p style={{ color: "white" }}>Folder jest pusty</p>
                          )}

                        <TrainerBox>
                          {column.trainers.length > 0 && (
                            <p>TRENERZY/OPIEKUNOWIE</p>
                          )}
                          {column.trainers.map((item: any, itemIndex: any) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={`${column.id}${item.id}`}
                                index={-1}
                              >
                                {(provided) => (
                                  <ProvidedDiv
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {item.type === "trainer" && (
                                      <SingleTrainer
                                        specialization={item.specialization}
                                        fullAccess={true}
                                        selectedTrainerId={selectedTrainerId}
                                        setSelectedTrainerId={
                                          setSelectedTrainerId
                                        }
                                        setIsTrainerModalOpen={
                                          setIsTrainerModalOpen
                                        }
                                        onContextMenu={(e: any) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setClickedItemId(item.id);
                                          setClickedTrainerId(item.id);
                                          setClickedColumnId(column.id);
                                          setClickedTrainer({
                                            id: item.id,
                                            x: e.clientX,
                                            y: e.clientY,
                                          });
                                        }}
                                        photoFilePath={item.photoFilePath}
                                        id={item.id}
                                        name={item.name}
                                      />
                                    )}
                                  </ProvidedDiv>
                                )}
                              </Draggable>
                            );
                          })}
                        </TrainerBox>
                        {column.folders.map((folder: any, itemIndex: any) => {
                          console.log(folder);
                          return (
                            <Draggable
                              key={folder.id}
                              draggableId={folder.id}
                              index={-2}
                            >
                              {(provided) => (
                                <ProvidedDiv
                                  ref={provided.innerRef}
                                  // {...provided.draggableProps}
                                  // {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {folder.type === "folder" && (
                                    <SingleFolder
                                      setHoverFolderId={setHoverFolderId}
                                      setIsFolderModalOpen={
                                        setIsFolderModalOpen
                                      }
                                      selectedFolderId={selectedFolderId}
                                      setSelectedFolderId={setSelectedFolderId}
                                      playerNumber={folder.items.length}
                                      trainerNumber={folder?.trainers?.length}
                                      onContextMenu={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setClickedFolderPlayers(folder.items);
                                        setClickedFolderId(folder.id);
                                        setClickedFolderIndex(columnIndex);
                                        setClickedFolder({
                                          id: folder.id,
                                          x: e.clientX,
                                          y: e.clientY,
                                        });
                                      }}
                                      onContextMenuFn={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setClickedFolderPlayers(folder.items);
                                        setClickedFolderId(folder.id);
                                        setClickedFolderIndex(columnIndex);
                                        setClickedFolder({
                                          id: folder.id,
                                          x: e.clientX + 25,
                                          y: e.clientY,
                                        });
                                      }}
                                      getFolderDetailFn={(FolderId: string) =>
                                        getSearchFolderDetailFn(
                                          FolderId,
                                          columnIndex,
                                        )
                                      }
                                      id={folder.id}
                                      name={folder.name}
                                      isSelected={
                                        customSearchFoldersPath[
                                          columnIndex
                                          // @ts-ignore
                                        ]?.FolderId === folder?.id
                                      }
                                      // style={{
                                      //   background:
                                      //     activeFolders[columnIndex] === folder?.id
                                      //       ? "rgba(163, 158, 157, 0.2)"
                                      //       : "rgba(163, 158, 157, 0.05)",
                                      // }}
                                    />
                                  )}
                                </ProvidedDiv>
                              )}
                            </Draggable>
                          );
                        })}
                        {column?.items.length > 0 && (
                          <div style={{ width: "100%" }}>
                            <p
                              style={{
                                color: "#a39e9d",

                                fontSize: "15px",
                                paddingLeft: "25px",
                              }}
                            >
                              ZAWODNICY
                            </p>
                          </div>
                        )}
                        {column.items
                          .slice()
                          .sort((a: any, b: any) => a.order - b.order)
                          .map((item: any, itemIndex: any) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={itemIndex}
                              >
                                {(provided) => (
                                  <ProvidedDiv
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {item.type === "item" && (
                                      <SingleUser
                                        selectedPlayerId={selectedPlayerId}
                                        setSelectedPlayerId={
                                          setSelectedPlayerId
                                        }
                                        setIsPlayerModalOpen={
                                          setIsPlayerModalOpen
                                        }
                                        onContextMenu={(e: any) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setClickedItemId(item.id);
                                          setClickedPlayerId(item.playerId);
                                          setClickedColumnId(column.id);
                                          setClickedPlayer({
                                            id: item.id,
                                            x: e.clientX,
                                            y: e.clientY,
                                          });
                                        }}
                                        photoFilePath={item.photoFilePath}
                                        id={item.playerId}
                                        name={item.name}
                                      />
                                    )}
                                  </ProvidedDiv>
                                )}
                              </Draggable>
                            );
                          })}
                      </div>
                    </SingleColumn>
                  )}
                </Droppable>
              ))}

              <LeftScrollBar
                onMouseEnter={() => handleScroll("left")}
                onMouseLeave={stopScroll}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                >
                  <path
                    id="Icon_awesome-arrow-alt-circle-left"
                    data-name="Icon awesome-arrow-alt-circle-left"
                    d="M14.562,28.562a14,14,0,1,1,14-14A14,14,0,0,1,14.562,28.562Zm6.548-16.484H14.562v-4a.678.678,0,0,0-1.157-.48L6.953,14.083a.671.671,0,0,0,0,.954l6.452,6.486a.677.677,0,0,0,1.157-.48v-4h6.548a.679.679,0,0,0,.677-.677V12.756A.679.679,0,0,0,21.111,12.079Z"
                    transform="translate(-0.563 -0.563)"
                    fill="#909090"
                  />
                </svg>
              </LeftScrollBar>

              <RightScrollBar
                onMouseEnter={() => handleScroll("right")}
                onMouseLeave={stopScroll}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                >
                  <path
                    id="Icon_awesome-arrow-alt-circle-left"
                    data-name="Icon awesome-arrow-alt-circle-left"
                    d="M14.563,28.562a14,14,0,1,0-14-14A14,14,0,0,0,14.563,28.562ZM8.014,12.079h6.548v-4A.678.678,0,0,1,15.72,7.6l6.452,6.486a.671.671,0,0,1,0,.954L15.72,21.523a.677.677,0,0,1-1.157-.48v-4H8.014a.679.679,0,0,1-.677-.677V12.756A.679.679,0,0,1,8.014,12.079Z"
                    transform="translate(-0.563 -0.563)"
                    fill="#909090"
                  />
                </svg>
              </RightScrollBar>
            </StructureContainer>
          </DragDropContext>

          <PathBox>
            {searchFoldersPath.length > 0 &&
              searchFoldersPath
                .slice()
                .sort((a: any, b: any) => b.Order - a.Order)
                .map((folderPath) => {
                  const { FolderId, Name, Order } = folderPath;
                  const nextIndex = Object.keys(activeFolders).length;
                  return (
                    <>
                      <div
                        onClick={() =>
                          Order > 0
                            ? null
                            : (setSearchColumns((prevColumns) => [
                                prevColumns[0],
                              ]),
                              setCustomSearchFoldersPath([]))
                        }
                        // onClick={() =>
                        //   Order > 0
                        //     ? null
                        //     : getFolderDetailFn(FolderId, nextIndex - 1)
                        // }
                        style={{
                          cursor: Order > 0 ? "not-allowed" : "pointer",
                        }}
                      >
                        <p style={{ color: Order > 0 ? "#ffffff" : "#0091FF" }}>
                          {Name}
                        </p>
                      </div>
                      <p>-</p>
                    </>
                  );
                })}

            {customSearchFoldersPath.length > 0 &&
              customSearchFoldersPath.map((folderPath: any, index: number) => {
                const { FolderId, Name } = folderPath;
                const nextIndex = Object.keys(activeFolders).length;
                return (
                  <>
                    <div
                      onClick={() => {
                        // setSearchColumns((prevColumns) => [
                        //   prevColumns[index + 2],
                        // ]);
                        setSearchColumns((prevColumns) =>
                          prevColumns.slice(0, index + 2),
                        );
                        setCustomSearchFoldersPath((prevPaths: any) =>
                          prevPaths.slice(0, index + 1),
                        );
                      }}
                      // onClick={() => getFolderDetailFn(FolderId, nextIndex - 1)}
                      style={{ cursor: "pointer" }}
                    >
                      <p style={{ color: "#0091FF" }}>{Name}</p>
                    </div>
                    <p>-</p>
                  </>
                );
              })}
          </PathBox>
        </Container>
      ) : (
        <Container>
          <DragDropContext onDragEnd={onDragEnd}>
            <StructureContainer ref={structureContainerRef}>
              <Droppable droppableId={"root"}>
                {(provided) => (
                  <SingleColumn
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    onContextMenu={(e) => {
                      e.preventDefault();

                      setClickedItem({
                        id: "root",
                        x: e.clientX,
                        y: e.clientY,
                        columnIndex: -1,
                      });
                    }}
                  >
                    <StyledSvg
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setClickedItem({
                          id: "root",
                          x: e.clientX + 25,
                          y: e.clientY,
                          columnIndex: -1,
                        });
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="3.563"
                      viewBox="0 0 18 3.563"
                    >
                      <g
                        id="Group_17670"
                        data-name="Group 17670"
                        transform="translate(-321 -48)"
                      >
                        <path
                          id="Path_5620"
                          data-name="Path 5620"
                          d="M17.318,15.328a1.9,1.9,0,0,0-2,1.781,1.9,1.9,0,0,0,2,1.781,1.9,1.9,0,0,0,2-1.781,1.9,1.9,0,0,0-2-1.781Z"
                          transform="translate(312.673 32.672)"
                          fill="#9cb0c4"
                        />
                        <path
                          id="Path_5621"
                          data-name="Path 5621"
                          d="M6.5,15.328a1.9,1.9,0,0,0-2,1.781,1.9,1.9,0,0,0,2,1.781,1.9,1.9,0,0,0,2-1.781,1.9,1.9,0,0,0-2-1.781Z"
                          transform="translate(316.5 32.672)"
                          fill="#9cb0c4"
                        />
                        <path
                          id="Path_5622"
                          data-name="Path 5622"
                          d="M28.16,15.328a1.9,1.9,0,0,0-2,1.781,2.018,2.018,0,0,0,4.008,0,1.9,1.9,0,0,0-2-1.781Z"
                          transform="translate(308.836 32.672)"
                          fill="#9cb0c4"
                        />
                      </g>
                    </StyledSvg>
                    <ColumnNameBox>
                      <img src={ColumnFolder} alt="folder" />
                      <p>Grupa</p>
                    </ColumnNameBox>
                    <div>
                      {!isLoading ? (
                        <>
                          {rootFolder?.map((item: any, index: any) => {
                            return (
                              <Draggable
                                key={item?.FolderId}
                                draggableId={item?.FolderId}
                                index={-2}
                              >
                                {(provided) => (
                                  <ProvidedDiv
                                    style={{ width: "100%" }}
                                    ref={provided.innerRef}
                                    // {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <SingleFolder
                                      setHoverFolderId={setHoverFolderId}
                                      selectedFolderId={selectedFolderId}
                                      setSelectedFolderId={setSelectedFolderId}
                                      setIsFolderModalOpen={
                                        setIsFolderModalOpen
                                      }
                                      playerNumber={item.Items.length}
                                      trainerNumber={0}
                                      onContextMenu={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setAuxFolderName(item?.Name);
                                        setClickedFolderIndex(-1);
                                        setClickedFolderPlayers(item.Items);
                                        setClickedFolderId(item.FolderId);
                                        setClickedFolder({
                                          id: item.FolderId,
                                          x: e.clientX,
                                          y: e.clientY,
                                        });
                                      }}
                                      onContextMenuFn={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setClickedFolderIndex(-1);
                                        setClickedFolderPlayers(item.Items);
                                        setAuxFolderName(item?.Name);
                                        setClickedFolderId(item.FolderId);
                                        setClickedFolder({
                                          id: item.FolderId,
                                          x: e.clientX + 25,
                                          y: e.clientY,
                                        });
                                      }}
                                      getFolderDetailFn={(FolderId: string) =>
                                        getFolderDetailFn(FolderId, -1)
                                      }
                                      id={item?.FolderId}
                                      name={item?.Name}
                                      isSelected={
                                        activeFolders["-1"] === item?.FolderId
                                      }
                                      // style={{
                                      //   background:
                                      //     activeFolders["-1"] === item?.FolderId
                                      //       ? "rgba(163, 158, 157, 0.2)"
                                      //       : "rgba(163, 158, 157, 0.05)",
                                      // }}
                                    />
                                  </ProvidedDiv>
                                )}
                              </Draggable>
                            );
                          })}

                          {/* {availableTrainers?.map((trainer: any, index: any) => (
                          <Draggable
                            key={trainer?.TrainerId}
                            draggableId={trainer?.TrainerId}
                            index={-1}
                          >
                            {(provided) => (
                              <ProvidedDiv
                                style={{ width: "100%" }}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <SingleTrainer
                                  selectedTrainerId={selectedTrainerId}
                                  setSelectedTrainerId={setSelectedTrainerId}
                                  setIsTrainerModalOpen={setIsTrainerModalOpen}
                                  photoFilePath={trainer.PhotoFilePath}
                                  onContextMenu={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setClickedTrainer({
                                      id: trainer.id,
                                      x: e.clientX,
                                      y: e.clientY,
                                    });
                                  }}
                                  id={trainer?.TrainerId}
                                  name={trainer?.Name}
                                />
                              </ProvidedDiv>
                            )}
                          </Draggable>
                        ))} */}

                          {availablePlayers?.map((player: any, index: any) => (
                            <Draggable
                              key={player?.PlayerId}
                              draggableId={player?.PlayerId}
                              index={index}
                            >
                              {(provided) => (
                                <ProvidedDiv
                                  style={{ width: "100%" }}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <SingleUser
                                    selectedPlayerId={selectedPlayerId}
                                    setSelectedPlayerId={setSelectedPlayerId}
                                    setIsPlayerModalOpen={setIsPlayerModalOpen}
                                    photoFilePath={player.PhotoFilePath}
                                    onContextMenu={(e: any) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setClickedRootPlayer(player.PlayerId);
                                      setClickedPlayer({
                                        id: player.PlayerId,
                                        x: e.clientX,
                                        y: e.clientY,
                                      });
                                    }}
                                    id={player?.PlayerId}
                                    name={player?.Name}
                                  />
                                </ProvidedDiv>
                              )}
                            </Draggable>
                          ))}
                        </>
                      ) : (
                        <p style={{ color: "white" }}>Loading...</p>
                      )}
                    </div>
                  </SingleColumn>
                )}
              </Droppable>

              {columns.map((column, columnIndex) => (
                <Droppable key={column.id} droppableId={column.id}>
                  {(provided) => (
                    <SingleColumn
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        setClickedColumnId(column.id);
                        setColumnClickedItem({
                          id: column.id,
                          x: e.clientX,
                          y: e.clientY,
                          columnIndex,
                        });
                      }}
                    >
                      <StyledSvg
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setClickedColumnId(column.id);
                          setColumnClickedItem({
                            id: column.id,
                            x: e.clientX + 25,
                            y: e.clientY,
                            columnIndex,
                          });
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="3.563"
                        viewBox="0 0 18 3.563"
                      >
                        <g
                          id="Group_17670"
                          data-name="Group 17670"
                          transform="translate(-321 -48)"
                        >
                          <path
                            id="Path_5620"
                            data-name="Path 5620"
                            d="M17.318,15.328a1.9,1.9,0,0,0-2,1.781,1.9,1.9,0,0,0,2,1.781,1.9,1.9,0,0,0,2-1.781,1.9,1.9,0,0,0-2-1.781Z"
                            transform="translate(312.673 32.672)"
                            fill="#9cb0c4"
                          />
                          <path
                            id="Path_5621"
                            data-name="Path 5621"
                            d="M6.5,15.328a1.9,1.9,0,0,0-2,1.781,1.9,1.9,0,0,0,2,1.781,1.9,1.9,0,0,0,2-1.781,1.9,1.9,0,0,0-2-1.781Z"
                            transform="translate(316.5 32.672)"
                            fill="#9cb0c4"
                          />
                          <path
                            id="Path_5622"
                            data-name="Path 5622"
                            d="M28.16,15.328a1.9,1.9,0,0,0-2,1.781,2.018,2.018,0,0,0,4.008,0,1.9,1.9,0,0,0-2-1.781Z"
                            transform="translate(308.836 32.672)"
                            fill="#9cb0c4"
                          />
                        </g>
                      </StyledSvg>
                      <ColumnNameBox>
                        <img src={ColumnFolder} alt="folder" />
                        <p>{activeFoldersName[columnIndex - 1]}</p>
                      </ColumnNameBox>
                      <div>
                        <TrainerBox>
                          {/* {column.trainers.length > 0 && (
                            <p>TRENERZY/OPIEKUNOWIE</p>
                          )} */}
                          {column.trainers.some(
                            (trainer: any) => trainer.isRootFolder === true,
                          ) && <p>TRENERZY/OPIEKUNOWIE</p>}
                          {column.trainers.map((item: any, itemIndex: any) => {
                            if (item.isRootFolder)
                              return (
                                <>
                                  {item.type === "trainer" && (
                                    <SingleTrainer
                                      specialization={item.specialization}
                                      selectedTrainerId={selectedTrainerId}
                                      setSelectedTrainerId={
                                        setSelectedTrainerId
                                      }
                                      setIsTrainerModalOpen={
                                        setIsTrainerModalOpen
                                      }
                                      fullAccess={true}
                                      onContextMenu={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setClickedItemId(item.id);
                                        setClickedTrainerId(item.id);
                                        setClickedColumnId(column.id);
                                        setClickedTrainer({
                                          id: item.id,
                                          x: e.clientX,
                                          y: e.clientY,
                                        });
                                      }}
                                      photoFilePath={item.photoFilePath}
                                      id={item.id}
                                      name={item.name}
                                    />
                                  )}
                                </>
                              );
                          })}
                        </TrainerBox>

                        {column.folders.length === 0 &&
                          column.items.length === 0 &&
                          column.trainers.length === 0 && (
                            <p style={{ color: "white" }}>Folder jest pusty</p>
                          )}

                        {column.folders.map((folder: any, itemIndex: any) => {
                          return (
                            <Draggable
                              key={folder.id}
                              draggableId={folder.id}
                              index={-2}
                            >
                              {(provided) => (
                                <ProvidedDiv
                                  ref={provided.innerRef}
                                  // {...provided.draggableProps}
                                  // {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {folder.type === "folder" && (
                                    <SingleFolder
                                      setHoverFolderId={setHoverFolderId}
                                      setIsFolderModalOpen={
                                        setIsFolderModalOpen
                                      }
                                      selectedFolderId={selectedFolderId}
                                      setSelectedFolderId={setSelectedFolderId}
                                      playerNumber={folder.items.length}
                                      trainerNumber={folder?.trainers?.length}
                                      onContextMenu={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setAuxFolderName(folder?.name);
                                        setClickedFolderPlayers(folder.items);
                                        setClickedFolderId(folder.id);
                                        setClickedFolderIndex(columnIndex);
                                        setClickedFolder({
                                          id: folder.id,
                                          x: e.clientX,
                                          y: e.clientY,
                                        });
                                      }}
                                      onContextMenuFn={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setAuxFolderName(folder?.name);
                                        setClickedFolderPlayers(folder.items);
                                        setClickedFolderId(folder.id);
                                        setClickedFolderIndex(columnIndex);
                                        setClickedFolder({
                                          id: folder.id,
                                          x: e.clientX + 25,
                                          y: e.clientY,
                                        });
                                      }}
                                      getFolderDetailFn={(FolderId: string) =>
                                        getFolderDetailFn(FolderId, columnIndex)
                                      }
                                      id={folder.id}
                                      name={folder.name}
                                      isSelected={
                                        activeFolders[columnIndex] ===
                                        folder?.id
                                      }
                                      // style={{
                                      //   background:
                                      //     activeFolders[columnIndex] === folder?.id
                                      //       ? "rgba(163, 158, 157, 0.2)"
                                      //       : "rgba(163, 158, 157, 0.05)",
                                      // }}
                                    />
                                  )}
                                </ProvidedDiv>
                              )}
                            </Draggable>
                          );
                        })}

                        {/* {column.trainers.map((item: any, itemIndex: any) => {
                        return (
                          <Draggable
                            key={item.id}
                            draggableId={`${column.id}${item.id}`}
                            index={-1}
                          >
                            {(provided) => (
                              <ProvidedDiv
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {item.type === "trainer" && (
                                  <SingleTrainer
                                    selectedTrainerId={selectedTrainerId}
                                    setSelectedTrainerId={setSelectedTrainerId}
                                    setIsTrainerModalOpen={
                                      setIsTrainerModalOpen
                                    }
                                    onContextMenu={(e: any) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setClickedItemId(item.id);
                                      setClickedTrainerId(item.id);
                                      setClickedColumnId(column.id);
                                      setClickedTrainer({
                                        id: item.id,
                                        x: e.clientX,
                                        y: e.clientY,
                                      });
                                    }}
                                    photoFilePath={item.photoFilePath}
                                    id={item.id}
                                    name={item.name}
                                  />
                                )}
                              </ProvidedDiv>
                            )}
                          </Draggable>
                        );
                      })} */}
                        {column?.items.length > 0 && (
                          <div style={{ width: "100%" }}>
                            <p
                              style={{
                                color: "#a39e9d",

                                fontSize: "15px",
                                paddingLeft: "25px",
                              }}
                            >
                              ZAWODNICY
                            </p>
                          </div>
                        )}
                        {column.items
                          .slice()
                          .sort((a: any, b: any) => a.order - b.order)
                          .map((item: any, itemIndex: any) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={itemIndex}
                              >
                                {(provided) => (
                                  <ProvidedDiv
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {item.type === "item" && (
                                      <SingleUser
                                        selectedPlayerId={selectedPlayerId}
                                        setSelectedPlayerId={
                                          setSelectedPlayerId
                                        }
                                        setIsPlayerModalOpen={
                                          setIsPlayerModalOpen
                                        }
                                        onContextMenu={(e: any) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setClickedItemId(item.id);
                                          setClickedPlayerId(item.playerId);
                                          setClickedColumnId(column.id);
                                          setClickedPlayer({
                                            id: item.id,
                                            x: e.clientX,
                                            y: e.clientY,
                                          });
                                        }}
                                        photoFilePath={item.photoFilePath}
                                        id={item.playerId}
                                        name={item.name}
                                      />
                                    )}
                                  </ProvidedDiv>
                                )}
                              </Draggable>
                            );
                          })}
                      </div>
                    </SingleColumn>
                  )}
                </Droppable>
              ))}

              <LeftScrollBar
                onMouseEnter={() => handleScroll("left")}
                onMouseLeave={stopScroll}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                >
                  <path
                    id="Icon_awesome-arrow-alt-circle-left"
                    data-name="Icon awesome-arrow-alt-circle-left"
                    d="M14.562,28.562a14,14,0,1,1,14-14A14,14,0,0,1,14.562,28.562Zm6.548-16.484H14.562v-4a.678.678,0,0,0-1.157-.48L6.953,14.083a.671.671,0,0,0,0,.954l6.452,6.486a.677.677,0,0,0,1.157-.48v-4h6.548a.679.679,0,0,0,.677-.677V12.756A.679.679,0,0,0,21.111,12.079Z"
                    transform="translate(-0.563 -0.563)"
                    fill="#909090"
                  />
                </svg>
              </LeftScrollBar>

              <RightScrollBar
                onMouseEnter={() => handleScroll("right")}
                onMouseLeave={stopScroll}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                >
                  <path
                    id="Icon_awesome-arrow-alt-circle-left"
                    data-name="Icon awesome-arrow-alt-circle-left"
                    d="M14.563,28.562a14,14,0,1,0-14-14A14,14,0,0,0,14.563,28.562ZM8.014,12.079h6.548v-4A.678.678,0,0,1,15.72,7.6l6.452,6.486a.671.671,0,0,1,0,.954L15.72,21.523a.677.677,0,0,1-1.157-.48v-4H8.014a.679.679,0,0,1-.677-.677V12.756A.679.679,0,0,1,8.014,12.079Z"
                    transform="translate(-0.563 -0.563)"
                    fill="#909090"
                  />
                </svg>
              </RightScrollBar>
            </StructureContainer>
          </DragDropContext>

          <PathBox>
            {Object.entries(activeFolders).map(
              ([index, folderId]) =>
                index === "-1" && (
                  <React.Fragment key={index}>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => handleItemClick(parseInt(index, 10))}
                    >
                      {/* <img src={Folders} alt="folders" /> */}
                      <p>{activeFoldersName[index]}</p>
                    </div>
                    <p>-</p>
                    {/* <img src={Arrow} alt="arrow" /> */}
                  </React.Fragment>
                ),
            )}
            {Object.entries(activeFolders).map(
              ([index, folderId]) =>
                index !== "-1" && (
                  <React.Fragment key={index}>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => handleItemClick(parseInt(index, 10))}
                    >
                      {/* <img src={Folders} alt="folders" /> */}
                      <p>{activeFoldersName[index]}</p>
                    </div>
                    <p>-</p>
                    {/* <img src={Arrow} alt="arrow" /> */}
                  </React.Fragment>
                ),
            )}
          </PathBox>
        </Container>
      )}

      {clickedItem &&
        !columnClickedItem &&
        !clickedFolder &&
        !clickedPlayer &&
        !clickedTrainer && (
          <ContextMenu
            x={clickedItem.x}
            y={clickedItem.y}
            onClose={() => setClickedItem(null)}
            setIsFolderAdded={setIsFolderAdded}
          />
        )}

      {columnClickedItem &&
        !clickedItem &&
        !clickedFolder &&
        !clickedPlayer &&
        !clickedTrainer && (
          <ColumnContextMenu
            auxFolderName={auxFolderName}
            x={columnClickedItem.x}
            y={columnClickedItem.y}
            onClose={() => setClickedItem(null)}
            setIsColumnFolderAdded={setIsColumnFolderAdded}
            copyItemId={copyItemId}
            copyFolderFrom={copyFolderFrom}
            copyFolderTo={copyFolderTo}
            copyPlayerId={copyPlayerId}
            clickedColumnId={clickedColumnId}
            columns={columns}
            getFolderDetailWithoutIndex={getFolderDetailWithoutIndex}
            setIsFolderNameEdited={setIsFolderNameEdited}
            setIsFolderModalOpen={setIsFolderModalOpen}
            clickedFolderPlayers={clickedFolderPlayers}
            setSelectedFolderId={setSelectedFolderId}
            setIsEventModalOpen={setIsEventModalOpen}
            clickedFolderId={clickedFolderId}
            getRootFolderFn={getRootFolderFn}
            setColumns={setColumns}
            getAvailablePlayers={getAvailablePlayers}
            activeColumnId={activeColumnId}
            setChangeNameType={setChangeNameType}
            setActiveConversationId={setActiveConversationId}
            setActiveConversationType={setActiveConversationType}
            setActiveConversationImageUrl={setActiveConversationImageUrl}
            setActiveConversationName={setActiveConversationName}
            setConversation={setConversation}
            setTempMemberId={setTempMemberId}
            setAllInterlocutors={setAllInterlocutors}
          />
        )}

      {clickedFolder &&
        !clickedItem &&
        !columnClickedItem &&
        !clickedPlayer &&
        !clickedTrainer && (
          <FolderContextMenu
            auxFolderName={auxFolderName}
            setIsFolderModalOpen={setIsFolderModalOpen}
            isSearching={isSearching}
            clickedFolderPlayers={clickedFolderPlayers}
            clickedFolderIndex={clickedFolderIndex}
            x={clickedFolder.x}
            y={clickedFolder.y}
            setIsEventModalOpen={setIsEventModalOpen}
            getFolderDetailFn={getFolderDetailFn}
            onClose={() => setClickedFolder(null)}
            setIsFolderNameEdited={setIsFolderNameEdited}
            clickedFolderId={clickedFolderId}
            getRootFolderFn={getRootFolderFn}
            getFolderDetailWithoutIndex={getFolderDetailWithoutIndex}
            columns={columns}
            getAvailablePlayers={getAvailablePlayers}
            setColumns={setColumns}
            getSearchFolderDetailFn={getSearchFolderDetailFn}
            setSelectedFolderId={setSelectedFolderId}
            setChangeNameType={setChangeNameType}
            setActiveConversationId={setActiveConversationId}
            setActiveConversationType={setActiveConversationType}
            setActiveConversationImageUrl={setActiveConversationImageUrl}
            setActiveConversationName={setActiveConversationName}
            setConversation={setConversation}
            setTempMemberId={setTempMemberId}
            setAllInterlocutors={setAllInterlocutors}
          />
        )}

      {clickedPlayer &&
        !clickedItem &&
        !columnClickedItem &&
        !clickedFolder &&
        !clickedTrainer && (
          <PlayerContextMenu
            x={clickedPlayer.x}
            y={clickedPlayer.y}
            clickedItemId={clickedItemId}
            clickedColumnId={clickedColumnId}
            clickedPlayer={clickedPlayer}
            onClose={() => setClickedPlayer(null)}
            getFolderDetailWithoutIndex={getFolderDetailWithoutIndex}
            getAvailablePlayers={getAvailablePlayers}
            getRootFolderFn={getRootFolderFn}
            isRootFolder={!clickedPlayer.id}
            clickedPlayerId={clickedPlayerId}
            setCopyFolderFrom={setCopyFolderFrom}
            setCopyItemId={setCopyItemId}
            setCopyPlayerId={setCopyPlayerId}
            setIsPlayerModalOpen={setIsPlayerModalOpen}
            setActiveConversationId={setActiveConversationId}
            setActiveConversationImageUrl={setActiveConversationImageUrl}
            setActiveConversationName={setActiveConversationName}
            setActiveConversationType={setActiveConversationType}
            setConversation={setConversation}
            setTempMemberId={setTempMemberId}
            clickedRootPlayer={clickedRootPlayer}
            setClickedRootPlayer={setClickedRootPlayer}
          />
        )}

      {clickedTrainer &&
        !clickedPlayer &&
        !clickedItem &&
        !columnClickedItem &&
        !clickedFolder && (
          <TrainerContextMenu
            columns={columns}
            getFolderDetailWithoutIndex={getFolderDetailWithoutIndex}
            clickedColumnId={clickedColumnId}
            clickedTrainerId={clickedTrainerId}
            x={clickedTrainer.x}
            y={clickedTrainer.y}
            onClose={() => setClickedTrainer(null)}
            isRootFolder={!clickedTrainer.id}
            setActiveConversationId={setActiveConversationId}
          />
        )}
    </ContentContainer>
  );
};

export default Structure;

const Container = styled.div`
  height: calc(100vh - 58px);
  width: 100%;
  display: flex;
  flex-direction: column;
  outline: none;
  &:focus {
    outline: none;
  }

  * {
    outline: none;
  }

  *:focus {
    outline: none;
  }
`;

const LeftScrollBar = styled.div`
  z-index: 99;
  height: calc(100vh - 58px - 31px);
  position: fixed;
  top: 58px;
  left: 224px;
  width: 21px;
  background: rgba(27, 55, 82, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 5px 0px 22px -1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 5px 0px 22px -1px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 0px 22px -1px rgba(0, 0, 0, 0.5);
  transition: all 0.1s ease-in-out;
  opacity: 0;

  &:hover {
    transition: all 0.1s ease-in-out;
    width: 42px;
    opacity: 1;
  }
`;

const RightScrollBar = styled.div`
  z-index: 99;
  height: calc(100vh - 58px - 31px);
  position: fixed;
  top: 58px;
  right: 0;
  width: 21px;
  background: rgba(27, 55, 82, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: -5px 0px 22px -1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -5px 0px 22px -1px rgba(0, 0, 0, 0.5);
  box-shadow: -5px 0px 22px -1px rgba(0, 0, 0, 0.5);
  transition: all 0.1s ease-in-out;
  opacity: 0;

  &:hover {
    transition: all 0.1s ease-in-out;
    opacity: 1;
    width: 42px;
  }
`;

const StructureContainer = styled.div`
  height: calc(100vh - 58px - 28px);
  padding: 0 24px;
  padding-top: 25px;
  display: flex;
  position: relative;
  overflow-x: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 145, 255, 1);
    border-radius: 0;
  }
`;

const ColumnNameBox = styled.div`
  display: flex;
  gap: 12px;
  width: 228px;
  position: absolute;
  left: 28px;
  top: 17px;

  & > img {
    width: 24.26px;
    height: 21px;
  }

  & > p {
    color: #0091ff;
    font-size: 13px;
    width: 140px;
    height: 39px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    /* white-space: nowrap; */
    -webkit-box-orient: vertical;
  }
`;

const SingleColumn = styled.div`
  height: calc(100%);
  min-width: 322px;
  /* min-width: 393px; */
  /* background: rgba(2, 2, 15, 0.3); */
  background-image: url(${ColumnBackground});
  background-size: cover;
  padding-top: 25px;
  position: relative;
  width: 322px;
  /* margin-top: 25px; */
  /* border-right: 5px solid rgba(2, 2, 15, 0.3); */
  /* border: 1px solid rgba(112, 112, 112, 0.5); */
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div:last-of-type {
    height: 90%;
    min-width: calc(100% - 30px);
    /* background: rgba(2, 2, 15, 0.3); */
    /* background-image: url(${ColumnBackground}); */
    /* background-size: cover; */
    padding-top: 13px;
    position: relative;
    width: calc(100% - 30px);
    /* border-right: 5px solid rgba(2, 2, 15, 0.3); */
    /* border: 1px solid rgba(112, 112, 112, 0.5); */
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 5px;
      /* width: 0px; */
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 145, 255, 1);
      border-radius: 0;
    }

    /* &::-webkit-scrollbar-track {
      background-color: rgba(2, 2, 15, 0.3);
    } */

    &:last-of-type {
      border-right: none;
    }

    & > div:nth-of-type(1) {
      margin-top: 5px;
    }
  }
`;

const PathBox = styled.div`
  width: 100%;
  height: 28px;
  background: #1b3752;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 12px;

  & > img {
    width: 24px;
    height: 24px;
  }

  & > p {
    font-size: 15px;
    color: white;
    margin-top: 2px;
  }

  & > div {
    display: flex;
    gap: 10px;

    & > img {
      width: 39px;
      height: 32px;
    }

    & > p {
      font-size: 15px;
      color: white;
      margin-top: 2px;
    }
  }
`;

const SearchPathBox = styled.div`
  width: 100%;
  height: 65px;
  background: #0091ff;
  border: 3px solid #fcec0c;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 23px;

  & > img {
    width: 24px;
    height: 24px;
  }

  & > div {
    display: flex;
    gap: 10px;

    & > img {
      width: 39px;
      height: 32px;
    }

    & > p {
      font-size: 18px;
      color: white;
      margin-top: 2px;
    }
  }
`;

const ProvidedDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  /* background: red; */

  & > div {
    /* width: 100%; */
  }
`;

const SideAddMenu = styled.div`
  /* position: absolute; */
  height: calc(100vh);

  right: 0;
  width: 917px;
  background: rgba(2, 2, 15, 1);
  transition: all 0.4s ease-in-out;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding-top: 5px;

  color: rgba(163, 158, 157, 1);
  & > * {
    transition: all 0.2s ease-in-out;
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  & > form {
    & > div:first-child {
      & > div {
        & > p {
          text-align: left;
          text-transform: uppercase;
          font-size: 15px;
          font-weight: 400;
          margin-bottom: 10px;
        }
      }
    }
  }
`;

const TrainerBox = styled.div`
  /* background: rgb(28, 56, 83); */
  width: calc(100% - 20px);
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  /* align-items: center; */
  gap: 10px;

  & > p {
    color: #a39e9d;
    font-size: 15px;
    /* padding: 5px 5px 0 5px; */
  }
`;

export const CustomInputBox = styled.div`
  height: 48px;
  position: relative;

  & > p {
    position: absolute;
    top: 5px;
    left: 9px;
    font-weight: 400;
    font-size: 11px;
    color: rgb(163, 158, 157);
  }

  & > input {
    width: 100%;
    height: 100%;
    background: rgba(163, 158, 157, 0.11);
    color: white;
    padding: 0 22px;
    text-align: right;

    &::placeholder {
      color: white;
    }
  }
`;

const StyledSvg = styled.svg`
  position: absolute;
  width: 20px;
  height: 15px;
  top: 6px;
  right: 25px;
  cursor: pointer;
  z-index: 99;
`;
