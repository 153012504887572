import { useEffect, useState } from "react";
import TabContent from "components/common/tabs/TabContent";

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 27px 39px; */
  width: 587px;

  & > div:nth-of-type(1) {
    background: rgb(2, 2, 15, 0.3);
    color: white;
    border-left: 5px solid #ff9226;
    opacity: 1;
    font-size: 15px;
    padding: 17px 37px;
    font-weight: 300;
    letter-spacing: 0.7px;
    text-transform: uppercase;
  }

  & > div:nth-of-type(2) > div {
    padding: 11px 10px 11px 40px;
    display: flex;
    gap: 5px;

    &:nth-child(even) {
      background: rgba(163, 158, 157, 0.09);
    }
    &:nth-child(odd) {
      background: rgba(163, 158, 157, 0.05);
    }

    & > p {
      :nth-child(1) {
        width: 100%;
        color: #707070;
        text-transform: capitalize;

      }
      :nth-child(2) {
        width: 100%;
        color: white;
        display: flex;
        justify-content: end;
      }
    }
  }
`;

const RelatedProfilesTab = ({ trainer }: any) => {
  const {
    Country,
    City,
    Region,
    PostCode,
    Street,
    BuildingNumber,
    FlatNumber,
  } = trainer?.Address;

  return (
    <TabContent id="address">
      <Container>
        <div>adres</div>
        <div>
          <div>
            <p>Kraj</p>
            <p>Polska</p>
          </div>
          <div>
            <p>Kod pocztowy</p>
            <p>{PostCode}</p>
          </div>
          <div>
            <p>Województwo</p>
            <p>{Region}</p>
          </div>
          <div>
            <p>Miasto</p>
            <p>{City}</p>
          </div>
          <div>
            <p>Ulica</p>
            <p>{Street}</p>
          </div>
          <div>
            <p>Numer budynku</p>
            <p>{BuildingNumber}</p>
          </div>
          <div>
            <p>Numer mieszkania</p>
            <p>{FlatNumber}</p>
          </div>
        </div>
      </Container>
    </TabContent>
  );
};

export default RelatedProfilesTab;
