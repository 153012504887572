import { conn } from '../api/BaseConnection';
import { ISeller, ISellerDetail } from 'types/seller.interface';

const endpoint = conn.endpoints.seller;

const getSeller = async (): Promise<ISellerDetail> => {
  const response = await conn.getJSON<ISeller>(endpoint.get, 'json');
  return response.Seller;
};

const sellerServices = {
  getSeller,
};

export default sellerServices;
