import { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import UsersList from "./UsersList";
import UsersTable from "./UsersTable";

import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import { RootState } from "../../store/store";
import { DataViewMode } from "../../types/globalTypes";
import { IUserManagement, UserInterface } from "../../types/userTypes";

import ContentContainer from "../layout/ContentContainer";
import FilterPanel from "../filters/FilterPanel";
import UsersTopbar from "./UsersTopbar";
import { DefaultSortContext } from "contexts/defaultSortContext";
import { getAllAcademyUsers } from "services/academyServices";

const Users: React.FC = () => {
  const [defaultSort, setDefaultSort] = useContext(DefaultSortContext);
  const [sortBy, setSortBy] =
    useState<{ value: number; label: string } | null>(null);
  const [sorts, setSorts] = useState<{ value: number; label: string }[]>([]);
  const viewMode = useSelector(
    (state: RootState) => state.ui.dataViewModes.usersManagment,
  );
  const [users, setUsers] = useState([]);
  const [queryString, setQueryString] = useState("");
  const [viewType, setViewType] = useState("list");
  // const {
  //   items: users,
  //   loading,
  //   containerRef,
  //   lastItemRef,
  //   // @ts-ignore
  // } = useInfiniteScroll<any>(academyServices.getAllAcademyUsers, queryString);

  useEffect(() => {
    getAllAcademyUsers()
      .then((data) => {
        setUsers(data.Data.Items);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const getAllSorts = async () => {
    try {
      setSorts([
        {
          label: "Najnowsze",
          value: 0,
        },
        {
          label: "Najstarsze",
          value: 1,
        },
        {
          label: "Imię (A-Z)",
          value: 2,
        },
        {
          label: "Imię (Z-A)",
          value: 3,
        },
        {
          label: "Nazwisko (A-Z)",
          value: 4,
        },
        {
          label: "Nazwisko (Z-A)",
          value: 5,
        },
        {
          label: "Email (A-Z)",
          value: 6,
        },
        {
          label: "Email (Z-A)",
          value: 7,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllSorts();
  }, []);

  useEffect(() => {
    if (sortBy) {
      // @ts-ignore
      setDefaultSort(sortBy.value);
    }
  }, [sortBy]);

  const handleSearch = (query: string) => {
    setQueryString(query);
  };

  return (
    <ContentContainer
      title="Lista pracowników"
      TopBar={
        <UsersTopbar
          sorts={sorts}
          sortBy={sortBy}
          setSortBy={setSortBy}
          handleQueryChange={handleSearch}
          defaultSort={defaultSort}
          viewType={viewType}
          setViewType={setViewType}
        />
      }
    >
      {viewType === "list" ? (
        <UsersTable
          users={users}
          containerRef={null}
          lastItemRef={null}
          // @ts-ignore
          loading={null}
          sortBy={sortBy}
        />
      ) : (
        <UsersList
          users={users}
          sortBy={sortBy}
          containerRef={null}
          lastItemRef={null}
        />
      )}
    </ContentContainer>
  );
};

export default Users;
