import { conn } from 'api/BaseConnection';
import { IListPageRequest, IListPageResponse } from 'types/globalTypes';
import { ITrainer } from 'types/transfer/trainer.interface';

const trainers = conn.endpoints.trainers;

const getAvailable = async (listPage?: IListPageRequest) => {
  return await conn.getJSON<IListPageResponse<ITrainer>>(
    trainers.avilable,
    'json',
    { ...listPage }
  );
};

const getAcademy = async (listPage?: IListPageRequest) => {
  return await conn.getJSON<IListPageResponse<ITrainer>>(
    trainers.academy,
    'json',
    { ...listPage }
  );
};

const trainerService = { getAvailable, getAcademy };

export default trainerService;
