import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import NewSelectStatystics from 'components/common/inputs/select/NewSelectStatystics';
import { useUrlQuery } from 'hooks/useUrlQuery';
import { IPlayerStateConditions } from 'middleware/transfer/playerTransferConditions';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ISelectOption } from 'types/globalTypes';
import {
  IPlayerDetail,
  PLAYER_STATE_CHANGE,
  TRANSFER_RECIEVER,
} from 'types/transfer/player.interface';
import { showServerErrors } from 'utils/errorsUtils';
import { useSelector } from 'react-redux';

interface IPlayerStateHeaderProps {
  refresh: () => Promise<void>;
  stateChangeOptions: IPlayerStateConditions[];
  selectItems: ISelectOption<PLAYER_STATE_CHANGE>[];
}

const PlayerStateHeader = ({
  refresh,
  stateChangeOptions,
  selectItems,
}: IPlayerStateHeaderProps) => {
  const [isLodaing, setLoading] = useState(false);
  const [state, setState] =
    useState<ISelectOption<PLAYER_STATE_CHANGE> | null>(null);

  const changePlayerState = async (state: PLAYER_STATE_CHANGE) => {
    setLoading(true);
    try {
      if (state === null) {
        return toast.error('Wybierz status zawodnika');
      }

      const changeState = stateChangeOptions.find(
        o => o.id === state
      )?.changeState;

      if (!changeState) {
        return toast.error('Nie można zmienić statusu zawodnika.');
      }

      await changeState();

      toast.success(
        `Zmieniono status zawodnika na: ${PLAYER_STATE_CHANGE[state]}`
      );

      setState(null);
      setLoading(false);

      await refresh();
    } catch (error: any) {
      showServerErrors(error);
      setLoading(false);
    }
  };

  const permission = useSelector((state: any) => state.permissions.permissions);

  const isElemHasPermission =  permission[0]?.Id === -1 ? true : !!permission.find((permission: any) => permission.Id === 181);

  return (
    <div className='flex p-8 gap-2'>
      <div
        style={{
          pointerEvents: isElemHasPermission ?  'auto' : 'none',
          opacity: isElemHasPermission ? '' : '0.6'
        }}
      >
        <NewSelectStatystics<PLAYER_STATE_CHANGE>
          name='state'
          label='Status'
          items={selectItems}
          selectedItem={state}
          setSelectedItem={setState}
        />

      </div>
      <Button
        variant={ButtonVariant.Submit}
        disabled={!state || isLodaing}
        onClick={() => state && changePlayerState(state.value)}>
        {isLodaing ? 'Zmieniam...' : 'Zmień status'}
      </Button>
    </div>
  );
};

export default PlayerStateHeader;
