import React, { useState, useEffect } from "react";
import FilterSingleSelect from "components/filters/FilterSingleSelect";
import FilterDropdownPanel from "components/common/panels/FilterDropdownPanel";
import styled from "styled-components";

const AmountLabel = styled.label`
  color: rgba(163, 158, 157, 1);
  font-size: 11px;
  position: absolute;
  top: 7px;
  left: 10px;
`;

const DateContainer = styled.div`
  cursor: pointer;
  min-height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 130px;
  min-width: 280px;
  background: rgba(163, 158, 157, 0.11);
  color: white;
`;

const DataLabel = styled.label`
  position: absolute;
  top: 7px;
  left: 15px;
  font-size: 11px;
  letter-spacing: 0px;
  font-weight: 400;
  color: rgb(163, 158, 157);
`;

const DataInput = styled.input`
  background-color: transparent;
  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
  padding: 0 10px;
`;

const AmountContainer = styled.div`
  min-height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  background: rgba(163, 158, 157, 0.11);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const AmountInput = styled.input`
  background: transparent;
  opacity: 1;
  height: 100%;
  width: 70%;
  text-align: right;
  padding: 0 10px;
  color: white;
  font-size: 15px;
  font-weight: 500;
  &::-webkit-input-placeholder {
    color: white;
    opacity: 1;
  }
`;

interface Props {
  filters: any;
  selectedItem: (value: any) => void;
}

const SelectOption: React.FC<Props> = ({ filters, selectedItem }) => {
  const [activeTrainerFilters, setActiveTrainerFilters] = useState<
    Array<{ name: string; value: string; pl: string; id?: number }>
  >([]);


  useEffect(() => {
    setActiveTrainerFilters(filters)
  },[filters] )

  const handleFilterChange = (filter: typeof activeTrainerFilters[0], checked: boolean) => {
    setActiveTrainerFilters((prev) => {
      if (checked) {
        let obj = [...prev, filter];
        const fil = filterFun(obj);
        selectedItem([...fil]);
        return [...fil];
      }
      const newFilters = prev.filter(
        (f) =>
          !(
            f.name === filter.name &&
            f.value === filter.value &&
            f.pl === filter.pl
          )
      );
      selectedItem([...newFilters]);
      return newFilters;
    });
  };

  const filterFun = (arr: any) => {
    return arr
      .filter(
        //@ts-ignore
        (value, index, self) =>
          index === self.findLastIndex((t: any) => t.name === value.name)
      )
      .filter((t: any) => t.value !== "");
  };

  return (
    <div className="flex justify-end">
      <div style={{ height: "80%", paddingTop: "10px" }}>
        {filters?.map((filter: any, id: number) =>
          filter.type === "checkbox" ? (
            <FilterDropdownPanel label={filter.pl} key={id}>
              <div>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "15px",
                    fontWeight: 400,
                    textTransform: "uppercase",
                    marginTop: "10px",
                  }}
                >
                  {filter.pl}
                </p>
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    flexDirection: "column",
                  }}
                >
                  {filter?.values?.map((val: any, index: any) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        gap: "13px",
                        width: "175px",
                      }}
                    >
                      <label
                        htmlFor={`${filter.name}${index}`}
                        style={{
                          color: "white",
                          fontSize: "15px",
                          fontWeight: 400,
                          textAlign: "right",
                          width: "130px",
                        }}
                      >
                        {val.pl}
                      </label>
                      <div style={{ width: "75px" }}>
                        <input
                          style={{ width: "20px", height: "20px" }}
                          type="checkbox"
                          name={filter.name}
                          id={`${filter.name}${index}`}
                          checked={activeTrainerFilters.some(
                            (f) =>
                              f.name === filter.name && f.value === val.backend
                          )}
                          onChange={(e) => {
                            const { checked } = e.target;
                            handleFilterChange(
                              {
                                name: filter.name,
                                value: val.backend,
                                pl: val.pl,
                              },
                              checked
                            );
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </FilterDropdownPanel>
          ) : filter.type === "select" ? (
            <FilterSingleSelect
              filters={filters}
              activeFilters={activeTrainerFilters}
              filter={filter}
              handleFilterChange={handleFilterChange}
              key={id}
            />
          ) : filter.type === "date" ? (
            <DateContainer key={id}>
              <DataInput
                type="date"
                name={filter.name}
                id={filter.name}
                value={
                  filter.name === "From"
                    ? activeTrainerFilters.find((obj) => obj.name === "From")
                      ? activeTrainerFilters.find((obj) => obj.name === "From")
                          ?.value
                      : ""
                    : filter.name === "To"
                    ? activeTrainerFilters.find((obj) => obj.name === "To")
                      ? activeTrainerFilters.find((obj) => obj.name === "To")
                          ?.value
                      : ""
                    : ""
                }
                onChange={(e) => {
                  const checked = true;
                  handleFilterChange(
                    {
                      name: filter.name,
                      value: e.target.value,
                      pl: filter.pl,
                    },
                    checked
                  );
                }}
              />
              <DataLabel htmlFor={filter.name}>{filter.pl}</DataLabel>
            </DateContainer>
          ) : filter.type === "rangeOfAmount" ? (
            <AmountContainer key={id}>
              <AmountInput
                type="text"
                name={filter.name}
                id={filter.name}
                autoComplete="off"
                placeholder="Uzupełnij"
                value={
                  activeTrainerFilters.find((obj) => obj.name === filter.name)
                    ?.value || ""
                }
                onChange={(e) => {
                  const checked = true;
                  if (e.target.value.length < 0) {
                    return;
                  }
                  handleFilterChange(
                    {
                      name: filter.name,
                      value: e.target.value,
                      pl: filter.pl,
                    },
                    checked
                  );
                }}
              />
              <AmountLabel htmlFor={filter.name}>{filter.pl}</AmountLabel>
            </AmountContainer>
          ) : (
            <></>
          )
        )}
      </div>
    </div>
  );
};

export default SelectOption;
