import { toast } from 'react-toastify';
import {
  IAddCombinationValue,
  ICombination,
} from 'types/marketpleace/combination.interface';

export enum ROW_MODE {
  default,
  edit,
  add,
}

export enum TABLE_MODE {
  default,
  edit,
}

export const getLargestCombinationValuesLength = (
  productCombinations: ICombination[]
): number => {
  if (!productCombinations.length) {
    return 0;
  }

  const longest = productCombinations.sort((a, b) =>
    a.Values.length < b.Values.length
      ? 1
      : a.Values.length > b.Values.length
      ? -1
      : 0
  )[0];

  return longest.Values.length;
};

export const getTableHeadersByLongestCombination = (
  attributesLength: number
) => {
  return [
    ...[...Array(attributesLength)].map((a, i) => `Atrybut ${i + 1}`),
    'EAN',
    'ZAPAS W MAGAZYNIE',
    'CENA',
    'VAT',
    'ZMIANA PARAMETRÓW',
  ];
};

interface ICombinationValidator {
  Values: IAddCombinationValue[];
  EAN: string;
}

export const validateCombination = (
  EAN: number | undefined,
  values: IAddCombinationValue[]
): ICombinationValidator | undefined => {
  const combinationValues = values.filter(
    c => c.ValueId !== '' && c.AttributeId !== ''
  );

  const EANCode = EAN?.toString() ?? '';

  if (!combinationValues.length) {
    toast.error('Dodaj minimalnie jeden atrybut');
    return;
  }

  if (EANCode.toString().length != 10) {
    toast.error('EAN musi składać się z 10 cyfr');
    return;
  }

  return {
    EAN: EANCode,
    Values: combinationValues,
  };
};
