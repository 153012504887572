import styled from "styled-components";
import Placeholder from "assets/icons/structure-placeholder.png";
import { useRef } from "react";

interface Props {
  name: string;
  id: string;
  onContextMenu: any;
  photoFilePath: any;
  setIsTrainerModalOpen: any;
  selectedTrainerId: string;
  setSelectedTrainerId: any;
  fullAccess: boolean;
  specialization: number;
}

const SingleTrainer: React.FC<Props> = ({
  name,
  id,
  onContextMenu,
  photoFilePath,
  setIsTrainerModalOpen,
  selectedTrainerId,
  setSelectedTrainerId,
  fullAccess,
  specialization,
}) => {
  const typeSwitch = (type: number) => {
    switch (type) {
      case 0:
        return "Trener";
      case 1:
        return "Scout";
      case 2:
        return "Manager";
      case 3:
        return "Analityk";
      default:
        return type;
    }
  };

  const openUserDetails = () => {
    setIsTrainerModalOpen(true);
  };
  const containerRef = useRef<HTMLDivElement>(null);
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === " " && selectedTrainerId === id) {
      openUserDetails();
    }
  };

  const handleContainerClick = () => {
    containerRef.current?.focus();
    setSelectedTrainerId(id);
  };

  return (
    <Container
      ref={containerRef}
      onContextMenu={onContextMenu}
      // onClick={openUserDetails}
      onKeyDown={handleKeyPress}
      onClick={handleContainerClick}
      selectedTrainerId={selectedTrainerId}
      id={id}
      tabIndex={0}
    >
      {fullAccess ? (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.519"
            height="18.281"
            viewBox="0 0 18.519 18.281"
          >
            <g
              id="Group_6334"
              data-name="Group 6334"
              transform="translate(-1546.964 -541.399)"
            >
              <path
                id="Icon_awesome-user-alt"
                data-name="Icon awesome-user-alt"
                d="M17.991,10.125A5.063,5.063,0,0,0,17.991,0Zm4.5,1.125H20.553a6.127,6.127,0,0,1-2.563.563c0,2.905-.009,5.366-.009,6.469L25.3,18a1.688,1.688,0,0,0,1.688-1.687V15.75A4.5,4.5,0,0,0,22.491,11.25Z"
                transform="translate(1538.493 541.399)"
                fill="#fff"
              />
              <path
                id="Icon_awesome-user-alt-2"
                data-name="Icon awesome-user-alt"
                d="M26.981,10.125A5.063,5.063,0,0,1,26.981,0Zm-4.5,1.125h1.937a6.127,6.127,0,0,0,2.563.563c0,2.905.009,5.366.009,6.469L19.669,18a1.688,1.688,0,0,1-1.687-1.687V15.75A4.5,4.5,0,0,1,22.481,11.25Z"
                transform="translate(1528.983 541.399)"
                fill="#fff"
              />
            </g>
          </svg>
        </>
      ) : (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.519"
            height="18.281"
            viewBox="0 0 18.519 18.281"
          >
            <g
              id="Group_6334"
              data-name="Group 6334"
              transform="translate(-1546.964 -541.399)"
            >
              <path
                id="Icon_awesome-user-alt"
                data-name="Icon awesome-user-alt"
                d="M17.991,10.125A5.063,5.063,0,0,0,17.991,0Zm4.5,1.125H20.553a6.127,6.127,0,0,1-2.563.563c0,2.905-.009,5.366-.009,6.469L25.3,18a1.688,1.688,0,0,0,1.688-1.687V15.75A4.5,4.5,0,0,0,22.491,11.25Z"
                transform="translate(1538.493 541.399)"
                fill="#fff"
                opacity="0.3"
              />
              <path
                id="Icon_awesome-user-alt-2"
                data-name="Icon awesome-user-alt"
                d="M26.981,10.125A5.063,5.063,0,0,1,26.981,0Zm-4.5,1.125h1.937a6.127,6.127,0,0,0,2.563.563c0,2.905.009,5.366.009,6.469L19.669,18a1.688,1.688,0,0,1-1.687-1.687V15.75A4.5,4.5,0,0,1,22.481,11.25Z"
                transform="translate(1528.983 541.399)"
                fill="#fff"
              />
            </g>
          </svg>
        </>
      )}
      <div>
        <img
          src={photoFilePath ? photoFilePath : Placeholder}
          alt="userimage"
        />
      </div>
      <UserInfoBox>
        <p>{name ? name : "-"}</p>
        {/* <p>Trener</p> */}
        <p>{typeSwitch(specialization)}</p>
      </UserInfoBox>
    </Container>
  );
};

export default SingleTrainer;

const Container = styled.div<{ selectedTrainerId: any; id: any }>`
  width: 100%;
  height: 50px;
  cursor: pointer;
  position: relative;
  /* background: rgb(10, 63, 97);
  background: linear-gradient(
    0deg,
    rgba(75, 0, 130, 0.7) 0%,
    rgba(156, 176, 196, 0.5) 100%
  ); */
  /* background: rgba(156, 176, 196, 0.11); */
  border-top: ${(props) =>
    props.selectedTrainerId === props.id
      ? "1px solid #0091FF"
      : "1px solid rgba(158,154,247,0.3)"};
  border-bottom: ${(props) =>
    props.selectedTrainerId === props.id
      ? "1px solid #0091FF"
      : "1px solid rgba(158,154,247,0.3)"};

  &:hover {
    border-top: 1px solid #0091ff;
    border-bottom: 1px solid #0091ff;
  }
  /* 
  
  border-top: 1px solid rgba(158, 154, 247, 0.3);
  border-bottom: 1px solid rgba(158, 154, 247, 0.3); */

  & > svg {
    position: absolute;
    bottom: 5px;
    right: 10px;
    width: 20px;
    height: 20px;
  }

  /* &:hover {
    border: 1px solid #0091ff;
  } */
  /* border-radius: 7px; */
  display: flex;
  align-items: center;
  gap: 15px;

  & > div:nth-of-type(1) {
    width: 50px;
    height: 50px;
    padding-left: 1px;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(2, 2, 15);

    & > img {
      height: 100%;
    }
  }

  & > p {
    color: rgba(248, 248, 248, 0.7);
    font-weight: lighter;
  }
`;

const UserInfoBox = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;

  & > p:nth-of-type(1) {
    font-size: 12px;
    color: white;
    line-height: 10px;
    margin-top: 5px;
  }

  & > p:nth-of-type(2) {
    font-size: 12px;
    line-height: 13px;
    color: #9769ea;
  }
`;
