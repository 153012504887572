import useInfiniteScroll from 'hooks/useInfiniteScroll';
import transferService from 'services/transfer/transferService';
import { ISentTransfer } from 'types/transfer/transfer.interface';
import { ISelectOption, LIST_SORT } from 'types/globalTypes';
import { getSortedRequestPlayersByListSort } from 'middleware/player/getSortedOutsidePlayersByListSort';
import { PLAYER_STATE } from 'types/transfer/player.interface';
import DataTable, { IDataTableRows } from 'components/common/dataTable/DataTable';
import { getFormatedDate } from 'utils/dateUtils';

interface IRequestedPlayersTableProps {
  queryString: string;
  sortBy: ISelectOption<LIST_SORT> | null;
}

const RequestedPlayersTable = ({
  queryString,
  sortBy,
}: IRequestedPlayersTableProps) => {
  const { items, loading, containerRef, lastItemRef, refresh } =
    useInfiniteScroll<ISentTransfer>(transferService.getAll, queryString);

  const headers = ['Imię i nazwisko', 'Rocznik', 'Kod pocztowy', 'Status'];

  const sortedArray = getSortedRequestPlayersByListSort(items, sortBy?.value);

  const rows = sortedArray.map(
    player =>
      ({
        data: {
          link: `/outside-players/detail/${player.PlayerId}`,
        },
        cols: [
          player.Name,
          getFormatedDate(player.Birthday, 'yyyy'),
          player.Address.PostCode,
          PLAYER_STATE[player.State],
        ],
      } as IDataTableRows)
  );

  return (
    <DataTable
      rows={rows}
      headers={headers}
      isDataLoading={loading}
      containerRef={containerRef}
      lastItemRef={lastItemRef}
    />
  );
};

export default RequestedPlayersTable;
