import HeaderContainer from 'components/common/Containers/HeaderContainer';
import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import MerchantData from '../MerchantData';
import { useEffect, useState } from 'react';
import MerchantRegistryAcceptance from './MerchantRegistryAcceptance';
import InfoContainerItem from 'components/common/Containers/InfoContainerItem';
import { InfoContainer } from 'styles/styles';
import sellerServices from 'services/sellerServices';
import { ISeller, ISellerDetail } from 'types/seller.interface';
import { IMerchantRegistryDispatcherProps } from '../Merchant';
import MerchantConditions from './MerchantConditions';

interface IMerchantRegistryProps extends IMerchantRegistryDispatcherProps {
  seller?: ISellerDetail
}

const MerchantRegistry = ({
  setMerchantRegistrationDisplay,seller
}: IMerchantRegistryProps) => {

  const [confirmConsents, setConformConsents] = useState(false);


  return (
    <div className='w-full'>
      <HeaderContainer
        title={'Rejestracja konta Merchant'}
        headerContent={
          <Button
            variant={ButtonVariant.Abort}
            className='m-2'
            onClick={() => setMerchantRegistrationDisplay(false)}>
            Wróć
          </Button>
        }>
        <div className='flex flex-col w-full 2xl:flex-row gap-4 pt-12'>
          <MerchantData />

          <div className='flex w-full 2xl:w-96 gap-4'>
            <div className='flex flex-col w-full gap-4 lg:flex-row xl:justify-between xl:flex-row 2xl:flex-col 2xl:justify-start'>
              <div className='w-full lg:w-1/2 xl:w-1/3 2xl:w-96'>
                <MerchantConditions seller={seller} />
              </div>
              <div className='w-full lg:w-1/2 xl:w-1/3 2xl:w-96'>
                <MerchantRegistryAcceptance
                  confirmConsents={confirmConsents}
                  setConformConsents={setConformConsents}
                  setMerchantRegistrationDisplay={
                    setMerchantRegistrationDisplay
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </HeaderContainer>
    </div>
  );
};

export default MerchantRegistry;
