import React, { useState, useEffect } from 'react';
import HeaderContainer from 'components/common/Containers/HeaderContainer';
import StatusSelector from './StatusSelector';
import DataTable from 'components/common/dataTable/DataTable';
import TabContent from 'components/common/tabs/TabContent';
import { playerStateConditions } from 'middleware/transfer/playerTransferConditions';
import { sendRequestToTrainer, deleteTrainerFromAcademy, revokeRelation, acceptTrainerReq } from 'services/transferServices';
import { toast } from 'react-toastify';
import { showServerErrors } from 'utils/errorsUtils';
import { TrainerRelationDetail, TRAINER_STATE } from '../../../../../../src/types/transfer/trainer.interface';
import { getFormatedDate } from 'utils/dateUtils';

interface Props {
  trainderData: any | undefined,
  getReqIdtrainer: string,
  trainerRelationDetail: TrainerRelationDetail,
}

const TrainerHistoryState: React.FC<Props> = (
  { trainderData, getReqIdtrainer, trainerRelationDetail }
) => {


  const [isLoading, setLoading] = useState(false);
  const [isSelectElemActive, setIsSelectElemActive] = useState(true)
  const [status, setStatus] = useState('');

  const selectStatus = (value: string) => {
    setStatus(value)
  };

  const [dropdownOptions, setDropdownOptions] = useState<string[]>(['Współpracownik', 'Były współpracownik']);

  const handleSubmit = async (): Promise<void> => {
    setLoading(true);
    try {
      await sendRequestToTrainer(trainderData.TrainerId);

      toast.success(`Zaproszenie dla ${trainderData.Name} zostało wysłane i oczekuje na zatwierdzenie przez trenera`);
      setLoading(false);
      setDropdownOptions([]);
      setIsSelectElemActive(false);

    } catch (error: any) {

      showServerErrors(error as { Errors: object });
      toast.error('Wystąpił błąd podczas wysyłania zaproszenia.');
      setLoading(false);
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const RevokeRequestTrainer = async () => {
    setLoading(true);
    try {
      await revokeRelation(getReqIdtrainer);

      toast.success(`Odwołanie zaproszenia dla ${trainderData.Name} zostało wysłane.`);
      setLoading(false);
      setDropdownOptions([]);
      setIsSelectElemActive(false);
    } catch (error) {
      showServerErrors(error as { Errors: object });
      toast.error('Wystąpił błąd podczas odwołania zaproszenia.');
      setLoading(false);
    }
  }

  const deleteTrainerFromAcademiq = async () => {
    setLoading(true);
    try {
      await deleteTrainerFromAcademy(trainderData.TrainerId);

      toast.success(`Trener ${trainderData.Name} został usunięty z Akademii.`);
      setLoading(false);
      setDropdownOptions([]);
      setIsSelectElemActive(false);
    } catch (error) {
      showServerErrors(error as { Errors: object });
      toast.error('Wystąpił błąd podczas usuwania trenera z Akademii.');
      setLoading(false);
    }
  }

  const acceptTrainerRequest = async () => {
    setLoading(true);
    if (trainerRelationDetail?.RelationRequestId) {

      try {
        await acceptTrainerReq(trainerRelationDetail?.RelationRequestId);

        toast.success(`Trener ${trainderData.Name} został przyjęty do Akademii.`);
        setLoading(false);
        setDropdownOptions([]);
        setIsSelectElemActive(false);
      } catch (error) {
        console.log(error)
      }
    }

  }

  useEffect(() => {

    if (status === 'Współpracownik') {
      handleSubmit();
    }

    if (status === 'Odwołaj zaproszenie') {
      RevokeRequestTrainer();
    }

    if (status === 'Były współpracownik') {
      deleteTrainerFromAcademiq();
    }

    if (status === 'Zaakceptuj współpracownika' && trainerRelationDetail?.RelationRequestId !== undefined) {
      acceptTrainerRequest();
    }

  }, [status])

  useEffect(() => {

    if (trainerRelationDetail?.RelationRequestId === null) {
      setDropdownOptions(['Współpracownik'])
    }

    else {
      setDropdownOptions(['Odwołaj zaproszenie'])
    }

    if (trainerRelationDetail?.IsRelationExist) {
      setDropdownOptions(['Były współpracownik'])
    }

    if (trainerRelationDetail?.RelationReceiver === 1) {
      setDropdownOptions(['Zaakceptuj współpracownika', 'Odwołaj zaproszenie'])
    }

  }, [trainerRelationDetail]);


  const tableRows = trainerRelationDetail?.TrainerRelationHistory.map(state => ({
    cols: [
      getFormatedDate(state.UpdatedAt, 'date-time'),
      TRAINER_STATE[state.State],
    ],
  }))

  return (
    <HeaderContainer
      title="Status trenera"
      headerContent={
        <div
          style={{ pointerEvents: isSelectElemActive ? 'auto' : 'none' }}
        >
          <StatusSelector
            dropdownOptions={dropdownOptions}
            selectItem={selectStatus}
            isLoading={isLoading}
          />

        </div>
      }
    >
      <>
        {trainerRelationDetail?.TrainerRelationHistory.length ?
          <DataTable
            headers={['Data', 'Status']}
            rows={tableRows}
            isDataLoading={false}
          /> :
          (<div></div>)}
      </>
    </HeaderContainer>
  );
};

export default TrainerHistoryState;
