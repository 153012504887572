import { MouseEvent } from "react";

import Placeholder from "assets/images/placeholder.svg";

import styled from "styled-components";

const StyledTile = styled.div`
  background: rgba(163, 158, 157, 0.05);
  color: white;
  transition: all ease-in 0.1s;
  cursor: pointer;

  &:hover {
    background: rgba(163, 158, 157, 0.08);
    transition: all ease-in 0.1s;
  }
`;

interface Props {
  title: string;
  content: JSX.Element;
  img: string;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
  handleClick: any;
}

const TileViewItem = ({ content, img, title, handleClick }: Props) => {
  return (
    <StyledTile
      onClick={handleClick}
      className="flex flex-col text-sm leading-relaxed relative"
      style={{ padding: "15px" }}
    >
      {/* <div className="text-base font-medium opacity-80 p-4">{title}</div> */}
      <div className="flex gap-x-2">
        <div
          className="flex flex-col flex-grow overflow-hidden whitespace-nowrap"
          // style={{ maxWidth: "70%" }}
        >
          {content}
        </div>
        {/* <div
          className="w-28 h-28 shadow-md"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <img
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
            }}
            src={img || Placeholder}
            alt=""
          />
        </div> */}
      </div>
    </StyledTile>
  );
};

export default TileViewItem;
