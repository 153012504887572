import { conn } from 'api/BaseConnection';
import api from './axiosConfig';

const systemEndpoints = conn.endpoints.system;

// const uploadFile = async (Base64String: string) => {
//   try {
//     const response = await api.post(`/api/System/UploadFile`, {
//       File: { Base64String: Base64String },
//     });
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

const uploadFile = async (Base64String: string): Promise<string> => {
  const uploadFile = {
    File: { Base64String: Base64String },
  };

  return await conn.postJSON<string>(
    systemEndpoints.uploadFile,
    'json',
    uploadFile
  );
};

const systemServices = {
  uploadFile,
};

export default systemServices;
