export interface ITrainer {
  TrainerId: string;
  Name: string;
  PhotoFilePath: string;
}

export enum TRAINER_TYPE {
  'Trener',
  'Zwiadowca',
  'Menadżer',
  'Analityk',
}

export enum LIST_VIEW {
  'Zaproszenia',
  'Dostępni zawodnicy'
}

export enum RELATION_RECEIVER{
  'Trener',
  'Academia',
}

interface TrainerRelationHistory {
  TrainerHistoryId: string,
  UpdatedAt: Date,
  State: number,
}

export interface TrainerRelationDetail {
  IsRelationExist: boolean,
  RelationReceiver: number,
  RelationRequestId: string | string,
  TrainerId: string,
  TrainerRelationHistory: TrainerRelationHistory[]
}

export enum TRAINER_STATE {
  "Trener wysłał prośbę o dołaczenie do Akademii",
  "Akademia wysłała prośbę o dołaczenie trenera",
  "Prośba została odwołana",
  "Współpracownik",
  "Były współpracownik"
}
