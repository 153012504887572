import { ISelectOption } from 'components/common/inputs/inputTypes';

export const openInNewTab = (url: string) => {
  if (!window) {
    throw new Error('Window object not exists');
  }

  window.open(url, '_blank')?.focus();
};

export const isActiveOptions = [
  { label: 'Aktywny', value: true },
  { label: 'Nieaktywny', value: false },
];

export const enumToSelectOptions = <T extends { [key: number]: string }>(
  enumType: T
): Array<ISelectOption<keyof T>> =>
  Object.keys(enumType)
    .filter(key => typeof enumType[key] !== 'number')
    .map(value => {
      const parsedValue = parseInt(`${value}`);
      const name = enumType[parsedValue];
      return { label: name, value: parsedValue };
    });

export const compareArray = <T>(a: T, b: T, sort: keyof T) => {
  if (a[sort] < b[sort]) {
    return 1;
  }
  if (a[sort] > b[sort]) {
    return -1;
  }
  return 0;
};

export const getGrossPrice = (netto: number, taxPercent: number): string => {
  const tax = taxPercent * 0.01 + 1;
  const gross = netto * tax;
  return gross.toFixed(2);
};
