import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import Button from "../../common/buttons/basicButton/Button";

import { RootState } from "../../../store/store";
import { IUserManagement, UserInterface } from "../../../types/userTypes";
import { showServerErrors } from "../../../utils/errorsUtils";
import { ButtonVariant } from "../../common/buttons/buttonTypes";
import UserStatusInfo from "../status/UserStatusInfo";
import styled from "styled-components";
import jwtDecode from "jwt-decode";
import EditDigitalRelease from "../form/EditDigitalRelease";
import { deactivateUser, activateUser, deleteUser } from "../../../services/usersServices"


const BlurBox = styled.div<{ isEditMenuOpen: boolean }>`
  position: absolute;
  display: ${(props) => (props.isEditMenuOpen ? "unset" : "none")};
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(3px);
  z-index: 99;
`;

const SideEditMenu = styled.div<{ isEditMenuOpen: boolean }>`
  position: absolute;
  height: calc(100vh - 48px);
  top: 48px;
  /* padding: ${(props) => (props.isEditMenuOpen ? "23px 44px" : "0")}; */
  padding: ${(props) => (props.isEditMenuOpen ? "23px 0" : "23px 0")};
  right: 0;
  width: ${(props) => (props.isEditMenuOpen ? "330px" : "0px")};
  background: rgba(2, 2, 15, 1);
  transition: all 0.2s ease-in-out;
  color: rgba(163, 158, 157, 1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  & > * {
    opacity: ${(props) => (props.isEditMenuOpen ? "1" : "0")};
    transition: all 0.1s ease-in-out;
  }

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    & > form {
      & > div {
        & > p {
          /* text-align: center; */
          text-transform: uppercase;
          font-size: 15px;
          font-weight: 400;
          margin-bottom: 10px;
        }
      }
    }
  }
`;

interface IUserDetailTopbarProps {
  user: UserInterface;
  IsActivate: boolean;
}

const UserDetailTopbar: React.FC<IUserDetailTopbarProps> = ({
  user,
  IsActivate,
}) => {
  const { push } = useHistory();
  const [isEditMenuOpen, toggleEditMenuOpen] = useState(false);
  const [role, setRole] = useState("");

  useEffect(() => {
    const token: any = localStorage.getItem("token");
    const decodedToken: any = jwtDecode(token);
    setRole(decodedToken?.Role);
  }, []);

  // const permissions = useSelector((state: RootState) => state.userPermissions);

  // if (!permissions) {
  //   return null;
  // }

  const handleRemove = async () => {
    try {
      await deleteUser(user.UserId);
      toast.success("Usunięto pracownika");
      push("/users");
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  const handleActivate = async () => {
    try {
      await activateUser(user.UserId);
      toast.success("Aktywowano pracownika");
      push("/users");
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  const handleDeactivate = async () => {
    try {
      await deactivateUser(user.UserId);
      toast.success("Deaktywowano pracownika");
      push("/users");
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  const handleEdit = () => {
    push(`/users/edit/${user.UserId}`);
  };

  return (
    <div className="flex justify-between text-sm">
      <BlurBox
        isEditMenuOpen={isEditMenuOpen}
        onClick={() => {
          toggleEditMenuOpen(false);
        }}
      />
      <SideEditMenu isEditMenuOpen={isEditMenuOpen}>
        <div>
          <EditDigitalRelease user={user} />
        </div>
      </SideEditMenu>

      <UserStatusInfo isActive={IsActivate} />
      <div className="flex" style={{ gap: "4px" }}>
        {IsActivate ? (
          <Button
            className="flex-1 md:flex-grow-0 py-8 px-18"
            // disabled={!permissions.Auth.SetUserActiveOrDeactive.checked}
            onClick={handleDeactivate}
            variant={ButtonVariant.Remove}
            style={{
              padding: "15px 41.4064px",
              background: "rgba(163,158,157,0.38)",
              width: "120px",
              fontSize: "15px",
              fontWeight: 400,
            }}
          >
            Dezaktywuj
          </Button>
        ) : (
          <Button
            className="flex-1 md:flex-grow-0 py-8 px-18"
            // disabled={!permissions.Auth.SetUserActiveOrDeactive.checked}
            onClick={handleActivate}
            variant={ButtonVariant.Remove}
            style={{
              padding: "15px 41.4064px",
              background: "rgba(163,158,157,0.38)",
              width: "120px",
              fontSize: "15px",
              fontWeight: 400,
            }}
          >
            Aktywuj
          </Button>
        )}

        <Button
          className="flex-1 md:flex-grow-0 py-8 px-18"
          // disabled={!permissions.Auth.SetUserActiveOrDeactive.checked}
          onClick={handleRemove}
          variant={ButtonVariant.Remove}
          style={{
            padding: "15px 41.4064px",
            background: "rgba(163,158,157,0.38)",
            width: "120px",
            fontSize: "15px",
            fontWeight: 400,
          }}
        >
          Usuń
        </Button>

        {role === "0" && (
          <Button
            // onClick={handleEdit}
            onClick={() => {
              toggleEditMenuOpen((prev) => !prev);
            }}
            variant={ButtonVariant.Submit}
            className="flex-1 md:flex-grow-0 py-8 px-18"
            style={{
              padding: "15px 41.4064px",
              background: "rgb(0, 160, 255)",
              width: "120px",
              fontSize: "15px",
              fontWeight: 400,
            }}
          >
            Edytuj
          </Button>
        )}
      </div>
    </div>
  );
};

export default UserDetailTopbar;
