import { conn } from 'api/BaseConnection';
import { ICategoriesResponse } from 'types/marketpleace/category.interface';

const endpoints = conn.endpoints.marketpleace.category;

const get = async (searchQuery?: string) => {
  const response = await conn.getJSON<ICategoriesResponse>(
    endpoints.get,
    'json',
    searchQuery?.length ? { SearchQuery: searchQuery } : {}
  );
  return response.Categories;
};

const categoriesServices = {
  get,
};

export default categoriesServices;
