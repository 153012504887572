import { LIST_SORT } from 'types/globalTypes';
import { IProductDto } from 'types/marketpleace/product.interface';
import { compareArray } from 'utils/baseUtils';

export const sortProductsByListSort = (
  array: IProductDto[],
  type?: LIST_SORT
) => {
  switch (type) {
    case 0:
      return array.slice().reverse();
    case 1:
      return array;
    case 2:
      return array
        .slice()
        .sort((a, b) => compareArray<IProductDto>(a, b, 'Name'))
        .reverse();
    case 3:
      return array
        .slice()
        .sort((a, b) => compareArray<IProductDto>(a, b, 'Name'));
    default:
      return array;
  }
};
