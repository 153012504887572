import { ReactNode, RefObject } from "react";
import { useHistory } from "react-router";

type DataTableRowProps = {
  row: { data?: { link?: string }; cols: Array<ReactNode> };
  innerRef?: RefObject<HTMLTableRowElement>;
  clickedIndex: any;
  setClickedIndex: any;
  isLast: boolean;
  selectedPlayerId: any;
  setSelectedPlayerId: any;
  selectedParam: any;
  setSelectedParam: any;
  selectedParamValueName: any;
  setSelectedParamValueName: any;
};

const AttendancesDataTableRow: React.FC<DataTableRowProps> = ({
  innerRef,
  row,
  clickedIndex,
  setClickedIndex,
  isLast,
  selectedPlayerId,
  setSelectedPlayerId,
  selectedParam,
  setSelectedParam,
  selectedParamValueName,
  setSelectedParamValueName,
}) => {
  const { push } = useHistory();
  const handleRowClick = () => {
    if (row.data?.link) {
      push(row.data.link);
    }
  };

  return (
    <tr
      ref={innerRef}
      onClick={handleRowClick}
      data-clickable={!!row.data?.link}
    >
      {row.cols.map((col, idx) => {
        const title = typeof col === "string" ? col : "";

        if (idx === 0) {
          return (
            <td
              style={{
                opacity: 1,
                margin: 0,
                padding: 0,
                paddingTop: "5px",
                paddingRight: "2px",
              }}
              colSpan={1}
              key={idx}
              title={title}
            >
              {col}
            </td>
          );
        } else {
          return (
            <td
              // colSpan={idx === 3 ? 3 : 1}
              colSpan={1}
              key={idx}
              title={title}
              style={{
                opacity: 1,
                margin: "0",
                padding: "0",
                borderTop: "0px solid rgb(2,2,15,0.1)",
              }}
            >
              {/* <div> */}
              <div
                style={
                  idx === clickedIndex
                    ? {
                        background: "rgba(0,0,0,0.2)",
                        borderLeft: "1px solid #FF9200",
                        borderRight: "1px solid #FF9200",
                        opacity: 1,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        ...(isLast && { borderBottom: "1px solid #FF9200" }),
                      }
                    : {
                        opacity: 1,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                }
              >
                <span style={{ opacity: 1 }}>{col || "- brak -"}</span>
              </div>
              {/* </div> */}
            </td>
          );
        }
      })}
    </tr>
  );
};

export default AttendancesDataTableRow;
