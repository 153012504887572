import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import NewSelectStatystics from 'components/common/inputs/select/NewSelectStatystics';
import React, { useState } from 'react';
import { ISelectOption } from 'types/globalTypes';
import { ORDER_HISTORY_STATE } from 'types/marketpleace/order.interface';
import { enumToSelectOptions } from 'utils/baseUtils';

const historyStateItems = enumToSelectOptions(ORDER_HISTORY_STATE);

interface IOrderHistoryStateHeaderProps {
  changeState: (state: ORDER_HISTORY_STATE) => Promise<void>;
}

const OrderHistoryStateHeader = ({
  changeState,
}: IOrderHistoryStateHeaderProps) => {
  const [state, setState] =
    useState<ISelectOption<ORDER_HISTORY_STATE> | null>(null);

  return (
    <div className='flex p-8 gap-2'>
      <NewSelectStatystics<ORDER_HISTORY_STATE>
        name='state'
        label='Status'
        items={historyStateItems}
        selectedItem={state}
        setSelectedItem={setState}
      />
      <Button
        variant={ButtonVariant.Submit}
        disabled={!state}
        onClick={() => state && changeState(state.value)}>
        Zapisz
      </Button>
    </div>
  );
};

export default OrderHistoryStateHeader;
