import DataTable, {
  IDataTableRows,
} from 'components/common/dataTable/DataTable';
import DropdownPanel from 'components/common/panels/DropdownPanel';
import React from 'react';
import { IAttributeSoldDetailStatstics } from 'types/marketpleace/attribute.interface';

interface IAttributesStatementProps {
  attributesSoldDetail?: IAttributeSoldDetailStatstics[];
}

const AttributesStatement = ({
  attributesSoldDetail,
}: IAttributesStatementProps) => {
  const attributesStatementRows: IDataTableRows[] = attributesSoldDetail
    ? attributesSoldDetail.map(attribute => ({
        cols: [attribute.AttributeName, attribute.EAN, attribute.SoldCount],
      }))
    : [];

  return (
    <DropdownPanel
      label='Szczegółowo (zgodnie z Atrybutem)'
      dropdownStyles={{ padding: '4px 0px', background: 'none' }}>
      <div className='w-full'>
        <DataTable
          headers={['atrybuty', 'EAN', 'liczba sprzedanych']}
          rows={attributesStatementRows}
          isDataLoading={false}
        />
      </div>
    </DropdownPanel>
  );
};

export default AttributesStatement;
