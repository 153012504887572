import { toast } from 'react-toastify';
import orderService from 'services/marketpleace/orderServices';
import { GridItem, GridTextItem } from 'styles/styles';
import { IOrderDocument } from 'types/marketpleace/order.interface';
import { showServerErrors } from 'utils/errorsUtils';
import { ReactComponent as DeleteIco } from 'assets/icons/delete.svg';
import { ReactComponent as FileIco } from 'assets/icons/filesIco.svg';
import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import { useState } from 'react';
import { useSelector } from 'react-redux';
interface IOrderDocumentsRowProps {
  orderId: string;
  document: IOrderDocument;
  refresh: () => Promise<void>;
}

const OrderDocumentsRow = ({
  document,
  refresh,
  orderId,
}: IOrderDocumentsRowProps) => {
  const [isRemoveing, setIsRemoveing] = useState(false);

  const permission = useSelector((state: any) => state.permissions.permissions);

  const handleRemove = async () => {
    try {
      setIsRemoveing(true);
      await orderService.removeDocument(orderId, document.DocumentId);

      await refresh();
      toast.success(`Usunięto dokument ${document.Name}`);
      setIsRemoveing(false);
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  const isDocumentDeleteActive = permission[0]?.Id === -1 ? true : !!permission.find((permission: any) => permission.Id === 22);

  return (
    <>
      <GridTextItem>{document.Name}</GridTextItem>
      <GridItem>
        <div className='flex gap-2 w-full h-full justify-center'>
          <Button
            variant={ButtonVariant.Submit}
            className=' h-full'
            disabled={isRemoveing}>
            <a
              href={document.FilePath}
              target='_blank'
              rel='noreferrer'
              download={document.Name}
              aria-disabled={isRemoveing}
              className='flex items-center justify-center h-full w-full'>
              Pobierz
            </a>
          </Button>

          <div
            style={{
              pointerEvents: isDocumentDeleteActive ? 'auto' : 'none',
              opacity: isDocumentDeleteActive ? '' : '0.6',
            }}
          >
            <Button
              variant={ButtonVariant.Abort}
              className='flex h-full'
              onClick={handleRemove}
              disabled={isRemoveing}>
              Usuń
            </Button>

          </div>
        </div>
      </GridItem>
    </>
  );
};

export default OrderDocumentsRow;
