import ContentContainer from 'components/layout/ContentContainer';
import { useEffect, useState } from 'react';
import { deliveryServices } from 'services/marketpleace';
import { IDelivery } from 'types/marketpleace/delivery.interface';
import DeliveriesTopbar from './DeliveriesTopbar';
import { PRODUCT_TYPES } from 'types/marketpleace/product.interface';
import DeliveriesTable from './DeliveriesTable';
import { toast } from 'react-toastify';
import { showServerErrors } from 'utils/errorsUtils';

const Deliveries = () => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [deliveries, setDeliveries] = useState<IDelivery[]>([]);

  const [isEditMenuOpen, toggleEditMenuOpen] = useState(false);
  const [isAddMenuOpen, toggleAddMenuOpen] = useState(false);

  const [selectedDelivery, selectDelivery] = useState<IDelivery | null>(null);

  const getDeliveries = async () => {
    const deliveries = await deliveryServices.get();

    setDeliveries(deliveries);
    setIsDataLoading(false);
  };

  const removeDelivery = async (id: string) => {
    try {
      await deliveryServices.remove({ DeliveryId: id });

      getDeliveries();

      toast.success('Pomyślnie usunięto dostawę');
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  const openEditMenu = (delivery: IDelivery) => {
    if (isEditMenuOpen) {
      toggleEditMenuOpen(false);
    } else if (!isEditMenuOpen && !isAddMenuOpen) {
      selectDelivery(delivery as IDelivery);
      setTimeout(() => {
        toggleEditMenuOpen(true);
      }, 250);
    } else {
      toggleAddMenuOpen(false);
      selectDelivery(delivery as IDelivery);
      setTimeout(() => {
        toggleEditMenuOpen(true);
      }, 250);
    }
  };

  const openAddSideMenu = () => {
    if (isAddMenuOpen) {
      toggleAddMenuOpen(false);
    } else if (!isEditMenuOpen && !isAddMenuOpen) {
      toggleAddMenuOpen(true);
    } else {
      toggleEditMenuOpen(false);
      setTimeout(() => {
        toggleAddMenuOpen(true);
      }, 250);
    }
  };

  const hideSideMenu = () => {
    if (isAddMenuOpen) {
      toggleAddMenuOpen(false);
    }
    if (isEditMenuOpen) {
      setTimeout(() => {
        selectDelivery(null);
      }, 250);

      toggleEditMenuOpen(false);
    }
  };

  useEffect(() => {
    getDeliveries();
  }, []);

  return (
    <ContentContainer
      title='Dostawy'
      TopBar={
        <DeliveriesTopbar
          selectedDelivery={selectedDelivery}
          hideSideMenu={hideSideMenu}
          isAddMenuOpen={isAddMenuOpen}
          toggleAddMenuOpen={toggleAddMenuOpen}
          isEditMenuOpen={isEditMenuOpen}
          toggleEditMenuOpen={toggleEditMenuOpen}
          openAddSideMenu={openAddSideMenu}
          deliveries={deliveries}
          refreshDeliveries={getDeliveries}
        />
      }>
      <div className='h-full'>
        <DeliveriesTable
          deliveries={deliveries}
          isLoading={isDataLoading}
          openEditMenu={openEditMenu}
          removeDelivery={removeDelivery}
        />
      </div>
    </ContentContainer>
  );
};

export default Deliveries;
