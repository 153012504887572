import ContentContainer from 'components/layout/ContentContainer';
import React, { createContext, useEffect, useState } from 'react';
import OrderDetailInfo from './OrderDetailInfo';
import { IOrderDetail } from 'types/marketpleace/order.interface';
import OrderDetailTabs from './Tabs/OrderDetailTabs';
import { useParams } from 'react-router-dom';
import orderService from 'services/marketpleace/orderServices';
import { ITabsContext } from 'types/globalTypes';

export const OrderDetailTabsContext = createContext<
  ITabsContext<IOrderDetail>
>(null!);

const OrderDetail = () => {
  const { id: orderId } = useParams<{ id: string }>();

  const [order, setOrder] = useState<IOrderDetail | null>(null);

  const getOrderDetail = async () => {
    const data = await orderService.getDetail(orderId);

    console.log('Order detail: ', data);

    setOrder(data);
  };

  useEffect(() => {
    getOrderDetail();
  }, [orderId]);

  return (
    <ContentContainer title={'Zamówienie'} path='/shop/orders'>
      <div>
        <div className='flex flex-col'>
          <OrderDetailInfo order={order} />
        </div>
        {order ? (
          <OrderDetailTabsContext.Provider
            value={{ data: order, refresh: getOrderDetail }}>
            <OrderDetailTabs />
          </OrderDetailTabsContext.Provider>
        ) : null}
      </div>
    </ContentContainer>
  );
};

export default OrderDetail;
