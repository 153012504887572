import DataTable, {
  IDataTableRows,
} from 'components/common/dataTable/DataTable';
import {
  IProductDto,
  PRODUCT_ACTIVATION_STATUS,
  PRODUCT_TYPES,
  PRODUCT_VERIFICATION_STATUS,
} from 'types/marketpleace/product.interface';
import { productHeaders } from './../utils/helpers';
import { sortProductsByListSort } from 'middleware/product/sortProductsByListSort';
import ProductStatus from '../components/ProductStatus';
import { ITableProps, LIST_SORT } from 'types/globalTypes';

const ProductsTable = ({
  isDataLoading,
  containerRef,
  lastItemRef,
  sortBy,
  data,
}: ITableProps<IProductDto, LIST_SORT>) => {
  const sortedProducts = sortProductsByListSort(data, sortBy?.value);

  const rows = sortedProducts.map(
    (product: IProductDto) =>
      ({
        data: { link: `/shop/products/detail/${product.ProductId}` },
        cols: [
          product.Name,
          product.CategoryName,
          PRODUCT_TYPES[product.Type],
          `${product.Amount === 0 ? '-' : product.Amount}`,
          <ProductStatus status={product.SystemStatus} />,
          PRODUCT_VERIFICATION_STATUS[product.CurrentStatus]
        ],
      } as IDataTableRows)
  );
  return (
    <DataTable
      rows={rows}
      headers={productHeaders}
      isDataLoading={isDataLoading}
      containerRef={containerRef}
      lastItemRef={lastItemRef}
    />
  );
};

export default ProductsTable;
