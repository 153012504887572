import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Visible from "../../assets/icons/visible.png";
import NotVisible from "../../assets/icons/not-visible.png";
import SubmitButton from "../common/buttons/submitButton/SubmitButton";
import TextField from "../common/inputs/textInput/TextField";
import { useHistory } from "react-router-dom";
import { signIn } from "../../store/actions/auth";
import { loginInitValues, loginValidationSchema } from "./authHelpers";
import axios from "axios";
import { changePassword } from "../../store/actions/auth";
import styled from "styled-components";
import { useState } from "react";

export type Values = {
  Email: string;
  Password: string;
};

const ChangePasswordForm: React.FC = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [isTemporaryPasswordShown, setIsTemporaryPasswordShown] =
    useState(false);
  const [isNewPasswordShown, setIsNewPasswordShown] = useState(false);
  const [isRepeatNewPasswordShown, setIsRepeatNewPasswordShown] =
    useState(false);

  const [isStatuteChecked1, setStatuteChecked1] = useState(false);
  const [isStatuteChecked2, setStatuteChecked2] = useState(false);
  const [isStatuteChecked3, setStatuteChecked3] = useState(false);
  const [isStatuteChecked4, setStatuteChecked4] = useState(false);

  const handleStatute1CheckboxChange = () => {
    setStatuteChecked1((prev: any) => !prev);
  };
  const handleStatute2CheckboxChange = () => {
    setStatuteChecked2((prev: any) => !prev);
  };
  const handleStatute3CheckboxChange = () => {
    setStatuteChecked3((prev: any) => !prev);
  };
  const handleStatute4CheckboxChange = () => {
    setStatuteChecked4((prev: any) => !prev);
  };

  const handleSubmit = async (values: any) => {
    try {
      if (
        !isStatuteChecked1 ||
        !isStatuteChecked2 ||
        !isStatuteChecked3 ||
        !isStatuteChecked4
      )
        return toast.error("Zaakceptuj zgody!");
      if (values.NewPassword !== values.RepeatNewPassword)
        return toast.error("Hasła się nie zgadzają!");
      if (values.CurrentPassword === values.NewPassword)
        return toast.error("Nowe hasło musi się różnić od poprzedniego!");
      const resp: any = await dispatch(changePassword(values));
      toast.success("Poprawnie zmieniono hasło!");
      console.log(resp.Data.Token);
      await localStorage.setItem("token", resp.Data.Token);
      push("/");
    } catch (errors: any) {
      console.log(errors);
      // toast.error("test");
      // toast.error(errors);
      // console.log(errors);
      toast.error(errors);
    }
  };

  return (
    <div className="flex flex-1 flex-col py-8">
      <div className="flex flex-1 xl:flex-2 flex-col md:justify-center lg:h-96 mt-8 text-center px-24">
        <div
          className="text-xxl text-gray  mt-4 mb-8"
          style={{ color: "white" }}
        >
          Zmień hasło
        </div>
        <Formik initialValues={loginInitValues} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <fieldset
                className="flex flex-col gap-2 mx-auto max-w-sm"
                // disabled={isSubmitting}
              >
                <InputContainer>
                  <TextField
                    name="CurrentPassword"
                    autoComplete="off"
                    label="Tymczasowe hasło"
                    placeholder="Wprowadź hasło"
                    type={isTemporaryPasswordShown ? "text" : "password"}
                  />
                  <div
                    onClick={() => setIsTemporaryPasswordShown((prev) => !prev)}
                  >
                    <img
                      src={isTemporaryPasswordShown ? Visible : NotVisible}
                    />
                  </div>
                </InputContainer>

                <InputContainer>
                  <TextField
                    name="NewPassword"
                    autoComplete="off"
                    label="Nowe hasło"
                    placeholder="Wprowadź nowe hasło"
                    type={isNewPasswordShown ? "text" : "password"}
                  />
                  <div onClick={() => setIsNewPasswordShown((prev) => !prev)}>
                    <img src={isNewPasswordShown ? Visible : NotVisible} />
                  </div>
                </InputContainer>

                <InputContainer>
                  <TextField
                    name="RepeatNewPassword"
                    autoComplete="off"
                    label="Powtórz nowe hasło"
                    placeholder="Powtórz nowe hasło"
                    type={isRepeatNewPasswordShown ? "text" : "password"}
                  />
                  <div
                    onClick={() => setIsRepeatNewPasswordShown((prev) => !prev)}
                  >
                    <img
                      src={isRepeatNewPasswordShown ? Visible : NotVisible}
                    />
                  </div>
                </InputContainer>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "start",
                    gap: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      color: "white",
                      justifyContent: "flex-end",
                      gap: "10px",
                      fontSize: "9px",
                      width: "374px",
                    }}
                  >
                    <label
                      style={{ width: "calc(374px - 30px)" }}
                      htmlFor="statute1"
                    >
                      Oświadczam, że zapoznałam/łem się z Regulaminem oraz
                      Polityką prywatności i akceptuję ich postanowienia
                    </label>
                    <input
                      style={{ width: "20px", height: "20px" }}
                      type="checkbox"
                      name="statute1"
                      id="statute1"
                      checked={isStatuteChecked1}
                      onChange={handleStatute1CheckboxChange}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      color: "white",
                      justifyContent: "flex-end",
                      gap: "10px",
                      fontSize: "9px",
                      width: "374px",
                    }}
                  >
                    <label
                      style={{ width: "calc(374px - 30px)" }}
                      htmlFor="statute2"
                    >
                      Aktualny cennik i zasady współpracy z dnia 01.02.2024
                    </label>
                    <input
                      style={{ width: "20px", height: "20px" }}
                      type="checkbox"
                      name="statute2"
                      id="statute2"
                      checked={isStatuteChecked2}
                      onChange={handleStatute2CheckboxChange}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      color: "white",
                      justifyContent: "flex-end",
                      gap: "10px",
                      fontSize: "9px",
                      width: "374px",
                    }}
                  >
                    <label
                      style={{ width: "calc(374px - 30px)" }}
                      htmlFor="statute3"
                    >
                      Wyrażam zgodę na przekazywanie przez JUSTWIN sp. zo.o. w
                      Wysogotowie, przy ulicy Wierzbowej 31, 62-081 Wysogotowo,
                      treści marketingowych za pośrednictwem moich urządzeń
                      telekomunikacyjnych, w szczególności takich jak laptop,
                      telefon czy smartfon, zgodnie z art. 172 ust.1 ustawy z
                      dnia 16 lipca 2004 r. Prawo telekomunikacyjne.
                    </label>
                    <input
                      style={{ width: "20px", height: "20px" }}
                      type="checkbox"
                      name="statute3"
                      id="statute3"
                      checked={isStatuteChecked3}
                      onChange={handleStatute3CheckboxChange}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      color: "white",
                      justifyContent: "flex-end",
                      gap: "10px",
                      fontSize: "9px",
                      width: "374px",
                    }}
                  >
                    <label
                      style={{ width: "calc(374px - 30px)" }}
                      htmlFor="statute4"
                    >
                      Wyrażam zgodę na otrzymywanie informacji handlowej od
                      JUSTWIN sp. zo.o. z siedzibą w Wysogotowie, przy ul.
                      Wierzbowej 31, 62-081 Wysogotowo, zgodnie z art. 10 ustawy
                      z dnia 18 lipca 2002 r. o świadczeni usług drogą
                      elektroniczną.
                    </label>
                    <input
                      style={{ width: "20px", height: "20px" }}
                      type="checkbox"
                      name="statute4"
                      id="statute4"
                      checked={isStatuteChecked4}
                      onChange={handleStatute4CheckboxChange}
                    />
                  </div>
                </div>
              </fieldset>
              <SubmitButton className="mx-auto mt-6" isSubmitting={false}>
                Zmień hasło
              </SubmitButton>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePasswordForm;

const InputContainer = styled.div`
  display: flex;
  width: 384px;

  & > div:nth-of-type(1) {
    width: calc(100% - 50px);
  }
  & > div:nth-of-type(2) {
    width: 50px;
    height: 48px;
    display: flex;
    background: rgba(163, 158, 157, 0.11);
    opacity: 0.7;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & > img {
      user-select: none;
      width: 30px;
      height: 25px;
    }
  }
`;
