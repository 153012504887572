import React from "react";
import '../../../styles/animationRing.scss';

interface Props {
    text: string;
}

const LoaderRing: React.FC<Props> = ({ text }) => {
    return (
        <div className="flex w-full justify-center items-center text-center text-white opacity-70 h-12 font-medium bg-white bg-opacity-5 uppercase"
        >
            <div className="loading-ring"></div>
            <div className="pl-8">
                {text}
            </div>
        </div>
    )
}

export default LoaderRing;