import HeaderContainer from 'components/common/Containers/HeaderContainer';
import { InfoContainer } from 'styles/styles';
import React from 'react';
import {
  IOrderDelivery,
  IOrderDetailDelivery,
} from 'types/marketpleace/order.interface';
import { Country } from 'utils/constants/countires';

interface IDeliveryAddressProps {
  delivery: IOrderDetailDelivery;
}

const DeliveryAddress = ({ delivery }: IDeliveryAddressProps) => {
  return (
    <HeaderContainer title='Adres'>
      <InfoContainer>
        <div>
          <p>Kraj</p>
          <p>
            {delivery.Country === Country['Poland']
              ? 'Polska'
              : Country[delivery.Country]}
          </p>
        </div>
        <div>
          <p>Miasto</p>
          <p>{delivery.City}</p>
        </div>
        <div>
          <p>Województwo</p>
          <p>{delivery.Region}</p>
        </div>
        <div>
          <p>Kod Pocztowy</p>
          <p>{delivery.PostCode}</p>
        </div>
        <div>
          <p>Ulica</p>
          <p>{delivery.Street}</p>
        </div>
        <div>
          <p>Numer budynku</p>
          <p>{delivery.BuildingNumber}</p>
        </div>
        <div>
          <p>Numer mieszkania</p>
          <p>{delivery.FlatNumber}</p>
        </div>
      </InfoContainer>
    </HeaderContainer>
  );
};

export default DeliveryAddress;
