import DropdownPanel from 'components/common/panels/DropdownPanel';
import { GridColumn, GridTextItem } from 'styles/styles';
import { IProductStatstics } from 'types/marketpleace/product.interface';

interface ISalesStatementProps {
  statistics: IProductStatstics | null;
}

const SalesStatement = ({ statistics }: ISalesStatementProps) => {
  return (
    <DropdownPanel
      label='Zestawienie sprzedaży i zwrotów'
      initialExpanded
      dropdownStyles={{ padding: '4px 0px', background: 'none' }}>
      <GridColumn cols={3}>
        <GridTextItem isHeader>Operacja</GridTextItem>
        <GridTextItem isHeader>Ilość</GridTextItem>
        <GridTextItem isHeader>Wartość</GridTextItem>

        <GridTextItem>Sprzedaż</GridTextItem>
        <GridTextItem>{statistics?.SoldCount ?? '0'}</GridTextItem>
        <GridTextItem>
          {statistics?.SoldAmount.toFixed(2) ?? '0'} zł
        </GridTextItem>

        <GridTextItem>Zwrot</GridTextItem>
        <GridTextItem>{statistics?.RefundCount ?? '0'}</GridTextItem>
        <GridTextItem>
          {statistics?.RefundAmount.toFixed(2) ?? '0'} zł
        </GridTextItem>
      </GridColumn>
    </DropdownPanel>
  );
};

export default SalesStatement;
