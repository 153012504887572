import React, { useState } from "react";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";

const Map = ({ onLocationChange }) => {
  const [position, setPosition] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 52.5, lng: 19.5 });
  const handleMapClick = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setPosition({ lat, lng });
    const newCenter = { lat, lng };
    setMapCenter(newCenter);

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK" && results[0]) {
        const addressComponents = results[0].address_components;
        console.log("addressComponents", addressComponents);
        const locationData = {
          position: { lat, lng },
          postalCode: "",
          city: "",
          street: "",
          streetNumber: "",
          country: "",
        };

        addressComponents.forEach((component) => {
          const types = component.types;

          if (types.includes("postal_code")) {
            locationData.postalCode = component.long_name;
          }

          if (types.includes("street_number")) {
            locationData.streetNumber = component.long_name;
          }

          if (types.includes("locality")) {
            locationData.city = component.long_name;
          }

          if (types.includes("route")) {
            locationData.street = component.long_name;
          }
          if (types.includes("country")) {
            locationData.country = component.long_name;
          }
        });

        onLocationChange(locationData);
      } else {
        console.error(
          "Geocode was not successful for the following reason:",
          status,
        );
      }
    });
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyAGGVwBfTHV32jO_wHfDWseyv2PIPjinGs">
      <GoogleMap
        mapContainerStyle={{ height: "100%", width: "100%" }}
        center={mapCenter}
        zoom={7}
        onClick={handleMapClick}
        draggable={false}
      >
        {position && <Marker position={position} />}
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;
