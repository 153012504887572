import { ReactNode, RefObject } from "react";
import { useHistory } from "react-router";
import styled, { css } from "styled-components";

type DataTableRowProps = {
  row: any;
  innerRef?: RefObject<HTMLTableRowElement>;
  setClickedCyclicalEventId: any;
  toggleDraftMenuOpen: any;
};

const EventListingDataTableRow: React.FC<DataTableRowProps> = ({
  innerRef,
  row,
  setClickedCyclicalEventId,
  toggleDraftMenuOpen,
}) => {
  const { push } = useHistory();
  const handleRowClick = () => {
    if (row.isCyclical) {
      const parts = row.data.link.split("/");
      const id = parts[parts.length - 1];
      toggleDraftMenuOpen(true);
      setClickedCyclicalEventId(id);
    } else {
      if (row.data?.link) {
        push(row.data.link);
      }
    }
  };

  return (
    <StyledTr
      isCyclical={row.isCyclical}
      ref={innerRef}
      onClick={handleRowClick}
      data-clickable={!!row.data?.link}
    >
      {row.cols.map((col: any, idx: any) => {
        const title = typeof col === "string" ? col : "";
        return (
          <StyledTd
            isCyclical={row.isCyclical}
            style={{ opacity: 1 }}
            // colSpan={idx === 3 ? 3 : 1}
            colSpan={1}
            key={idx}
            title={title}
          >
            {/* <div> */}
            <div style={{ opacity: 0.7 }}>{col || "- brak -"}</div>
            {/* </div> */}
          </StyledTd>
        );
      })}
    </StyledTr>
  );
};

export default EventListingDataTableRow;

const StyledTd = styled.td<{ isCyclical: boolean }>``;

const StyledTr = styled.tr<{ isCyclical: boolean }>`
  /* ${({ isCyclical }) =>
    isCyclical &&
    css`
      & {
        box-shadow: inset 0 0 5px rgb(236, 29, 255) !important;
      }
    `} */
`;
