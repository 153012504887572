import styled from "styled-components";

export const Container = styled.div`
  height: 92%;
  width: 100%;
  /* background: white; */
  /* padding: 30px; */
  color: white;
  overflow: auto;

  a {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 400;
    width: 100%;
    height: 100%;
    background: rgb(2, 106, 188);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 4px 8px;
  }

  table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    border-top: 1px solid #e3e8e9;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.05em;

    thead > tr > th {
      padding: 12px 8px;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.05em;
      background: rgba(2, 2, 15, 0.3);
    }

    td {
      border-top: 1px solid #e3e8e9;
      padding: 12px 8px;
    }

    td,
    th {
      padding: 12px 8px;
    }

    thead > tr > th:nth-of-type(1),
    tbody > tr > td:nth-of-type(1) {
      width: calc(100px);
      padding-left: 30px;
    }
    tbody > tr > td {
      background: rgba(163, 158, 157, 0.05);
    }
    thead > tr > th:nth-of-type(2),
    tbody > tr > td:nth-of-type(2) {
      width: calc(60% - 100px - 75px - 35px);
    }

    thead > tr > th:nth-of-type(3),
    tbody > tr > td:nth-of-type(3) {
      width: 100px;
    }

    thead > tr > th:nth-of-type(4),
    tbody > tr > td:nth-of-type(4) {
      width: 75px;
      color: #4f90ff;
      font-weight: 500;
      cursor: pointer;
    }

    thead > tr > th:nth-of-type(5),
    tbody > tr > td:nth-of-type(5) {
      width: 35px;
      cursor: pointer;
      /* padding-top: 20px; */
    }
  }
  table > thead > tr > th {
    padding: 12px 0px;
    height: 60px;
  }
`;
