import HeaderContainer from 'components/common/Containers/HeaderContainer';
import InfoContainerItem from 'components/common/Containers/InfoContainerItem';
import { InfoContainer, StyledTableInput } from 'styles/styles';
import { Country } from 'utils/constants/countires';
import { IAcademyInformationsProps } from '../informations.interface';
import { IAddress } from 'types/address.interface';

interface IAcademyAddressProps extends IAcademyInformationsProps {
  address: IAddress;
}

const AcademyAddress = ({ address, header }: IAcademyAddressProps) => {
  return (
    <HeaderContainer title={header ?? 'Adres'}>
      <InfoContainer className='w-full'>
        <InfoContainerItem
          header='Kraj'
          content={
            address.Country === Country['Poland']
              ? 'Polska'
              : Country[address.Country]
          }
        />
        <InfoContainerItem header='Kod pocztowy' content={address.PostCode} />
        <InfoContainerItem header='Województwo' content={address.Region} />
        <InfoContainerItem header='Miasto' content={address.City} />
        <InfoContainerItem header='Ulica' content={address.Street} />
        <InfoContainerItem
          header='Numer Budynku'
          content={address.BuildingNumber}
        />
        <InfoContainerItem
          header='Numer Mieszkania'
          content={address.FlatNumber}
        />
      </InfoContainer>
    </HeaderContainer>
  );
};

export default AcademyAddress;
