import TabContent from 'components/common/tabs/TabContent';
import ApplyPass from './ApplyPass';
import { IPlayerDetail, TRANSFER_LEVEL } from 'types/transfer/player.interface';
import { IPassPeriod, IPlayerHistoryPass } from 'types/transfer/pass.interface';
import { useEffect, useState } from 'react';
import passService from 'services/transfer/passServices';
import HeaderContainer from 'components/common/Containers/HeaderContainer';
import AppliedPassesHistoryTable from './AppliedPassesHistoryTable';
import { isValidDateRange } from 'utils/dateUtils';
import { compareArray } from 'utils/baseUtils';

interface IPlayerPassesProps {
  player: IPlayerDetail;
  refresh: () => Promise<void>;
}

const PlayerPasses = ({ player, refresh }: IPlayerPassesProps) => {
  const [passesHistory, setPassesHistory] = useState<IPlayerHistoryPass[]>([]);

  const isMember = player.Level === TRANSFER_LEVEL['Członkowska'];

  const appliedPass = player.AppliedPasses[0];

  const newestHistoryPass = passesHistory.sort((a, b) =>
    compareArray<IPassPeriod>(a.Period, b.Period, 'StartAt')
  )[0];

  const getPlayerPassesHistory = async () => {
    const { Items } = await passService.getPlayerHistory(player.PlayerId);

    setPassesHistory(Items);
  };

  const refreshPasses = async () => {
    await refresh();
    await getPlayerPassesHistory();
  };

  useEffect(() => {
    getPlayerPassesHistory();
  }, []);

  useEffect(() => {
    console.log(newestHistoryPass);
  }, [newestHistoryPass]);
  return (
    <TabContent id='passes'>
      <div
        className='flex flex-col gap-4 w-full overflow-x-auto  md:overflow-x-hidden'
      >
        {isMember && (
          <ApplyPass
            appliedPass={appliedPass}
            refresh={refreshPasses}
            newestHistoryPass={newestHistoryPass}
          />
        )}
        <HeaderContainer title='Historia składek' className='h-16 '>
          <AppliedPassesHistoryTable passesHistory={passesHistory} />
        </HeaderContainer>
      </div>
    </TabContent>
  );
};

export default PlayerPasses;
