import UserListItem from "./UserListItem";
import { IUserManagement, UserInterface } from "../../types/userTypes";

interface IUsersListProps {
  users: Array<UserInterface>;
  lastItemRef: any;
  containerRef: any;
  sortBy: any;
}

const UsersList: React.FC<IUsersListProps> = ({
  users,
  lastItemRef,
  containerRef,
  sortBy,
}) => {
  const compare = (a: any, b: any, sort: any) => {
    if (a[sort] < b[sort]) {
      return 1;
    }
    if (a[sort] > b[sort]) {
      return -1;
    }
    return 0;
  };

  const sortingFunction = (type: number, array: any) => {
    switch (type) {
      case 0:
        return array.slice().reverse();
      case 1:
        return array;
      case 2:
        return array
          .slice()
          .sort((a: any, b: any) => compare(a, b, "FirstName"))
          .reverse();
      case 3:
        return array
          .slice()
          .sort((a: any, b: any) => compare(a, b, "FirstName"));
      case 4:
        return array
          .slice()
          .sort((a: any, b: any) => compare(a, b, "LastName"))
          .reverse();
      case 5:
        return array
          .slice()
          .sort((a: any, b: any) => compare(a, b, "LastName"));
      case 6:
        return array
          .slice()
          .sort((a: any, b: any) => compare(a, b, "Email"))
          .reverse();
      case 7:
        return array.slice().sort((a: any, b: any) => compare(a, b, "Email"));
      default:
        return array;
    }
  };

  const sortedArray = sortingFunction(sortBy?.value, users);

  return (
    <div
      style={{
        gridTemplateColumns: "repeat(auto-fit, minmax(550px, 1fr))",
        // gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "min-content",
        gap: "4px",
      }}
      className="flex flex-col md:grid"
      ref={containerRef}
    >
      {sortedArray.map((user: any, index: any) => {
        const isLast = index === users.length - 1;
        return isLast ? (
          <UserListItem key={user.UserId} user={user} innerRef={lastItemRef} />
        ) : (
          <UserListItem key={user.UserId} user={user} />
        );
      })}
    </div>
  );
};

export default UsersList;
