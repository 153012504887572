import {
  IPass,
  IPassTransaction,
  PASSES_SORT_LIST,
} from 'types/transfer/pass.interface';

export const sortPassesByListSort = (
  array: IPassTransaction[],
  type?: PASSES_SORT_LIST
) => {
  switch (type) {
    case 0:
      return array.slice().reverse();
    case 1:
      return array;
    default:
      return array;
  }
};
