import deliveryServices from 'services/marketpleace/deliveryServices';
import { PRODUCT_TYPES } from 'types/marketpleace/product.interface';

export const isDeliveryExists = async (
  deliveryType: PRODUCT_TYPES
): Promise<boolean> => {
  const deliveries = await deliveryServices.get();

  const deliveryExists = deliveries.find(
    delivery => delivery.AvailableFor === deliveryType
  );

  return !!deliveryExists;
};
